import { Component } from '@angular/core';
import { PopupService } from 'src/app/common/popup/popup.service';
import { Router } from '@angular/router';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-issue-confirm-popup',
  templateUrl: './issue-confirm.popup.html',
  styleUrls: ['./issue-confirm.popup.scss']
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class IssueConfirmPopup {
  constructor(
    public dialogRef: MatDialogRef<IssueConfirmPopup>,
    public popUpService: PopupService,
    public router: Router
  ) {}

  confirm() {
    this.dialogRef.close();
  }
}
