import { RoutesRecognized } from '@angular/router';

// there's default redirect from / to /dashboard which is expected behaviour. with or without query params.
// /#state and /#error accounts for the sign in redirect which follows the same rule described above but also has the state hash in it.
// We're using RoutesRecognized instead of NavigationEnd, so we can capture these events even during an auth redirect.
// /signin and /signup are expected to redirect to the dashboard
export function IsCatchAllRedirect(event: RoutesRecognized) {
  const { url: originalUrl, urlAfterRedirects: newUrl } = event;

  return (
    originalUrl !== newUrl &&
    originalUrl !== '/' &&
    !(originalUrl === '/on-assignment' && newUrl === '/on-assignment/dashboard') &&
    !originalUrl.match(/^\/#(error|state)=/) &&
    !originalUrl.match(/^\/(signin|signup)/i) &&
    !(originalUrl.startsWith('/?') && newUrl.startsWith('/dashboard?'))
  );
}
