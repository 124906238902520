<ion-app>
  <ng-progress *ngIf="loaded()"></ng-progress>
  <div class="viewport" *ngIf="!forceAppUpdateAvailable; else updateAvailable">
    <app-no-connection class="viewport" *ngIf="noConnection()"></app-no-connection>
    <div class="loading-image-area" *ngIf="!loaded()">
      <mat-progress-spinner color="accent" mode="indeterminate"></mat-progress-spinner>
      <div class="message"></div>
    </div>
    <div fxFlex class="viewport" fxLayout="column">
      <router-outlet class="router-flex"></router-outlet>
    </div>
  </div>
  <ng-template #updateAvailable>
    <div class="update-available">
      <hc-body class="message"> There is an update available for the application that must be installed before continuing! Please update via your App Store. </hc-body>
      <hc-button (click)="openAppStore()">Go to App Store</hc-button>
    </div>
  </ng-template>
</ion-app>
