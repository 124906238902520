import { Action } from '@ngrx/store';
import { IQuestionsLookups, ISkillAreaInfoLookups, ILookups, IProfessionalHierarchy } from 'src/app/common';

export enum ELookupsActions {
  GetLookups = '[LOOKUPS] get lookups',
  GetLookupsSuccess = '[LOOKUPS] get lookups success',
  GetLookupsError = '[LOOKUPS] get lookups error',
  GetSkillAreaInfoLookups = '[LOOKUPS] get skill area info lookups',
  GetSkillAreaInfoLookupsSuccess = '[LOOKUPS] get skill area info lookups success',
  GetSkillAreaInfoLookupsError = '[LOOKUPS] get skill area info lookups error',
  GetQuestionLookups = '[LOOKUPS] get question lookups',
  GetQuestionLookupsSuccess = '[LOOKUPS] get question lookups success',
  GetQuestionLookupsError = '[LOOKUPS] get question lookups error',
  GetProfessionalHierarchy = '[LOOKUPS] get professional hierarchy lookups',
  GetProfessionalHierarchySuccess = '[LOOKUPS] get professional hierarchy lookups success',
  GetProfessionalHierarchyError = '[LOOKUPS] get professional hierarchy lookups error',
  GetINLookups = '[LOOKUPS] get IN lookups',
  GetINLookupsSuccess = '[LOOKUPS] get IN lookups success',
  GetINLookupsError = '[LOOKUPS] get IN lookups error'
}

export class GetLookups implements Action {
  public readonly type = ELookupsActions.GetLookups;

  constructor() {}
}

export class GetLookupsSuccess implements Action {
  public readonly type = ELookupsActions.GetLookupsSuccess;

  constructor(public payload: ILookups) {}
}

export class GetLookupsError implements Action {
  public readonly type = ELookupsActions.GetLookupsError;

  constructor(public error: Error) {}
}

export class GetSkillAreaInfoLookups implements Action {
  public readonly type = ELookupsActions.GetSkillAreaInfoLookups;

  constructor() {}
}

export class GetSkillAreaInfoLookupsSuccess implements Action {
  public readonly type = ELookupsActions.GetSkillAreaInfoLookupsSuccess;

  constructor(public payload: ISkillAreaInfoLookups) {}
}

export class GetSkillAreaInfoLookupsError implements Action {
  public readonly type = ELookupsActions.GetSkillAreaInfoLookupsError;

  constructor(public error: Error) {}
}

export class GetQuestionLookups implements Action {
  public readonly type = ELookupsActions.GetQuestionLookups;

  constructor() {}
}

export class GetQuestionLookupsSuccess implements Action {
  public readonly type = ELookupsActions.GetQuestionLookupsSuccess;

  constructor(public payload: IQuestionsLookups) {}
}

export class GetQuestionLookupsError implements Action {
  public readonly type = ELookupsActions.GetQuestionLookupsError;

  constructor(public error: Error) {}
}

export class GetProfessionalHierarchy implements Action {
  public readonly type = ELookupsActions.GetProfessionalHierarchy;

  constructor() {}
}

export class GetProfessionalHierarchySuccess implements Action {
  public readonly type = ELookupsActions.GetProfessionalHierarchySuccess;

  constructor(public payload: IProfessionalHierarchy[]) {}
}

export class GetProfessionalHierarchyError implements Action {
  public readonly type = ELookupsActions.GetProfessionalHierarchyError;

  constructor(public error: Error) {}
}

export class GetINLookups implements Action {
  public readonly type = ELookupsActions.GetINLookups;

  constructor() {}
}

export class GetINLookupsSuccess implements Action {
  public readonly type = ELookupsActions.GetINLookupsSuccess;

  constructor(public payload: ILookups) {}
}

export class GetINLookupsError implements Action {
  public readonly type = ELookupsActions.GetINLookupsError;

  constructor(public error: Error) {}
}

export type LookupsActions =
  | GetLookups
  | GetLookupsSuccess
  | GetLookupsError
  | GetSkillAreaInfoLookups
  | GetSkillAreaInfoLookupsSuccess
  | GetSkillAreaInfoLookupsError
  | GetQuestionLookups
  | GetQuestionLookupsSuccess
  | GetQuestionLookupsError
  | GetProfessionalHierarchy
  | GetProfessionalHierarchySuccess
  | GetProfessionalHierarchyError
  | GetINLookups
  | GetINLookupsSuccess
  | GetINLookupsError;
