<app-loading-screen-spinner [show]="lookupsLoading$ | async"></app-loading-screen-spinner>
<div *ngIf="(lookupsLoading$ | async) === false" id="reference-questionnaire-page">
  <div *ngIf="!isInvalid">
    <div class="body">
      <form [formGroup]="form" class="form" fxLayout="column">
        <div class="uneditable" fxLayout="column">
          <div class="header">Reference Information</div>
          <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap.gt-sm="8px">
            <mat-form-field fxFlex>
              <input type="text" matInput formControlName="fullName" placeholder="Full Name" readonly />
            </mat-form-field>

            <mat-form-field>
              <mat-select name="position" formControlName="positionCode" placeholder="Position">
                <mat-option *ngFor="let item of positionTitles" [value]="item.id">{{ item.name }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap.gt-sm="8px">
            <mat-form-field fxFlex>
              <input type="text" matInput formControlName="email" placeholder="Email" readonly />
            </mat-form-field>

            <mat-form-field fxFlex>
              <input type="tel" matInput formControlName="phone" placeholder="Phone #" readonly />
            </mat-form-field>
          </div>

          <div class="header">Candidate Information</div>
          <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap.gt-sm="8px">
            <mat-form-field fxFlex>
              <input type="text" matInput formControlName="candidateFullName" placeholder="Candidate Full Name" readonly />
            </mat-form-field>
          </div>
          <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap.gt-sm="8px">
            <mat-form-field fxFlex>
              <input type="text" matInput formControlName="candidateEmail" placeholder="Candidate Email" readonly />
            </mat-form-field>

            <mat-form-field fxFlex>
              <input type="text" matInput formControlName="candidatePhone" placeholder="Candidate Phone #" readonly />
            </mat-form-field>
          </div>

          <mat-form-field fxFlex>
            <mat-select formControlName="candidateProfession" placeholder="Candidate Profession">
              <mat-option *ngFor="let item of professions" [value]="item.id">{{ item.name }}</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field>
            <input type="text" matInput formControlName="facilityName" placeholder="Facility Name" readonly />
          </mat-form-field>
          <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap.gt-sm="8px">
            <mat-form-field fxFlex>
              <input type="text" matInput formControlName="facilityCity" placeholder="Facility City" readonly />
            </mat-form-field>

            <mat-form-field fxFlex="30%">
              <mat-select formControlName="facilityState" placeholder="Facility State">
                <mat-option *ngFor="let item of states" [value]="item.id">{{ item.code }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap.gt-xs="8px">
            <mat-form-field fxFlex>
              <input matInput [matDatepicker]="picker1" placeholder="Start Date" formControlName="startDate" readonly />
              <mat-datepicker-toggle matSuffix [for]="picker1" disabled></mat-datepicker-toggle>
              <mat-datepicker #picker1></mat-datepicker>
            </mat-form-field>

            <mat-form-field fxFlex>
              <input matInput [matDatepicker]="picker2" placeholder="End Date" formControlName="endDate" readonly />
              <mat-datepicker-toggle matSuffix [for]="picker2" disabled></mat-datepicker-toggle>
              <mat-datepicker #picker2></mat-datepicker>
            </mat-form-field>
          </div>
        </div>
        <div class="question-area" fxLayout="column">
          <div class="header">Performance Evaluation</div>
          <mat-form-field>
            <mat-select placeholder="Clinical Competence" formControlName="clinicalCompetence">
              <mat-option *ngFor="let item of performanceScale" [value]="item.id">
                {{ item.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <mat-select placeholder="Follows coordinated plan of patient care " formControlName="patientCare">
              <mat-option *ngFor="let item of performanceScale" [value]="item.id">
                {{ item.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <mat-select placeholder="Follows safety & emergency procedures" formControlName="safety">
              <mat-option *ngFor="let item of performanceScale" [value]="item.id">
                {{ item.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <mat-select placeholder="Follows facility policies & procedures" formControlName="policies">
              <mat-option *ngFor="let item of performanceScale" [value]="item.id">
                {{ item.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <mat-select placeholder="Overall Quality of work" formControlName="quality">
              <mat-option *ngFor="let item of performanceScale" [value]="item.id">
                {{ item.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="question-area" fxLayout="column">
          <div class="header">Personal Evaluation</div>
          <mat-form-field>
            <mat-select placeholder="Adaptability to work situations" formControlName="adaptability">
              <mat-option *ngFor="let item of performanceScale" [value]="item.id">
                {{ item.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <mat-select placeholder="Communication Skills" formControlName="communication">
              <mat-option *ngFor="let item of performanceScale" [value]="item.id">
                {{ item.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <mat-select placeholder="Attendance and punctuality" formControlName="attendance">
              <mat-option *ngFor="let item of performanceScale" [value]="item.id">
                {{ item.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <mat-select placeholder="Attitude" formControlName="attitude">
              <mat-option *ngFor="let item of performanceScale" [value]="item.id">
                {{ item.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <mat-select placeholder="Ability to work with others" formControlName="workWithOthers">
              <mat-option *ngFor="let item of performanceScale" [value]="item.id">
                {{ item.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <mat-select placeholder="Overall Professionalism" formControlName="professionalism">
              <mat-option *ngFor="let item of performanceScale" [value]="item.id">
                {{ item.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <mat-select placeholder="Is this candidate still employed at the facility?" formControlName="currentPosition">
              <mat-option *ngFor="let item of yesNo" [value]="item.id">
                {{ item.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <mat-select placeholder="Is this Candidate Eligible for Rehire" formControlName="rehire">
              <mat-option *ngFor="let item of yesNo" [value]="item.id">
                {{ item.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <div fxLayout="row">
            <mat-checkbox fxLayoutAlign="start center" formControlName="hireFire" color="primary"></mat-checkbox>
            <div class="checkbox">During the employee's tenure, did you have the ability to hire/fire?</div>
          </div>
        </div>
        <div>
          <mat-form-field fxFlex hintLabel="Max 500 characters">
            <textarea
              [style.min-height]="'200px'"
              #input
              maxlength="500"
              matInput
              formControlName="comments"
              placeholder="Please provide any additional comments below"
            ></textarea>
            <mat-hint align="end">{{ input.value?.length || 0 }}/500</mat-hint>
          </mat-form-field>
        </div>
      </form>
      <div fxLayout="row" fxLayoutAlign="end start" class="width">
        <div fxLayoutGap="10px" fxLayout="row">
          <button id="referenceQuestionnaireSubmit" mat-raised-button color="accent" type="submit" [disabled]="!form.valid" (click)="onSubmit()">Submit</button>
        </div>
      </div>
    </div>
  </div>

  <div fxLayoutAlign="center center" *ngIf="isInvalid" class="invalid-message">
    {{ errorMessage }}
  </div>
</div>
