<div fxLayout="column" class="full-height">
  <div fxLayout="column" class="top-section">
    <div fxLayoutAlign="end end">
      <button id="removeSubmittalPopupclose" mat-icon-button class="global-x-btn">
        <mat-icon (click)="cancel()">close</mat-icon>
      </button>
    </div>
  </div>
  <!-- STAGE OF NEW APPLICATION  -->
  <div *ngIf="stageId === newApplicationId" class="main" fxLayout="column" fxLayoutAlign="stretch center" fxLayoutGap="4px" fxFlex>
    <div class="title">Why do you not want to submit this application?</div>
    <mat-form-field>
      <mat-label>Select Reason</mat-label>
      <mat-select [formControl]="reasonControl">
        <mat-option *ngFor="let substage of assignmentSubstageList" [value]="substage.id">
          {{ substage.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <div *ngIf="reasonControl.value === CandidateToEnterOtherReason" style="width: 100%">
      <mat-form-field>
        <textarea matInput [formControl]="otherText" class="otherText"></textarea>
      </mat-form-field>
    </div>
    <div fxLayoutGap="8px" fxLayout="column">
      <div *ngIf="reasonControl.value === CandidateDeclinedOfferWithOtherCompany || reasonControl.value === CandidateDeclinedPermPosition" class="max-width">
        <mat-checkbox [formControl]="bulkRemoveControl" class="max-width">Withdraw all others too</mat-checkbox>
      </div>
      <div fxFlex></div>
      <button id="removeSubmittalPopupDeleteApplication" mat-raised-button color="accent" (click)="submit()">Delete This Application</button>
      <button id="removeSubmittalPopupDeleteCancel" mat-button color="accent" (click)="cancel()">Cancel</button>
    </div>
  </div>
  <!-- IN PROGRESS + OTHER STAGE -->
  <div *ngIf="stageId !== newApplicationId" class="main" fxLayout="column" fxLayoutAlign="stretch center" fxLayoutGap="4px" fxFlex>
    <div class="title">Why are you withdrawing?</div>
    <div class="content">We'll take care of it from here.</div>
    <div class="content spacing">But you cannot reapply to this job.</div>
    <mat-form-field>
      <mat-label>Select Reason</mat-label>
      <mat-select [formControl]="reasonControl">
        <mat-option *ngFor="let substage of assignmentSubstageList" [value]="substage.id">
          {{ substage.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <div *ngIf="reasonControl.value === CandidateToEnterOtherReason" style="width: 100%">
      <mat-form-field>
        <textarea matInput [formControl]="otherText" class="otherText"></textarea>
      </mat-form-field>
    </div>
    <div fxLayoutGap="8px" fxLayout="column">
      <div *ngIf="reasonControl.value === CandidateDeclinedOfferWithOtherCompany || reasonControl.value === CandidateDeclinedPermPosition" class="max-width">
        <mat-checkbox [formControl]="bulkRemoveControl" class="max-width">Withdraw all others too</mat-checkbox>
      </div>
      <div fxFlex></div>
      <button id="removeSubmittalPopupWithdrawApplication" mat-raised-button color="accent" (click)="submit()">Withdraw My Application</button>
      <button id="removeSubmittalPopupWithdrawCancel" mat-button color="accent" (click)="cancel()">Cancel</button>
    </div>
  </div>
</div>
