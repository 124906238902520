import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, switchMap, tap } from 'rxjs/operators';
import { FileUploadTargets } from 'src/app/common';
import { getDateInFutureWithMinutes } from 'src/app/common/functions/date-manipulations';
import { NurseTask, VaccineTask } from 'src/app/common/models/db-objects';
import { DocumentHelperService, NursePortalApi } from 'src/app/services';
import { IAppState } from '../app/app.state';
import { ECertificationsActions } from '../certifications/certifications.actions';
import {
  ETasksActions,
  GetCovidVaccineError,
  GetCovidVaccineSuccess,
  GetFluVaccineError,
  GetFluVaccineSuccess,
  GetTasks,
  GetTasksError,
  GetTasksSuccess,
  TasksActions,
  UploadCovidVaccine,
  UploadCovidVaccineError,
  UploadCovidVaccineSuccess,
  UploadFluVaccine,
  UploadFluVaccineError,
  UploadFluVaccineSuccess
} from './tasks.actions';
import { selectTasks, selectTasksExpirationDate, selectTasksExpired } from './tasks.selectors';
import { NotificationService } from 'hc-design-system-lib';

@Injectable({
  providedIn: 'root'
})
export class TasksEffects {
  constructor(
    private actions$: Actions,
    private _store: Store<IAppState>,
    private _api: NursePortalApi,
    private _documentHelperService: DocumentHelperService,
    private _notificationService: NotificationService
  ) {}

  getTasks$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<Action>(
        ETasksActions.GetTasks,
        ECertificationsActions.AddCertificationSuccess,
        ECertificationsActions.UpdateCertificationSuccess,
        ECertificationsActions.DeleteCertificationSuccess
      ),
      concatLatestFrom(() => [this._store.select(selectTasks), this._store.select(selectTasksExpired), this._store.select(selectTasksExpirationDate)]),
      switchMap(([action, tasks, isExpired, expiration]: [GetTasks, NurseTask[], boolean, Date]) => {
        if (!isExpired && !action.forceUpdateCache) {
          return of(new GetTasksSuccess({ tasks, expiration }));
        }

        return this._api.getMyTasks(action.includePending).pipe(
          switchMap(response => {
            const newExpirationDate = getDateInFutureWithMinutes(10);
            const payload = {
              tasks: response ?? [],
              expiration: newExpirationDate
            };
            return of(new GetTasksSuccess(payload));
          }),
          catchError(error => of(new GetTasksError(error)))
        );
      })
    );
  });

  uploadVaccine$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<TasksActions>(ETasksActions.UploadFluVaccine),
      exhaustMap((action: UploadFluVaccine) =>
        this._documentHelperService._getDocumentObservable(action.options, action.files).pipe(
          map((response: any) => {
            let fluTask: VaccineTask;
            if (response.length > 1) {
              fluTask = {
                documentUrl: response[0].returnValue.fileDownloadUrl,
                qualificationID: response[0].returnValue.id
              } as VaccineTask;
            } else {
              fluTask = {
                documentUrl: response.returnValue.fileDownloadUrl,
                qualificationID: response.returnValue.id
              } as VaccineTask;
            }
            return new UploadFluVaccineSuccess(fluTask);
          }),
          catchError((error: Error) => of(new UploadFluVaccineError(error)))
        )
      )
    );
  });

  updateSummarySuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType<TasksActions>(ETasksActions.UploadFluVaccineSuccess),
        tap(() => this._notificationService.showNotification('Your response was saved successfully!', 'success'))
      );
    },
    { dispatch: false }
  );

  updateSummaryError$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType<TasksActions>(ETasksActions.UploadFluVaccineError),
        tap(() => {
          this._notificationService.showNotification(`We were unable to save your changes. Please try again.`, 'error');
        })
      );
    },
    { dispatch: false }
  );

  getFluVaccine$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<TasksActions>(ETasksActions.GetFluVaccine),
      exhaustMap(() =>
        this._api.getVaccinationFile(FileUploadTargets.FluVaccination.toString()).pipe(
          map((response: any) => {
            if (!response) {
              return new GetFluVaccineSuccess(null);
            }

            const fluTask: VaccineTask = {
              documentUrl: response.sharepointUrl,
              qualificationID: response.qualificationId
            } as VaccineTask;
            return new GetFluVaccineSuccess(fluTask);
          }),
          catchError((error: Error) => of(new GetFluVaccineError(error)))
        )
      )
    );
  });

  getCovidVaccine$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<TasksActions>(ETasksActions.GetCovidVaccine),
      exhaustMap(() =>
        this._api.getVaccinationFile(FileUploadTargets.CovidVaccination.toString()).pipe(
          map((response: any) => {
            if (!response) {
              return new GetCovidVaccineSuccess(null);
            }
            const covidTask: VaccineTask = {
              documentUrl: response.sharepointUrl,
              qualificationID: response.qualificationId
            } as VaccineTask;
            return new GetCovidVaccineSuccess(covidTask);
          }),
          catchError((error: Error) => of(new GetCovidVaccineError(error)))
        )
      )
    );
  });

  uploadCovidVaccine$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<TasksActions>(ETasksActions.UploadCovidVaccine),
      exhaustMap((action: UploadCovidVaccine) =>
        this._documentHelperService._getDocumentObservable(action.options, action.files).pipe(
          map((response: any) => {
            let covidTask: VaccineTask;
            if (response.length > 1) {
              covidTask = {
                documentUrl: response[0].returnValue.fileDownloadUrl,
                qualificationID: response[0].returnValue.id
              } as VaccineTask;
            } else {
              covidTask = {
                documentUrl: response.returnValue.fileDownloadUrl,
                qualificationID: response.returnValue.id
              } as VaccineTask;
            }
            return new UploadCovidVaccineSuccess(covidTask);
          }),
          catchError((error: Error) => of(new UploadCovidVaccineError(error)))
        )
      )
    );
  });

  updateCovidSummarySuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType<TasksActions>(ETasksActions.UploadCovidVaccineSuccess),
        tap(() => this._notificationService.showNotification('Your response was saved successfully!', 'success'))
      );
    },
    { dispatch: false }
  );

  updateCovidSummaryError$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType<TasksActions>(ETasksActions.UploadCovidVaccineError),
        tap(() => {
          this._notificationService.showNotification(`We were unable to save your changes. Please try again.`, 'error');
        })
      );
    },
    { dispatch: false }
  );
}
