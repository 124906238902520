<ng-container
  *ngIf="{
    nurseLoading: nurseLoading$ | async,
    nurseLoadingIN: nurseINLoading$ | async,
    avatarConfig: avatarConfiguration$ | async,
    avatarCardDetails: avatarCardDetails$ | async,
    zipCodes: zipCodeDropdown$ | async,
    statesDropdown: statesDropdown$ | async,
    showTravel: showTravelContent$ | async,
    showInternational: showInternationalContent$ | async,
    isMobile: isMobile$ | async
  } as obs"
>
  <app-loading-screen-spinner *ngIf="obs.nurseLoading || obs.nurseINLoading || !form; else aboutMeForm" [show]="true"></app-loading-screen-spinner>
  <ng-template #aboutMeForm>
    <div class="tasks-container">
      <app-base-form
        *ngIf="form"
        #baseForm
        [form]="form"
        [isLoadingObs]="nurseLoading$"
        [isSavingObs]="updateAboutMeTaskSaving$"
        [formHeaderAttributes]="formHeaderAttributes"
        (saveableEmitter)="onSave($event)"
      >
        <ng-container ngProjectAs="[formControls]">
          <div fxLayout="column" fxLayoutGap="16px">
            <hc-avatar-card
              *ngIf="!!obs.avatarConfig"
              [avatarConfiguration]="obs.avatarConfig"
              [avatarCardDetails]="obs.avatarCardDetails"
              (editAvatarClicked)="!isNative ? profileInput.click() : profileInputMobile.click()"
            ></hc-avatar-card>
            <hc-icon-prompt-button id="editContactInfoButton" *ngIf="!showContactInformation" icon="edit" [small]="true" (promptClick)="enableContactInformationSection()">
              Edit contact information
            </hc-icon-prompt-button>
            <div fxLayout="column">
              <ng-container *ngIf="showContactInformation">
                <hc-heading size="h4" styles="margin-bottom: 16px;">Contact Information</hc-heading>
                <hc-input
                  id="firstNameInput"
                  placeholder="Enter first name"
                  label="First Name"
                  [errorMessage]="baseForm.getErrorMessage(form?.controls?.firstName, 'First Name')"
                  [control]="form?.controls?.firstName"
                  [required]="true"
                ></hc-input>
                <hc-input
                  id="lastNameInput"
                  placeholder="Enter last name"
                  label="Last Name"
                  [errorMessage]="baseForm.getErrorMessage(form?.controls?.lastName, 'Last Name')"
                  [control]="form?.controls?.lastName"
                  [required]="true"
                ></hc-input>
                <hc-input id="emailInput" placeholder="Enter email" label="Email" [control]="form?.controls?.email" [required]="true"></hc-input>
                <hc-masked-input
                  id="phoneInput"
                  placeholder="Enter phone number"
                  label="Phone"
                  [errorMessage]="baseForm.getErrorMessage(form?.controls?.mobilePhone, 'Phone Number')"
                  [control]="form?.controls?.mobilePhone"
                  [mask]="phoneMask"
                  [required]="true"
                ></hc-masked-input>
              </ng-container>
              <div *ngIf="obs.showTravel">
                <div class="padding-bottom-20">
                  <hc-heading [size]="h4">Address Information</hc-heading>
                </div>
                <div>
                  <hc-body [size]="small" class="padding-bottom-8">Tax Home Address</hc-body>
                  <div class="padding-bottom-20 update-tax-home-address">
                    <hc-body *ngIf="taxAddressDeclared === undefined"
                      >Update via
                      <span class="link" (click)="goToQuestionnaire()">Background Questionnaire</span>
                    </hc-body>
                    <hc-body *ngIf="taxAddressDeclared === yesId">
                      {{ taxAddress }}
                    </hc-body>
                    <hc-body *ngIf="taxAddressDeclared === noId"> No permanent tax residence </hc-body>
                    <mat-icon (click)="openInfoModal()"> info </mat-icon>
                  </div>
                  <mat-divider class="padding-bottom-20"></mat-divider>
                </div>
                <div>
                  <div class="padding-bottom-20">
                    <hc-body [size]="small">Mailing Address</hc-body>
                  </div>
                  <div class="padding-bottom-20">
                    <hc-checkbox text="Same as Tax Home Address" [control]="isMailingSameAsTaxAddressControl" (checkedChange)="sameAsTaxAddressChanged($event)"></hc-checkbox>
                  </div>
                  <div *ngIf="showAddressFields">
                    <hc-input
                      id="address1Input"
                      placeholder="Enter address"
                      label="Address Line 1"
                      [required]="true"
                      [errorMessage]="baseForm.getErrorMessage(form?.controls?.streetAddress1, 'Address')"
                      [control]="form?.controls?.streetAddress1"
                    >
                    </hc-input>
                    <hc-input id="address2Input" placeholder="Enter address" label="Address Line 2" [control]="form?.controls?.streetAddress2"></hc-input>
                    <hc-dropdown
                      id="zipInput"
                      placeholder="Enter zip code"
                      label="Zip Code"
                      [required]="true"
                      [control]="form?.controls?.zipCode"
                      [data]="obs.zipCodes"
                      [isDataPrimitive]="false"
                      [errorMessage]="baseForm.getErrorMessage(form?.controls?.zipCode, 'Zip Code')"
                      [useDataFilter]="false"
                      [dropdownValueTemplate]="zipCodeDropdownTemplate"
                      icon="location_pin"
                      (searchInputChanged)="updateZipDropdown($event)"
                    ></hc-dropdown>
                    <hc-input
                      id="cityInput"
                      placeholder="Enter city"
                      label="City"
                      [required]="true"
                      [errorMessage]="baseForm.getErrorMessage(form?.controls?.city, 'City')"
                      [control]="form?.controls?.city"
                    ></hc-input>
                    <hc-dropdown
                      id="stateInput"
                      placeholder="Enter state"
                      label="State"
                      icon="location_pin"
                      [required]="true"
                      [control]="form?.controls?.state"
                      [data]="obs.statesDropdown"
                      [isDataPrimitive]="false"
                      [errorMessage]="baseForm.getErrorMessage(form?.controls?.state, 'State')"
                    ></hc-dropdown>
                  </div>
                </div>
              </div>
              <div>
                <hc-heading [size]="h4" class="padding-bottom-8">Personal Information</hc-heading>
                <div *ngIf="obs.showTravel">
                  <hc-body class="padding-bottom-24"
                    >SSN and DOB are used for Nursys licenses verification and for employment verification when you submit an application.</hc-body
                  >
                  <hc-masked-input
                    id="ssnInput"
                    placeholder="Enter SSN"
                    label="Social Security Number"
                    [errorMessage]="baseForm.getErrorMessage(form?.controls?.ssnViewValue, 'Social Security Number')"
                    [control]="form?.controls?.ssnViewValue"
                    [mask]="ssnMask"
                    (focused)="showSsn()"
                    (blurred)="hideSsn()"
                  ></hc-masked-input>
                  <hc-date-picker
                    id="dobInput"
                    placeholder="MM/DD/YYYY"
                    label="Date of Birth"
                    [control]="form?.controls?.dateOfBirth"
                    [required]="false"
                    [errorMessage]="baseForm.getErrorMessage(form?.controls?.dateOfBirth, 'Date of Birth')"
                  ></hc-date-picker>
                </div>
                <div *ngIf="obs.showInternational" class="padding-top-24">
                  <hc-input
                    id="preferredNameInput"
                    placeholder="Enter preferred name"
                    label="Preferred Name"
                    [errorMessage]="baseForm.getErrorMessage(form?.controls?.preferredName, 'Preferred Name')"
                    [control]="form?.controls?.preferredName"
                    [required]="false"
                  ></hc-input>
                  <hc-radio-button
                    id="genderRadioInput"
                    label="Gender Identity"
                    required="false"
                    [options]="genderOptions"
                    [control]="form?.controls?.gender"
                    (selectedValueChange)="form?.markAsDirty()"
                    [displayVertically]="obs.isMobile ? true : false"
                  ></hc-radio-button>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-container ngProjectAs="[formButtons]">
          <app-flow-handler
            #flowHandlerComponent
            [recordObs]="updateAboutMeTask$"
            [form]="form"
            [isSavingObs]="updateAboutMeTaskSaving$"
            [segmentDestinationUrl]="'/profile-menu'"
            [showRegularSaveButton]="true"
            (saveClickEmitter)="baseForm.saveCheck()"
          ></app-flow-handler>
        </ng-container>
      </app-base-form>
    </div>
  </ng-template>
  <input #profileInput type="file" accept="image/*;capture=camera" (change)="handleFileInput($event.target.files, profileInput)" style="display: none" />
  <!-- Used just for native as the ionic component only allows a single photo take/upload and not bulk -->
  <button #profileInputMobile (click)="takePicture(profileInputMobile)" style="display: none">Take Photo</button>

  <ng-template #zipCodeDropdownTemplate let-option="option"> {{ option.key }} - {{ option.value.city }}, {{ option.value.state }} </ng-template>
</ng-container>
<ng-template #taxHomeAddressDialog>
  <div>
    <div>Your Tax Home determines your eligibility for reimbursement for your living and travel expenses.</div>
    <br />
    <div>
      Change your Tax Home address through the
      <span class="link" (click)="goToQuestionnaire()">Background Questionnaire.</span>
    </div>
  </div>
</ng-template>
