import { AfterContentChecked, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { ICardConfig } from 'hc-design-system-lib/lib/components/cards/cards.interfaces';
import { filter, map, skipWhile, take, tap } from 'rxjs/operators';
import { combineLatest, Observable, Subject } from 'rxjs';
import { IJob, ILookup, NurseModel } from 'src/app/common';
import { IAppState } from 'src/app/store/app/app.state';
import { GetRecommendedJobsByPreference, SetSavedRecommendedJob } from 'src/app/store/jobs/jobs.actions';
import {
  selectContractType,
  selectRecommendedJobsByPreference,
  selectRecommendedJobsByPreferenceLoading,
  selectSavedJobs,
  selectSavedJobsLoading
} from 'src/app/store/jobs/jobs.selectors';
import {
  selectLookupsLoading,
  selectProfessionLookup,
  selectSpecialtyLookup,
  selectStateLookup,
  selectYesNoLookup,
  selectYesNoOnlyLookup
} from 'src/app/store/lookups/lookups.selectors';
import { selectAccountStateData, selectNurseData } from 'src/app/store/userContext/userContext.selectors';
import { HcEvent } from 'hc-design-system-lib/lib/models/hc-event';
import { NavHelper } from 'src/app/services';
import { CardElevation, DialogService, HeadingSize, IDialogParameters, LinkSize, LinkTarget } from 'hc-design-system-lib';
import { LOCAL_BADGE, TRAVEL_BADGE } from 'src/app/common/models/badge';
import { mapJobToCardConfig } from 'src/app/components/jobs/job-card-utils/job-card-utils';
import { ContractType } from 'src/app/common/contracts/contract-type';
import { JobDataModel } from 'src/app/common/models/job-data-model';
import { emptyGuid } from 'src/app/common/constants';
import { IAccountStateModel } from 'src/app/common/models/account-state-model';

@Component({
  selector: 'app-recommended-jobs-carousel',
  templateUrl: './recommended-jobs-carousel.component.html',
  styleUrls: ['./recommended-jobs-carousel.component.scss']
})
export class RecommendedJobsCarouselComponent implements OnInit, OnDestroy, AfterContentChecked {
  @Input() showCounter = false;
  @Input() showCardsAsColumn = false;

  private readonly destroy$ = new Subject<void>();

  @ViewChild('recommendedDialogTemplate')
  recommendedDialogTemplate: TemplateRef<any>;

  headingSizeH2: HeadingSize = HeadingSize.H2;
  headingSizeH5: HeadingSize = HeadingSize.H5;
  linkTarget: LinkTarget = LinkTarget.Self;
  linkSize: LinkSize = LinkSize.Body;

  yesNoOnlyLookup$ = this._store.select(selectYesNoOnlyLookup);
  yesNoLookup$ = this._store.select(selectYesNoLookup);
  specialtyLookup$ = this._store.select(selectSpecialtyLookup);
  professionalLookup$ = this._store.select(selectProfessionLookup);
  stateLookup$ = this._store.select(selectStateLookup);
  lookupsLoading$ = this._store.select(selectLookupsLoading);
  savedJobs$ = this._store.select(selectSavedJobs);
  savedJobsLoading$ = this._store.select(selectSavedJobsLoading);
  contractType$ = this._store.select(selectContractType);

  nurse$: Observable<NurseModel> = this._store.select(selectNurseData);

  recommendedJobsByPreference$: Observable<IJob[]> = this._store.select(selectRecommendedJobsByPreference);

  recommendedJobCardConfigs$: Observable<ICardConfig[]> = combineLatest([
    this.nurse$,
    this.recommendedJobsByPreference$,
    this.yesNoOnlyLookup$,
    this.yesNoLookup$,
    this.specialtyLookup$,
    this.professionalLookup$,
    this.stateLookup$,
    this.lookupsLoading$,
    this.contractType$,
    this.savedJobs$,
    this.savedJobsLoading$
  ]).pipe(
    skipWhile(
      ([nurse, jobs, yesNoOnlyLookup, yesNoLookup, specialtyLookup, professionLookup, stateLookup, loading, , , savedJobsLoading]: [
        NurseModel,
        IJob[],
        Map<string, ILookup<string>>,
        Map<string, ILookup<string>>,
        Map<string, ILookup<string>>,
        Map<string, ILookup<string>>,
        Map<string, ILookup<string>>,
        boolean,
        ContractType,
        JobDataModel,
        boolean
      ]) => nurse && !jobs?.length && loading && !!yesNoOnlyLookup && !yesNoLookup && !specialtyLookup && !professionLookup && !!stateLookup && savedJobsLoading
    ),
    map(([, jobs, , yesNoLookup, specialtyLookup, professionLookup, stateLookup, , contractType, savedJobs]) =>
      this.mapJobsToCardConfigs(jobs, yesNoLookup, specialtyLookup, professionLookup, stateLookup, contractType, savedJobs)
    )
  );

  isDataReadyToBeFetched: boolean = false;
  accountState$: Observable<IAccountStateModel> = this._store.select(selectAccountStateData);
  recommendedJobsByPreferenceLoading$ = this._store.select(selectRecommendedJobsByPreferenceLoading);

  constructor(
    private readonly _store: Store<IAppState>,
    private readonly _navHelper: NavHelper,
    private readonly _dialogService: DialogService,
    private readonly ref: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.accountState$
      .pipe(
        filter(account => account?.merlinId && account?.merlinId !== emptyGuid),
        take(1)
      )
      .subscribe(() => {
        this.isDataReadyToBeFetched = true;
        this._store.dispatch(new GetRecommendedJobsByPreference());
      });
  }

  // Resolves ExpressionChangedAfterItHasBeenCheckedError error in testing
  ngAfterContentChecked() {
    this.ref.detectChanges();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  mapJobsToCardConfigs(
    jobs: IJob[],
    yesNoLookup: Map<string, ILookup<string>>,
    specialtyLookup: Map<string, ILookup<string>>,
    professionLookup: Map<string, ILookup<string>>,
    stateLookup: Map<string, ILookup<string>>,
    contractType: ContractType,
    savedJobs: JobDataModel
  ): ICardConfig[] {
    const jobCardConfigs: ICardConfig[] = [];

    if (jobs?.length > 0) {
      const currentContractTypeBadge = contractType === ContractType.Local ? LOCAL_BADGE : TRAVEL_BADGE;

      jobs.forEach((job: IJob) => {
        let saved = savedJobs?.jobs.some(savedJob => savedJob.id === job.id && savedJob.contractType === contractType);

        jobCardConfigs.push(
          mapJobToCardConfig({ ...job, contractType, saved }, { yesNoLookup, specialtyLookup, professionLookup, stateLookup }, [], currentContractTypeBadge, true)
        );
      });
    }

    return jobCardConfigs;
  }

  jobCardClicked(event: HcEvent, recommended = true, columnCardIndex?: number): void {
    const jobData = event?.data?.jobCardData?.jobData;
    const cardIndex = columnCardIndex ?? event?.data?.jobCardData?.cardIndex;
    const jobId = jobData['jobId'] || null;

    this.recommendedJobCardConfigs$
      .pipe(
        filter(jobDetails => !!jobDetails),
        take(1),
        tap(jobDetails => {
          const contractType = jobDetails[cardIndex].primaryBadge?.title as ContractType;
          if (jobId) {
            this._navHelper.goToJobsSpecificCustomParams(jobId, {
              recommendedJob: recommended,
              contractType,
              fromSearch: true
            });
          }
        })
      )
      .subscribe();
  }

  toggleSavedJob(event: HcEvent, columnCardIndex?: number): void {
    const jobEventInfo = event?.data?.jobCardData?.jobData;
    const clickedJobId = jobEventInfo['jobId'] || null;
    const contractType = jobEventInfo['contractType'];
    const cardIndex = columnCardIndex ?? event?.data?.jobCardData?.cardIndex;

    combineLatest([this.recommendedJobCardConfigs$, this.recommendedJobsByPreference$])
      .pipe(
        filter(([jobDetails, recommendedJobs]) => !!jobDetails && !!recommendedJobs),
        take(1),
        tap(([jobDetails, recommendedJobs]) => {
          const saveValue = !jobDetails[cardIndex].isCardActionActive;
          const job = recommendedJobs.find((j: IJob) => j.id === clickedJobId);
          this._store.dispatch(new SetSavedRecommendedJob({ job, saveValue, contractType }));
        })
      )
      .subscribe();
  }

  showRecommendationDialog(): void {
    const dialogData: IDialogParameters = {
      title: 'Job Recommendations',
      text: '',
      showCloseIcon: true,
      elevation: CardElevation.Default,
      icon: undefined,
      template: this.recommendedDialogTemplate
    };
    this._dialogService.showDialog(dialogData);
  }
}
