import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { Moment } from 'moment';
import * as moment from 'moment';
import { Injectable } from '@angular/core';

@Injectable()
export class DateFieldAdapter extends MomentDateAdapter {
  parse(value: any, parseFormat: string | string[]): Moment | null {
    const strValue = value as string;
    if (strValue && strValue.length > 7) {
      return super.parse(value, parseFormat);
    }

    if (strValue === '') {
      return null;
    }
    return moment.invalid();
  }
}
