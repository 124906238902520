import { Injectable, ComponentRef, Injector, ElementRef } from '@angular/core';
import { Overlay, OverlayRef, OverlayConfig, PositionStrategy, ConnectionPositionPair } from '@angular/cdk/overlay';
import { ComponentPortal, ComponentType, PortalInjector } from '@angular/cdk/portal';
import { Subject, Observable } from 'rxjs';

export interface FormModalConfig {
  elementRef?: ElementRef | HTMLElement;
  panelClass?: string | string[];
  hasBackdrop?: boolean;
  backdropClass?: string;
  width?: number | string;
  height?: number | string;
  minWidth?: number | string;
  minHeight?: number | string;
}

const DEFAULT_CONFIG: FormModalConfig = {
  hasBackdrop: true,
  backdropClass: 'dark-backdrop',
  panelClass: 'tm-file-preview-dialog-panel'
};

export class FormModalOverlayRef {
  private _beforeClose = new Subject<void>();
  private _afterClosed = new Subject<void>();

  constructor(private overlayRef: OverlayRef) {}

  close(): void {
    this.overlayRef.dispose();
    this._afterClosed.next();
    this._afterClosed.complete();
  }

  afterClosed(): Observable<void> {
    return this._afterClosed.asObservable();
  }

  beforeClose(): Observable<void> {
    return this._beforeClose.asObservable();
  }
}

@Injectable()
export class PopupService {
  dialogRef: FormModalOverlayRef;
  component: any;

  // LIST SETS THE SEARCHCONFIGS FOR THE POP UP
  message: string;
  buttonName: string;

  constructor(
    private overlay: Overlay,
    private _injector: Injector
  ) {}

  open<T>(component: ComponentType<T>, config: FormModalConfig = {}, message: string = '', buttonName: string = '') {
    // Returns an OverlayRef (which is a PortalHost)
    this.message = message;
    this.buttonName = buttonName;

    const modalConfig = { ...DEFAULT_CONFIG, ...config };
    const overlayRef = this.createOverlay(modalConfig);
    const dialogRef = new FormModalOverlayRef(overlayRef);
    const overlayComponent = this.attachModalContainer<T>(overlayRef, modalConfig, dialogRef, component);
    this.component = overlayComponent;
    this.dialogRef = dialogRef;
    overlayRef.backdropClick().subscribe(() => this.dialogRef.close());
    return dialogRef;
  }

  private createOverlay(config: FormModalConfig): OverlayRef {
    const overlayConfig = this.getOverlayConfig(config);
    const overlayRef = this.overlay.create(overlayConfig);
    return overlayRef;
  }

  private getOverlayConfig(config: FormModalConfig): OverlayConfig {
    let positionStrategy: PositionStrategy;
    if (config.elementRef) {
      const pair = new ConnectionPositionPair({ originX: 'start', originY: 'bottom' }, { overlayX: 'start', overlayY: 'top' });
      const flex = this.overlay.position().flexibleConnectedTo(config.elementRef);
      flex.positions.push(pair);
      positionStrategy = flex;
    } else {
      positionStrategy = this.overlay.position().global().centerHorizontally().centerVertically();
    }

    const overlayConfig = new OverlayConfig({
      hasBackdrop: config.hasBackdrop,
      backdropClass: config.backdropClass,
      panelClass: config.panelClass,
      // scrollStrategy: this.overlay.scrollStrategies.block(),
      positionStrategy,
      height: config.height,
      width: config.width
    });

    return overlayConfig;
  }

  private attachModalContainer<T>(overlayRef: OverlayRef, config: FormModalConfig, dialogRef: FormModalOverlayRef, component: ComponentType<T>) {
    const injector = this.createInjector(config, dialogRef);
    const containerPortal = new ComponentPortal(component, null, injector);
    const containerRef: ComponentRef<T> = overlayRef.attach(containerPortal);
    return containerRef.instance;
  }

  private createInjector(config: FormModalConfig, dialogRef: FormModalOverlayRef): PortalInjector {
    // Instantiate new WeakMap for our custom injection tokens
    const injectionTokens = new WeakMap();

    // Set custom injection tokens
    injectionTokens.set(FormModalOverlayRef, dialogRef);
    // injectionTokens.set(FILE_PREVIEW_DIALOG_DATA, config.data);

    // Instantiate new PortalInjector
    return new PortalInjector(this._injector, injectionTokens);
  }
}
