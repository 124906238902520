import { NavigationItem } from 'hc-design-system-lib/lib/components/navigation/navigation.interfaces';
import { AppUrls } from 'src/app/app-urls';

export const taskListItem: NavigationItem = {
  displayName: 'Task List',
  iconName: 'assignment_turned_in',
  route: `/${AppUrls.TASKS}`
};

export const profileMenuDefaultItems: NavigationItem[] = [
  {
    displayName: 'Profile',
    iconName: 'account_circle',
    route: `/${AppUrls.PROFILEMENU}`
  }
];

export const docsNavItem: NavigationItem = {
  displayName: 'Documents',
  iconName: 'folder_shared',
  route: `/${AppUrls.DOCUMENTS}`
};

export const jobsItems: NavigationItem[] = [
  {
    displayName: 'Favorites',
    iconName: 'favorite',
    route: `/${AppUrls.JOB_SEARCH}?index=1`
  },
  {
    displayName: 'Job Preferences',
    iconName: 'assignment_turned_in',
    route: `/${AppUrls.JOB_PREFERENCES}`
  }
];

export const jobsItemsIN: NavigationItem[] = [jobsItems[1]];

export const tabBarDefaultItems: NavigationItem[] = [{ displayName: 'Dashboard', iconName: 'dashboard', route: `/${AppUrls.DASHBOARD}` }];

export const toolDefaultItems: NavigationItem[] = [
  { displayName: 'Clear Cache', iconName: 'clear', exec: 2, route: null },
  { displayName: 'Reset User', iconName: 'refresh', exec: 3, route: null }
];

export const jobsNavItem: NavigationItem = {
  displayName: 'Jobs',
  iconName: 'business_center',
  route: `/${AppUrls.JOB_SEARCH}`
};

export const profileNavItem: NavigationItem = {
  displayName: 'Profile',
  iconName: 'account_circle',
  route: `/${AppUrls.PROFILEMENU}`
};

export const applicationNavItem: NavigationItem = {
  displayName: 'Applications',
  iconName: 'description',
  route: `/${AppUrls.MY_SUBMITTALS}`
};

export const onAssignmentNavItem: NavigationItem = {
  displayName: 'Assignments',
  iconName: 'badge',
  route: `/${AppUrls.ON_ASSIGNMENT}`
};

export const signOutItem: NavigationItem = {
  displayName: 'Sign Out',
  iconName: 'power_settings_new',
  exec: 1,
  route: null
};

export const settingsNavItem: NavigationItem = { displayName: 'Settings', iconName: 'settings', route: AppUrls.SETTINGS };
