import { NavigationItem, NavigationProfile } from 'hc-design-system-lib/lib/components/navigation/navigation.interfaces';
import { EnvironmentId } from 'src/app/common';
import { profileMenuDefaultItems, tabBarDefaultItems } from 'src/app/components/main-components/main/main.constants';

export const initialTabBarState: ITabBarState = {
  tabBarItems: tabBarDefaultItems,
  tabbarActiveItemIndex: 0
};

export const initialNavigationProfile: NavigationProfile = {
  profileImage: null,
  initials: null,
  fullName: null
};

export const intitialMenuState: NavigationItem[] = profileMenuDefaultItems;

export interface ITabBarState {
  tabBarItems: NavigationItem[];
  tabbarActiveItemIndex: number;
}

export interface UiState {
  screenWidth: number;
  tabBar: ITabBarState;
  menuItems: NavigationItem[];
  enviromentId: EnvironmentId;
  navigationProfile: NavigationProfile;
}

export const initialUiState: UiState = {
  screenWidth: 0,
  tabBar: initialTabBarState,
  menuItems: intitialMenuState,
  enviromentId: EnvironmentId.Production,
  navigationProfile: initialNavigationProfile
};
