import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { map, skipWhile, filter } from 'rxjs/operators';
import { AppUrls } from 'src/app/app-urls';
import { IAppState } from 'src/app/store/app/app.state';
import { IJob } from 'src/app/common';
import { selectSpecificJob, selectSpecificJobError, selectSpecificJobLoading } from '../store/jobs/jobs.selectors';
import { GetSpecificJob } from '../store/jobs/jobs.actions';

@Injectable()
export class AutoOfferConfirmationGuard implements CanActivate {
  constructor(
    private _router: Router,
    private _store: Store<IAppState>
  ) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | UrlTree {
    const jobId = next.params['id'];

    if (!jobId) {
      return this._router.parseUrl(AppUrls.DASHBOARD);
    }
    const specificJob$: Observable<IJob> = this._store.select(selectSpecificJob);
    const specificJobLoading$: Observable<boolean> = this._store.select(selectSpecificJobLoading);
    const specificJobError$: Observable<Error> = this._store.select(selectSpecificJobError);

    return combineLatest([specificJob$, specificJobLoading$, specificJobError$]).pipe(
      skipWhile(([jobData, jobLoading, jobError]: [IJob, boolean, Error]) => jobLoading),
      map(([jobData, jobLoading, jobError]: [IJob, boolean, Error]) => {
        if (jobError) {
          return this._router.parseUrl(AppUrls.DASHBOARD);
        } else if (!jobData || jobData.id !== jobId.toLowerCase()) {
          this._store.dispatch(new GetSpecificJob(jobId));
        }
        return jobData;
      }),
      filter((jobData: IJob) => jobData !== null),
      map((jobData: IJob) => {
        const jobType = jobData.interviewTypeComplianceRequirement;
        if (jobType?.name === 'Interview Process - Auto-Offer, No Interview') {
          return true;
        }
        return this._router.parseUrl(AppUrls.DASHBOARD);
      })
    );
  }
}
