import { Resolve } from '@angular/router';
import { Injectable, Inject } from '@angular/core';
import { Observable, forkJoin } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { NursePortalApi, AppState } from 'src/app/services';
import { Store } from '@ngrx/store';

import { GetLookups, GetProfessionalHierarchy } from 'src/app/store/lookups/lookups.actions';

@Injectable()
export class ReferenceQuestionnaireResolve implements Resolve<any> {
  constructor(
    private _nurseProfileApi: NursePortalApi,
    @Inject(DOCUMENT) private document: Document,
    private _appState: AppState,
    private _store: Store
  ) {}

  referenceId: string;

  resolve(): Observable<any> | Promise<any> | any {
    this._store.dispatch(new GetLookups());
    this._store.dispatch(new GetProfessionalHierarchy());
    this.referenceId = this._getParameterbyName('referenceId', this.document.location.href);

    // COVERING FOR EMPTY REFERENCEID THAT ISNT NULL
    if (this.referenceId === '') {
      this.referenceId = null;
    }
    const reference = this._nurseProfileApi.getReferenceForQuestionnaire(this.referenceId);
    const contact = this._nurseProfileApi.getContactByReference(this.referenceId);
    this._appState.isLoaded = true;
    return forkJoin([reference, contact]);
  }

  private _getParameterbyName(name: string, url: string): string {
    if (!url) {
      url = window.location.href;
    }
    name = name.replace(/[\[\]]/g, '\\$&');
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
    const results = regex.exec(url);
    if (!results) {
      return null;
    }
    if (!results[2]) {
      return '';
    }
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }
}
