export enum TaskCompletionIds {
  noTaskCompleted = 1,
  taskCompleted = 2,
  allTasksCompleted = 3
}

export enum TaskCompletionPopUpReturnIds {
  close = 1,
  addAnother = 2,
  goToTasks = 3,
  searchForJobs = 4,
  goToDashBoard = 5,
  payTaskNavigation = 6
}
