import { DashboardActions, EDashboardActions } from 'src/app/store/dashboard/dashboard.actions';
import { DashboardState, initialDashboardState } from 'src/app/store/dashboard/dashboard.state';

export function dashboardReducer(state = initialDashboardState, action: DashboardActions): DashboardState {
  switch (action.type) {
    case EDashboardActions.GetDashboardInfo:
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          error: null,
          loading: true,
          expirationDate: null
        }
      };
    case EDashboardActions.GetDashboardInfoSuccess:
      return {
        ...state,
        dashboard: {
          data: {
            ...action.payload.dashboardObject,
            resources: state.dashboard.data?.resources ?? null,
            resourcesError: state.dashboard.data?.resourcesError ?? null,
            isLoadingResources: state.dashboard.data?.isLoadingResources ?? false
          },
          error: null,
          loading: false,
          expirationDate: action.payload.expiration
        }
      };
    case EDashboardActions.GetDashboardInfoError:
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          error: action.error,
          loading: false,
          expirationDate: null
        }
      };
    case EDashboardActions.UpdateUserSeenDashboard:
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          error: null,
          loading: true
        }
      };
    case EDashboardActions.UpdateUserSeenDashboardSuccess:
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          error: null,
          loading: false
        }
      };
    case EDashboardActions.UpdateUserSeenDashboardError:
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          error: action.error,
          loading: false
        }
      };
    case EDashboardActions.GetResources:
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          data: {
            ...state.dashboard.data,
            isLoadingResources: true,
            resourcesError: null
          }
        }
      };
    case EDashboardActions.GetResourcesSuccess:
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          data: {
            ...state.dashboard.data,
            resources: action.payload.resources,
            isLoadingResources: false,
            resourcesError: null
          }
        }
      };
    case EDashboardActions.GetResourcesError:
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          data: {
            ...state.dashboard.data,
            isLoadingResources: false,
            resourcesError: action.error
          }
        }
      };
    default:
      return state;
  }
}
