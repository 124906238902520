import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AppState } from 'src/app/services';
import { Store } from '@ngrx/store';
import { GetINLookups, GetLookups, GetProfessionalHierarchy } from 'src/app/store/lookups/lookups.actions';

@Injectable({
  providedIn: 'root'
})
export class AppInitGuard implements CanActivate {
  constructor(
    private _appState: AppState,
    private _store: Store
  ) {}

  canActivate(): Observable<boolean> {
    console.log('AppInitGuard.canActivate');

    this._store.dispatch(new GetLookups());
    this._store.dispatch(new GetINLookups());
    this._store.dispatch(new GetProfessionalHierarchy());

    this._appState.isLoaded = true;
    return of(true);
  }
}
