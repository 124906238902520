<div fxLayout="column" class="viewport standard-padding" fxFlex>
  <div fxLayoutAlign="end center">
    <button id="notFoundSignIn" mat-raised-button color="primary" (click)="logout()">Sign In</button>
  </div>
  <div fxFlex fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="24px">
    <img height="250" src="/assets/img/notFoundIcon.svg" />
    <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="16px">
      <div class="header">Oops!</div>
      <div>Unable To Log You In. Let's Try That Again</div>
    </div>
  </div>
</div>
