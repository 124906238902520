import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NurseModel } from 'src/app/common';
import { VerificationType } from 'src/app/common/constants';
import { NavHelper } from 'src/app/services';
import { SetVerificationType } from 'src/app/store/userContext/userContext.actions';
import { selectNurseData } from 'src/app/store/userContext/userContext.selectors';

@Component({
  selector: 'app-verify-selection',
  templateUrl: './verify-selection.component.html',
  styleUrls: ['./verify-selection.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class VerifySelectionComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();

  userEmail: string;
  userPhoneNumber: string;
  nurse: NurseModel;
  selection: string;

  nurse$: Observable<NurseModel> = this.store.select(selectNurseData);

  constructor(
    private _navHelper: NavHelper,
    public snackBar: MatSnackBar,
    private store: Store
  ) {}

  ngOnInit() {
    this.addNurseSubscription();
    this.selection = 'sms';
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  addNurseSubscription(): void {
    this.nurse$.pipe(takeUntil(this.destroy$)).subscribe((nurseData: NurseModel) => {
      if (nurseData) {
        this.nurse = nurseData;
        this.userEmail = nurseData.email;
        this.userPhoneNumber = nurseData.mobileNumber;
      }
    });
  }

  navigateToVerify() {
    if (this.selection === 'sms') {
      this._navHelper.goToVerifyUserSMS();
    } else {
      this.store.dispatch(new SetVerificationType(VerificationType.Email));
    }
  }
}
