import { ZipCodeModel } from 'src/app/common/models/zip-code-model';
import { initializeData, IDataState } from '../app/app.models';

export interface SharedDataState {
  zipCodes: IDataState<ZipCodeModel[]>;
}

export const initialSharedDataState = {
  zipCodes: initializeData()
};
