export enum Shifts {
  Days = 948050000,
  Evenings = 948050001,
  Mids = 948050002,
  Nights = 948050003,
  Rotating = 948050004,
  Weekend = 948050005,
  RotatingDaysMids = 948050006,
  RotatingMidsEvenings = 948050007,
  RotatingDaysNights = 948050008,
  RotatingDaysEvenings = 948050010,
  RotatingEveningsNights = 948050011,
  Flexible = 948050012,
  NA = 948050013,
  SeeJobDetails = 948050014
}
