import { AfterViewInit, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Certification, CandidateQualification, ILookup, ICertificationLookupModel } from 'src/app/common';
import { Store } from '@ngrx/store';
import { IAppState } from 'src/app/store/app/app.state';
import { GetCertificationPopulate, GetCertifications } from 'src/app/store/certifications/certifications.actions';
import { BodyColor, CardElevation, DialogService, HeadingSize, IDialogParameters } from 'hc-design-system-lib';
import { IRecordCardConfig } from 'hc-design-system-lib/lib/components/cards/cards.interfaces';
import { selectCertificationLookup, selectIssuingLookup, selectLicenseCertificationStatusLookup } from 'src/app/store/lookups/lookups.selectors';
import { selectCertifications, selectCertificationsLoading } from 'src/app/store/certifications/certifications.selectors';
import { combineLatest, Observable } from 'rxjs';
import { filter, map, take, tap } from 'rxjs/operators';
import { NurseTask } from 'src/app/common/models/db-objects';
import { selectTasksLoading, selectToDoCertTasks } from 'src/app/store/tasks/tasks.selectors';
import { ButtonAppearance } from 'hc-design-system-lib/lib/components/button/button.enums';
import { selectCanSeeInternational, selectCanSeeTravel } from 'src/app/store/userContext/userContext.selectors';
import { emptyGuid } from '../../../../common/constants';

@Component({
  selector: 'app-certification-list',
  templateUrl: './certification-list.component.html',
  styleUrls: ['./certification-list.component.scss']
})
export class CertificationListComponent implements OnInit, AfterViewInit {
  @ViewChild('addCertForm')
  addCertFormTemplate: TemplateRef<any>;

  prefilledCertId: string = null;
  headingSize6 = HeadingSize.H6;
  buttonAppearanceSecondary = ButtonAppearance.Secondary;
  certifications: Certification[];
  qualifications: CandidateQualification[];
  certificationLookup: Map<string, ICertificationLookupModel>;
  licenseCertificationStatusLookup: Map<number, ILookup<number>>;
  editingCert: Certification = null;

  certificationLookup$ = this._store.select(selectCertificationLookup);
  statusLookup$ = this._store.select(selectLicenseCertificationStatusLookup);
  certifications$ = this._store.select(selectCertifications);
  issuingLookup$ = this._store.select(selectIssuingLookup);
  canSeeInternational$: Observable<boolean> = this._store.select(selectCanSeeInternational);
  canSeeTravel$: Observable<boolean> = this._store.select(selectCanSeeTravel);

  certificationCards$: Observable<IRecordCardConfig[]> = combineLatest([
    this.certificationLookup$,
    this.statusLookup$,
    this.certifications$,
    this.issuingLookup$,
    this.canSeeTravel$
  ]).pipe(
    filter(([certLookup, statusLookup, certs, issuingLookup, canSeeTravel]) => !!certLookup && !!statusLookup && !!certs && !!issuingLookup),
    map(([certLookup, statusLookup, certs, issuingLookup, canSeeTravel]) => {
      this.certifications = certs;
      return this.mapCertsToCardDetails(certLookup, statusLookup, certs, issuingLookup, canSeeTravel);
    })
  );
  certificationsLoading$: Observable<boolean> = this._store.select(selectCertificationsLoading);

  toDoCertTasks$: Observable<NurseTask[]> = this._store.select(selectToDoCertTasks).pipe(filter(tasks => !!tasks));
  tasksLoading$: Observable<boolean> = this._store.select(selectTasksLoading);

  constructor(
    private readonly _route: ActivatedRoute,
    private readonly _store: Store<IAppState>,
    private readonly _dialogService: DialogService
  ) {}

  ngOnInit() {
    this._store.dispatch(new GetCertifications());
  }

  ngAfterViewInit() {
    this.prefillCertAndOpenDialog();
  }

  mapCertsToCardDetails(
    certLookup: Map<string, ICertificationLookupModel>,
    statusLookup: Map<number, ILookup<number>>,
    certs: Certification[],
    issuingLookup: Map<string, ILookup<string>>,
    canSeeTravel: boolean
  ): IRecordCardConfig[] {
    return certs.map((cert: Certification) => {
      const status = statusLookup?.get(cert.statusId)?.name;
      const lookedUpCert = certLookup?.get(cert.certificationId);
      const isIelts = cert.certificationName === 'IELTS' || cert.certificationId === emptyGuid;
      const certificationName = lookedUpCert?.certification ?? cert.certificationName;
      const expDate = new Date(cert.expirationDate);

      // Currently not mapping status because it is not accurate to what we expect from Merlin.
      return {
        title: isIelts ? 'IELTS' : certificationName,
        firstLine: canSeeTravel || (!lookedUpCert?.isProfessionalCert && !isIelts) ? issuingLookup.get(cert.issuingBodyId)?.name : null,
        secondLine: `Exp: ${expDate.toLocaleDateString()}`,
        statusColor: canSeeTravel ? this.getStatusColor(status) : null,
        icon: canSeeTravel ? 'edit' : null,
        id: cert.id
      };
    });
  }

  getStatusColor(status): BodyColor {
    switch (status) {
      case 'Pending Review':
        return BodyColor.purple;
      case 'Active':
        return BodyColor.green;
      case 'Inactive/Expired':
        return BodyColor.orange;
      default:
        break;
    }
  }

  prefillCertAndOpenDialog(): void {
    const id = this._route.snapshot.queryParamMap.get('id');
    if (id) {
      this.certificationCards$
        .pipe(
          filter(cards => !!cards),
          take(1),
          tap(certCards => this.openFormFromTask(id))
        )
        .subscribe();
    }
  }

  openFormFromTask(id): void {
    this.resetEditingCertAndPrefillCert();
    const prexistingCert = this.certifications.find(cert => cert.id === id);

    if (prexistingCert) {
      this.editingCert = prexistingCert;
    } else {
      this._store.dispatch(new GetCertificationPopulate(id));
      this.prefilledCertId = id;
    }
    this.openCertModalDialog();
  }

  openEditForm(id): void {
    this.resetEditingCertAndPrefillCert();
    this.editingCert = this.certifications.find(cert => cert.id === id);
    this.openCertModalDialog();
  }

  openAddForm(): void {
    this.resetEditingCertAndPrefillCert();
    this.openCertModalDialog();
  }

  resetEditingCertAndPrefillCert(): void {
    this.editingCert = null;
    this.prefilledCertId = null;
  }

  openCertModalDialog(): void {
    const title = this.editingCert ? 'Edit Certification' : 'Add Certification';
    const template = this.addCertFormTemplate;
    const dialogData: IDialogParameters = {
      title,
      text: '',
      showCloseIcon: true,
      elevation: CardElevation.Default,
      icon: null,
      template,
      separatedHeader: true,
      noStyling: true,
      isResponsive: true,
      useCustomCloseLogic: true
    };

    this._dialogService.showDialog(dialogData, true, true, false);
  }
}
