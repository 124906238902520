import { createSelector } from '@ngrx/store';
import { IAppState } from 'src/app/store/app/app.state';
import { ISkillsChecklistState } from './skillsChecklist.state';
import { AccordionConfig, SkillDictionary } from 'src/app/common/models/skill-dictionary';

const selectSkillsChecklistState = (state: IAppState) => state.skillsChecklist;

export const selectSkill = createSelector(selectSkillsChecklistState, (state: ISkillsChecklistState) => state?.skill?.data);
export const selectSkillLoading = createSelector(selectSkillsChecklistState, (state: ISkillsChecklistState) => state?.skill?.loading);
export const selectSkillError = createSelector(selectSkillsChecklistState, (state: ISkillsChecklistState) => state?.skill?.error);

export const selectSkillsData = createSelector(selectSkillsChecklistState, (state: ISkillsChecklistState) => state.skills?.data);
export const selectSkillsLoading = createSelector(selectSkillsChecklistState, (state: ISkillsChecklistState) => state.skills?.loading);
export const selectSkillsError = createSelector(selectSkillsChecklistState, (state: ISkillsChecklistState) => state.skills?.error);

export const selectCandidateSkill = createSelector(selectSkillsChecklistState, (state: ISkillsChecklistState) => state?.candidateSkill?.data);
export const selectCandidateSkillLoading = createSelector(selectSkillsChecklistState, (state: ISkillsChecklistState) => state?.candidateSkill?.loading);
export const selectCandidateSkillError = createSelector(selectSkillsChecklistState, (state: ISkillsChecklistState) => state?.candidateSkill?.error);

export const selectCandidateSkillsData = createSelector(selectSkillsChecklistState, (state: ISkillsChecklistState) => state.candidateSkills?.data);
export const selectCandidateSkillsLoading = createSelector(selectSkillsChecklistState, (state: ISkillsChecklistState) => state.candidateSkills?.loading);
export const selectCandidateSkillsError = createSelector(selectSkillsChecklistState, (state: ISkillsChecklistState) => state.candidateSkills?.error);

export const selectSkillDictionaryData = createSelector(selectSkillsChecklistState, (state: ISkillsChecklistState) => state.skillDictionary?.data);
export const selectSkillDictionaryLoading = createSelector(selectSkillsChecklistState, (state: ISkillsChecklistState) => state.skillDictionary?.loading);
export const selectSkillDictionaryError = createSelector(selectSkillsChecklistState, (state: ISkillsChecklistState) => state.skillDictionary?.error);

export const selectSkillDictionaryFilter = createSelector(selectSkillsChecklistState, (state: ISkillsChecklistState) => state.skillDictionaryFilter?.data);

export const selectSkillDictonaryAccordionFilteredData = createSelector(
  selectSkillDictionaryData,
  selectSkillDictionaryFilter,
  (skillDictionary: SkillDictionary, skillDictionaryFilter) => {
    if (!skillDictionary) return null;
    if (!skillDictionaryFilter) return skillDictionary.accordion;

    return new Map(
      [...skillDictionary.accordion]?.reduce(
        (prev, [initial, skillConfigs]: [string, AccordionConfig[]]) => {
          const filteredData = skillConfigs.filter((skillConfig: AccordionConfig) => skillConfig.config.name?.toLowerCase().includes(skillDictionaryFilter?.toLowerCase()));

          if (filteredData.length) {
            return [...prev, [initial, filteredData]];
          }

          return prev;
        },
        [] as [string, AccordionConfig[]][]
      )
    );
  }
);

export const selectUpdateSkillQuestions = createSelector(selectSkillsChecklistState, (state: ISkillsChecklistState) => state?.updateSkillQuestions);
export const selectUpdateSkillQuestionsData = createSelector(selectSkillsChecklistState, (state: ISkillsChecklistState) => state?.updateSkillQuestions?.data);
export const selectUpdateSkillQuestionsLoading = createSelector(selectSkillsChecklistState, (state: ISkillsChecklistState) => state?.updateSkillQuestions?.loading);
export const selectUpdateSkillQuestionsError = createSelector(selectSkillsChecklistState, (state: ISkillsChecklistState) => state?.updateSkillQuestions?.error);

// To be removed in the future when the Guest User Skills feature flag is no longer needed
export const selectNurseApiSkillQuestions = createSelector(selectSkillsChecklistState, (state: ISkillsChecklistState) => state?.nurseApiSkillQuestions?.data);
export const selectNurseApiSkillQuestionsLoading = createSelector(selectSkillsChecklistState, (state: ISkillsChecklistState) => state?.nurseApiSkillQuestions?.loading);
export const selectNurseApiSkillQuestionsError = createSelector(selectSkillsChecklistState, (state: ISkillsChecklistState) => state?.nurseApiSkillQuestions?.error);
