import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NavHelper } from 'src/app/services';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { SendVerificationSupportEmail } from 'src/app/store/userContext/userContext.actions';
import { selectVerificationSupportEmailLoading } from 'src/app/store/userContext/userContext.selectors';

@Component({
  selector: 'app-verify-support',
  templateUrl: './verify-support.component.html',
  styleUrls: ['./verify-support.component.scss']
})
export class VerifySupportComponent implements OnInit {
  verifySupportReport: UntypedFormGroup;

  verificationSupportEmailLoading$ = this.store.select(selectVerificationSupportEmailLoading);

  constructor(
    private _navHelper: NavHelper,
    public snackBar: MatSnackBar,
    private _fb: UntypedFormBuilder,
    private store: Store
  ) {}

  ngOnInit() {
    this.verifySupportReport = this._fb.group({
      name: new UntypedFormControl(''),
      email: new UntypedFormControl('', [Validators.email]),
      description: new UntypedFormControl('')
    });
  }

  onSubmit(): void {
    if (this.verifySupportReport.valid) {
      this.store.dispatch(new SendVerificationSupportEmail(this.verifySupportReport.value));
    }
  }

  navigateToVerifySupport() {
    this._navHelper.goToVerifySupport();
  }
}
