import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { ITileCardConfig } from 'hc-design-system-lib/lib/components/cards/cards.interfaces';
import { Observable } from 'rxjs';
import { AppUrls } from 'src/app/app-urls';
import { Shifts } from 'src/app/common/contracts/shifts-id';
import { JobPreferenceReturn, JobPreferencesIN } from 'src/app/common/models/job-preference';
import { selectJobPreferences, selectJobPreferencesIN, selectJobPreferencesLoading } from 'src/app/store/jobs/jobs.selectors';
import { selectCanSeeInternational } from 'src/app/store/userContext/userContext.selectors';

@Component({
  selector: 'app-job-preferences-tile',
  templateUrl: './job-preferences-tile.component.html',
  styleUrls: ['./job-preferences-tile.component.scss']
})
export class JobPreferencesTileComponent {
  IN_STATES_WITH_CITIES = ['CA', 'NY', 'FL', 'TX'];
  NULL_VALUE = '---';
  jobPreferences$: Observable<JobPreferenceReturn> = this._store.select(selectJobPreferences);
  jobPreferencesLoading$: Observable<boolean> = this._store.select(selectJobPreferencesLoading);

  jobPreferencesIN$: Observable<JobPreferencesIN> = this._store.select(selectJobPreferencesIN);

  canSeeInternational$: Observable<boolean> = this._store.select(selectCanSeeInternational);

  tileConfig: ITileCardConfig = {
    title: 'Job Preferences',
    linkDetails: {
      title: 'Update Preferences',
      routerLink: `../${AppUrls.JOB_PREFERENCES}`
    }
  };

  constructor(private _store: Store) {}

  getJobPreferencesPay(pay: number) {
    return (
      pay?.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      }) ?? this.NULL_VALUE
    );
  }

  getJobPreferencesShift(shift: number) {
    return Shifts[shift]?.toString() ?? this.NULL_VALUE;
  }

  getJobPreferencesLocations(jobPreferences: JobPreferenceReturn) {
    if (!jobPreferences?.locationDetails?.length && !jobPreferences?.submitToCompactState) return this.NULL_VALUE;
    else if (jobPreferences?.locationDetails?.length) {
      return jobPreferences.locationDetails.map(location => location.location.replace(', USA', '')).join(', ');
    } else {
      return 'Compact License states';
    }
  }

  getJobPreferencesINSpecialties(jobPrefs: JobPreferencesIN) {
    return this.addSpacesAfterCommas(jobPrefs?.desiredSpecialties) || this.NULL_VALUE;
  }

  getJobPreferencesINLocations(jobPrefs: JobPreferencesIN) {
    if (!jobPrefs) {
      return this.NULL_VALUE;
    }

    let desiredLocations = this.addSpacesAfterCommas(jobPrefs?.desiredLocations);
    if (!desiredLocations) {
      return this.NULL_VALUE;
    }

    for (let state of this.IN_STATES_WITH_CITIES) {
      let cities = jobPrefs['desiredCities' + state];
      if (cities?.length) {
        desiredLocations = desiredLocations.replace(state, `${state} (${cities.join(', ')})`);
      }
    }
    return desiredLocations;
  }

  addSpacesAfterCommas(input: string) {
    return input?.replace(/,/g, ', ');
  }
}
