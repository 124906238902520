import { createSelector } from '@ngrx/store';
import { IAppState } from 'src/app/store/app/app.state';
import { EducationState } from './education.state';
import { checkIfDateIsAfter } from 'src/app/common/functions/date-manipulations';

const selectEducationState = (state: IAppState) => state.education;

export const selectEducationListResult = createSelector(selectEducationState, (state: EducationState) => state.educations.data);
export const selectEducationListExpired = createSelector(selectEducationState, (state: EducationState) => checkIfDateIsAfter(new Date(), state.educations.expirationDate));
export const selectEducationListExpirationDate = createSelector(selectEducationState, (state: EducationState) => state.educations.expirationDate);
export const selectEducationListLoading = createSelector(selectEducationState, (state: EducationState) => state.educations.loading);

export const selectEducationResult = createSelector(selectEducationState, (state: EducationState) => state.education.data);
export const selectEducationExpired = createSelector(selectEducationState, (state: EducationState) => checkIfDateIsAfter(new Date(), state.education.expirationDate));
export const selectEducationExpirationDate = createSelector(selectEducationState, (state: EducationState) => state.education.expirationDate);
export const selectEducationLoading = createSelector(selectEducationState, (state: EducationState) => state.education.loading);

export const selectEducationFileUrl = createSelector(selectEducationState, (state: EducationState) => state.fileUrl.data);

export const selectAddEducationResult = createSelector(selectEducationState, (state: EducationState) => state.addEducationResult.data);
export const selectAddEducationLoading = createSelector(selectEducationState, (state: EducationState) => state.addEducationResult.loading);
export const selectUpdateEducationResult = createSelector(selectEducationState, (state: EducationState) => state.updateEducationResult.data);
export const selectUpdateEducationLoading = createSelector(selectEducationState, (state: EducationState) => state.updateEducationResult.loading);
export const selectDeleteEducationResult = createSelector(selectEducationState, (state: EducationState) => state.deleteEducationResult.data);
export const selectDeleteEducationLoading = createSelector(selectEducationState, (state: EducationState) => state.deleteEducationResult.loading);

export const selectEducationSaving = createSelector(
  selectAddEducationLoading,
  selectUpdateEducationLoading,
  selectDeleteEducationLoading,
  (addLoading: boolean, updateLoading: boolean, deleteLoading: boolean) => addLoading || updateLoading || deleteLoading
);
