import { createSelector } from '@ngrx/store';
import { checkIfDateIsAfter } from 'src/app/common/functions/date-manipulations';
import { IAppState } from 'src/app/store/app/app.state';
import { DashboardState } from './dashboard.state';

const selectDashboardState = (state: IAppState) => state.dashboard;

export const selectDashboardObject = createSelector(selectDashboardState, (state: DashboardState) => state.dashboard.data);

export const selectDashboardObjectLoading = createSelector(selectDashboardState, (state: DashboardState) => state.dashboard.loading);

export const selectIsDashboardObjectExpired = createSelector(selectDashboardState, (state: DashboardState) => {
  return checkIfDateIsAfter(new Date(), state.dashboard?.expirationDate);
});

export const selectIsDashboardObjectExpirationDate = createSelector(selectDashboardState, (state: DashboardState) => {
  return state.dashboard?.expirationDate;
});

export const selectIsLoadingResources = createSelector(selectDashboardState, (state: DashboardState) => {
  return state.dashboard?.data.isLoadingResources;
});

export const selectResources = createSelector(selectDashboardState, (state: DashboardState) => {
  return state.dashboard?.data.resources;
});
