import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router';

@Injectable()
export class SanitizeParamGuard implements CanActivateChild {
  constructor(private _router: Router) {}

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
    const lowercase = this.lowercaseUrl(state.url.toString());
    const url = state.url.toString();
    if (lowercase !== url) {
      return this._router.parseUrl(lowercase);
    } else {
      return true;
    }
  }

  lowercaseUrl(mUrl: string): string {
    const [path, queryParams] = mUrl.split('?');
    if (queryParams) {
      const queryParamTuples = queryParams.split('&');

      const updatedQueryParams = queryParamTuples
        .map(rawParam => {
          const [key, value] = rawParam.split('=');
          return `${key}=${value.toLowerCase()}`;
        })
        .join('&');

      return `${path.toLowerCase()}?${updatedQueryParams}`;
    } else {
      return path.toLowerCase();
    }
  }
}
