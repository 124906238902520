<app-loading-screen-spinner *ngIf="documentLoading$ | async" [show]="true"></app-loading-screen-spinner>

<div class="view-documents" fxLayout="column">
  <div fxLayout *ngIf="countOfDisplayableQuals() > 0" fxLayoutAlign="space-between center" class="documents-header global-body2-menu">
    {{ countOfDisplayableQuals() }} DOCUMENTS

    <div fxLayoutGap="16px">
      <button id="documentsSortToggle" mat-icon-button (click)="sortToggle()" class="global-icon-btn-24w">
        <mat-icon class="sort-button global-icon-gray material-icons md-24" [ngClass]="{ 'sort-button-active': sortByCategory }"> calendar_view_day </mat-icon>
      </button>
      <button id="documentsSortAlpha" mat-icon-button (click)="sortAlpha()" class="global-icon-btn-24w">
        <mat-icon class="global-icon-gray sort-button" [ngClass]="{ 'sort-button-active': !sortByCategory }"> sort_by_alpha </mat-icon>
      </button>
    </div>
  </div>
  <div fxLayout fxLayoutAlign="space-between center" class="documents-header global-body2-menu">
    <mat-chip-list>
      <mat-chip [selected]="currentFilterId === filterIds.active" (click)="filterListByStatus(filterIds.active)">Active</mat-chip>
      <mat-chip [selected]="currentFilterId === filterIds.expired" (click)="filterListByStatus(filterIds.expired)"> Expired</mat-chip>
      <mat-chip [selected]="currentFilterId === filterIds.all" (click)="filterListByStatus(filterIds.all)">All</mat-chip>
    </mat-chip-list>
  </div>
  <cdk-virtual-scroll-viewport class="full-height" [itemSize]="2" *ngIf="!sortByCategory && countOfDisplayableQuals() > 0">
    <div *cdkVirtualFor="let item of getDisplayedQualifications()" class="standard-padding">
      <div *ngIf="item.display !== false" matLine class="mat-body-strong" fxLayout="row" fxLayoutAlign="space-between start">
        <div>
          <div>
            <span class="global-subheader file-name">{{ item.title }}</span>
          </div>
          <div>
            <span class="global-body1 file-date">File Name: {{ item.fileName }}</span>
          </div>
          <div>
            <span class="global-body1 file-date">Upload Date: {{ item.createdDate | date }}</span>
          </div>
          <div *ngIf="item.hasOwnProperty('expirationDate')">
            <span class="global-body1 file-date">Expiration Date: {{ item.expirationDate | date }}</span>
          </div>
        </div>
        <div *ngIf="!editMode">
          <button id="documentsGetDocument" (click)="getDocument(item)" mat-icon-button class="global-icon-btn-24w">
            <mat-icon class="a material-icons md-24 global-icon-gray">visibility</mat-icon>
          </button>
        </div>
        <div *ngIf="editMode">
          <button id="documentsDeleteDocument" mat-icon-button (click)="deleteDocument(item)" class="global-icon-btn-24w">
            <mat-icon class="global-error-color-text">remove_circle</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </cdk-virtual-scroll-viewport>
  <cdk-virtual-scroll-viewport [itemSize]="2" class="full-height" *ngIf="sortByCategory && countOfDisplayableQuals() > 0">
    <div class="category-list" fxLayout="column" fxLayoutGap="8px">
      <mat-expansion-panel *cdkVirtualFor="let category of categories" class="catagory-container" id="category-panels">
        <mat-expansion-panel-header>
          <mat-panel-title class="global-subheader">
            <div fxLayout="row">
              <mat-icon fxLayout="column" fxLayoutAlign="start center" class="material-icons md-24 global-icon-gray category-icon">{{ category.icon }}</mat-icon>
              <span fxLayout="column" fxLayoutAlign="center" class="category-text">{{ category.displayName }} </span>
              <span fxLayout="column" fxLayoutAlign="center" *ngIf="category.getDisplayNumber() > 0" class="category-number"> ({{ category.getDisplayNumber() }})</span>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-list-item *ngFor="let item of category.docs">
          <div *ngIf="item.display !== false" matLine class="mat-body-strong" fxLayout="row" fxLayoutAlign="space-between start">
            <div>
              <div>
                <span class="global-subheader file-name">{{ item.title }}</span>
              </div>
              <div>
                <span class="global-body1 file-date">File Name: {{ item.fileName }}</span>
              </div>
              <div>
                <span class="global-body1 file-date">Upload Date: {{ item.createdDate | date }}</span>
              </div>
              <div *ngIf="item.hasOwnProperty('expirationDate')">
                <span class="global-body1 file-date">Expiration Date: {{ item.expirationDate | date }}</span>
              </div>
            </div>
            <div *ngIf="!editMode">
              <button id="documentsNonEditGetDocument" (click)="getDocument(item)" mat-icon-button class="global-icon-btn-24w">
                <mat-icon class="a material-icons md-24 global-icon-gray">visibility</mat-icon>
              </button>
            </div>
            <div *ngIf="editMode">
              <button id="documentsNonEditDeleteDocument" mat-icon-button (click)="deleteDocument(item)" class="global-icon-btn-24w">
                <mat-icon class="global-error-color-text">remove_circle</mat-icon>
              </button>
            </div>
          </div>
        </mat-list-item>
      </mat-expansion-panel>
    </div>
  </cdk-virtual-scroll-viewport>
  <app-empty-list fxFlex *ngIf="countOfDisplayableQuals() === 0" [word]="'Documents'" [buttonAction]="goToBulkUpload">
    <img src="/assets/img/icon-document-library.svg" />
  </app-empty-list>

  <mat-divider></mat-divider>
  <div class="footer no-shrink" fxLayout="column" fxLayoutAlign="start start" fxLayoutAlign.gt-sm="center center">
    <button id="documentsGoToBulkUpload" class="global-dropgo-btn" mat-stroked-button (click)="goToBulkUpload()">
      <mat-icon class="global-icon-gray material-icons md-24">camera_alt</mat-icon>
      <span class="line-break global-dropgo-btn-text">SNAP OR UPLOAD DOCUMENTS</span>
    </button>
  </div>
</div>
<ng-template #customTemplate>
  <div fxLayout="row" fxLayoutAlign="end center" class="full-width" *ngIf="countOfDisplayableQuals() !== 0">
    <button id="documentsEdit" mat-button class="global-header-alert-btn global-icon-gray" *ngIf="!editMode" (click)="edit(true)">
      <mat-icon>edit</mat-icon>
    </button>
    <button id="documentsClose" mat-button class="global-header-alert-btn global-icon-gray" *ngIf="editMode" (click)="edit(false)">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</ng-template>
