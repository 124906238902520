<ng-container
  *ngIf="{
    recruiterData: recruiter$ | async,
    recruiterDataLoading: recruiterDataLoading$ | async,
    nurseData: nurse$ | async,
    nurseDataLoading: nurseDataLoading$ | async,
    isIN: isIN$ | async
  } as observables"
>
  <app-loading-screen-spinner [show]="observables.recruiterDataLoading || observables.nurseDataLoading"></app-loading-screen-spinner>
  <div fxLayout="column" class="padding" *ngIf="!observables.nurseDataLoading && !observables.recruiterDataLoading">
    <div class="main-content" fxLayout="column" fxLayoutGap="16px">
      <div fxLayout="column" class="global-body2-menu">
        <span>REPORT AN ISSUE</span>
      </div>
      <div fxLayout="column" class="global-body1">
        <span>Let us know if parts of our app didn’t work the way you thought it would or if you are experiencing a technical issue.</span>
      </div>
      <form [formGroup]="bugReport" (ngSubmit)="onSubmit()">
        <div fxLayout="column">
          <mat-form-field>
            <input matInput required placeholder="Message subject" formControlName="subject" />
          </mat-form-field>
        </div>
        <div fxLayout="column">
          <mat-form-field>
            <input matInput required placeholder="Description of issue" formControlName="description" />
          </mat-form-field>
        </div>
        <div fxLayout="column">
          <button id="supportSendIssue" class="fill search-button" [disabled]="isSaving" type="submit" mat-raised-button color="primary">SEND ISSUE</button>
        </div>
      </form>
      <div fxLayout="row" fxLayoutGap="16px">
        <div class="divider">OR</div>
      </div>
      <div *ngIf="observables.recruiterData" fxLayout="column" fxLayoutGap="16px">
        <div fxLayout="column" class="global-body2-menu">
          <span *ngIf="observables.isIN; else elseContactTitle">CONTACT YOUR CURRENT ASSIGNEE</span>
          <ng-template #elseContactTitle>CONTACT A RECRUITER</ng-template>
        </div>
        <div fxLayout="column" class="global-body1">
          <span *ngIf="observables.isIN; else elseContactSubitle">Get in touch with your current assignee.</span>
          <ng-template #elseContactSubitle>Get in touch with a recruiter for questions about jobs, process, status, or submission.</ng-template>
        </div>
        <div fxLayout="column">
          <div fxLayout="column" class="global-body2-menu">
            {{ observables.recruiterData.name }}
          </div>
          <div class="global-body1">
            {{ observables.recruiterData.title }}
          </div>
        </div>
        <div fxLayout="row" fxLayoutGap="16px" *ngIf="observables.recruiterData.email">
          <div>
            <mat-icon class="global-icon-gray">email</mat-icon>
          </div>
          <div (click)="contactRecruiter()">
            <a href="mailto:{{ observables.recruiterData.email }}">{{ observables.recruiterData.email }}</a>
          </div>
        </div>
        <div fxLayout="row" fxLayoutGap="16px" *ngIf="observables.recruiterData.phone">
          <div>
            <mat-icon class="global-icon-gray">phone</mat-icon>
          </div>
          <div (click)="contactRecruiterPhone(observables.recruiterData.phone)">
            <a href="tel:{{ observables.recruiterData.phone }}">{{ observables.recruiterData.phone }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
