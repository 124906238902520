import { FormControl } from '@angular/forms';
import { IDropdownData } from 'hc-design-system-lib/lib/components/form/form.interfaces';
import { Moment } from 'moment';
import { TaskStatusConstants } from './task-status';

export class Certification {
  id: string;
  candidateId: string;
  certificationId: string;
  certificationName?: string;
  expirationDate: Date;
  statusId: number;
  qualificationStatusId: TaskStatusConstants;
  type?: number;
  document?: string;
  documentId?: number;
  files?: Array<string> = [];
  qualificationId?: string;
  issuingBodyId: string;
  issuingDate?: Date;
  isProfessionalCert?: boolean;
  isIelts?: boolean;
  testType?: number;
}

export class HcinCertification {
  id: string;
  candidateId: string;
  certificationId: string;
  certificationName?: string;
  complianceId: string;
  isProfessionalCert: boolean;
  isIelts?: boolean;
  expirationDate: Date;
  statusId: number;
  qualificationStatusId: TaskStatusConstants;
  type?: number;
  document?: string;
  documentId?: number;
  files?: Array<string> = [];
  qualificationId?: string;
  issuingBody: string;
  issuingBodyName: string;
  issueDate?: Date;
  testType?: number;
}

export class CertificationForm {
  certificationId: FormControl<IDropdownData>;
  expirationDate: FormControl<Moment>;
  issuingBodyId: FormControl<IDropdownData>;
  issuingDate: FormControl<Moment>;
  fileUploadFront: FormControl<File[]>;
  fileUploadBack: FormControl<File[]>;
  fileUploadPreview: FormControl<File[]>;
}

export function MapHcinCertificateToHctn(hcinCert: HcinCertification): Certification {
  let cert = new Certification();
  for (const key in hcinCert) {
    switch (key) {
      case 'issuingBody':
        cert.issuingBodyId = hcinCert[key];
        break;
      case 'issueDate':
        cert.issuingDate = hcinCert[key];
        break;
      default:
        cert[key] = hcinCert[key];
    }
  }

  return cert;
}

export function MapHctnCertificateToHcin(cert: Certification): HcinCertification {
  let hcinCert = new HcinCertification();
  for (const key in cert) {
    switch (key) {
      case 'issuingBodyId':
        hcinCert.issuingBody = cert[key];
        break;
      case 'issuingDate':
        hcinCert.issueDate = cert[key];
        break;
      default:
        hcinCert[key] = cert[key];
    }
  }

  return hcinCert;
}
