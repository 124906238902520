import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable()
export class ApiUrlInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    req = req.clone({ url: this.prepareUrl(req.url) });
    return next.handle(req);
  }

  private isAbsoluteUrl(url: string): boolean {
    const absolutePattern = /^https?:\/\//i;
    return absolutePattern.test(url);
  }

  private prepareUrl(url: string): string {
    if (this.isVersionFile(url) || this.isAsset(url)) {
      return url;
    }

    url = this.isAbsoluteUrl(url) ? url : environment.apiBaseUrl + '/' + url;
    return url.replace(/([^:]\/)\/+/g, '$1');
  }

  // We make an HTTP call to a local file to get the version information. The
  // existing guard logic with URLs was conflicting with the relative URL
  isVersionFile(url: string): boolean {
    return url.indexOf('version.json') > 0;
  }

  //Used to allow relative pathing on assets files
  isAsset(url: string): boolean {
    const bool = url.includes('assets/');
    return bool;
  }
}
