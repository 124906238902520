import { IFile } from 'hc-design-system-lib/lib/components/form/file-upload/file-upload.interfaces';

export const generateFileTruncationSettings = (settings?: ITruncationSettings) => {
  const truncationSettings = {
    maxLength: settings?.maxLength || defaultTruncationSettings.maxLength,
    extLength: settings?.extLength || defaultTruncationSettings.extLength
  };

  return (inputFile: IFile) => {
    const fileName = inputFile.fileName;

    if (fileName?.length > truncationSettings.maxLength) {
      let extensionFound = fileName.includes('.');
      let ext, displayString;
      if (extensionFound) {
        let sections = fileName.split('.');
        ext = sections.pop();
        displayString = sections.join('.').slice(0, truncationSettings.maxLength - ext.length);
      } else {
        ext = fileName.slice(fileName.length - truncationSettings.extLength);
        displayString = fileName.slice(0, truncationSettings.maxLength - truncationSettings.extLength);
      }
      return `${displayString}...${ext}`;
    } else {
      return fileName;
    }
  };
};

export const defaultTruncationSettings: ITruncationSettings = {
  maxLength: 50,
  extLength: 4,
  mobileMaxLength: 20
};

export interface ITruncationSettings {
  maxLength?: number;
  extLength?: number;
  mobileMaxLength?: number;
}
