import { Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { BodySize, CardElevation, DialogService, HeadingSize, IDialogParameters, LinkSize, LinkTarget } from 'hc-design-system-lib';
import { ICardConfig, ITileCardConfig } from 'hc-design-system-lib/lib/components/cards/cards.interfaces';
import { HcEvent } from 'hc-design-system-lib/lib/models/hc-event';
import { Observable, of, combineLatest, Subject } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { ILookups, PortalStatuses, Submittal, SubmittalLinks } from 'src/app/common';
import { SubmittalsService } from 'src/app/services/submittals.service';
import { GetJobSubmittals } from 'src/app/store/jobs/jobs.actions';
import { selectJobSubmittalsByUserType, selectJobSubmittalsLoadingByUserType } from 'src/app/store/jobs/jobs.selectors';
import { selectLookups } from 'src/app/store/lookups/lookups.selectors';
import { selectCanSeeTravel } from 'src/app/store/userContext/userContext.selectors';
import { WEEKLY_RATES_REMINDER } from 'src/app/common/models/job-constants';

@Component({
  selector: 'app-current-submission-carousel',
  templateUrl: './current-submission-carousel.component.html'
})
export class CurrentSubmissionCarouselComponent implements OnInit, OnDestroy {
  @Input() hiddenStatuses: PortalStatuses[] = [];
  @Input() hiddenLinks: SubmittalLinks[] = [];
  @Input() completedTasks: boolean;
  @ViewChild('filDialogTemplate')
  filDialogTemplate: TemplateRef<any>;

  linkTarget: LinkTarget = LinkTarget.Self;
  linkSize: LinkSize = LinkSize.Body;
  bodySize: BodySize = BodySize.Body;
  headingSize: HeadingSize = HeadingSize.H2;
  headingSizeH3: HeadingSize = HeadingSize.H3;
  headingSizeH6: HeadingSize = HeadingSize.H6;
  weeklyRatesReminder = WEEKLY_RATES_REMINDER;
  submittalCards$: Observable<ICardConfig[]> = of([]);
  jobSubmittals$: Observable<Submittal[]> = this._store.select(selectJobSubmittalsByUserType);
  jobSubmittalsLoading$: Observable<boolean> = this._store.select(selectJobSubmittalsLoadingByUserType);
  canSeeTravel$: Observable<boolean> = this._store.select(selectCanSeeTravel);
  lookups: ILookups;
  lookups$: Observable<ILookups> = this._store.select(selectLookups);

  private readonly destroy$ = new Subject<void>();

  constructor(
    private _store: Store,
    public _dialog: MatDialog,
    private _dialogService: DialogService,
    private _submittalsService: SubmittalsService
  ) {}

  ngOnInit() {
    this.initializeLookups();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  initializeLookups(): void {
    this.lookups$.pipe(takeUntil(this.destroy$)).subscribe(lookups => {
      this.submittalCards$ = this.getJobSubmittals();
    });
  }

  getCurrentSubmissionTileDetails(canSeeTravel: boolean): ITileCardConfig {
    return {
      title: 'Current Submissions',
      bodyText: null,
      promptText: canSeeTravel ? 'Learn how First in Line works' : null,
      linkDetails: {
        title: 'View All',
        routerLink: '/my-submittals'
      }
    };
  }

  getJobSubmittals(): Observable<ICardConfig[]> {
    return combineLatest([this.jobSubmittals$]).pipe(
      tap(([jobSubmittals]) => {
        if (jobSubmittals === null) {
          this._store.dispatch(new GetJobSubmittals());
        }
      }),
      map(([jobSubmittals]) => this.mapSubmittalsToCards(jobSubmittals))
    );
  }

  mapSubmittalsToCards(jobSubmittals) {
    const submittalDetails: ICardConfig[] = [];
    if (jobSubmittals && jobSubmittals.length > 0) {
      jobSubmittals.forEach((submittal: Submittal) => {
        const mappedCard = this._submittalsService.mapSubmittalToCard(submittal, this.hiddenLinks, this.hiddenStatuses);
        if (mappedCard) {
          submittalDetails.push(mappedCard);
        }
      });
    }
    return submittalDetails;
  }

  submittalCardLinkClicked(event) {
    this._submittalsService.submittalCardLinkClicked(event, this.completedTasks);
  }

  submittalCardHelp(event) {
    this._submittalsService.submittalCardHelp(event);
  }

  jobCardClicked(event: HcEvent, recommended = false): void {
    this._submittalsService.jobCardClicked(event, recommended);
  }

  showFirstInLineModal(): void {
    const dialogData: IDialogParameters = {
      title: 'First in Line',
      text: '',
      showCloseIcon: true,
      elevation: CardElevation.Default,
      icon: undefined,
      template: this.filDialogTemplate
    };
    this._dialogService.showDialog(dialogData);
  }
}
