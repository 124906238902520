import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavHelper } from 'src/app/services';
import { ButtonAppearance } from 'hc-design-system-lib/lib/components/button/button.enums';
import { MatDialog } from '@angular/material/dialog';
import { IAppState } from 'src/app/store/app/app.state';
import { Store } from '@ngrx/store';
import { ApplyToSpecificJob } from 'src/app/store/jobs/jobs.actions';
import { IJob } from 'src/app/common';

@Component({
  selector: 'app-start-application-dialog',
  templateUrl: './start-application-dialog.component.html',
  styleUrls: ['./start-application-dialog.component.scss']
})
export class StartApplicationDialogComponent {
  @Input() job: IJob;

  primaryButtonAppearance = ButtonAppearance.Primary;
  secondaryButtonAppearence = ButtonAppearance.Secondary;

  constructor(
    public _route: ActivatedRoute,
    private navHelper: NavHelper,
    public _dialog: MatDialog,
    private _store: Store<IAppState>
  ) {}

  apply(): void {
    if ((!this.job.applied && !this.job.hasStarted) || (this.job.applied && this.job.hasWithdrawn && !this.job.hasCompletedApplication)) {
      this._store.dispatch(new ApplyToSpecificJob(this.job, '', false, this.job?.recommendedJob, this.job.contractType));
      sessionStorage.setItem('InProgressApplication', this.job.id);
    }
    this.navHelper.goToJobApplication(this.job.id);
    this._dialog.closeAll();
  }

  cancel(): void {
    this._dialog.closeAll();
  }
}
