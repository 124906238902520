import { Injectable, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { BadgeColor, FlagColor, IFlagDetails, LinkType } from 'hc-design-system-lib';

import { ButtonColor, ButtonSize } from 'hc-design-system-lib/lib/components/button/button.enums';
import { IBadgeDetails, ICardConfig, ICardDetailSection, ILinkDetails } from 'hc-design-system-lib/lib/components/cards/cards.interfaces';
import { HcEvent } from 'hc-design-system-lib/lib/models/hc-event';
import moment from 'moment';
import { Observable, Subscription } from 'rxjs';
import {
  AssignmentReasonSubStagesArray,
  AssignmentSources,
  AssignmentStageConstants,
  ILookups,
  PortalStatuses,
  Submittal,
  SubmittalLinks,
  SubmittalType,
  WithdrawnReasonsSubstageArray
} from '../common';
import { Interview } from '../common/models/interview-model';
import { RemoveSubmittalPopupComponent } from '../components';
import { InterviewPopupComponent } from '../components/shared/interview-popup/interview-popup.component';
import { StatusInfoPopupComponent } from '../components/shared/status-info-popup/status-info-popup.component';
import { IAppState } from '../store/app/app.state';
import { ArchiveSubmittal, ClearSpecificJob, GetJobSubmittals } from '../store/jobs/jobs.actions';
import { selectLookups } from '../store/lookups/lookups.selectors';
import { NavHelper } from './nav-helper.service';
import { ContractType } from '../common/contracts/contract-type';
import { HelpModalComponent } from '../components/shared/help-modal-component/help-modal-component';
import { AssignmentsHelpModal } from '../components/shared/help-modal-component/help-modal.constants';

@Injectable({
  providedIn: 'root'
})
export class SubmittalsService implements OnDestroy {
  lookups: ILookups;
  lookupsSubscription: Subscription;
  lookups$: Observable<ILookups> = this._store.select(selectLookups);

  constructor(
    private _store: Store<IAppState>,
    private _dialog: MatDialog,
    private _navHelper: NavHelper
  ) {
    this.initializeLookups();
  }

  ngOnDestroy(): void {
    this.lookupsSubscription?.unsubscribe();
  }

  initializeLookups(): void {
    this.lookupsSubscription = this.lookups$.subscribe(lookups => {
      this.lookups = lookups;
    });
  }

  mapSubmittalToCard(submittal: Submittal, hiddenLinks = [], hiddenStatuses = []) {
    let submittalDetails: ICardConfig;
    if (!hiddenStatuses.includes(submittal.portalStatus)) {
      submittalDetails = {
        title: this.calculateJobTitle(submittal),
        secondaryText: submittal.workSite,
        tertiaryText: `${submittal.city + ', ' + submittal.state}`,
        detailSectionConfig: this.calculateDetailSectionConfig(submittal),
        isCardActionActive: false,
        cardActionIconOverride: 'help',
        primaryBadge: this.calculateSubmittalPrimaryBadge(submittal),
        badgeDetails: this.calculateSubmittalBadges(submittal),
        flagDetails: this.calculateSubmittalFlagDetails(submittal, submittal.portalStatus),
        cardData: {
          jobId: submittal.job.id,
          portalStatus: submittal.portalStatus,
          submittal: submittal
        },
        linkDetails: this.getSubmittalCardLinks(submittal, submittal.portalStatus, hiddenLinks)
      };
    }
    return submittalDetails;
  }

  calculateJobTitle(submittal: Submittal): string {
    const specialty = this.lookups.specialtyLookup.get(submittal.specialty);
    const prof = this.lookups.professionLookup.get(submittal.profession);
    const profName = prof != null ? prof.shortName : null;
    let jobName = '';
    if (prof && specialty) {
      jobName = specialty.name + (submittal.submittalType == SubmittalType.Travel ? ' Travel ' : ' ') + profName;
    } else {
      jobName = (submittal.submittalType == SubmittalType.Travel ? 'Travel ' : '') + profName;
    }
    return jobName;
  }

  calculateDetailSectionConfig(submittal: Submittal): ICardDetailSection[] {
    if (submittal.submittalType == SubmittalType.International) {
      return null;
    } else {
      return [
        {
          sectionHeading: 'Est. Weekly Pay',
          sectionText: this.getEstWeeklyPay(submittal)
        },
        {
          sectionHeading: 'Start Date',
          sectionText: this.formatStartDate(submittal.startDate)
        },
        {
          sectionHeading: 'Shift',
          sectionText: this.getShift(submittal.shift, this.lookups)
        }
      ];
    }
  }

  getEstWeeklyPay(submittal: Submittal): string {
    if (
      submittal.portalStatus === PortalStatuses.OfferDeclined ||
      submittal.portalStatus === PortalStatuses.OfferAccepted ||
      submittal.portalStatus === PortalStatuses.Offered ||
      submittal.portalStatus === PortalStatuses.OnAssignment ||
      submittal.portalStatus === PortalStatuses.AssignmentComplete
    ) {
      return 'See TACL';
    }

    let pay = submittal.contractType === ContractType.Local ? submittal.job.localEstWeeklyGross : submittal.job.estGrossPay;

    return pay !== undefined ? `$${pay}` : '----';
  }

  getShift(id: number, lookups, anyShift: boolean = false): string {
    // Update this to correctly map shifts
    const x = lookups.shiftLookup.get(id);
    return x != null ? (!anyShift ? x.name : 'n/a') : 'Any Shift';
  }

  formatStartDate(date: Date): string {
    if (typeof date === 'undefined') {
      return 'Soon';
    }
    const today = new Date();
    const startDate = new Date(date);
    if (startDate <= today) {
      return 'ASAP';
    }

    return moment(startDate).format('MM/DD/YYYY');
  }

  calculateSubmittalPrimaryBadge(submittal: Submittal): IBadgeDetails | null {
    if (submittal.submittalType == SubmittalType.International) {
      return null;
    } else {
      return {
        title: submittal.contractType,
        color: submittal.contractType == ContractType.Travel ? BadgeColor.Blue : BadgeColor.Green
      };
    }
  }

  calculateSubmittalBadges(submittal: Submittal): IBadgeDetails[] | null {
    const submittalBadgeArray = [];

    if (submittal.submittalType == SubmittalType.International) {
      if (submittal.facilityIN?.jointCommission) {
        submittalBadgeArray.push({
          title: 'Joint Comm. Accredited',
          color: BadgeColor.Green
        });
      }
      if (submittal.facilityIN?.traumaLevel != undefined) {
        submittalBadgeArray.push({
          title: 'Trauma Center',
          color: BadgeColor.Green
        });
      }
      if (submittal.facilityIN?.teachingFacility != undefined) {
        submittalBadgeArray.push({
          title: 'Teaching Facility',
          color: BadgeColor.Green
        });
      }
      if (submittal.facilityIN?.awards === 'ANCC') {
        submittalBadgeArray.push({
          title: 'Magnet Hospital',
          color: BadgeColor.Green
        });
      }
    } else {
      if (submittal.assignmentSource == AssignmentSources.FIL) {
        submittalBadgeArray.push({
          title: 'First in Line',
          color: BadgeColor.Green
        });
      }
    }

    return submittalBadgeArray.length ? submittalBadgeArray : null;
  }

  getPortalStatusDisplay(submittal: Submittal): number {
    if (submittal.job.closed && submittal.portalStatus !== PortalStatuses.NoLongerAvailable) {
      return PortalStatuses.NoLongerAvailable;
    }

    return submittal.portalStatus;
  }

  calculateSubmittalFlagDetails(submittal: Submittal, portalStatus): IFlagDetails | null {
    if (submittal.assnStage === AssignmentStageConstants.Application && this.lookups.yesNoOnlyLookup.get('No').id === submittal.archived) {
      return null;
    }

    switch (portalStatus) {
      case PortalStatuses.InterviewScheduled: {
        return {
          icon: 'timelapse',
          color: FlagColor.Purple,
          text: 'INTERVIEW SCHEDULED',
          secondaryText: null
        };
      }
      case PortalStatuses.InterviewCompleted: {
        return {
          icon: 'check_circle_outline',
          color: FlagColor.Purple,
          text: 'INTERVIEW COMPLETED',
          secondaryText: null
        };
      }
      case PortalStatuses.OnAssignment: {
        return {
          icon: 'card_travel',
          color: FlagColor.Green,
          text: 'ON ASSIGNMENT',
          secondaryText: null
        };
      }
      case PortalStatuses.OfferAccepted: {
        return {
          icon: 'workspace_premium',
          color: FlagColor.Green,
          text: 'OFFER ACCEPTED',
          secondaryText: 'Contact Recruiter.'
        };
      }
      case PortalStatuses.OfferDeclined: {
        return {
          color: FlagColor.Gray,
          text: 'OFFER DECLINED',
          icon: 'cancel',
          secondaryText: null
        };
      }
      case PortalStatuses.Offered: {
        return {
          color: FlagColor.Green,
          text: 'CONGRATULATIONS! OFFER EXTENDED',
          secondaryText: 'Contact Recruiter.',
          icon: 'workspace_premium'
        };
      }
      case PortalStatuses.NoLongerAvailable: {
        let secondaryText = null;
        if (WithdrawnReasonsSubstageArray.includes(submittal.assnSubstage)) {
          secondaryText = `WITHDRAWN: ${moment(submittal.submittalWithdrawnDate).format('MM/DD')}`;
        } else {
          secondaryText = `NO LONGER AVAILABLE: ${moment(submittal.jobOrderInactiveDate).format('MM/DD h:mm a')}`;
        }
        return {
          color: FlagColor.Gray,
          text: 'NO LONGER AVAILABLE',
          secondaryText,
          icon: 'error'
        };
      }
      case PortalStatuses.ActionRequired: {
        return {
          color: FlagColor.Red,
          text: 'CANDIDATE ACTION NEEDED',
          secondaryText: null,
          icon: 'error'
        };
      }
      case PortalStatuses.PendingReview: {
        return {
          color: FlagColor.DarkBlue,
          text: 'PENDING REVIEW',
          secondaryText: null,
          icon: 'pending'
        };
      }
      case PortalStatuses.HospitalReview: {
        let secondaryText = null;
        if (moment(submittal.estimatedFeedback).isSameOrAfter(moment(), 'day')) {
          secondaryText = `Estimated feedback by ${moment(submittal.estimatedFeedback).format('MM/DD')}.`;
        } else if (moment(submittal.estimatedFeedback2).isSameOrAfter(moment(), 'day')) {
          secondaryText = `Estimated feedback by ${moment(submittal.estimatedFeedback2).format('MM/DD')}.`;
        } else {
          secondaryText = `Estimated feedback by ${moment(submittal.estimatedFeedback3).format('MM/DD')}.`;
        }
        return {
          color: FlagColor.Purple,
          text: 'HOSPITAL REVIEW:',
          secondaryText,
          icon: 'apartment'
        };
      }
      default: {
        return {
          color: FlagColor.Gray,
          text: 'STATUS UNKNOWN',
          secondaryText: 'Contact Recruiter.',
          icon: 'error'
        };
      }
    }
  }

  submittalCardShouldShowSimilarJobsButton(submittal: Submittal, portalStatus, hiddenLinks): boolean {
    return (
      !hiddenLinks.includes(SubmittalLinks.viewSimilarJobs) &&
      (portalStatus === PortalStatuses.NoLongerAvailable || moment(submittal.estimatedFeedback3).isBefore(moment(), 'day'))
    );
  }

  submittalCardShouldShowInterviewButton(submittal: Submittal, portalStatus, hiddenLinks): boolean {
    return !hiddenLinks.includes(SubmittalLinks.interview) && portalStatus === PortalStatuses.HospitalReview;
  }

  submittalIsPending(submittal: Submittal): boolean {
    return submittal.assnStage === AssignmentStageConstants.Application && this.lookups.yesNoOnlyLookup.get('No').id === submittal.archived;
  }

  submittalCardShouldShowArchiveButton(submittal: Submittal, portalStatus, hiddenLinks): boolean {
    const linkIsNotHidden = !hiddenLinks.includes(SubmittalLinks.viewSimilarJobs);
    const declinedOrNoLongerAvailable = submittal.portalStatus === PortalStatuses.OfferDeclined || portalStatus === PortalStatuses.NoLongerAvailable;
    return linkIsNotHidden && declinedOrNoLongerAvailable && this.lookups.yesNoOnlyLookup.get('Yes').id !== submittal.archived;
  }

  submittalCanBeWithdrawn(submittal: Submittal, portalStatus): boolean {
    return portalStatus === PortalStatuses.PendingReview || submittal.portalStatus === PortalStatuses.HospitalReview;
  }

  submittalIsNoLongerAvailable(submittal: Submittal, portalStatus): boolean {
    return portalStatus === PortalStatuses.NoLongerAvailable;
  }

  submittalIsInProgress(submittal: Submittal): boolean {
    return submittal.assnStage == AssignmentStageConstants.Application;
  }

  getSubmittalCardLinks(submittal: Submittal, portalStatus, hiddenLinks): ILinkDetails[] {
    let links: ILinkDetails[] = [];
    if (submittal.submittalType == SubmittalType.International) {
      return links;
    }
    if (this.submittalIsInProgress(submittal)) {
      if (submittal.jobOrderInactiveDate && submittal.matchingInactiveJobOrder) {
        links.push({
          title: 'Transfer this application',
          linkType: LinkType.LinkButton,
          message: {
            text: SubmittalLinks.transferApplication,
            data: submittal
          }
        });
      } else {
        links.push({
          title: 'Finish Application',
          linkType: LinkType.ButtonPrimary,
          buttonOptions: {
            size: ButtonSize.Narrow
          },
          message: { text: SubmittalLinks.finishApplication, data: submittal }
        });
      }
    }
    if (this.submittalCardShouldShowSimilarJobsButton(submittal, portalStatus, hiddenLinks)) {
      links.push({
        title: 'View Similar Jobs',
        linkType: LinkType.LinkButton,
        message: { text: SubmittalLinks.viewSimilarJobs, data: submittal }
      });
    }
    if (this.submittalCardShouldShowInterviewButton(submittal, portalStatus, hiddenLinks) && !this.submittalIsPending(submittal)) {
      links.push({
        title: `${submittal.interviewNotes ? 'Update' : 'Add'} Interview Notes`,
        linkType: LinkType.LinkButton,
        message: { text: SubmittalLinks.interview, data: submittal }
      });
    }
    if (this.submittalCardShouldShowArchiveButton(submittal, portalStatus, hiddenLinks)) {
      links.push({
        title: 'Archive',
        linkType: LinkType.LinkButton,
        message: { text: SubmittalLinks.archive, data: submittal }
      });
    }
    if (
      (this.submittalCanBeWithdrawn(submittal, portalStatus) || this.submittalIsPending(submittal)) &&
      !hiddenLinks.includes(SubmittalLinks.withdrawApplication) &&
      !this.submittalIsNoLongerAvailable(submittal, portalStatus)
    ) {
      if (this.submittalIsInProgress(submittal)) {
        // N.B. unshift() is called rather than push() so that the button appears before 'Finsh Application'
        links.unshift({
          title: 'Delete Application',
          linkType: LinkType.ButtonText,
          buttonOptions: {
            color: ButtonColor.Red,
            size: ButtonSize.Narrow
          },
          message: { text: SubmittalLinks.deleteApplication, data: submittal }
        });
      } else {
        links.push({
          title: 'Withdraw Application',
          linkType: LinkType.ButtonText,
          buttonOptions: {
            color: ButtonColor.Red,
            size: ButtonSize.Narrow
          },
          message: {
            text: SubmittalLinks.withdrawApplication,
            data: submittal
          }
        });
      }
    }

    return links;
  }

  submittalCardLinkClicked(event, completedTasks: boolean) {
    const link = event?.data?.jobCardData?.cardLink;

    switch (link?.message.text) {
      case SubmittalLinks.finishApplication: {
        this._navHelper.goToJobsSpecificCustomParams(link.message.data.jobOrderId, {
          finishApplication: true,
          completedTask: completedTasks,
          contractType: link.message.data.contractType
        });
        break;
      }

      case SubmittalLinks.viewSimilarJobs: {
        this._navHelper.goToSimilarJobs(link.message.data.jobOrderId, link.message.data.contractType);
        break;
      }

      case SubmittalLinks.interview: {
        const dlg = this._dialog.open(InterviewPopupComponent, {
          panelClass: 'interview-popup-dialog',
          data: {
            assignmentid: link.message.data.id,
            interviewDate: link.message.data.interviewDate,
            interviewNotes: link.message.data.interviewNotes,
            interviewer: link.message.data.interviewer
          } as Interview,
          autoFocus: false,
          minHeight: '135px',
          minWidth: '300px',
          width: '50vw',
          maxWidth: '450px',
          restoreFocus: false
        });
        dlg.afterClosed().subscribe(o => {
          this._store.dispatch(new GetJobSubmittals());
        });
        break;
      }

      case SubmittalLinks.archive: {
        const id = link.message.data.id;
        this._store.dispatch(new ArchiveSubmittal({ id }));
        break;
      }

      case SubmittalLinks.withdrawApplication: {
        const dlg = this._dialog.open(RemoveSubmittalPopupComponent, {
          data: {
            assignmentId: link.message.data.id,
            stageId: link.message.data.assnStage
          },
          minHeight: '135px',
          minWidth: '300px',
          width: '50vw',
          maxWidth: '450px'
        });

        // Removes selected Submittal
        dlg.componentInstance.outputOnConfirm.subscribe(() => {
          this._store.dispatch(new GetJobSubmittals());
        });

        // Removes ALL submittals
        dlg.componentInstance.outputRemoveAll.subscribe(() => {
          this._store.dispatch(new GetJobSubmittals());
        });
        break;
      }

      case SubmittalLinks.deleteApplication: {
        const dlg = this._dialog.open(RemoveSubmittalPopupComponent, {
          data: {
            assignmentId: link.message.data.id,
            stageId: link.message.data.assnStage
          },
          minHeight: '135px',
          minWidth: '300px',
          width: '50vw',
          maxWidth: '450px'
        });

        // Removes selected Submittal
        dlg.componentInstance.outputOnConfirm.subscribe(() => {
          this._store.dispatch(new GetJobSubmittals());
          this._store.dispatch(new ClearSpecificJob());
        });

        // Removes ALL submittals
        dlg.componentInstance.outputRemoveAll.subscribe(() => {
          this._store.dispatch(new GetJobSubmittals());
        });
        break;
      }

      case SubmittalLinks.transferApplication: {
        sessionStorage.setItem('transferredApplicationId', `${link.message.data.id}:${link.message.data.matchingInactiveJobOrder}`);
        this._navHelper.goToJobsSpecific(link.message.data.id);
        break;
      }

      default: {
        break;
      }
    }
  }

  submittalCardHelp(event: HcEvent) {
    const jobData = event?.data?.jobCardData?.jobData;
    if (jobData['submittal'].submittalType == SubmittalType.International) {
      this._dialog.open(HelpModalComponent, {
        panelClass: 'help-modal-dialog',
        data: AssignmentsHelpModal
      });
    } else {
      this._dialog.open(StatusInfoPopupComponent, {
        panelClass: 'status-info-popup',
        data: jobData['portalStatus'] || null,
        autoFocus: false,
        minHeight: '135px',
        minWidth: '300px',
        width: '90vw',
        maxWidth: '450px',
        restoreFocus: false
      });
    }
  }

  jobCardClicked(event: HcEvent, recommended = false): void {
    const jobData = event?.data?.jobCardData?.jobData;
    const jobId = jobData['jobId'] || null;
    const contractType = jobData['submittal']?.contractType || null;
    const submittalType = jobData['submittal']?.submittalType || null;
    const isTravel = submittalType != SubmittalType.International;

    let params = {};
    if (isTravel) {
      params = {
        recommendedJob: recommended,
        contractType
      };
    }

    if (jobId) {
      this._navHelper.goToJobsSpecificCustomParams(jobId, params, isTravel);
    }
  }

  isNoLongerAvailableAndSubmitted(submittal: Submittal) {
    return (
      this.getPortalStatusDisplay(submittal) === PortalStatuses.NoLongerAvailable &&
      ![...AssignmentReasonSubStagesArray, AssignmentStageConstants.Application].includes(submittal.assnSubstage) &&
      !submittal.submittalWithdrawnDate &&
      !submittal.matchingInactiveJobOrder
    );
  }
}
