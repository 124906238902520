import { Component, Input } from '@angular/core';
import { Recruiter } from 'src/app/common/models/recruiter';
import { ButtonAppearance } from 'hc-design-system-lib/lib/components/button/button.enums';
import { MatDialog } from '@angular/material/dialog';
import { ContactRecruiterPopUpComponent, ContactRecruiterType } from '../contact-recruiter-pop-up/contact-recruiter-pop-up.component';
import { NursePortalApi } from 'src/app/services';
import { Store } from '@ngrx/store';
import { selectNurseData } from 'src/app/store/userContext/userContext.selectors';
import { IAppState } from 'src/app/store/app/app.state';
import { combineLatest, Observable } from 'rxjs';
import { selectRecruiterData } from 'src/app/store/recruiter/recruiter.selectors';
import { filter, take, map } from 'rxjs/operators';
import { IJobFilterAPI, NurseModel } from 'src/app/common';
import { ContractType } from 'src/app/common/contracts/contract-type';
import { selectJobFilterV2 } from 'src/app/store/jobs/jobs.selectors';

@Component({
  selector: 'app-contact-recruiter-tray',
  templateUrl: './contact-recruiter-tray.component.html',
  styleUrls: ['./contact-recruiter-tray.component.scss']
})
export class ContactRecruiterTrayComponent {
  @Input() contactRecruiterType: ContactRecruiterType;
  buttonAppearanceSecondary = ButtonAppearance.Secondary;
  showContent = true;
  emailSent = false;
  recruiter$: Observable<Recruiter> = this._store.select(selectRecruiterData);
  nurse$: Observable<NurseModel> = this._store.select(selectNurseData);
  jobSearchFilter$: Observable<IJobFilterAPI> = this._store.select(selectJobFilterV2);

  constructor(
    public _api: NursePortalApi,
    public _dialog: MatDialog,
    private _store: Store<IAppState>
  ) {}

  contactRecruiterTrayEmailSend(): void {
    combineLatest([this.recruiter$, this.nurse$, this.jobSearchFilter$])
      .pipe(
        filter(([recruiter, nurse]) => !!recruiter && !!nurse),
        take(1),
        map(([recruiter, nurse, jobSearchFilter]): [Recruiter, NurseModel, IJobFilterAPI] => [recruiter, nurse, jobSearchFilter])
      )
      .subscribe(([recruiter, nurse, jobSearchFilter]: [Recruiter, NurseModel, IJobFilterAPI]) => {
        const jobSearchParameters: IJobFilterAPI = this.contactRecruiterType === ContactRecruiterType.JobSearch ? jobSearchFilter : null;

        const data = {
          a: recruiter,
          contactRecruiterType: this.contactRecruiterType,
          contractType: jobSearchParameters?.contractType ?? ContractType.Travel,
          jobSearchParameters
        };
        this.openContactRecruiterPopup(data);
      });
  }

  openContactRecruiterPopup(data): void {
    this._dialog.open(ContactRecruiterPopUpComponent, {
      data,
      panelClass: 'contact-recruiter-dialog',
      autoFocus: false
    });
  }
}
