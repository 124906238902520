import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AppUrls } from '../app-urls';
import { IAppState } from 'src/app/store/app/app.state';
import { Store } from '@ngrx/store';
import { IUserModel } from 'src/app/common';
import { selectUserData } from 'src/app/store/userContext/userContext.selectors';
import { map, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ContactSelectGuard implements CanActivate {
  user$: Observable<IUserModel> = this._store.select(selectUserData);

  constructor(
    private _router: Router,
    private _store: Store<IAppState>
  ) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.user$.pipe(
      take(1),
      map((userData: IUserModel) => {
        if (userData.isInternal !== true) {
          return this._router.parseUrl(AppUrls.DASHBOARD);
        }

        return true;
      })
    );
  }
}
