<ng-container *ngIf="(candidateFacilitiesCount$ | async) > 0; else noResults">
  <div *ngIf="successful; else unsuccessful">
    <div class="facilities-carousel successful" *ngIf="facilityParameters.facility || facilityParameters.location">
      <div class="facilities-carousel-heading">
        <hc-heading [size]="h6">Matching Facilities at this location</hc-heading>
      </div>
      <hc-card-carousel-v2 [facilityCardData]="facilityCards" (facilityCardButtonClicked)="postFacilityApplication($event)" [grid]="grid" [gridBreakpoints]="gridBreakpoints">
      </hc-card-carousel-v2>
    </div>
  </div>
  <ng-template #unsuccessful>
    <div>
      <!--Facility, no location-->
      <div *ngIf="(facilityParameters.facility && !facilityParameters.location) || facilityCards.length === 1; else multipleFacilities" class="no-results-container">
        <div class="no-results-text">
          We couldn't find you a job at this facility. You can try changing your search criteria, or express your interest directly to the facility.
        </div>
        <div class="facilities-carousel">
          <hc-facility-card [facilityConfig]="getFacilityById(facilityParameters.facility)" (buttonClicked)="postFacilityApplication()"></hc-facility-card>
        </div>
        <div *ngIf="isMobile$ | async" class="try-a-new-search-button">
          <hc-button (buttonClick)="backToSearch()">Try a new search</hc-button>
        </div>
      </div>
      <ng-template #multipleFacilities>
        <div class="no-results-container">
          <div class="no-results-text" *ngIf="!facilityParameters.facility && facilityParameters.location; else multipleParameters">
            We couldn't find you a job at this location. You can try changing your search criteria, or express your interest directly to matching facilities at this location.
          </div>
          <ng-template #multipleParameters>
            <div class="no-results-text">
              We couldn't find you a job at this facility. You can try changing you search criteria, or express your interest directly to the facilities in the area.
            </div>
          </ng-template>
          <div class="facilities-carousel">
            <div class="facilities-carousel-heading" *ngIf="!facilityParameters.facility && facilityParameters.location">
              <hc-heading [size]="h6">Matching Facilities at this location</hc-heading>
            </div>
            <hc-card-carousel-v2
              [facilityCardData]="facilityCards"
              (facilityCardButtonClicked)="postFacilityApplication($event)"
              [grid]="grid"
              [gridBreakpoints]="gridBreakpoints"
            >
            </hc-card-carousel-v2>
          </div>
          <div *ngIf="isMobile$ | async" class="try-a-new-search-button">
            <hc-button (buttonClick)="backToSearch()">Try a new search</hc-button>
          </div>
        </div>
      </ng-template>
    </div>
  </ng-template>
  <ng-template #popupTemplate>
    <hc-body style="margin-bottom: 4px"> Interested in getting first in line for opportunities with this facility? </hc-body>
    <hc-body> We'll send your profile to the hiring manager so that you're the first to know when they have a job that meets your preferences. </hc-body>
  </ng-template>
</ng-container>
<ng-template #noResults>
  <div *ngIf="!successful">
    <div class="no-results">
      <img class="no-results-image" src="/assets/img/job-search-empty.svg" alt="no results image" *ngIf="isMobile$ | async" />
      <div class="no-results-text" [ngClass]="{ desktop: isMobile$ | async }">We couldn’t find any jobs for you. Try changing your search criteria.</div>
    </div>
    <div *ngIf="isMobile$ | async" class="no-results-try-a-new-search-button">
      <hc-button (buttonClick)="backToSearch()">Try a new search</hc-button>
    </div>
  </div>
</ng-template>
