<ng-container
  *ngIf="{
    recommendedJobsByPreferenceLoading: recommendedJobsByPreferenceLoading$ | async,
    recommendedJobCardConfigs: recommendedJobCardConfigs$ | async
  } as obs"
>
  <hc-tile-card
    *ngIf="obs.recommendedJobsByPreferenceLoading || obs.recommendedJobCardConfigs?.length"
    [cardConfig]="cardConfig"
    [templateReference]="jobCardCarousel"
    (promptClicked)="showRecommendationDialog()"
  >
  </hc-tile-card>

  <ng-template #jobCardCarousel>
    <hc-card-carousel
      [isLoading]="obs.recommendedJobsByPreferenceLoading"
      [skeletonCardsCount]="3"
      [cardData]="obs.recommendedJobCardConfigs"
      (cardClicked)="jobCardClicked($event, true)"
      (cardActionClicked)="toggleSavedJob($event)"
    ></hc-card-carousel>
  </ng-template>

  <ng-template #recommendedDialogTemplate>
    <app-recommended-jobs-popup></app-recommended-jobs-popup>
  </ng-template>
</ng-container>
