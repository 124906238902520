import { AfterViewChecked, Component } from '@angular/core';
import { AppState, AuthenticationService } from 'src/app/services';

@Component({
  selector: 'app-inactive-user',
  templateUrl: './inactive-user.component.html',
  styleUrls: ['./inactive-user.component.scss']
})
export class InactiveUserComponent implements AfterViewChecked {
  constructor(
    private _appState: AppState,
    private _authService: AuthenticationService
  ) {}

  ngAfterViewChecked() {
    this._appState.setSpinnerState(false);
  }

  logout() {
    this._authService.logout();
  }
}
