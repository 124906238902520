import { createSelector } from '@ngrx/store';
import { IAppState } from '../app/app.state';

const selectUiState = (state: IAppState) => state.ui;

export const selectIsMobile = createSelector(selectUiState, state => state.screenWidth < 959);
export const selectIsMobileNonTablet = createSelector(selectUiState, state => state.screenWidth < 600);
export const selectIsTablet = createSelector(selectUiState, state => state.screenWidth >= 600 && state.screenWidth < 959);

export const selectTabbarData = createSelector(selectUiState, state => state.tabBar);

export const selectMenuItems = createSelector(selectUiState, state => state.menuItems);

export const selectEnvironmentId = createSelector(selectUiState, state => state.enviromentId);

export const selectNavigationProfile = createSelector(selectUiState, state => state.navigationProfile);
