import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { AuthenticationService } from '../services';
import { AppInsights } from '../shared';
import { environment } from 'src/environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthenticationService,
    private appInsights: AppInsights
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let scope = this.authService.getScopesForEndpoint(req.url);

    return from(
      this.authService.getAccessToken(scope).then(token => {
        return this.handleToken(req, token);
      })
    ).pipe(
      switchMap(newReq => {
        return next.handle(newReq).pipe(
          tap(
            event => {
              // no action for success
            },
            (err: unknown) => {
              if (err instanceof HttpErrorResponse) {
                this.handleHttpErrors(err, newReq);
              }
            }
          )
        );
      })
    );
  }

  private handleToken(req: HttpRequest<any>, token: string) {
    let isHCINLookups = req.url.includes(environment.internationalApiBaseUrl) && req.url.toLocaleLowerCase().includes('lookups');
    if (token && !isHCINLookups) {
      const headers = req.headers.set('Authorization', `Bearer ${token}`);
      let requestClone = req.clone({ headers });
      return requestClone;
    } else {
      return req;
    }
  }

  private handleHttpErrors(err: HttpErrorResponse, request: HttpRequest<any>) {
    const callDetails = this.appInsights.trackRequest(request, 'using temporary stored token');
    callDetails.logErrorResponse(err);
    if (err.status === 401) {
      // let the auth service handle logging out the user when we receive a 401.
      this.authService.userUnathenticated$.next(true);
    }
  }
}
