import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IJob, ILookup, NurseModel } from 'src/app/common';
import { Location } from '@angular/common';
import { NavHelper } from 'src/app/services/nav-helper.service';
import { FormHeaderService } from 'src/app/services';
import { ContactRecruiterPopUpComponent, ContactRecruiterType } from '../../shared/contact-recruiter-pop-up/contact-recruiter-pop-up.component';
import { MatDialog } from '@angular/material/dialog';
import { Observable, Subscription, combineLatest } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectNurseData } from 'src/app/store/userContext/userContext.selectors';
import { IAppState } from 'src/app/store/app/app.state';
import { GetSpecificJob } from 'src/app/store/jobs/jobs.actions';
import { selectHasAutoOfferAssignmentsData, selectHasAutoOfferAssignmentsLoading, selectSpecificJob, selectSpecificJobLoading } from 'src/app/store/jobs/jobs.selectors';
import { Recruiter } from 'src/app/common/models/recruiter';
import { selectPayRecruiterData, selectPayRecruiterLoading } from 'src/app/store/recruiter/recruiter.selectors';
import { selectProfessionLookup, selectSpecialtyLookup } from '../../../store/lookups/lookups.selectors';

@Component({
  selector: 'app-auto-offer-confirmation-review',
  templateUrl: './auto-offer-confirmation.component.html',
  styleUrls: ['./auto-offer-confirmation.component.scss']
})
export class AutoOfferConfirmationComponent implements OnInit, OnDestroy {
  job: IJob;
  payRecruiter: Recruiter;

  nurse$: Observable<NurseModel> = this._store.select(selectNurseData);
  specificJob$: Observable<IJob> = this._store.select(selectSpecificJob);
  hasAutoOfferAssignments$: Observable<boolean> = this._store.select(selectHasAutoOfferAssignmentsData);
  payRecruiter$: Observable<Recruiter> = this._store.select(selectPayRecruiterData);

  nurseSubscription: Subscription;
  jobByIdSubscription: Subscription;
  payRecruiterSubscription: Subscription;
  lookupsSubscription: Subscription;

  professionLookup: Map<string, ILookup<string>>;
  professionLookup$ = this._store.select(selectProfessionLookup);

  specialtyLookup: Map<string, ILookup<string>>;
  specialtyLookup$ = this._store.select(selectSpecialtyLookup);

  payRecruiterLoading$: Observable<boolean> = this._store.select(selectPayRecruiterLoading);
  specificJobLoading$: Observable<boolean> = this._store.select(selectSpecificJobLoading);
  hasAutoOfferAssignmentsLoading$: Observable<boolean> = this._store.select(selectHasAutoOfferAssignmentsLoading);

  constructor(
    private _store: Store<IAppState>,
    private _route: ActivatedRoute,
    private _location: Location,
    private _navHelper: NavHelper,
    private _dialog: MatDialog,
    private _formHeaderService: FormHeaderService
  ) {}

  ngOnInit() {
    this.addPayRecruiterSubscription();
    this.addLookupsSubscription();
    this.dispatchGetJobById();
  }

  ngOnDestroy(): void {
    this.payRecruiterSubscription?.unsubscribe();
    this.nurseSubscription?.unsubscribe();
    this.lookupsSubscription?.unsubscribe();
  }

  addLookupsSubscription(): void {
    this.lookupsSubscription = combineLatest([this.professionLookup$, this.specialtyLookup$]).subscribe(
      ([professionLookup, specialtyLookup]: [Map<string, ILookup<string>>, Map<string, ILookup<string>>]) => {
        this.professionLookup = professionLookup;
        this.specialtyLookup = specialtyLookup;

        this.addJobByIdSubscription();
      }
    );
  }

  addJobByIdSubscription(): void {
    this.jobByIdSubscription = this.specificJob$.subscribe((job: IJob) => {
      if (job) {
        this.job = job;
        const specialty = this.specialtyLookup.get(job.specialty)?.name;
        const profession = this.professionLookup.get(job.profession)?.name;

        if (specialty && profession) {
          this._formHeaderService.setTitle(`Apply for ${specialty} Travel ${profession}`);
        }
      }
    });
  }
  addPayRecruiterSubscription(): void {
    this.payRecruiterSubscription = this.payRecruiter$.subscribe((payRecruiter: Recruiter) => {
      if (payRecruiter) {
        this.payRecruiter = payRecruiter;
      }
    });
  }

  dispatchGetJobById(): void {
    const jobId = this._route.snapshot.params['id'];

    if (jobId) {
      this._store.dispatch(new GetSpecificJob(jobId));
    }
  }

  continue() {
    this._navHelper.goToApplicationReview(this.job.id);
  }

  contactRecruiter(): void {
    const job = [{ id: this.job.id, name: this.job.name }];
    this._dialog.open(ContactRecruiterPopUpComponent, {
      data: { a: this.payRecruiter, b: job, contactRecruiterType: ContactRecruiterType.AutoOffer },
      panelClass: 'contact-recruiter-dialog',
      autoFocus: false
    });
  }

  back(): void {
    this._location.back();
  }
}
