import { EducationActions, EEducationActions } from './education.actions';
import { initialEducationState, EducationState } from './education.state';

export function educationReducer(state = initialEducationState, action: EducationActions): EducationState {
  switch (action.type) {
    case EEducationActions.GetEducationList:
      return {
        ...state,
        educations: {
          ...state.educations,
          error: null,
          loading: true
        }
      };

    case EEducationActions.GetEducationListSuccess:
      return {
        ...state,
        educations: {
          data: action.payload.educations,
          error: null,
          loading: false,
          expirationDate: action.payload.expirationDate
        }
      };

    case EEducationActions.GetEducationListError:
      return {
        ...state,
        educations: {
          data: null,
          error: action.error,
          loading: false,
          expirationDate: null
        }
      };

    case EEducationActions.GetEducation:
      return {
        ...state,
        education: {
          ...state.education,
          error: null,
          loading: true
        },
        fileUrl: {
          ...state.fileUrl,
          data: action.educationId === state.education.data?.id ? state.fileUrl.data : null
        }
      };

    case EEducationActions.GetEducationSuccess:
      return {
        ...state,
        education: {
          data: action.payload.education,
          error: null,
          loading: false,
          expirationDate: action.payload.expirationDate
        },
        fileUrl: {
          ...state.fileUrl,
          data: action.payload.fileUrl
        }
      };

    case EEducationActions.GetEducationError:
      return {
        ...state,
        education: {
          data: null,
          error: action.error,
          loading: false,
          expirationDate: null
        },
        fileUrl: {
          ...state.fileUrl,
          data: null
        }
      };

    case EEducationActions.AddEducation:
      return {
        ...state,
        addEducationResult: {
          ...state.addEducationResult,
          loading: true
        }
      };

    case EEducationActions.AddEducationSuccess:
      const appendedEducation = [...(state.educations.data || []), action.payload.education];
      return {
        ...state,
        education: {
          data: null,
          error: null,
          loading: false,
          expirationDate: null
        },
        educations: {
          ...state.educations,
          data: appendedEducation,
          expirationDate: null
        },
        addEducationResult: {
          ...state.addEducationResult,
          loading: false,
          data: action.payload.education.id
        }
      };

    case EEducationActions.AddEducationFileError:
      const appendedEducation2 = [...(state.educations.data || []), action.payload.education];
      return {
        ...state,
        education: {
          data: action.payload.education,
          error: null,
          loading: false,
          expirationDate: null
        },
        educations: {
          ...state.educations,
          data: appendedEducation2,
          expirationDate: null
        },
        addEducationResult: {
          ...state.addEducationResult,
          loading: false,
          error: action.error,
          data: action.payload.education.id
        }
      };
    case EEducationActions.AddEducationError:
      return {
        ...state,
        addEducationResult: {
          ...state.addEducationResult,
          loading: false,
          error: action.error
        }
      };

    case EEducationActions.AddEducationReset:
      return {
        ...state,
        addEducationResult: {
          error: null,
          data: null,
          loading: false
        }
      };

    case EEducationActions.UpdateEducation:
      return {
        ...state,
        updateEducationResult: {
          ...state.updateEducationResult,
          loading: true
        }
      };

    case EEducationActions.UpdateEducationSuccess:
      let updatedEducation = [];
      if (state.educations.data) {
        const toUpdateIndex = state.educations.data.findIndex(edu => edu.id === action.payload.education.id);
        updatedEducation = [...state.educations.data.slice(0, toUpdateIndex), action.payload.education, ...state.educations.data.slice(toUpdateIndex + 1)];
      } else {
      }

      return {
        ...state,
        education: {
          data: null,
          error: null,
          loading: false,
          expirationDate: null
        },
        educations: {
          ...state.educations,
          data: updatedEducation
        },
        updateEducationResult: {
          ...state.updateEducationResult,
          loading: false,
          data: action.payload.education.id
        }
      };

    case EEducationActions.UpdateEducationFileError:
      let updatedEducation2 = [];
      if (state.educations.data) {
        const toUpdateIndex = state.educations.data.findIndex(edu => edu.id === action.payload.education.id);
        updatedEducation2 = [...state.educations.data.slice(0, toUpdateIndex), action.payload.education, ...state.educations.data.slice(toUpdateIndex + 1)];
      }

      return {
        ...state,
        education: {
          data: null,
          error: null,
          loading: false,
          expirationDate: null
        },
        educations: {
          ...state.educations,
          data: updatedEducation2
        },
        updateEducationResult: {
          ...state.updateEducationResult,
          loading: false,
          data: action.payload.education.id,
          error: action.error
        }
      };

    case EEducationActions.UpdateEducationError:
      return {
        ...state,
        updateEducationResult: {
          ...state.updateEducationResult,
          loading: false,
          error: action.error
        }
      };

    case EEducationActions.UpdateEducationReset:
      return {
        ...state,
        updateEducationResult: {
          error: null,
          data: null,
          loading: false
        }
      };

    case EEducationActions.DeleteEducation:
      return {
        ...state,
        deleteEducationResult: {
          ...state.addEducationResult,
          loading: true
        }
      };

    case EEducationActions.ResetEducationExpiryDate:
      return {
        ...state,
        education: {
          ...state.education,
          error: null,
          loading: false,
          expirationDate: null
        }
      };

    case EEducationActions.DeleteEducationSuccess:
      const toDeleteIndex = state.educations.data.findIndex(edu => edu.id === action.id);
      const educationList = [...state.educations.data.slice(0, toDeleteIndex), ...state.educations.data.slice(toDeleteIndex + 1)];
      return {
        ...state,
        education: {
          data: null,
          error: null,
          loading: false,
          expirationDate: null
        },
        educations: {
          ...state.educations,
          data: educationList
        },
        deleteEducationResult: {
          ...state.deleteEducationResult,
          loading: false,
          data: action.id
        }
      };

    case EEducationActions.DeleteEducationError:
      return {
        ...state,
        deleteEducationResult: {
          ...state.addEducationResult,
          loading: false,
          error: action.error
        }
      };

    case EEducationActions.DeleteEducationReset:
      return {
        ...state,
        deleteEducationResult: {
          error: null,
          data: null,
          loading: false
        }
      };

    default:
      return state;
  }
}
