import { Component, TemplateRef } from '@angular/core';
import { FormHeaderService } from 'src/app/services';
import { HeadingSize } from 'hc-design-system-lib/lib/typography/components/heading/heading.component';
import { BehaviorSubject } from 'rxjs';
import { ButtonSize } from 'hc-design-system-lib/lib/components/button/button.enums';

@Component({
  selector: 'app-form-header',
  templateUrl: './form-header.component.html',
  styleUrls: ['./form-header.component.scss']
})
export class FormHeaderComponent {
  constructor(private _formService: FormHeaderService) {}

  get title(): BehaviorSubject<string> {
    return this._formService.title;
  }

  get subTitle(): BehaviorSubject<string> {
    return this._formService.subTitle;
  }

  get showBackButton(): BehaviorSubject<boolean> {
    return this._formService.showBackButton;
  }

  get showEditButton(): BehaviorSubject<boolean> {
    return this._formService.showEditButton;
  }

  get editButtonIcon(): BehaviorSubject<string> {
    return this._formService.editButtonIcon;
  }

  get showSaveButton(): BehaviorSubject<boolean> {
    return this._formService.showSaveButton;
  }

  get disableActions(): BehaviorSubject<boolean> {
    return this._formService.disableActions;
  }

  get customContent(): BehaviorSubject<TemplateRef<any>> {
    return this._formService.customContent;
  }

  get showFormActions(): boolean {
    return this._formService.showFormActions;
  }

  get headingSize(): HeadingSize {
    return HeadingSize.H4;
  }

  get buttonSize(): ButtonSize {
    return ButtonSize.Small;
  }

  sendSave() {
    this._formService.save.emit();
  }

  sendOpenOptions() {
    this._formService.openOptions.emit();
  }

  back() {
    this._formService.goBack();
  }
}
