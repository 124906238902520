import { ELicensesActions, LicensesActions } from './licenses.actions';
import { initialLicensesState, LicensesState } from './licenses.state';

export function licensesReducer(state = initialLicensesState, action: LicensesActions): LicensesState {
  switch (action.type) {
    case ELicensesActions.GetLicense:
      return {
        ...state,
        license: {
          ...state.license,
          error: null,
          loading: true
        },
        fileUrl: {
          ...state.fileUrl,
          data: action.id === state.license.data?.id ? state.fileUrl.data : null
        }
      };

    case ELicensesActions.GetLicenseSuccess:
      return {
        ...state,
        license: {
          data: action.payload.license,
          error: null,
          loading: false,
          expirationDate: action.payload.expiration
        },
        fileUrl: {
          ...state.fileUrl,
          data: action.payload.fileUrl
        }
      };

    case ELicensesActions.GetLicenseError:
      return {
        ...state,
        license: {
          data: null,
          error: action.error,
          loading: false,
          expirationDate: null
        },
        fileUrl: {
          ...state.fileUrl,
          data: null
        }
      };

    case ELicensesActions.GetLicenses:
      return {
        ...state,
        licenses: {
          ...state.licenses,
          error: null,
          loading: true
        }
      };

    case ELicensesActions.GetLicensesSuccess:
      return {
        ...state,
        licenses: {
          data: action.payload.licenses,
          error: null,
          loading: false,
          expirationDate: action.payload.expiration
        }
      };

    case ELicensesActions.GetLicensesError:
      return {
        ...state,
        licenses: {
          data: null,
          error: action.error,
          loading: false,
          expirationDate: null
        }
      };

    case ELicensesActions.GetLicensesIN:
      return {
        ...state,
        licensesIN: {
          ...state.licensesIN,
          error: null,
          loading: true
        }
      };

    case ELicensesActions.GetLicensesINSuccess:
      return {
        ...state,
        licensesIN: {
          data: action.payload.licenses,
          error: null,
          loading: false,
          expirationDate: action.payload.expiration
        }
      };

    case ELicensesActions.GetLicensesINError:
      return {
        ...state,
        licensesIN: {
          data: null,
          error: action.error,
          loading: false,
          expirationDate: null
        }
      };

    case ELicensesActions.CreateLicense:
      return {
        ...state,
        license: {
          ...state.license,
          error: null,
          loading: true
        }
      };

    case ELicensesActions.CreateLicenseSuccess:
      const appendedLicenses = [...(state.licenses.data || []), action.payload.license];

      return {
        ...state,
        license: {
          data: null,
          error: null,
          loading: false,
          expirationDate: null
        },
        licenses: {
          ...state.licenses,
          data: appendedLicenses,
          expirationDate: null
        }
      };

    case ELicensesActions.CreateLicenseFileError:
      const newLicenses = [...(state.licenses.data || []), action.payload.license];

      return {
        ...state,
        license: {
          data: null,
          error: action.error,
          loading: false,
          expirationDate: null
        },
        licenses: {
          ...state.licenses,
          data: newLicenses,
          expirationDate: null
        }
      };

    case ELicensesActions.CreateLicenseError:
      return {
        ...state,
        license: {
          ...state.license,
          error: action.error,
          loading: false
        }
      };

    case ELicensesActions.DeleteLicense:
      return {
        ...state,
        license: {
          ...state.license,
          error: null,
          loading: true
        }
      };

    case ELicensesActions.DeleteLicenseSuccess:
      const toDeleteIndex = state.licenses.data.findIndex(license => license.id === action.id);
      const licenses = [...state.licenses.data.slice(0, toDeleteIndex), ...state.licenses.data.slice(toDeleteIndex + 1)];

      return {
        ...state,
        license: {
          ...state.license,
          data: null,
          error: null,
          loading: false,
          expirationDate: null
        },
        licenses: {
          ...state.licenses,
          data: licenses
        }
      };

    case ELicensesActions.DeleteLicenseError:
      return {
        ...state,
        license: {
          ...state.license,
          error: action.error,
          loading: false
        }
      };

    case ELicensesActions.UpdateLicense:
      return {
        ...state,
        license: {
          ...state.license,
          error: null,
          loading: true
        },
        updateResponse: {
          data: null,
          loading: true,
          error: null
        }
      };

    case ELicensesActions.UpdateLicenseSuccess:
      const toUpdateIndex = state.licenses.data.findIndex(license => license.id === action.payload.license.id);
      const updatedLicenses = [...state.licenses.data.slice(0, toUpdateIndex), action.payload.license, ...state.licenses.data.slice(toUpdateIndex + 1)];

      return {
        ...state,
        license: {
          ...state.license,
          data: null,
          error: null,
          loading: false,
          expirationDate: null
        },
        licenses: {
          ...state.licenses,
          data: updatedLicenses
        },
        updateResponse: {
          ...state.updateResponse,
          data: action.payload.updateResponse,
          loading: false
        }
      };

    case ELicensesActions.UpdateLicenseFileError:
      const toUpdateIndex2 = state.licenses.data.findIndex(license => license.id === action.payload.license.id);
      const updatedLicenses2 = [...state.licenses.data.slice(0, toUpdateIndex2), action.payload.license, ...state.licenses.data.slice(toUpdateIndex2 + 1)];

      return {
        ...state,
        license: {
          ...state.license,
          data: null,
          error: action.error,
          loading: false,
          expirationDate: null
        },
        licenses: {
          ...state.licenses,
          data: updatedLicenses2
        },
        updateResponse: {
          ...state.updateResponse,
          data: action.payload.updateResponse,
          error: action.error,
          loading: false
        }
      };

    case ELicensesActions.UpdateLicenseError:
      return {
        ...state,
        license: {
          ...state.license,
          loading: false,
          error: action.error
        },
        updateResponse: {
          ...state.updateResponse,
          error: action.error,
          loading: false
        }
      };

    case ELicensesActions.GetUpdateDocumentObservable:
      return {
        ...state,
        license: {
          ...state.license,
          loading: true
        }
      };
    case ELicensesActions.NursysAdd:
      return {
        ...state,
        nursysAdd: {
          ...state.nursysAdd,
          data: null,
          error: null,
          loading: true
        },
        nursysUpdate: {
          ...state.nursysUpdate,
          data: null,
          error: null,
          loading: false
        }
      };

    case ELicensesActions.NursysAddSuccess:
      return {
        ...state,
        nursysAdd: {
          ...state.nursysAdd,
          data: action.result,
          error: null,
          loading: false
        },
        licenses: {
          ...state.licenses,
          data: null,
          error: null,
          loading: false,
          expirationDate: null
        }
      };

    case ELicensesActions.NursysAddError:
      return {
        ...state,
        nursysAdd: {
          ...state.nursysAdd,
          error: action.error,
          loading: false
        }
      };

    case ELicensesActions.NursysUpdate:
      return {
        ...state,
        nursysUpdate: {
          ...state.nursysUpdate,
          data: null,
          error: null,
          loading: true
        },
        nursysAdd: {
          ...state.nursysAdd,
          data: null,
          error: null,
          loading: false
        }
      };

    case ELicensesActions.NursysUpdateSuccess:
      return {
        ...state,
        nursysUpdate: {
          ...state.nursysUpdate,
          data: action.result,
          error: null,
          loading: false
        },
        nursysAdd: {
          ...state.nursysAdd,
          loading: true
        }
      };

    case ELicensesActions.NursysUpdateError:
      return {
        ...state,
        nursysUpdate: {
          ...state.nursysUpdate,
          data: null,
          error: action.error,
          loading: false
        },
        nursysAdd: {
          ...state.nursysAdd,
          loading: false
        }
      };

    case ELicensesActions.ResetLicenseExpiryDate:
      return {
        ...state,
        license: {
          ...state.license,
          error: null,
          loading: false,
          expirationDate: null
        }
      };

    default:
      return state;
  }
}
