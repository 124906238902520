import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType, concatLatestFrom } from '@ngrx/effects';
import { NotificationService } from 'hc-design-system-lib';
import { combineLatest, from, of } from 'rxjs';
import { catchError, exhaustMap, filter, map, retry, skipWhile, switchMap, take, tap } from 'rxjs/operators';
import {
  AboutMeModel,
  CandidateQualification,
  FileUploadTargets,
  HcinContactModel,
  IAdUserModel,
  IAdUserResponseModel,
  IFileUploadOptions,
  IRegistrationModel,
  IRegistrationResponseModel,
  IResetPasswordModel,
  IUserModel,
  NurseModel,
  NurseProfileModel,
  PreEmploymentQuestionnaire,
  TaskCompletionReturnObject,
  UserContextIN
} from 'src/app/common';
import { IAccountStateModel } from 'src/app/common/models/account-state-model';
import { AuthenticationService, DocumentHelperService, HCINPortalAPIService, NavHelper, NursePortalApi } from 'src/app/services';
import { UsersApiService } from 'src/app/services/users-api.service';
import {
  CheckVerificationKey,
  CheckVerificationKeyError,
  CheckVerificationKeySuccess,
  ClearCacheError,
  ClearCacheLogOutError,
  ClearCacheLogOutSuccess,
  ClearCacheSuccess,
  ContactSelectResetError,
  ContactSelectResetSuccess,
  CreateAdUser,
  CreateAdUserError,
  CreateAdUserSuccess,
  DeleteDocuments,
  DeleteDocumentsError,
  DeleteDocumentsSuccess,
  EUserContextActions,
  GetAboutMeError,
  GetAboutMeSuccess,
  GetAccountStateError,
  GetAccountStateSuccess,
  GetDocumentsByID,
  GetDocumentsByIDError,
  GetDocumentsByIDSuccess,
  GetDocumentsError,
  GetDocumentsSuccess,
  GetNurseError,
  GetNurseSuccess,
  GetNurseINSuccess,
  GetNurseINError,
  GetProfessionalOverviewError,
  GetProfessionalOverviewSuccess,
  GetQuestionnaireError,
  GetQuestionnaireSuccess,
  GetRegistrationError,
  GetRegistrationSuccess,
  GetSummaryPromptsError,
  GetSummaryPromptsSuccess,
  GetUserError,
  GetUserSuccess,
  InitializeUser,
  InitializeUserError,
  InitializeUserSuccess,
  LogOutError,
  LogOutSuccess,
  PostSignInError,
  PostSignInSuccess,
  ResetUserError,
  ResetUserSuccess,
  SendResetPasswordEmail,
  SendResetPasswordEmailError,
  SendResetPasswordEmailSuccess,
  SendVerificationEmail,
  SendVerificationEmailError,
  SendVerificationEmailSuccess,
  SendVerificationSms,
  SendVerificationSmsError,
  SendVerificationSmsSuccess,
  SendVerificationSupportEmail,
  SendVerificationSupportEmailError,
  SendVerificationSupportEmailSuccess,
  SetNurseIsVerified,
  SetNurseProfilePicture,
  SetShowSpecialtyPopup,
  SetVerificationType,
  SetVerificationTypeError,
  SetVerificationTypeSuccess,
  SubmitRegistration,
  SubmitRegistrationError,
  SubmitRegistrationSuccess,
  UnlockRegistrationError,
  UnlockRegistrationSuccess,
  UpdateAboutMe,
  UpdateAboutMeError,
  UpdateAboutMeReset,
  UpdateAboutMeSuccess,
  UpdateQuestionnaire,
  UpdateQuestionnaireError,
  UpdateQuestionnaireSuccess,
  UpdateSummaryData,
  UpdateSummaryError,
  UpdateSummarySuccess,
  UploadProfilePictureError,
  UploadProfilePictureSuccess,
  UploadProfilePicture,
  UpdateSummaryReset,
  UpdateQuestionnaireReset,
  DeleteUserRequestError,
  DeleteUserRequestSuccess,
  DeleteUserSuccess,
  DeleteUserError,
  DeleteUser,
  UserContextActions,
  GetUserINSuccess,
  GetUserINError,
  GetContactPreferencesSuccess,
  GetContactPreferencesError,
  UpdateContactPreferences,
  UpdateContactPreferencesSuccess,
  UpdateContactPreferencesError,
  PostRegistrationCheckSuccess,
  PostRegistrationCheckError,
  BulkUploadDocuments,
  BulkUploadDocumentsSuccess,
  BulkUploadDocumentsError
} from 'src/app/store/userContext/userContext.actions';
import { Store } from '@ngrx/store';
import {
  selectAccountStateData,
  selectCanSeeInternational,
  selectNurseData,
  selectNurseINData,
  selectQuestionnaireData,
  selectUserData,
  selectUserProfessionSector
} from './userContext.selectors';
import { EWorkHistoryContextActions } from '../workHistory/workHistoryContext.actions';
import { ETasksActions } from 'src/app/store/tasks/tasks.actions';
import { ECertificationsActions, GetCertifications } from 'src/app/store/certifications/certifications.actions';
import { ETechnologyActions } from 'src/app/store/technologies/technology.actions';
import { ELicensesActions, ResetLicenseExpiryDate } from 'src/app/store/licenses/licenses.actions';
import { EEducationActions, ResetEducationExpiryDate } from 'src/app/store/education/education.actions';
import { AngularticsEventTrack } from '../angulartics2/angulartics2.actions';
import { categoryNames } from 'src/app/common/models/documents';
import { selectProfessionalHierarchy, selectShowSpecialtyPopup } from '../lookups/lookups.selectors';
import { VerificationType } from 'src/app/common/constants';
import { updateINContactDataWithAboutMe } from 'src/app/common/functions/nurse-helpers';
import {
  applicationNavItem,
  jobsNavItem,
  onAssignmentNavItem,
  profileNavItem,
  settingsNavItem,
  tabBarDefaultItems
} from 'src/app/components/main-components/main/main.constants';
import { setTabBarItems } from '../ui/ui.actions';
import { selectIsMobile } from '../ui/ui.selectors';

@Injectable({
  providedIn: 'root'
})
export class UserContextEffects {
  constructor(
    private actions$: Actions,
    private _usersApiService: UsersApiService,
    private _nursePortalApi: NursePortalApi,
    private _hcinPortalApi: HCINPortalAPIService,
    private _notificationService: NotificationService,
    private store: Store,
    public _auth: AuthenticationService,
    private _documentHelper: DocumentHelperService,
    private _navHelper: NavHelper
  ) {}

  getAccountState$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<UserContextActions>(
        EUserContextActions.GetAccountState,
        EUserContextActions.PostRegistrationCheckSuccess,
        EUserContextActions.SubmitRegistrationSuccess,
        EUserContextActions.CheckVerificationKeySuccess
      ),
      exhaustMap(() =>
        this._usersApiService.getAccountState().pipe(
          retry(2),
          map((accountStateData: IAccountStateModel) => new GetAccountStateSuccess(accountStateData)),
          catchError((error: Error) => of(new GetAccountStateError(error)))
        )
      )
    );
  });

  getAccountStateError$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<UserContextActions>(EUserContextActions.GetAccountStateError),
      filter((action: GetAccountStateError) => (action.error as HttpErrorResponse).status === 404),
      map(() => new InitializeUser())
    );
  });

  getUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<UserContextActions>(
        EUserContextActions.GetUser,
        EUserContextActions.SubmitRegistrationSuccess,
        EUserContextActions.SetVerificationTypeSuccess,
        EUserContextActions.CheckVerificationKeySuccess
      ),
      exhaustMap(() =>
        this._usersApiService.getUserState().pipe(
          retry(1),
          map((userData: IUserModel) => new GetUserSuccess(userData)),
          catchError((error: Error) => of(new GetUserError(error)))
        )
      )
    );
  });

  getUserIN$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<UserContextActions>(EUserContextActions.GetAccountStateSuccess),
      filter((response: GetAccountStateSuccess) => response.payload.canSeeInternational),
      exhaustMap(() =>
        this._hcinPortalApi.getUser().pipe(
          retry(1),
          map((userData: UserContextIN) => new GetUserINSuccess(userData)),
          catchError((error: Error) => of(new GetUserINError(error)))
        )
      )
    );
  });

  setTabBarItems$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<UserContextActions>(EUserContextActions.GetNurseSuccess, EUserContextActions.GetAccountStateSuccess),
      concatLatestFrom(() => [this.store.select(selectIsMobile), this.store.select(selectNurseData), this.store.select(selectAccountStateData)]),
      map(([action, isMobile, nurseData, accountState]) => {
        let toolbarItems = tabBarDefaultItems;
        if (accountState.canSeeTravel) {
          // TN nurses will see jobs and applications
          toolbarItems = [...toolbarItems, jobsNavItem, applicationNavItem];
        } else {
          // Non-TN nurses will see profile and applications
          toolbarItems = [...toolbarItems, profileNavItem, applicationNavItem];
        }

        if (nurseData && nurseData.hasAssignment) {
          // Nurses with TimeMobile will see on assignment
          toolbarItems = [...toolbarItems, onAssignmentNavItem];
        }

        if (!isMobile) {
          // Desktop users will see settings mobile users can access it via the profile menu
          toolbarItems = [...toolbarItems, settingsNavItem];
        }
        return setTabBarItems({ tabBarItems: [...toolbarItems] });
      })
    );
  });

  createAdUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<UserContextActions>(EUserContextActions.CreateAdUser),
      map((action: CreateAdUser) => action.payload),
      exhaustMap((input: IAdUserModel) =>
        this._usersApiService.createAdUser(input).pipe(
          map((payload: IAdUserResponseModel) => new CreateAdUserSuccess(payload)),
          catchError((error: Error) => of(new CreateAdUserError(error)))
        )
      )
    );
  });

  getNurse$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<UserContextActions>(
        EUserContextActions.GetNurse,
        EUserContextActions.SubmitRegistrationSuccess,
        EUserContextActions.UpdateSummarySuccess,
        EUserContextActions.UpdateAboutMeSuccess,
        EUserContextActions.SetVerificationTypeSuccess,
        EUserContextActions.CheckVerificationKeySuccess
      ),
      exhaustMap(() =>
        this._usersApiService.getNurseState().pipe(
          retry(1),
          map((nurseData: NurseModel) => {
            return new GetNurseSuccess(nurseData);
          }),
          catchError((error: Error) => of(new GetNurseError(error)))
        )
      )
    );
  });

  getNurseIN$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<UserContextActions>(EUserContextActions.GetNurseIN, EUserContextActions.UpdateSummarySuccess, EUserContextActions.UpdateAboutMeSuccess),
      concatLatestFrom(() => this.store.select(selectCanSeeInternational)),
      exhaustMap(([, canSeeInternational]) => {
        if (!canSeeInternational) {
          return of(new GetNurseINSuccess(null));
        }
        return this._hcinPortalApi.getNurseState().pipe(
          retry(1),
          map((nurseData: HcinContactModel) => {
            return new GetNurseINSuccess(nurseData);
          }),
          catchError((error: Error) => of(new GetNurseINError(error)))
        );
      })
    );
  });

  contactSelectReset$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<UserContextActions>(EUserContextActions.ContactSelectReset),
      exhaustMap((input: any) =>
        this._nursePortalApi.contactSelectReset(input).pipe(
          map((contactSelectResetData: any) => new ContactSelectResetSuccess(contactSelectResetData)),
          catchError((error: Error) => of(new ContactSelectResetError(error)))
        )
      )
    );
  });

  submitRegistration$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<UserContextActions>(EUserContextActions.SubmitRegistration),
      map((action: SubmitRegistration) => action.payload),
      exhaustMap((input: IRegistrationModel) =>
        this._usersApiService.postRegisterV2(input).pipe(
          map((payload: IRegistrationResponseModel) => new SubmitRegistrationSuccess(payload)),
          catchError((error: Error) => of(new SubmitRegistrationError(error)))
        )
      )
    );
  });

  unlockRegistration$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<UserContextActions>(EUserContextActions.UnlockRegistration),
      exhaustMap(() =>
        this._nursePortalApi.unlockRegistationLock().pipe(
          map(() => new UnlockRegistrationSuccess()),
          catchError((error: Error) => of(new UnlockRegistrationError(error)))
        )
      )
    );
  });

  getRegistration$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<UserContextActions>(EUserContextActions.GetRegistration),
      exhaustMap(() =>
        this._usersApiService.getRegistration().pipe(
          map((payload: IRegistrationModel) => new GetRegistrationSuccess(payload)),
          catchError((error: Error) => of(new GetRegistrationError(error)))
        )
      )
    );
  });

  resetUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<UserContextActions>(EUserContextActions.ResetUser),
      exhaustMap(() =>
        this._nursePortalApi.resetUser().pipe(
          map((response: any) => new ResetUserSuccess(response)),
          catchError((error: Error) => of(new ResetUserError(error)))
        )
      )
    );
  });

  postSignIn$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<UserContextActions>(EUserContextActions.PostSignIn),
      exhaustMap(() =>
        this._usersApiService.postSignIn().pipe(
          map((response: any) => new PostSignInSuccess(response)),
          catchError((error: Error) => of(new PostSignInError(error)))
        )
      )
    );
  });

  clearCache$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<UserContextActions>(EUserContextActions.ClearCache, EUserContextActions.CheckVerificationKeySuccess),
      exhaustMap(() =>
        this._nursePortalApi.clearCache().pipe(
          map((response: any) => new ClearCacheSuccess(response)),
          catchError((error: Error) => of(new ClearCacheError(error)))
        )
      )
    );
  });

  clearCacheLogOut$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<UserContextActions>(EUserContextActions.ClearCacheLogOut, EUserContextActions.GetAccountStateError, EUserContextActions.InitializeUserError),
      exhaustMap(() =>
        this._nursePortalApi.clearCache().pipe(
          map((response: any) => new ClearCacheLogOutSuccess(response)),
          catchError((error: Error) => of(new ClearCacheLogOutError(error)))
        )
      )
    );
  });

  logOut$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<UserContextActions>(EUserContextActions.ClearCacheLogOutSuccess),
      exhaustMap(() =>
        from(
          this._auth.logout().then(x => {
            return x;
          })
        ).pipe(
          map((response: any) => new LogOutSuccess(response)),
          catchError((error: Error) => of(new LogOutError(error)))
        )
      )
    );
  });

  // TODO: why are we reloading? Moved this logic over from main.component.ts
  reloadWindow$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<UserContextActions>(EUserContextActions.ReloadWindow, EUserContextActions.ResetUserSuccess),
      tap(() => window.location.reload())
    );
  });

  initializeUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<UserContextActions>(EUserContextActions.InitializeUser),
      exhaustMap(() =>
        this._usersApiService.initializeUser().pipe(
          retry(2),
          map((response: HttpResponse<{ email: string }>) => new InitializeUserSuccess(response.status === 201, response.body.email)),
          catchError((error: Error) => of(new InitializeUserError(error)))
        )
      )
    );
  });

  getProfessionalOverview$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<UserContextActions>(EUserContextActions.GetProfessionalOverview, EUserContextActions.UpdateSummarySuccess, EUserContextActions.CheckVerificationKeySuccess),
      exhaustMap(() =>
        this._nursePortalApi.getProfessionalOverview().pipe(
          retry(2),
          map((professionalOverviewData: NurseProfileModel) => new GetProfessionalOverviewSuccess(professionalOverviewData)),
          catchError((error: Error) => of(new GetProfessionalOverviewError(error)))
        )
      )
    );
  });

  updateSummary$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<UserContextActions>(EUserContextActions.UpdateSummaryData),
      exhaustMap((action: UpdateSummaryData) =>
        this._nursePortalApi.updateSummary(action.summaryData).pipe(
          retry(2),
          map((summaryModel: TaskCompletionReturnObject) => {
            const model: any = {
              completionId: summaryModel.completionId,
              returnValue: summaryModel.returnValue,
              Userspeciality: action.summaryData.userSpecialty,
              UserSubSpeciality: action.summaryData.userSubSpecialty,
              UserProfession: action.summaryData.userProfession
            };
            return new UpdateSummarySuccess(model);
          }),
          catchError((error: Error) => of(new UpdateSummaryError(error)))
        )
      )
    );
  });

  updateSummarySuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<UserContextActions>(EUserContextActions.UpdateSummarySuccess),
      tap(() => this._notificationService.showNotification('Your response was saved successfully!', 'success')),
      map(() => new UpdateSummaryReset())
    );
  });

  updateSummaryError$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType<UserContextActions>(EUserContextActions.UpdateSummaryError),
        tap(() => {
          this._notificationService.showNotification(`We were unable to save your changes. Please try again.`, 'error');
        })
      );
    },
    { dispatch: false }
  );

  getSummaryPrompts$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<UserContextActions>(EUserContextActions.GetSummaryPrompts, EUserContextActions.CheckVerificationKeySuccess),
      exhaustMap(() =>
        this._nursePortalApi.getSummaryPrompts().pipe(
          retry(2),
          map((summaryPrompts: string[]) => new GetSummaryPromptsSuccess(summaryPrompts)),
          catchError((error: Error) => of(new GetSummaryPromptsError(error)))
        )
      )
    );
  });

  getAboutMe$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<UserContextActions>(EUserContextActions.GetAboutMe),
      exhaustMap(() =>
        this._nursePortalApi.getAboutMe().pipe(
          retry(1),
          map((aboutMeData: AboutMeModel) => new GetAboutMeSuccess(aboutMeData)),
          catchError((error: Error) => of(new GetAboutMeError(error)))
        )
      )
    );
  });

  sendResetPasswordEmail$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<UserContextActions>(EUserContextActions.SendResetPasswordEmail),
      map((action: SendResetPasswordEmail) => action.payload),
      exhaustMap((input: IResetPasswordModel) =>
        this._usersApiService.sendResetPasswordEmail(input).pipe(
          map(() => new SendResetPasswordEmailSuccess()),
          catchError((error: Error) => of(new SendResetPasswordEmailError(error)))
        )
      )
    );
  });

  updateAboutMe$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<UserContextActions>(EUserContextActions.UpdateAboutMe),
      concatLatestFrom(() => [this.store.select(selectCanSeeInternational), this.store.select(selectNurseINData)]),
      exhaustMap(([action, canSeeInternational, nurseINData]: [UpdateAboutMe, boolean, NurseModel]) =>
        (canSeeInternational
          ? this._hcinPortalApi.updateAboutMe(updateINContactDataWithAboutMe(action.payload, nurseINData))
          : this._usersApiService.updateAboutMe(action.payload)
        ).pipe(
          retry(2),
          map((returnObj: TaskCompletionReturnObject) => new UpdateAboutMeSuccess(returnObj)),
          catchError((error: Error) => of(new UpdateAboutMeError(error)))
        )
      )
    );
  });

  updateAboutMeSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<UserContextActions>(EUserContextActions.UpdateAboutMeSuccess),
      tap(() => this._notificationService.showNotification('Your profile changes have been successfully saved.', 'success')),
      map(() => new UpdateAboutMeReset())
    );
  });

  updateAboutMeError$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType<UserContextActions>(EUserContextActions.UpdateAboutMeError),
        tap((action: UpdateAboutMeError) => this._notificationService.showNotification(`Failed to save: ${action.error}`, 'error'))
      );
    },
    { dispatch: false }
  );

  uploadProfilePicture$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<UserContextActions>(EUserContextActions.UploadProfilePicture),
      concatLatestFrom(() => [this.store.select(selectNurseData), this.store.select(selectNurseINData), this.store.select(selectCanSeeInternational)]),
      exhaustMap(([action, nurse, nurseIN, canSeeInternational]: [UploadProfilePicture, NurseModel, NurseModel, boolean]) => {
        const options: IFileUploadOptions = {
          itemId: canSeeInternational ? nurseIN.merlinId : nurse.merlinId,
          target: FileUploadTargets.ProfilePicture
        };

        // HCIN API doesn't return the file, so we need to convert the uploaded file to base64 and use it instead of the response object
        if (canSeeInternational) {
          return this._hcinPortalApi.fileUpload(options, action.files).pipe(
            retry(2),
            switchMap(() => this._documentHelper.convertBlobToBase64(action.files[0])),
            map(returnObj => {
              let encoded = (returnObj as string).replace(/^data:(.*,)?/, '');
              if (encoded.length % 4 > 0) {
                encoded += '='.repeat(4 - (encoded.length % 4));
              }
              return new UploadProfilePictureSuccess(encoded);
            }),
            catchError((error: Error) => of(new UploadProfilePictureError(error)))
          );
        }

        return this._nursePortalApi.fileUpload(options, action.files).pipe(
          retry(2),
          map(returnObj => {
            return new UploadProfilePictureSuccess(returnObj);
          }),
          catchError((error: Error) => of(new UploadProfilePictureError(error)))
        );
      })
    );
  });

  uploadProfilePictureSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<UserContextActions>(EUserContextActions.UploadProfilePictureSuccess),
      tap((action: UploadProfilePictureSuccess) => this._notificationService.showNotification('Your profile picture was successfully saved.', 'success')),
      map(action => new SetNurseProfilePicture(action.payload))
    );
  });

  uploadProfilePictureError$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType<UserContextActions>(EUserContextActions.UploadProfilePictureError),
        tap((action: UploadProfilePictureError) => this._notificationService.showNotification(`There was an error updating your profile picture. ${action.error}`, 'error'))
      );
    },
    { dispatch: false }
  );

  getQuestionnaire$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<UserContextActions>(EUserContextActions.GetQuestionnaire, EUserContextActions.CheckVerificationKeySuccess),
      exhaustMap(() =>
        this._nursePortalApi.getQuestionnaire().pipe(
          retry(2),
          map((data: PreEmploymentQuestionnaire) => new GetQuestionnaireSuccess(data)),
          catchError((error: Error) => of(new GetQuestionnaireError(error)))
        )
      )
    );
  });

  updateQuestionnaire$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<UserContextActions>(EUserContextActions.UpdateQuestionnaire),
      concatLatestFrom(() => this.store.select(selectQuestionnaireData)),
      exhaustMap(([action, questionnaire]: [UpdateQuestionnaire, PreEmploymentQuestionnaire]) => {
        const data = { ...action.data, id: questionnaire.id };
        return this._nursePortalApi.updateQuestionnaire(data).pipe(
          retry(2),
          map((returnData: any) => new UpdateQuestionnaireSuccess(returnData, data)),
          catchError((error: Error) => of(new UpdateQuestionnaireError(error)))
        );
      })
    );
  });

  updateQuestionnaireSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<UserContextActions>(EUserContextActions.UpdateQuestionnaireSuccess),
      tap(() => this._notificationService.showNotification('Your background questionnaire was saved successfully!', 'success')),
      map(() => new UpdateQuestionnaireReset())
    );
  });

  updateQuestionnaireSuccessTrack$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<UserContextActions>(EUserContextActions.UpdateQuestionnaireSuccess),
      map(() => new AngularticsEventTrack('Completed', 'Complete Profile', 'Completed Questionnaire'))
    );
  });

  updateQuestionnaireError$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType<UserContextActions>(EUserContextActions.UpdateQuestionnaireError),
        tap(() => {
          this._notificationService.showNotification(`We were unable to save your changes. Please try again.`, 'error');
        })
      );
    },
    { dispatch: false }
  );

  getDocuments$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<UserContextActions>(
        EUserContextActions.GetDocuments,
        EWorkHistoryContextActions.UploadResumeSuccess,
        EWorkHistoryContextActions.AddWorkHistorySuccess,
        EWorkHistoryContextActions.UpdateWorkHistorySuccess,
        EWorkHistoryContextActions.DeleteWorkHistorySuccess,
        ETasksActions.UploadFluVaccineSuccess,
        ETasksActions.UploadCovidVaccineSuccess,
        ECertificationsActions.AddCertificationSuccess,
        ECertificationsActions.UpdateCertificationSuccess,
        ECertificationsActions.DeleteCertificationSuccess,
        ECertificationsActions.AddCertificationBlsSuccess,
        ETechnologyActions.AddTechnologySuccess,
        ETechnologyActions.UpdateTechnologySuccess,
        ETechnologyActions.DeleteTechnologySuccess,
        ELicensesActions.DeleteLicenseSuccess,
        ELicensesActions.CreateLicenseSuccess,
        ELicensesActions.UpdateLicenseSuccess,
        EEducationActions.AddEducationSuccess,
        EEducationActions.UpdateEducationSuccess,
        EEducationActions.DeleteEducationSuccess,
        EUserContextActions.DeleteDocumentsSuccess,
        EUserContextActions.CheckVerificationKeySuccess,
        EUserContextActions.BulkUploadDocumentsSuccess
      ),
      exhaustMap(() =>
        this._nursePortalApi.getMyDocuments().pipe(
          retry(1),
          map((documentData: CandidateQualification[]) => new GetDocumentsSuccess(documentData)),
          catchError((error: Error) => of(new GetDocumentsError(error)))
        )
      )
    );
  });

  deleteDocuments$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<UserContextActions>(EUserContextActions.DeleteDocuments),
      exhaustMap((item: DeleteDocuments) =>
        this._nursePortalApi.setDocumentToInactive(item.payload.id).pipe(
          retry(1),
          map(() => new DeleteDocumentsSuccess(item.payload)),
          catchError((error: Error) => of(new DeleteDocumentsError(error)))
        )
      )
    );
  });

  getDocumentsByID$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<UserContextActions>(EUserContextActions.GetDocumentsByID),
      exhaustMap((item: GetDocumentsByID) =>
        this._nursePortalApi.getDocument(item.payload.id).pipe(
          retry(1),
          map((documentData: Response) => {
            this._documentHelper.downloadFile(documentData, item.payload.fileDownloadUrl);
            return new GetDocumentsByIDSuccess(documentData);
          }),
          catchError((error: Error) => of(new GetDocumentsByIDError(error)))
        )
      )
    );
  });

  bulkUploadDocuments$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<UserContextActions>(EUserContextActions.BulkUploadDocuments),
      concatLatestFrom(() => [this.store.select(selectCanSeeInternational)]),
      switchMap(([action, canSeeInternational]: [BulkUploadDocuments, boolean]) => {
        const options: IFileUploadOptions = {
          target: FileUploadTargets.Bulk
        };
        return this._documentHelper._getDocumentObservable(options, action.payload.files, canSeeInternational).pipe(
          filter(response => !!response),
          take(1),
          switchMap(_response => of(new BulkUploadDocumentsSuccess())),
          catchError(error => of(new BulkUploadDocumentsError(error)))
        );
      })
    );
  });

  deleteDocumentSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType<UserContextActions>(EUserContextActions.DeleteDocumentsSuccess),
        tap((action: DeleteDocumentsSuccess) => {
          switch (action.payload.category) {
            case categoryNames.LicenseAndCertifications:
              this.store.dispatch(new GetCertifications(true));
              this.store.dispatch(new ResetLicenseExpiryDate());
              break;
            case categoryNames.BackgroundAndVerifications:
            case categoryNames.Employment:
              this.store.dispatch(new ResetEducationExpiryDate());
          }
        })
      );
    },
    { dispatch: false }
  );

  checkForSpeciality$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<UserContextActions>(EUserContextActions.GetNurseSuccess),
      map((action: GetNurseSuccess) => action.payload),
      switchMap((payload: NurseModel) =>
        combineLatest([of(payload), this.store.select(selectUserData), this.store.select(selectProfessionalHierarchy), this.store.select(selectUserProfessionSector)])
      ),
      skipWhile(([nurse, userData, professionalHierarchy]) => !nurse?.professionId || !userData || !professionalHierarchy),
      exhaustMap(([nurse, , , sector]) => {
        return this.store.select(selectShowSpecialtyPopup(nurse.professionId, nurse.specialtyId, sector)).pipe(
          exhaustMap(showPopup => {
            return of(new SetShowSpecialtyPopup(showPopup));
          })
        );
      })
    );
  });

  sendVerificationEmail$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<UserContextActions>(EUserContextActions.SendVerificationEmail),
      concatLatestFrom(() => this.store.select(selectNurseData)),
      exhaustMap(([action, nurseData]: [SendVerificationEmail, NurseModel]) => {
        if (action.resend) {
          return this._nursePortalApi.resendVerifyEmail().pipe(
            map(() => new SendVerificationEmailSuccess(nurseData.email)),
            catchError((error: Error) => of(new SendVerificationEmailError(error)))
          );
        } else {
          return this._nursePortalApi.sendVerifyEmail().pipe(
            map(() => new SendVerificationEmailSuccess(nurseData.email)),
            catchError((error: Error) => of(new SendVerificationEmailError(error)))
          );
        }
      })
    );
  });
  sendVerificationEmailSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType<UserContextActions>(EUserContextActions.SendVerificationEmailSuccess),
        tap((action: SendVerificationEmailSuccess) => {
          this._notificationService.showNotification(`We sent a verification email to ${action.userEmail}. Please check your inbox.`, 'success');
        })
      );
    },
    { dispatch: false }
  );

  sendVerificationSms$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<UserContextActions>(EUserContextActions.SendVerificationSms),
      concatLatestFrom(() => this.store.select(selectNurseData)),
      exhaustMap(([action, nurseData]: [SendVerificationSms, NurseModel]) => {
        if (action.resend) {
          return this._nursePortalApi.resendVerifySMS().pipe(
            map((response: number) => new SendVerificationSmsSuccess(nurseData.mobileNumber, response, action.resend)),
            catchError((error: Error) => of(new SendVerificationSmsError(error)))
          );
        } else {
          return this._nursePortalApi.sendVerifySMS().pipe(
            map((response: number) => new SendVerificationSmsSuccess(nurseData.mobileNumber, response)),
            catchError((error: Error) => of(new SendVerificationSmsError(error)))
          );
        }
      })
    );
  });
  sendVerificationSmsSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType<UserContextActions>(EUserContextActions.SendVerificationSmsSuccess),
        filter((action: SendVerificationSmsSuccess) => !(action.resend && action.response === 2)),
        tap((action: SendVerificationSmsSuccess) =>
          this._notificationService.showNotification(`We sent a verification code to ${action.userPhoneNumber}. Please check your inbox.`, 'success')
        )
      );
    },
    { dispatch: false }
  );
  resendVerificationSmsExceeded$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<UserContextActions>(EUserContextActions.SendVerificationSmsSuccess),
      filter((action: SendVerificationSmsSuccess) => action.resend && action.response === 2),
      tap(() => this._notificationService.showNotification(`You've exceeded resend requests. Please continue verifying through email.`, 'error')),
      map(() => new SetVerificationType(VerificationType.Email))
    );
  });
  sendVerificationSmsError$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<UserContextActions>(EUserContextActions.SendVerificationSmsError),
      map((_action: SendVerificationSmsError) => new SetVerificationType(VerificationType.Email))
    );
  });

  setVerificationType$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<UserContextActions>(EUserContextActions.SetVerificationType),
      exhaustMap((action: SetVerificationType) =>
        this._usersApiService.setVerificationType(action.verificationType).pipe(
          retry(2),
          map((returnData: any) => new SetVerificationTypeSuccess(returnData, action.verificationType)),
          catchError((error: Error) => of(new SetVerificationTypeError(error)))
        )
      )
    );
  });
  setVerificationTypeSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType<UserContextActions>(EUserContextActions.SetVerificationTypeSuccess),
        filter((action: SetVerificationTypeSuccess) => action.verificationType === 1),
        tap((_action: SetVerificationTypeSuccess) => this._navHelper.goToVerifyUserEmail())
      );
    },
    { dispatch: false }
  );

  checkVerificationKey$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<UserContextActions>(EUserContextActions.CheckVerificationKey),
      exhaustMap((action: CheckVerificationKey) =>
        this._usersApiService.checkVerificationKey(action.verificationKey).pipe(
          retry(2),
          map((returnData: any) => new CheckVerificationKeySuccess(returnData)),
          catchError((error: Error) => of(new CheckVerificationKeyError(error)))
        )
      )
    );
  });
  checkVerificationKeySuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<UserContextActions>(EUserContextActions.CheckVerificationKeySuccess),
      map((_action: CheckVerificationKeySuccess) => {
        this._navHelper.goToJobPreferences(true);
        return new SetNurseIsVerified(true);
      })
    );
  });
  checkVerificationKeyError$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<UserContextActions>(EUserContextActions.CheckVerificationKeyError),
      map((_action: CheckVerificationKeyError) => {
        this._notificationService.showNotification('Invalid Code', 'error');
        return new SetNurseIsVerified(false);
      })
    );
  });

  sendVerificationSupportEmail$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<UserContextActions>(EUserContextActions.SendVerificationSupportEmail),
      exhaustMap((action: SendVerificationSupportEmail) =>
        this._nursePortalApi.sendVerifySupportReport(action.supportReport).pipe(
          map(() => new SendVerificationSupportEmailSuccess()),
          catchError((error: Error) => of(new SendVerificationSupportEmailError(error)))
        )
      )
    );
  });
  sendVerificationSupportEmailSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType<UserContextActions>(EUserContextActions.SendVerificationSupportEmailSuccess),
        tap((_action: SendVerificationSupportEmailSuccess) => {
          this._notificationService.showNotification('Your message has been successfully sent.', 'success');
        })
      );
    },
    { dispatch: false }
  );

  deleteUserRequest$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<UserContextActions>(EUserContextActions.DeleteUserRequest),
      exhaustMap(() =>
        this._usersApiService.requestDeleteUser().pipe(
          map(resp => new DeleteUserRequestSuccess(resp)),
          catchError((error: unknown) => of(new DeleteUserRequestError(error as HttpErrorResponse)))
        )
      )
    );
  });

  deleteUserConfirm$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<UserContextActions>(EUserContextActions.DeleteUser),
      exhaustMap((action: DeleteUser) =>
        this._usersApiService.confirmDeleteUser(action.payload).pipe(
          map(resp => new DeleteUserSuccess(resp)),
          catchError((error: unknown) => of(new DeleteUserError(error as HttpErrorResponse)))
        )
      )
    );
  });

  getContactPreferences$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<UserContextActions>(EUserContextActions.GetContactPreferences),
      exhaustMap(() =>
        this._usersApiService.getCommunicationPreferences().pipe(
          map(resp => {
            return new GetContactPreferencesSuccess(resp);
          }),
          catchError((error: unknown) => of(new GetContactPreferencesError(error as HttpErrorResponse)))
        )
      )
    );
  });

  updateContactPreferences$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<UserContextActions>(EUserContextActions.UpdateContactPreferences),
      exhaustMap((action: UpdateContactPreferences) =>
        this._usersApiService.postCommunicationPreferences(action.payload).pipe(
          map(resp => {
            return new UpdateContactPreferencesSuccess(resp);
          }),
          catchError((error: unknown) => of(new UpdateContactPreferencesError(error as HttpErrorResponse)))
        )
      )
    );
  });
  updateContactPreferencesSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType<UserContextActions>(EUserContextActions.UpdateContactPreferencesSuccess),
        tap((_action: UpdateContactPreferencesSuccess) => {
          this._notificationService.showNotification('Your contact preferences have been saved.', 'success');
        })
      );
    },
    { dispatch: false }
  );
  updateContactPreferencesError$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType<UserContextActions>(EUserContextActions.UpdateContactPreferencesError),
        tap((_action: UpdateContactPreferencesSuccess) => {
          this._notificationService.showNotification('Your contact preferences have not been saved. Please try again.', 'error');
        })
      );
    },
    { dispatch: false }
  );

  postRegistrationCheck$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<UserContextActions>(EUserContextActions.PostRegistrationCheck),
      exhaustMap(() =>
        this._usersApiService.registrationCheck().pipe(
          map(resp => new PostRegistrationCheckSuccess(resp)),
          catchError((error: unknown) => of(new PostRegistrationCheckError(error as HttpErrorResponse)))
        )
      )
    );
  });
}
