import { ReferencesResponse, TaskCompletionReturnObject } from 'src/app/common';
import { IDataState, initializeData } from '../app/app.models';

export interface ReferencesState {
  allReferences: IDataState<ReferencesResponse>;
  referenceEmails: IDataState<string[]>;
  referenceAction: IDataState<TaskCompletionReturnObject>;
}

export const initialReferencesState: ReferencesState = {
  allReferences: initializeData(),
  referenceEmails: initializeData(),
  referenceAction: initializeData()
};
