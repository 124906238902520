import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HelpModalTemplate, HelpParagraph } from '../../../common/models/help-modal';

@Component({
  selector: 'app-help-modal',
  templateUrl: './help-modal-component.html',
  styleUrls: ['./help-modal-component.scss']
})
export class HelpModalComponent {
  title: string;
  paragraph: HelpParagraph;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: HelpModalTemplate,
    private _helpModal: MatDialogRef<HelpModalComponent>
  ) {}

  closeHelpModal() {
    this._helpModal.close();
  }
}
