import { Facility, WorkHistory } from 'src/app/common';
import { IDataState, initializeData } from '../app/app.models';
import { EWorkHistoryContextActions } from './workHistoryContext.actions';

export interface IWorkHistoryContextState {
  workHistoryList: IDataState<WorkHistory[]>;
  workHistory: IDataState<WorkHistory>;
  workHistoryAction: IDataState<{ payload: any; type: EWorkHistoryContextActions }>;
  facilityResultList: IDataState<Array<Facility>>;
}

export const initialWorkHistoryContextState: IWorkHistoryContextState = {
  workHistoryList: initializeData(),
  workHistory: initializeData(),
  workHistoryAction: initializeData(),
  facilityResultList: initializeData()
};
