import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NavController } from '@ionic/angular';
import { CardElevation, DialogService } from 'hc-design-system-lib';
import { Capacitor } from '@capacitor/core';
import { AuthenticationService } from 'src/app/services';

interface SettingsNavItem {
  id: string;
  name: string;
  func: () => void;
  disabled?: boolean;
}

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  @ViewChild('deleteAccountFlow')
  deleteAccountFlowTemplate: TemplateRef<any>;
  isNative = Capacitor.isNativePlatform();

  navItems: SettingsNavItem[] = [];

  constructor(
    private readonly _authService: AuthenticationService,
    private readonly navCtrl: NavController,
    private readonly _dialogService: DialogService
  ) {}

  async ngOnInit(): Promise<void> {
    await this.determineNavItems();
  }

  async determineNavItems(): Promise<void> {
    const hasLongLivingToken = await this._authService.isRefreshTokenAvailable();
    this.navItems = [
      {
        name: 'Change Password',
        id: 'change-password',
        func: () => {
          this._authService.passwordReset(null, null, true);
          this.navCtrl.navigateRoot('/');
        },
        disabled: !hasLongLivingToken
      }
    ];

    if (this.isNative) {
      this.navItems.push({
        name: 'Delete My Account',
        id: 'delete-account',
        func: () => {
          this._dialogService.showDialog(
            {
              title: 'Delete Account?',
              template: this.deleteAccountFlowTemplate,
              text: '',
              showCloseIcon: true,
              elevation: CardElevation.Default,
              icon: null,
              separatedHeader: false,
              noStyling: true,
              useCustomCloseLogic: false
            },
            true
          );
        }
      });
    }
  }
}
