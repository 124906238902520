import { CurrentAssigneeInformation, Recruiter } from 'src/app/common/models/recruiter';
import { initializeData, IDataState } from 'src/app/store/app/app.models';

export interface IRecruiterState {
  recruiterData: IDataState<Recruiter>;
  defaultReferralRecruiterData: IDataState<Recruiter>;
  recruiterPopupData: IDataState<any>;
  payRecruiter: IDataState<Recruiter>;
  currentAssigneeIN: IDataState<CurrentAssigneeInformation>;
}

export const initialRecruiterState: IRecruiterState = {
  recruiterData: initializeData(),
  defaultReferralRecruiterData: initializeData(),
  recruiterPopupData: initializeData(),
  payRecruiter: initializeData(),
  currentAssigneeIN: initializeData()
};
