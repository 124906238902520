export * from './main.guard';
export * from './app-init.guard';
export * from './contact-select.guard';
export * from './registration-check.guard';
export * from './inactive-user.guard';
export * from './job-preferences-registration.guard';
export * from './travel.guard';
export * from './international.guard';

import { MainGuard } from './main.guard';
import { AppInitGuard } from './app-init.guard';
import { AuthGuard } from './auth-guard';
import { RegistrationCheckGuard } from './registration-check.guard';
import { InactiveUserGuard } from './inactive-user.guard';
import { CongratulationsGuard } from './congratulations.guard';
import { ContactSelectGuard } from './contact-select.guard';
import { AutoOfferConfirmationGuard } from './auto-offer-confirmation.guard';
import { EnrollGuard } from './enroll.guard';
import { SanitizeParamGuard } from './sanitize-params.guard';
import { JobPreferencesRegistrationGuard } from './job-preferences-registration.guard';
import { TravelGuard } from './travel.guard';
import { InternationalGuard } from './international.guard';
import { OnAssignmentGuard } from './on-assignment.guard';
import { SpecificJobGuard } from './specific-job.guard';

export const guards = [
  MainGuard,
  AppInitGuard,
  AuthGuard,
  RegistrationCheckGuard,
  InactiveUserGuard,
  CongratulationsGuard,
  ContactSelectGuard,
  AutoOfferConfirmationGuard,
  EnrollGuard,
  SanitizeParamGuard,
  JobPreferencesRegistrationGuard,
  TravelGuard,
  InternationalGuard,
  OnAssignmentGuard,
  SpecificJobGuard
];
