import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { NotificationService } from 'hc-design-system-lib';
import { defer, forkJoin, of } from 'rxjs';
import { catchError, exhaustMap, map, skipWhile, tap } from 'rxjs/operators';
import { TaskCompletionReturnObject, GetFacilitiesResult, HcinWorkHistory, mapToHcinWorkHistory } from 'src/app/common';
import { checkIfDateIsAfter, getDateInFutureWithMinutes } from 'src/app/common/functions/date-manipulations';
import { GenericCompletionReturnObject } from 'src/app/common/models/generic-completion-return-model';
import { HCINPortalAPIService, NursePortalApi } from 'src/app/services';
import { IAppState } from '../app/app.state';
import {
  AddWorkHistory,
  AddWorkHistoryError,
  AddWorkHistorySuccess,
  DeleteWorkHistory,
  DeleteWorkHistoryError,
  DeleteWorkHistorySuccess,
  UpdateWorkHistory,
  UpdateWorkHistoryError,
  UpdateWorkHistorySuccess,
  EWorkHistoryContextActions,
  GetFacilityList,
  GetFacilityListError,
  GetFacilityListSuccess,
  GetWorkHistoryList,
  GetWorkHistoryListError,
  GetWorkHistoryListSuccess,
  WorkHistoryContextActions,
  GetWorkHistoryById,
  GetWorkHistoryByIdError,
  GetWorkHistoryByIdSuccess,
  AddWorkHistoryLapseReason,
  AddWorkHistoryLapseReasonSuccess,
  AddWorkHistoryLapseReasonError,
  UploadResume,
  UploadResumeSuccess,
  UploadResumeError
} from './workHistoryContext.actions';
import {
  selectWorkHistoryActionRequestId,
  selectWorkHistoryById,
  selectWorkHistoryByIdExpiration,
  selectWorkHistoryList,
  selectWorkHistoryListExpiration
} from './workHistoryContext.selectors';
import { selectCanSeeInternational } from '../userContext/userContext.selectors';

@Injectable({
  providedIn: 'root'
})
export class WorkHistoryContextEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly _api: NursePortalApi,
    private readonly _hcinApi: HCINPortalAPIService,
    private readonly _store: Store<IAppState>,
    private readonly _notificationService: NotificationService
  ) {}

  getWorkHistoryList$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<WorkHistoryContextActions>(EWorkHistoryContextActions.GetWorkHistoryList),
      map((action: GetWorkHistoryList) => action.forceUpdate),
      concatLatestFrom(() => [this._store.select(selectCanSeeInternational), this._store.select(selectWorkHistoryList), this._store.select(selectWorkHistoryListExpiration)]),
      exhaustMap(([forceUpdate, canSeeInternational, workHistoryList, expirationDate]) => {
        const isExpired = checkIfDateIsAfter(new Date(), expirationDate);
        if (!forceUpdate && !isExpired) {
          return of(new GetWorkHistoryListSuccess({ workHistoryList, expirationDate }));
        }

        if (canSeeInternational) {
          return this._hcinApi.getWorkHistories().pipe(
            map((hcinWorkHistoryList: HcinWorkHistory[]) => {
              return new GetWorkHistoryListSuccess({
                workHistoryList: hcinWorkHistoryList ?? [],
                expirationDate: getDateInFutureWithMinutes(10)
              });
            }),
            catchError((error: Error) => of(new GetWorkHistoryListError(error)))
          );
        }

        return this._api.getWorkHistory().pipe(
          map(
            response =>
              new GetWorkHistoryListSuccess({
                workHistoryList: response,
                expirationDate: getDateInFutureWithMinutes(10)
              })
          ),
          catchError(error => of(new GetWorkHistoryListError(error)))
        );
      })
    );
  });

  getWorkHistoryById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<WorkHistoryContextActions>(EWorkHistoryContextActions.GetWorkHistoryById),
      map((action: GetWorkHistoryById) => action.id),
      concatLatestFrom(() => [this._store.select(selectWorkHistoryById), this._store.select(selectWorkHistoryByIdExpiration)]),
      exhaustMap(([workHistoryId, workHistory, expirationDate]) => {
        if (!workHistoryId) {
          return of(new GetWorkHistoryByIdSuccess({ workHistory: null, expirationDate: null }));
        }
        const isAfter = checkIfDateIsAfter(new Date(), expirationDate);
        if (isAfter || workHistoryId !== workHistory?.id) {
          return this._api.getWorkHistory(workHistoryId).pipe(
            map(
              response =>
                new GetWorkHistoryByIdSuccess({
                  workHistory: response,
                  expirationDate: getDateInFutureWithMinutes(10)
                })
            ),
            catchError((error: Error) => of(new GetWorkHistoryByIdError(error)))
          );
        } else {
          return of(new GetWorkHistoryByIdSuccess({ workHistory, expirationDate }));
        }
      })
    );
  });

  deleteWorkHistory$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<WorkHistoryContextActions>(EWorkHistoryContextActions.DeleteWorkHistory),
      exhaustMap((action: DeleteWorkHistory) =>
        forkJoin({
          delete: this._api.deleteWorkHistory(action.id),
          workHistory: defer(() => {
            if (action.workHistoryToUpdate) {
              return this._api.updateWorkHistory(action.workHistoryToUpdate);
            } else {
              return of(true);
            }
          })
        }).pipe(
          map(
            (response: { delete: GenericCompletionReturnObject<number>; workHistory: boolean | GenericCompletionReturnObject<number> }) =>
              new DeleteWorkHistorySuccess(response, action.id)
          ),
          catchError(error => of(new DeleteWorkHistoryError(error)))
        )
      )
    );
  });

  updateWorkHistory$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<WorkHistoryContextActions>(EWorkHistoryContextActions.UpdateWorkHistory),
      exhaustMap((action: UpdateWorkHistory) =>
        this._api.updateWorkHistory(action.workHistory).pipe(
          map((response: GenericCompletionReturnObject<number>) => new UpdateWorkHistorySuccess(response)),
          catchError(error => of(new UpdateWorkHistoryError(error)))
        )
      )
    );
  });

  addWorkHistory$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<WorkHistoryContextActions>(EWorkHistoryContextActions.AddWorkHistory),
      concatLatestFrom(() => [this._store.select(selectCanSeeInternational)]),
      exhaustMap(([action, canSeeInternational]: [AddWorkHistory, boolean]) => {
        if (canSeeInternational) {
          let hcinWorkHistory = mapToHcinWorkHistory(action.workHistory);
          return this._hcinApi.addWorkHistory(hcinWorkHistory).pipe(
            map(response => new AddWorkHistorySuccess({ returnValue: response } as GenericCompletionReturnObject<string>, action.workHistory)),
            catchError(error => of(new AddWorkHistoryError(error)))
          );
        }

        return this._api.addWorkHistory(action.workHistory).pipe(
          map((response: GenericCompletionReturnObject<string>) => new AddWorkHistorySuccess(response, action.workHistory)),
          catchError(error => of(new AddWorkHistoryError(error)))
        );
      })
    );
  });

  getFacilityList$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<WorkHistoryContextActions>(EWorkHistoryContextActions.GetFacilityList),
      exhaustMap((action: GetFacilityList) =>
        this._api.getAccountsByName(action.payload).pipe(
          map((facility: GetFacilitiesResult) => new GetFacilityListSuccess(facility.data)),
          catchError((error: Error) => of(new GetFacilityListError(error)))
        )
      )
    );
  });

  editWorkHistorySuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<WorkHistoryContextActions>(
        EWorkHistoryContextActions.AddWorkHistorySuccess,
        EWorkHistoryContextActions.UpdateWorkHistorySuccess,
        EWorkHistoryContextActions.DeleteWorkHistorySuccess
      ),
      map(() => new GetWorkHistoryList(true))
    );
  });

  refreshWorkHistory$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<WorkHistoryContextActions>(EWorkHistoryContextActions.UpdateWorkHistorySuccess),
      map((action: UpdateWorkHistorySuccess) => action.payload),
      concatLatestFrom(() => this._store.select(selectWorkHistoryActionRequestId)),
      skipWhile(([, requestId]) => !requestId),
      exhaustMap(([, requestId]) => of(new GetWorkHistoryById(requestId)))
    );
  });

  addWorkHistoryLapseReason$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<WorkHistoryContextActions>(EWorkHistoryContextActions.AddWorkHistoryLapseReason),
      exhaustMap((action: AddWorkHistoryLapseReason) =>
        this._api.sendWorkHistoryLapseReason(action.newHistoryId, action.lapseReason).pipe(
          map((response: TaskCompletionReturnObject) => new AddWorkHistoryLapseReasonSuccess(response)),
          catchError(error => of(new AddWorkHistoryLapseReasonError(error)))
        )
      )
    );
  });

  uploadResume$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<WorkHistoryContextActions>(EWorkHistoryContextActions.UploadResume),
      exhaustMap((action: UploadResume) => {
        return this._api.fileUpload(action.options, action.files).pipe(
          map(returnObj => new UploadResumeSuccess(returnObj)),
          catchError((error: Error) => of(new UploadResumeError(error)))
        );
      })
    );
  });

  uploadResumeError$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType<WorkHistoryContextActions>(EWorkHistoryContextActions.UploadResumeError),
        tap(() => {
          this._notificationService.showNotification('Resume upload failed, please try again or contact support if issue persists.', 'error');
        })
      );
    },
    { dispatch: false }
  );
}
