<div *ngIf="!newStyling" fxLayout="column" fxLayout="row wrap" fxLayoutAlign="space-between center">
  <div *ngFor="let item of checkboxFormArray.controls; let i = index" fxFlex="50">
    <div style="margin-bottom: 16px">
      <hc-checkbox [text]="checkboxItemArray[i].name" [control]="item" (checkedChange)="updateCheckbox($event, i, checkboxFormArray.controls)"></hc-checkbox>
    </div>
  </div>
</div>

<div *ngIf="newStyling" class="checkbox-form-array">
  <div *ngFor="let item of checkboxFormArray.controls; let i = index" class="checkbox-array">
    <hc-checkbox [text]="checkboxItemArray[i].name" [control]="item" (checkedChange)="updateCheckbox($event, i, checkboxFormArray.controls)"></hc-checkbox>
  </div>
</div>
