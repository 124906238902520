import { AfterViewChecked, Component, OnInit } from '@angular/core';
import { AppState, NursePortalApi, AuthenticationService } from 'src/app/services';

@Component({
  selector: 'app-multiple-found',
  templateUrl: './multiple-found.component.html',
  styleUrls: ['./multiple-found.component.scss']
})
export class MultipleFoundComponent implements OnInit, AfterViewChecked {
  constructor(
    private _appState: AppState,
    private _authService: AuthenticationService,
    private _api: NursePortalApi
  ) {}

  ngOnInit() {
    this._api.onMultipleAccountsFound().subscribe();
  }

  ngAfterViewChecked() {
    this._appState.setSpinnerState(false);
  }

  logout() {
    this._authService.logout();
  }
}
