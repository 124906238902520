import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { IAppState } from 'src/app/store/app/app.state';
import { selectCanSeeTravel } from 'src/app/store/userContext/userContext.selectors';

@Component({
  selector: 'app-bulk-upload-popup',
  templateUrl: './bulk-upload-popup.component.html',
  styleUrls: ['./bulk-upload-popup.component.scss']
})
export class BulkUploadPopupComponent {
  canSeeTravel$: Observable<boolean> = this._store.select(selectCanSeeTravel);

  constructor(
    public readonly dialogRef: MatDialogRef<BulkUploadPopupComponent>,
    private readonly _store: Store<IAppState>,
    @Inject(MAT_DIALOG_DATA) public readonly data: any
  ) {}

  close(): void {
    this.dialogRef.close();
  }
}
