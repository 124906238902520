import { initializeData, IDataState } from 'src/app/store/app/app.models';
import { Technology } from 'src/app/common';
import { TaskCompletionReturnObject } from 'src/app/common/models/task-competion-return-object';

export interface TechnologyState {
  technologyData: IDataState<Technology[]>;
  technologyDataByID: IDataState<Technology>;
  addTechnologyData: IDataState<TaskCompletionReturnObject>;
  deleteTechnologyData: IDataState<TaskCompletionReturnObject>;
  updateTechnologyData: IDataState<TaskCompletionReturnObject>;
}

export const initialTechnologyState: TechnologyState = {
  technologyData: initializeData(),
  technologyDataByID: initializeData(),
  addTechnologyData: initializeData(),
  deleteTechnologyData: initializeData(),
  updateTechnologyData: initializeData()
};
