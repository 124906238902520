<ng-container
  *ngIf="{
    skill: skill$ | async,
    skillLoading: skillLoading$ | async,
    skillSaving: skillSaving$ | async,
    isMobile: isMobile$ | async
  } as obs"
>
  <app-loading-screen-spinner [show]="obs.skillLoading"></app-loading-screen-spinner>

  <app-base-form
    *ngIf="form"
    #baseForm
    [form]="form"
    [formHeaderAttributes]="formHeaderAttributes"
    [isLoadingObs]="skillLoading$"
    [isSavingObs]="skillSaving$"
    (saveableEmitter)="onSave($event)"
    [takeUpFullHeight]="true"
  >
    <ng-container ngProjectAs="[formControls]">
      <div class="skill-header" fxLayout="column" fxLayoutGap="8px">
        <hc-heading size="h2">{{ obs.skill?.name }}</hc-heading>
        <hc-heading size="h5" class="header-subtext"> Rate each skill based on your experience. </hc-heading>
      </div>
      <mat-divider *ngIf="obs.isMobile"></mat-divider>
      <div class="skill-questions">
        <hc-accordion
          *ngFor="let group of form.controls.questionGroups.controls; index as groupIndex; trackBy: trackGroup"
          isList="true"
          #panel
          [title]="
            group.controls.text.value + ' (' + questionsAnswered(group.controls.questions?.controls) + '/' + (group.controls.questions?.controls.length ?? 0) + ')' | titlecase
          "
          [bodyTemplate]="panelBody"
          [expanded]="panelBody.expanded"
          (accordionClicked)="accordionClick(panelBody, $event)"
          (accordionExpanded)="accordionExpansion($event)"
        >
          <ng-template #panelBody let-expanded="false">
            <div *ngIf="panelBody.expanded" fxLayout="column" [id]="'group-' + group.controls.groupId.value">
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px" class="experience-toggle">
                <hc-toggle [control]="group.controls.hasExperience" (checkedChange)="hasExperienceChange(group, groupIndex)"></hc-toggle>
                <hc-body> I have experience in this skill set</hc-body>
              </div>

              <div *ngIf="!obs.isMobile" class="question-answer-labels">
                <hc-body size="small">Limited or no experience</hc-body>
                <hc-body size="small">May need review or supervision</hc-body>
                <hc-body size="small">Able to function independently</hc-body>
              </div>

              <ng-template ngFor let-question [ngForOf]="group.controls.questions.controls" [ngForTrackBy]="trackQuestion" let-isOdd="odd">
                <div
                  [id]="'question-' + question.controls.id.value"
                  class="skill-question"
                  fxLayout="row"
                  fxLayoutGap="40px"
                  [ngClass]="{ 'odd-skill-row': isOdd && !obs.isMobile }"
                >
                  <hc-body *ngIf="!obs.isMobile" class="skill-text">{{ question.controls.text.value }}</hc-body>
                  <hc-radio-button
                    class="skill-radio"
                    [label]="obs.isMobile ? question.controls.text.value : ''"
                    [useButtonStyle]="obs.isMobile"
                    [options]="obs.isMobile ? mobileSkillOptions : nonMobileSkillOptions"
                    [control]="question.controls.score"
                    (selectedValueChange)="radioSelected($event, group, groupIndex)"
                  ></hc-radio-button>
                </div>
                <mat-divider *ngIf="(obs.isMobile && !isLast) || true" [ngClass]="{ 'mobile-divider': obs.isMobile }"></mat-divider>
              </ng-template>
            </div>
          </ng-template>
        </hc-accordion>
      </div>
    </ng-container>
    <ng-container ngProjectAs="[formButtons]">
      <mat-divider></mat-divider>
      <div class="buttons">
        <hc-button
          id="saveButton"
          type="button"
          [disabled]="obs.skillSaving"
          [appearance]="secondaryButtonAppearance"
          [alwaysDisplayFullWidth]="true"
          [size]="obs.isMobile ? 'small' : 'default'"
          (buttonClick)="baseForm.saveCheck()"
        >
          Save
        </hc-button>
        <hc-button
          id="saveAndSignButton"
          type="button"
          [disabled]="obs.skillSaving"
          [appearance]="primaryButtonAppearance"
          [alwaysDisplayFullWidth]="true"
          [size]="obs.isMobile ? 'small' : 'default'"
          (buttonClick)="isSigning = true; baseForm.saveCheck()"
        >
          Save and Sign
        </hc-button>
      </div>
    </ng-container>
  </app-base-form>

  <ng-template #missingResponsesTemplate>
    <hc-body>
      Looks like we're missing your response to
      {{ questionsTotalNotAnsweredCount() }} skills.
    </hc-body>
  </ng-template>

  <ng-template #saveAndSignTemplate>
    <div>
      <hc-input
        id="signature-control"
        [control]="signatureForm.controls.signature"
        label="Electronic Signature"
        placeholder="Enter Signature"
        [errorMessage]="getErrorMessage(signatureForm.controls.signature, 'Signature')"
        [required]="true"
      ></hc-input>
      <hc-date-picker
        id="signature-date-control"
        [control]="signatureForm.controls.signatureDate"
        label="Date"
        placeholder="Enter Date"
        errorMessage="Date required"
        [required]="true"
      ></hc-date-picker>
      <hc-body [size]="micro">
        By clicking “Agree and Sign” below, you acknowledge that the information provided is accurate to the best of your knowledge and accurately represents your abilities.
      </hc-body>
      <div class="agree-and-sign-button">
        <hc-button [disabled]="!signatureForm.valid" (click)="agreeAndSign()">Agree and Sign</hc-button>
      </div>
    </div>
  </ng-template>
</ng-container>
