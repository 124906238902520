import { createSelector } from '@ngrx/store';
import { Reference, ReferenceRoleCode } from 'src/app/common';
import { IAppState } from '../app/app.state';
import { ReferencesState } from './references.state';

const selectReferencesState = (state: IAppState) => state.references;

export const selectPendingReferences = createSelector(selectReferencesState, (state: ReferencesState) => state?.allReferences.data?.references);

export const selectCompletedReferences = createSelector(selectReferencesState, (state: ReferencesState) => state?.allReferences.data?.completedReferences);

export const selectReferenceById = (id: string) => createSelector(selectAllReferences, allReferences => allReferences?.find(reference => reference?.id === id));

export const selectAllReferences = createSelector(selectPendingReferences, selectCompletedReferences, (pendingReferences: Reference[], completedReferences: Reference[]) =>
  pendingReferences?.concat(completedReferences)
);

export const selectAllNonChargeNurseReferences = createSelector(selectAllReferences, allReferences =>
  allReferences?.filter(reference => reference.positionCode !== ReferenceRoleCode.ChargeNurse)
);

export const selectPendingNonChargeNurseReferences = createSelector(selectPendingReferences, pendingReferences =>
  pendingReferences?.filter(reference => reference.positionCode !== ReferenceRoleCode.ChargeNurse)
);

export const selectCompletedNonChargeNurseReferences = createSelector(selectCompletedReferences, completedReferences =>
  completedReferences?.filter(reference => reference.positionCode !== ReferenceRoleCode.ChargeNurse)
);

export const selectReferenceEmails = createSelector(selectReferencesState, (state: ReferencesState) => state?.referenceEmails.data);

export const selectReferencesLoading = createSelector(selectReferencesState, (state: ReferencesState) => state?.allReferences.loading);

export const selectReferenceActionLoading = createSelector(selectReferencesState, (state: ReferencesState) => state?.referenceAction.loading);

export const selectReferenceAction = createSelector(selectReferencesState, (state: ReferencesState) => state?.referenceAction.data);
