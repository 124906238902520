<ng-container
  *ngIf="{
    education: education$ | async,
    educationLoading: educationLoading$ | async,
    educationSaving: educationSaving$ | async,
    yesNoOnlyRadios: yesNoOnlyRadios$ | async,
    showInternational: canSeeInternational$ | async,
    showTravel: canSeeTravel$ | async
  } as obs"
>
  <app-loading-screen-spinner [show]="!form || obs.educationLoading || obs.educationSaving"></app-loading-screen-spinner>
  <div
    cdkScrollable
    *ngIf="!showDeleteConfirmation && !showWarningConfirmation; else showDeleteConfirmation ? deleteConfirmation : showWarningConfirmation ? warningConfirmation : null"
  >
    <form
      *ngIf="form && !obs.educationSaving"
      class="base-form tasks-responsive-modal-form"
      [formGroup]="form"
      [disabled]="obs.educationSaving"
      #formDirective="ngForm"
      autoFocus="false"
    >
      <div class="tasks-responsive-modal-form__inputs">
        <hc-input
          id="schoolAttendedInput"
          [control]="form?.controls?.schoolAttended"
          label="School Attended"
          placeholder="Enter School Attended"
          [required]="true"
          errorMessage="School Required"
        >
        </hc-input>
        <hc-dropdown
          *ngIf="obs.showInternational"
          id="countryDropdownInput"
          [control]="form?.controls?.country"
          label="Country"
          placeholder="Select Country"
          [required]="true"
          errorMessage="Country Required"
          [data]="countries"
        ></hc-dropdown>
        <hc-input
          *ngIf="obs.showInternational"
          id="cityInput"
          [control]="form?.controls?.city"
          label="City/Locality"
          placeholder="Type City"
          [required]="true"
          errorMessage="City Required"
        >
        </hc-input>
        <hc-date-picker-month-year
          id="graduationDatePicker"
          [control]="form?.controls?.graduationDate"
          label="Graduation Date"
          placeholder="Enter Graduation Date"
          [required]="true"
          errorMessage="Graduation Date Required"
          [minDate]="minDate"
          [maxDate]="maxDate"
        ></hc-date-picker-month-year>
        <hc-dropdown
          id="degreeDropdownInput"
          [control]="form?.controls?.degree"
          label="Degree"
          placeholder="Select Degree"
          [required]="true"
          errorMessage="Degree Required"
          [data]="degrees"
        ></hc-dropdown>
        <hc-dropdown
          *ngIf="obs.showInternational"
          id="majorDropdownInput"
          [control]="form?.controls?.major"
          label="Degree Focus"
          placeholder="Select Degree Focus"
          [required]="true"
          errorMessage="Degree Focus Required"
          [data]="majors"
        ></hc-dropdown>
        <hc-label isInputLabel="true" class="form-label" *ngIf="obs.showInternational">
          Were classes taught in English?*
          <mat-icon
            class="padding-left-5"
            hcTooltipHeading="English Curriculum"
            hcTooltip="Different countries have different language requirements, which may impact your journey. We need to know whether your courses were taught in English to get a better understanding of your background."
          >
            help
          </mat-icon>
        </hc-label>
        <hc-radio-button
          *ngIf="obs.showInternational"
          id="educatedInEnglishInput"
          required="true"
          [options]="obs.yesNoOnlyRadios"
          [control]="form?.controls?.educatedInEnglish"
          (selectedValueChange)="form?.markAsDirty()"
        ></hc-radio-button>
        <hc-file-upload
          id="fileUpload"
          aboveLabel="Upload Photo of Degree"
          label="Take Photo or Upload"
          size="small"
          acceptedExtensions=".jpg, .jpeg, .png, .doc, .docx, .pdf"
          [disabled]="obs.educationSaving"
          [allowMultipleFiles]="false"
          [required]="false"
          [enableFilePreviewPopup]="true"
          [filenameMappingFunction]="fileTruncationFunction | async"
          [control]="form?.controls?.fileUpload"
          [prepopulateWithFiles]="prepopulatedFile ? [prepopulatedFile] : []"
          (previewDeleteFileClicked)="deleteUploadedFile()"
          (valueChanged)="handleFileInput($event)"
        ></hc-file-upload>
      </div>

      <div class="tasks-responsive-modal-form__buttons">
        <hc-button
          *ngIf="!obs.education"
          segmentTrackCTA
          destinationUrl="/educations"
          id="saveAndAddAnotherButton"
          [disabled]="obs.educationSaving"
          (buttonClick)="save(true)"
          [appearance]="primaryButtonAppearance"
        >
          Save and Add Another
        </hc-button>
        <hc-button
          segmentTrackCTA
          destinationUrl="/educations"
          id="saveButton"
          [disabled]="obs.educationSaving"
          (buttonClick)="save()"
          [appearance]="obs.education ? primaryButtonAppearance : secondaryButtonAppearance"
          >Save</hc-button
        >
        <hc-button
          *ngIf="obs.education"
          segmentTrackCTA
          destinationUrl="/educations"
          id="deleteButton"
          [disabled]="obs.educationSaving"
          (buttonClick)="showDelete()"
          [appearance]="secondaryButtonAppearance"
          [color]="redButton"
        >
          Delete Record
        </hc-button>
        <hc-button
          class="tasks-responsive-modal-form__buttons__cancel"
          segmentTrackCTA
          destinationUrl="/educations"
          id="cancelButton"
          [disabled]="obs.educationSaving"
          (buttonClick)="cancel()"
          [appearance]="textButtonAppearance"
        >
          Cancel
        </hc-button>
      </div>
    </form>
  </div>

  <ng-template #deleteConfirmation>
    <div class="tasks-modal-delete-confirmation">
      <div class="tasks-modal-delete-confirmation__buttons">
        <hc-button
          [disabled]="obs.educationSaving"
          id="deleteConfirmDeleteButton"
          (buttonClick)="deleteEducation()"
          [alwaysDisplayFullWidth]="true"
          [appearance]="primaryButtonAppearance"
          >Delete</hc-button
        >
        <hc-button
          [disabled]="obs.educationSaving"
          id="deleteConfirmCancelButton"
          (buttonClick)="showDelete()"
          [alwaysDisplayFullWidth]="true"
          [appearance]="secondaryButtonAppearance"
          >Cancel</hc-button
        >
      </div>
    </div>
  </ng-template>

  <ng-template #warningConfirmation>
    <div class="tasks-modal-warning-confirmation">
      <hc-body [size]="bodySize">Any unsaved changes will be lost.</hc-body>
      <div class="tasks-modal-warning-confirmation__buttons">
        <hc-button
          [disabled]="obs.educationSaving"
          id="warnConfirmLeaveButton"
          (buttonClick)="cancel(true)"
          [alwaysDisplayFullWidth]="true"
          [appearance]="primaryButtonAppearance"
          >Leave this page</hc-button
        >
        <hc-button
          [disabled]="obs.educationSaving"
          id="warnConfirmCancelButton"
          (buttonClick)="showWarning()"
          [alwaysDisplayFullWidth]="true"
          [appearance]="secondaryButtonAppearance"
          >Cancel</hc-button
        >
      </div>
    </div>
  </ng-template>
</ng-container>
