<div class="recommendedDialogTemplate-content">
  <hc-body> We take on the heavy lifting of searching through hundreds of jobs and present opportunities specifically tailored to you. </hc-body>
  <div class="tip-list">
    <hc-heading [size]="headingSizeH6"> How to optimize your recommendations: </hc-heading>
    <div>
      <hc-body class="rec-tip">
        <mat-icon class="rec-icon">person_outline</mat-icon>
        Keep updating your&nbsp;<hc-link [target]="linkTarget" [url]="null" [routerLink]="['/profile-menu']" (click)="_dialogService.dialog.closeAll()">Profile.</hc-link>
      </hc-body>
    </div>
    <div>
      <hc-body class="rec-tip">
        <mat-icon class="rec-icon">search</mat-icon>
        Search for jobs in locations of interest with your ideal criteria.
      </hc-body>
    </div>
    <div>
      <hc-body class="rec-tip">
        <mat-icon class="rec-icon">favorite_border</mat-icon>
        Give the jobs you love a favorite.<br />(Tip: they may become available again)
      </hc-body>
    </div>
    <div>
      <hc-body class="rec-tip">
        <mat-icon class="rec-icon">check_circle_outline</mat-icon>
        Apply when you’re ready.
      </hc-body>
    </div>
  </div>
</div>
