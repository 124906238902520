import { Action } from '@ngrx/store';
import { IFeatureFlag } from 'src/app/common/contracts/feature-flag';

export enum EFlagsActions {
  InitializeAndGetFlags = '[Flags] initialize and get Flags',
  GetFlags = '[Flags] get Flags',
  GetFlagsSuccess = '[Flags] get Flags success',
  GetFlagsError = '[Flags] get Flags error',
  GetVariant = '[Flags] get flag variant',
  GetVariantSuccess = '[Flags] get flag variant success',
  GetVariantError = '[Flags] get flag variant error'
}

export class InitializeAndGetFlags implements Action {
  public readonly type = EFlagsActions.InitializeAndGetFlags;
}

export class GetFlags implements Action {
  public readonly type = EFlagsActions.GetFlags;

  constructor(public forceUpdateCache = false) {}
}

export class GetFlagsSuccess implements Action {
  public readonly type = EFlagsActions.GetFlagsSuccess;

  constructor(public result: { flags: Map<string, IFeatureFlag>; expiration: Date }) {}
}

export class GetFlagsError implements Action {
  public readonly type = EFlagsActions.GetFlagsError;

  constructor(public error: Error) {}
}

export class GetVariant implements Action {
  public readonly type = EFlagsActions.GetVariant;

  constructor(public flagName: string) {}
}

export class GetVariantSuccess implements Action {
  public readonly type = EFlagsActions.GetVariantSuccess;

  constructor(public result: { variant: string }) {}
}

export class GetVariantError implements Action {
  public readonly type = EFlagsActions.GetVariantError;

  constructor(public error: Error) {}
}

export type FlagsActions = InitializeAndGetFlags | GetFlags | GetFlagsSuccess | GetFlagsError | GetVariant | GetVariantSuccess | GetVariantError;
