<div fxFlex class="view-verify" fxLayout="column">
  <div fxLayout="column" fxLayoutGap="16px">
    <span class="global-font-20 verify-header"> Enter Your Code </span>
    <div *ngIf="userPhoneNumber" fxLayout="column" fxLayoutGap="10px" class="margin-override">
      <span fxFlex class="sub-header">
        We texted a 6-digit verification code to <b>+{{ userPhoneNumber }}</b>
      </span>
      <form [formGroup]="form">
        <span class="sub-header"> Please check your phone and enter the code. </span>
        <hc-input placeholder="Verification Code" [errorMessage]="'Code is required'" [control]="form.controls.key" [type]="'text'"></hc-input>
      </form>
    </div>
    <button fxFlex class="choice-button" [disabled]="!formValid() || (verificationCheckLoading$ | async)" (click)="verifySMS()" mat-raised-button>Verify</button>
    <div>
      <span> Didn't receive the code? </span>
      <span *ngIf="userPhoneNumber" class="a click-link" (click)="resendSMS()"> Send Again </span>
    </div>
    <div>
      <span> Need Help? </span>
      <span class="a click-link" (click)="navigateToVerifySupport()"> Contact Us </span>
    </div>
    <div>
      <span class="a click-link" (click)="navigateToEmailVerification()"> Verify With Email </span>
    </div>
  </div>
</div>
