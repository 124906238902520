import { IProfessionalHierarchy } from './lookups';

export class EnrollUserModel {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  profession: string;
  specialty?: string;
  startDate: string;
  pastExperience: string;
  isReferred: boolean;
  referredBy?: string;
  source: string;
  medium: string;
  campaign: string;
  professionHierarchy?: IProfessionalHierarchy;
  professionSector?: string;
  recruiterId?: string;

  private _baseRequiredFields: string[] = ['firstName', 'lastName', 'phoneNumber', 'email', 'profession', 'startDate', 'pastExperience', 'isReferred'];
  get requiredFields(): string[] {
    const fields = [...this._baseRequiredFields];

    if (this.isReferred) {
      fields.push('referredBy');
    }

    return fields;
  }

  get missingFields(): string[] {
    return this.requiredFields.filter(field => this[field] == null || this[field] === '');
  }
}
