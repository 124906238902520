<div class="dropAndGo" fxLayout="column" fxLayoutAlign="center center">
  <div fxLayout="column" fxLayoutAlign="center center">
    <span class="mat-body-2">Short on Time? Use Drop and Go.</span>
    <span class="mat-body-1">Let us manage your document uploads.</span>
  </div>
  <div *ngIf="!isNative" class="dropAndGo">
    <button id="bulkUpload" mat-raised-button color="primary" [disabled]="bulkUploadDocumentsLoading$ | async" (click)="fileInput.click()">
      File Upload
      <input #fileInput type="file" multiple accept="image/*;capture=camera" (change)="handleFileInput($event.target.files, fileInput)" style="display: none" />
    </button>
  </div>
  <!-- Used just for native as the ionic component only allows a single photo take/upload and not bulk -->
  <div *ngIf="isNative" class="dropAndGo">
    <button id="bulkUpload1" mat-raised-button color="primary" [disabled]="bulkUploadDocumentsLoading$ | async" (click)="takePicture()">File Upload</button>
  </div>
  <div>
    <span class="global-body2-menu">PRO TIP: </span>
    <span class="global-body1">When uploading your documents, make sure the file type is in a jpg, jpeg, png, doc, docx or pdf format.</span>
  </div>
</div>
