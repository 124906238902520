import { Action } from '@ngrx/store';
import {
  AboutMeModel,
  CandidateQualification,
  DataDeletionResponse,
  DeletionCodeConfirmationResponse,
  HcinContactModel,
  IAdUserModel,
  IAdUserResponseModel,
  IRegistrationModel,
  IRegistrationResponseModel,
  IResetPasswordModel,
  IUserModel,
  NurseModel,
  NurseProfileModel,
  PreEmploymentQuestionnaire,
  ProfessionalOverviewModel,
  TaskCompletionReturnObject,
  UserContextIN
} from 'src/app/common';
import { VerificationStatus } from 'src/app/common/constants';
import { IAccountStateModel } from 'src/app/common/models/account-state-model';
import { CommunicationPreferences } from 'src/app/common/models/communication-preferences';
import { GenericCompletionReturnObject } from 'src/app/common/models/generic-completion-return-model';

export enum EUserContextActions {
  NukeState = '[USER_CONTEXT] nuking state',
  GetAccountState = '[USER_CONTEXT] get account state',
  GetAccountStateSuccess = '[USER_CONTEXT] get account state success',
  GetAccountStateError = '[USER_CONTEXT] get account state error',
  GetUser = '[USER_CONTEXT] get user',
  GetUserSuccess = '[USER_CONTEXT] get user success',
  GetUserError = '[USER_CONTEXT] get user error',
  GetUserIN = '[USER_CONTEXT] get user IN',
  GetUserINSuccess = '[USER_CONTEXT] get user IN success',
  GetUserINError = '[USER_CONTEXT] get user IN error',
  CreateAdUser = '[USER_CONTEXT] create AD user',
  CreateAdUserSuccess = '[USER_CONTEXT] create AD user success',
  CreateAdUserError = '[USER_CONTEXT] create AD user error',
  GetNurse = '[USER_CONTEXT] get nurse',
  GetNurseSuccess = '[USER_CONTEXT] get nurse success',
  GetNurseError = '[USER_CONTEXT] get nurse error',
  GetNurseIN = '[USER_CONTEXT] get international nurse',
  GetNurseINSuccess = '[USER_CONTEXT] get international nurse success',
  GetNurseINError = '[USER_CONTEXT] get international nurse error',
  SetNurseIsNewStatus = '[USER_CONTEXT] set nurse isNew status',
  SubmitRegistration = '[USER_CONTEXT] submit registration',
  SubmitRegistrationSuccess = '[USER_CONTEXT] submit registration success',
  SubmitRegistrationError = '[USER_CONTEXT] submit registration error',
  UnlockRegistration = '[USER_CONTEXT] unlock registration',
  UnlockRegistrationSuccess = '[USER_CONTEXT] unlock registration success',
  UnlockRegistrationError = '[USER_CONTEXT] unlock registration error',
  GetRegistration = '[USER_CONTEXT] get registration',
  GetRegistrationSuccess = '[USER_CONTEXT] get registration success',
  GetRegistrationError = '[USER_CONTEXT] get registration error',
  SetNurseProfilePicture = '[USER_CONTEXT] set nurse profilePicture',
  SetUserJobNotificationsActive = '[USER_CONTEXT] set user jobNotificationsActive',
  SetNurseIsVerified = '[USER_CONTEXT] set nurse isVerified',
  SetUserIsNewToFalse = '[USER_CONTEXT] set user isNewToFalse',
  ResetUser = '[USER_CONTEXT] reset user',
  ResetUserSuccess = '[USER_CONTEXT] reset user success',
  ResetUserError = '[USER_CONTEXT] reset user error',
  PostSignIn = '[USER_CONTEXT] post sign in',
  PostSignInSuccess = '[USER_CONTEXT] post sign in success',
  PostSignInError = '[USER_CONTEXT] post sign in error',
  ClearCache = '[USER_CONTEXT] clear cache',
  ClearCacheSuccess = '[USER_CONTEXT] clear cache success',
  ClearCacheError = '[USER_CONTEXT] clear cache error',
  ClearCacheLogOut = '[USER_CONTEXT] clear cache + log out',
  ClearCacheLogOutSuccess = '[USER_CONTEXT] clear cache + log out success',
  ClearCacheLogOutError = '[USER_CONTEXT] clear cache + log out error',
  LogOut = '[USER_CONTEXT] log out user',
  LogOutSuccess = '[USER_CONTEXT] log out user success',
  LogOutError = '[USER_CONTEXT] log out user error',
  ReloadWindow = '[USER_CONTEXT] reload window',
  ContactSelectReset = '[USER_CONTEXT] contact select reset',
  ContactSelectResetSuccess = '[USER_CONTEXT] contact select reset success',
  ContactSelectResetError = '[USER_CONTEXT] contact select reset error',
  InitializeUser = '[USER_CONTEXT] initialize user',
  InitializeUserSuccess = '[USER_CONTEXT] initialize user success',
  InitializeUserError = '[USER_CONTEXT] initialize user error',
  SetIsReferred = '[USER_CONTEXT] set is referred',
  GetProfessionalOverview = '[USER_CONTEXT] get professional overview',
  GetProfessionalOverviewSuccess = '[USER_CONTEXT] get professional overview success',
  GetProfessionalOverviewError = '[USER_CONTEXT] get professional overview error',
  UpdateSummaryData = '[USER_CONTEXT] get update summary',
  UpdateSummarySuccess = '[USER_CONTEXT] update summary success',
  UpdateSummaryError = '[USER_CONTEXT] update summary error',
  UpdateSummaryReset = '[USER_CONTEXT] update summary reset',
  GetSummaryPrompts = '[USER_CONTEXT] get summary prompts',
  GetSummaryPromptsSuccess = '[USER_CONTEXT] get summary prompts success',
  GetSummaryPromptsError = '[USER_CONTEXT] get summary prompts error',
  SendResetPasswordEmail = '[USER_CONTEXT] send reset password email',
  SendResetPasswordEmailSuccess = '[USER_CONTEXT] send reset password email success',
  SendResetPasswordEmailError = '[USER_CONTEXT] send reset password email error',
  GetQuestionnaire = '[USER_CONTEXT] get questionnaire',
  GetQuestionnaireSuccess = '[USER_CONTEXT] get questionnaire success',
  GetQuestionnaireError = '[USER_CONTEXT] get questionnaire error',
  UpdateQuestionnaire = '[USER_CONTEXT] update questionnaire',
  UpdateQuestionnaireSuccess = '[USER_CONTEXT] update questionnaire success',
  UpdateQuestionnaireError = '[USER_CONTEXT] update questionnaire error',
  UpdateQuestionnaireReset = '[USER_CONTEXT] update questionnaire reset',
  GetAboutMe = '[USER_CONTEXT] get about me',
  GetAboutMeSuccess = '[USER_CONTEXT] get about me success',
  GetAboutMeError = '[USER_CONTEXT] get about me error',
  UpdateAboutMe = '[USER_CONTEXT] update about me',
  UpdateAboutMeSuccess = '[USER_CONTEXT] update about me success',
  UpdateAboutMeError = '[USER_CONTEXT] update about me error',
  UpdateAboutMeReset = '[USER_CONTEXT] update about me reset',
  DeleteQualification = '[USER_CONTEXT] delete qualification',
  UploadProfilePicture = '[USER_CONTEXT] upload file',
  UploadProfilePictureSuccess = '[USER_CONTEXT] upload file success',
  UploadProfilePictureError = '[USER_CONTEXT] upload file error',
  GetDocuments = '[USER_CONTEXT] get documents',
  GetDocumentsSuccess = '[USER_CONTEXT] get documents success',
  GetDocumentsError = '[USER_CONTEXT] get documents error',
  DeleteDocuments = '[USER_CONTEXT] delete documents',
  DeleteDocumentsSuccess = '[USER_CONTEXT] delete documents success',
  DeleteDocumentsError = '[USER_CONTEXT] delete documents error',
  GetDocumentsByID = '[USER_CONTEXT] get documents by id',
  GetDocumentsByIDSuccess = '[USER_CONTEXT] get documents by id success',
  GetDocumentsByIDError = '[USER_CONTEXT] get documents by id error',
  BulkUploadDocuments = '[USER_CONTEXT] bulk upload documents',
  BulkUploadDocumentsSuccess = '[USER_CONTEXT] bulk upload documents success',
  BulkUploadDocumentsError = '[USER_CONTEXT] bulk upload documents error',
  SetShowSpecialtyPopup = '[USER_CONTEXT] set show specialty popup',
  SendVerificationEmail = '[USER_CONTEXT] send verification email',
  SendVerificationEmailSuccess = '[USER_CONTEXT] send verification email success',
  SendVerificationEmailError = '[USER_CONTEXT] send verification email error',
  SendVerificationSms = '[USER_CONTEXT] send verification sms',
  SendVerificationSmsSuccess = '[USER_CONTEXT] send verification sms success',
  SendVerificationSmsError = '[USER_CONTEXT] send verification sms error',
  SetVerificationType = '[USER_CONTEXT] set verification type',
  SetVerificationTypeSuccess = '[USER_CONTEXT] set verification type success',
  SetVerificationTypeError = '[USER_CONTEXT] set verification type error',
  CheckVerificationKey = '[USER_CONTEXT] check verification key',
  CheckVerificationKeySuccess = '[USER_CONTEXT] check verification key success',
  CheckVerificationKeyError = '[USER_CONTEXT] check verification key error',
  SendVerificationSupportEmail = '[USER_CONTEXT] send verification support email',
  SendVerificationSupportEmailSuccess = '[USER_CONTEXT] send verification support email success',
  SendVerificationSupportEmailError = '[USER_CONTEXT] send verification support email error',
  DeleteUserRequest = '[USER_CONTEXT] delete user requested',
  DeleteUserRequestSuccess = '[USER_CONTEXT] delete user request success',
  DeleteUserRequestError = '[USER_CONTEXT] delete user request error',
  DeleteUser = '[USER_CONTEXT] delete user',
  DeleteUserSuccess = '[USER_CONTEXT] delete user success',
  DeleteUserError = '[USER_CONTEXT] delete user error',
  GetContactPreferences = '[USER_CONTEXT] get contact preferences',
  GetContactPreferencesSuccess = '[USER_CONTEXT] get contact preferences success',
  GetContactPreferencesError = '[USER_CONTEXT] get contact preferences error',
  UpdateContactPreferences = '[USER_CONTEXT] update contact preferences',
  UpdateContactPreferencesSuccess = '[USER_CONTEXT] update contact preferences success',
  UpdateContactPreferencesError = '[USER_CONTEXT] update contact preferences error',
  PostRegistrationCheck = '[USER CONTEXT] post registration check',
  PostRegistrationCheckSuccess = '[USER CONTEXT] post registration check success',
  PostRegistrationCheckError = '[USER CONTEXT] post registration check error'
}

// Stefano Fiumara made this
export class NukeState implements Action {
  public readonly type = EUserContextActions.NukeState;
}
export class GetAccountState implements Action {
  public readonly type = EUserContextActions.GetAccountState;
}

export class GetAccountStateSuccess implements Action {
  public readonly type = EUserContextActions.GetAccountStateSuccess;

  constructor(public payload: IAccountStateModel) {}
}

export class GetAccountStateError implements Action {
  public readonly type = EUserContextActions.GetAccountStateError;

  constructor(public error: Error) {}
}

export class GetUser implements Action {
  public readonly type = EUserContextActions.GetUser;
}

export class GetUserSuccess implements Action {
  public readonly type = EUserContextActions.GetUserSuccess;

  constructor(public payload: IUserModel) {}
}

export class GetUserError implements Action {
  public readonly type = EUserContextActions.GetUserError;

  constructor(public error: Error) {}
}

export class GetUserIN implements Action {
  public readonly type = EUserContextActions.GetUserIN;
}

export class GetUserINSuccess implements Action {
  public readonly type = EUserContextActions.GetUserINSuccess;

  constructor(public payload: UserContextIN) {}
}

export class GetUserINError implements Action {
  public readonly type = EUserContextActions.GetUserINError;

  constructor(public error: Error) {}
}

export class CreateAdUser implements Action {
  public readonly type = EUserContextActions.CreateAdUser;

  constructor(public payload: IAdUserModel) {}
}

export class CreateAdUserSuccess implements Action {
  public readonly type = EUserContextActions.CreateAdUserSuccess;

  constructor(public payload: IAdUserResponseModel) {}
}

export class CreateAdUserError implements Action {
  public readonly type = EUserContextActions.CreateAdUserError;

  constructor(public error: Error) {}
}

export class SetUserJobNotificationsActive implements Action {
  public readonly type = EUserContextActions.SetUserJobNotificationsActive;

  constructor(public payload: boolean) {}
}

export class GetNurse implements Action {
  public readonly type = EUserContextActions.GetNurse;
}

export class GetNurseSuccess implements Action {
  public readonly type = EUserContextActions.GetNurseSuccess;

  constructor(public payload: NurseModel) {}
}

export class GetNurseError implements Action {
  public readonly type = EUserContextActions.GetNurseError;

  constructor(public error: Error) {}
}

export class GetNurseIN implements Action {
  public readonly type = EUserContextActions.GetNurseIN;
}

export class GetNurseINSuccess implements Action {
  public readonly type = EUserContextActions.GetNurseINSuccess;

  constructor(public payload: HcinContactModel) {}
}

export class GetNurseINError implements Action {
  public readonly type = EUserContextActions.GetNurseINError;

  constructor(public error: Error) {}
}

export class SetNurseIsNewStatus implements Action {
  public readonly type = EUserContextActions.SetNurseIsNewStatus;

  constructor(public payload: NurseModel) {}
}

export class SubmitRegistration implements Action {
  public readonly type = EUserContextActions.SubmitRegistration;

  constructor(public payload: IRegistrationModel) {}
}

export class SubmitRegistrationSuccess implements Action {
  public readonly type = EUserContextActions.SubmitRegistrationSuccess;

  constructor(public payload: IRegistrationResponseModel) {}
}

export class SubmitRegistrationError implements Action {
  public readonly type = EUserContextActions.SubmitRegistrationError;

  constructor(public error: Error) {}
}

export class UnlockRegistration implements Action {
  public readonly type = EUserContextActions.UnlockRegistration;
}

export class UnlockRegistrationSuccess implements Action {
  public readonly type = EUserContextActions.UnlockRegistrationSuccess;
}

export class UnlockRegistrationError implements Action {
  public readonly type = EUserContextActions.UnlockRegistrationError;

  constructor(public error: Error) {}
}

export class GetRegistration implements Action {
  public readonly type = EUserContextActions.GetRegistration;
}

export class GetRegistrationSuccess implements Action {
  public readonly type = EUserContextActions.GetRegistrationSuccess;

  constructor(public payload: IRegistrationModel) {}
}

export class GetRegistrationError implements Action {
  public readonly type = EUserContextActions.GetRegistrationError;

  constructor(public error: Error) {}
}

export class SetNurseProfilePicture implements Action {
  public readonly type = EUserContextActions.SetNurseProfilePicture;

  constructor(public payload: string) {}
}

export class SetNurseIsVerified implements Action {
  public readonly type = EUserContextActions.SetNurseIsVerified;

  constructor(public payload: boolean) {}
}

export class SetUserIsNewToFalse implements Action {
  public readonly type = EUserContextActions.SetUserIsNewToFalse;
}

export class ResetUser implements Action {
  public readonly type = EUserContextActions.ResetUser;
}

export class ResetUserSuccess implements Action {
  public readonly type = EUserContextActions.ResetUserSuccess;

  constructor(public response: any) {}
}

export class ResetUserError implements Action {
  public readonly type = EUserContextActions.ResetUserError;

  constructor(public error: Error) {}
}

export class PostSignIn implements Action {
  public readonly type = EUserContextActions.PostSignIn;
}

export class PostSignInSuccess implements Action {
  public readonly type = EUserContextActions.PostSignInSuccess;

  constructor(public response: any) {}
}

export class PostSignInError implements Action {
  public readonly type = EUserContextActions.PostSignInError;

  constructor(public error: Error) {}
}

export class ClearCache implements Action {
  public readonly type = EUserContextActions.ClearCache;
}

export class ClearCacheSuccess implements Action {
  public readonly type = EUserContextActions.ClearCacheSuccess;

  constructor(public response: any) {}
}

export class ClearCacheError implements Action {
  public readonly type = EUserContextActions.ClearCacheError;

  constructor(public error: Error) {}
}
export class LogOut implements Action {
  public readonly type = EUserContextActions.LogOut;
}

export class LogOutSuccess implements Action {
  public readonly type = EUserContextActions.LogOutSuccess;

  constructor(public response: any) {}
}
export class LogOutError implements Action {
  public readonly type = EUserContextActions.LogOutError;

  constructor(public error: Error) {}
}

export class ClearCacheLogOut implements Action {
  public readonly type = EUserContextActions.ClearCacheLogOut;
}

export class ClearCacheLogOutSuccess implements Action {
  public readonly type = EUserContextActions.ClearCacheLogOutSuccess;

  constructor(response: any) {}
}

export class ClearCacheLogOutError implements Action {
  public readonly type = EUserContextActions.ClearCacheLogOutError;

  constructor(public error: Error) {}
}

export class ReloadWindow implements Action {
  public readonly type = EUserContextActions.ReloadWindow;
}

export class ContactSelectReset implements Action {
  public readonly type = EUserContextActions.ContactSelectReset;

  constructor(public request: any) {}
}

export class ContactSelectResetSuccess implements Action {
  public readonly type = EUserContextActions.ContactSelectResetSuccess;

  constructor(public response: any) {}
}

export class ContactSelectResetError implements Action {
  public readonly type = EUserContextActions.ContactSelectResetError;

  constructor(public error: Error) {}
}

export class InitializeUser implements Action {
  public readonly type = EUserContextActions.InitializeUser;
}

export class InitializeUserSuccess implements Action {
  public readonly type = EUserContextActions.InitializeUserSuccess;

  constructor(
    public isNewUser: boolean,
    public email: string
  ) {}
}

export class InitializeUserError implements Action {
  public readonly type = EUserContextActions.InitializeUserError;

  constructor(public error: Error) {}
}

export class SetIsReferred implements Action {
  public readonly type = EUserContextActions.SetIsReferred;

  constructor(public isReferred: boolean) {}
}

export class GetProfessionalOverview implements Action {
  public readonly type = EUserContextActions.GetProfessionalOverview;
}

export class GetProfessionalOverviewSuccess implements Action {
  public readonly type = EUserContextActions.GetProfessionalOverviewSuccess;

  constructor(public profileData: NurseProfileModel) {}
}

export class GetProfessionalOverviewError implements Action {
  public readonly type = EUserContextActions.GetProfessionalOverviewError;

  constructor(public error: Error) {}
}

export class UpdateSummaryData implements Action {
  public readonly type = EUserContextActions.UpdateSummaryData;

  constructor(public summaryData: ProfessionalOverviewModel) {}
}

export class UpdateSummarySuccess implements Action {
  public readonly type = EUserContextActions.UpdateSummarySuccess;

  constructor(public summaryData: TaskCompletionReturnObject) {}
}

export class UpdateSummaryError implements Action {
  public readonly type = EUserContextActions.UpdateSummaryError;

  constructor(public error: Error) {}
}
export class UpdateSummaryReset implements Action {
  public readonly type = EUserContextActions.UpdateSummaryReset;
}

export class GetSummaryPrompts implements Action {
  public readonly type = EUserContextActions.GetSummaryPrompts;
}

export class GetSummaryPromptsSuccess implements Action {
  public readonly type = EUserContextActions.GetSummaryPromptsSuccess;

  constructor(public summaryPrompts: string[]) {}
}

export class GetSummaryPromptsError implements Action {
  public readonly type = EUserContextActions.GetSummaryPromptsError;

  constructor(public error: Error) {}
}

export class SendResetPasswordEmail implements Action {
  public readonly type = EUserContextActions.SendResetPasswordEmail;

  constructor(public payload: IResetPasswordModel) {}
}

export class SendResetPasswordEmailSuccess implements Action {
  public readonly type = EUserContextActions.SendResetPasswordEmailSuccess;
}

export class SendResetPasswordEmailError implements Action {
  public readonly type = EUserContextActions.SendResetPasswordEmailError;

  constructor(public error: Error) {}
}
export class GetQuestionnaire implements Action {
  public readonly type = EUserContextActions.GetQuestionnaire;
}

export class GetQuestionnaireSuccess implements Action {
  public readonly type = EUserContextActions.GetQuestionnaireSuccess;

  constructor(public data: PreEmploymentQuestionnaire) {}
}

export class GetQuestionnaireError implements Action {
  public readonly type = EUserContextActions.GetQuestionnaireError;
  constructor(public error: Error) {}
}
export class GetAboutMe implements Action {
  public readonly type = EUserContextActions.GetAboutMe;
}
export class GetAboutMeSuccess implements Action {
  public readonly type = EUserContextActions.GetAboutMeSuccess;

  constructor(public payload: AboutMeModel) {}
}
export class GetAboutMeError implements Action {
  public readonly type = EUserContextActions.GetAboutMeError;

  constructor(public error: Error) {}
}
export class UpdateQuestionnaire implements Action {
  public readonly type = EUserContextActions.UpdateQuestionnaire;

  constructor(public data: PreEmploymentQuestionnaire) {}
}

export class UpdateQuestionnaireSuccess implements Action {
  public readonly type = EUserContextActions.UpdateQuestionnaireSuccess;

  constructor(
    public response: GenericCompletionReturnObject<number>,
    public data: PreEmploymentQuestionnaire
  ) {}
}

export class UpdateQuestionnaireError implements Action {
  public readonly type = EUserContextActions.UpdateQuestionnaireError;
  constructor(public error: Error) {}
}

export class UpdateQuestionnaireReset implements Action {
  public readonly type = EUserContextActions.UpdateQuestionnaireReset;
}

export class UpdateAboutMe implements Action {
  public readonly type = EUserContextActions.UpdateAboutMe;

  constructor(public payload: AboutMeModel) {}
}

export class UpdateAboutMeSuccess implements Action {
  public readonly type = EUserContextActions.UpdateAboutMeSuccess;

  constructor(public response: TaskCompletionReturnObject) {}
}

export class UpdateAboutMeError implements Action {
  public readonly type = EUserContextActions.UpdateAboutMeError;

  constructor(public error: Error) {}
}

export class UpdateAboutMeReset implements Action {
  public readonly type = EUserContextActions.UpdateAboutMeReset;
}

export class DeleteQualification implements Action {
  public readonly type = EUserContextActions.DeleteQualification;

  constructor(public payload: string) {}
}

export class UploadProfilePicture implements Action {
  public readonly type = EUserContextActions.UploadProfilePicture;

  constructor(public files: File[]) {}
}
export class UploadProfilePictureSuccess implements Action {
  public readonly type = EUserContextActions.UploadProfilePictureSuccess;

  constructor(public payload: any) {}
}
export class UploadProfilePictureError implements Action {
  public readonly type = EUserContextActions.UploadProfilePictureError;

  constructor(public error: Error) {}
}

export class GetDocuments implements Action {
  public readonly type = EUserContextActions.GetDocuments;
}
export class GetDocumentsSuccess implements Action {
  public readonly type = EUserContextActions.GetDocumentsSuccess;

  constructor(public payload: CandidateQualification[]) {}
}
export class GetDocumentsError implements Action {
  public readonly type = EUserContextActions.GetDocumentsError;

  constructor(public error: Error) {}
}

export class DeleteDocuments implements Action {
  public readonly type = EUserContextActions.DeleteDocuments;

  constructor(public payload: CandidateQualification) {}
}

export class DeleteDocumentsSuccess implements Action {
  public readonly type = EUserContextActions.DeleteDocumentsSuccess;

  constructor(public payload: CandidateQualification) {}
}

export class DeleteDocumentsError implements Action {
  public readonly type = EUserContextActions.DeleteDocumentsError;

  constructor(public error: Error) {}
}

export class GetDocumentsByID implements Action {
  public readonly type = EUserContextActions.GetDocumentsByID;

  constructor(public payload: CandidateQualification) {}
}

export class GetDocumentsByIDSuccess implements Action {
  public readonly type = EUserContextActions.GetDocumentsByIDSuccess;

  constructor(public payload: Response) {}
}

export class GetDocumentsByIDError implements Action {
  public readonly type = EUserContextActions.GetDocumentsByIDError;

  constructor(public error: Error) {}
}

export class BulkUploadDocuments implements Action {
  public readonly type = EUserContextActions.BulkUploadDocuments;
  constructor(public payload: { files: File[] }) {}
}

export class BulkUploadDocumentsSuccess implements Action {
  public readonly type = EUserContextActions.BulkUploadDocumentsSuccess;
}

export class BulkUploadDocumentsError implements Action {
  public readonly type = EUserContextActions.BulkUploadDocumentsError;
  constructor(public error: Error) {}
}

export class SetShowSpecialtyPopup implements Action {
  public readonly type = EUserContextActions.SetShowSpecialtyPopup;

  constructor(public showSpecialtyPopup: boolean) {}
}

export class SendVerificationEmail implements Action {
  public readonly type = EUserContextActions.SendVerificationEmail;

  constructor(public resend?: boolean) {}
}
export class SendVerificationEmailSuccess implements Action {
  public readonly type = EUserContextActions.SendVerificationEmailSuccess;

  constructor(public userEmail: string) {}
}
export class SendVerificationEmailError implements Action {
  public readonly type = EUserContextActions.SendVerificationEmailError;

  constructor(public error: Error) {}
}

export class SendVerificationSms implements Action {
  public readonly type = EUserContextActions.SendVerificationSms;

  constructor(public resend?: boolean) {}
}
export class SendVerificationSmsSuccess implements Action {
  public readonly type = EUserContextActions.SendVerificationSmsSuccess;

  constructor(
    public userPhoneNumber: string,
    public response: number,
    public resend?: boolean
  ) {}
}
export class SendVerificationSmsError implements Action {
  public readonly type = EUserContextActions.SendVerificationSmsError;

  constructor(public error: Error) {}
}

export class SetVerificationType implements Action {
  public readonly type = EUserContextActions.SetVerificationType;

  constructor(public verificationType: number) {}
}
export class SetVerificationTypeSuccess implements Action {
  public readonly type = EUserContextActions.SetVerificationTypeSuccess;

  constructor(
    public response: number,
    public verificationType: number
  ) {}
}
export class SetVerificationTypeError implements Action {
  public readonly type = EUserContextActions.SetVerificationTypeError;

  constructor(public error: Error) {}
}

export class CheckVerificationKey implements Action {
  public readonly type = EUserContextActions.CheckVerificationKey;

  constructor(public verificationKey: { emailKey?: string; smsKey?: number }) {}
}
export class CheckVerificationKeySuccess implements Action {
  public readonly type = EUserContextActions.CheckVerificationKeySuccess;

  constructor(public response: { message: string; status: VerificationStatus }) {}
}
export class CheckVerificationKeyError implements Action {
  public readonly type = EUserContextActions.CheckVerificationKeyError;

  constructor(public error: Error) {}
}

export class SendVerificationSupportEmail implements Action {
  public readonly type = EUserContextActions.SendVerificationSupportEmail;

  constructor(public supportReport: { name: string; email: string; description: string }) {}
}
export class SendVerificationSupportEmailSuccess implements Action {
  public readonly type = EUserContextActions.SendVerificationSupportEmailSuccess;
}
export class SendVerificationSupportEmailError implements Action {
  public readonly type = EUserContextActions.SendVerificationSupportEmailError;

  constructor(public error: Error) {}
}

export class DeleteUserRequest implements Action {
  public readonly type = EUserContextActions.DeleteUserRequest;
}

export class DeleteUserRequestSuccess implements Action {
  public readonly type = EUserContextActions.DeleteUserRequestSuccess;

  constructor(public payload: DataDeletionResponse) {}
}

export class DeleteUserRequestError implements Action {
  public readonly type = EUserContextActions.DeleteUserRequestError;

  constructor(public error: Error) {}
}

export class DeleteUser implements Action {
  public readonly type = EUserContextActions.DeleteUser;

  constructor(public payload: { confirmationCode: string }) {}
}

export class DeleteUserSuccess implements Action {
  public readonly type = EUserContextActions.DeleteUserSuccess;

  constructor(public payload: DeletionCodeConfirmationResponse) {}
}

export class DeleteUserError implements Action {
  public readonly type = EUserContextActions.DeleteUserError;

  constructor(public error: Error) {}
}

export class GetContactPreferences implements Action {
  public readonly type = EUserContextActions.GetContactPreferences;
}

export class GetContactPreferencesSuccess implements Action {
  public readonly type = EUserContextActions.GetContactPreferencesSuccess;

  constructor(public payload: CommunicationPreferences) {}
}

export class GetContactPreferencesError implements Action {
  public readonly type = EUserContextActions.GetContactPreferencesError;

  constructor(public error: Error) {}
}

export class UpdateContactPreferences implements Action {
  public readonly type = EUserContextActions.UpdateContactPreferences;

  constructor(public payload: CommunicationPreferences) {}
}

export class UpdateContactPreferencesSuccess implements Action {
  public readonly type = EUserContextActions.UpdateContactPreferencesSuccess;

  constructor(public payload: any) {}
}

export class UpdateContactPreferencesError implements Action {
  public readonly type = EUserContextActions.UpdateContactPreferencesError;

  constructor(public error: Error) {}
}

export class PostRegistrationCheck implements Action {
  public readonly type = EUserContextActions.PostRegistrationCheck;
}
export class PostRegistrationCheckSuccess implements Action {
  public readonly type = EUserContextActions.PostRegistrationCheckSuccess;

  constructor(public payload: number) {}
}
export class PostRegistrationCheckError implements Action {
  public readonly type = EUserContextActions.PostRegistrationCheckError;

  constructor(public error: Error) {}
}

export type UserContextActions =
  | NukeState
  | GetAccountState
  | GetAccountStateSuccess
  | GetAccountStateError
  | GetUser
  | GetUserSuccess
  | GetUserError
  | GetUserIN
  | GetUserINSuccess
  | GetUserINError
  | CreateAdUser
  | CreateAdUserSuccess
  | CreateAdUserError
  | SetUserJobNotificationsActive
  | GetNurse
  | GetNurseSuccess
  | GetNurseError
  | GetNurseIN
  | GetNurseINSuccess
  | GetNurseINError
  | SetNurseIsNewStatus
  | SetNurseProfilePicture
  | SetNurseIsVerified
  | SetUserIsNewToFalse
  | GetRegistration
  | GetRegistrationSuccess
  | GetRegistrationError
  | SubmitRegistration
  | SubmitRegistrationSuccess
  | SubmitRegistrationError
  | UnlockRegistration
  | UnlockRegistrationSuccess
  | UnlockRegistrationError
  | ResetUser
  | ResetUserSuccess
  | ResetUserError
  | PostSignIn
  | PostSignInSuccess
  | PostSignInError
  | ClearCache
  | ClearCacheSuccess
  | ClearCacheError
  | LogOut
  | LogOutSuccess
  | LogOutError
  | ClearCacheLogOut
  | ClearCacheLogOutSuccess
  | ClearCacheLogOutError
  | ReloadWindow
  | ContactSelectReset
  | ContactSelectResetSuccess
  | ContactSelectResetError
  | InitializeUser
  | InitializeUserSuccess
  | InitializeUserError
  | SetIsReferred
  | GetProfessionalOverview
  | GetProfessionalOverviewSuccess
  | GetProfessionalOverviewError
  | UpdateSummaryData
  | UpdateSummarySuccess
  | UpdateSummaryError
  | UpdateSummaryReset
  | GetSummaryPrompts
  | GetSummaryPromptsSuccess
  | GetSummaryPromptsError
  | SendResetPasswordEmail
  | SendResetPasswordEmailSuccess
  | SendResetPasswordEmailError
  | GetQuestionnaireSuccess
  | GetQuestionnaire
  | GetQuestionnaireError
  | UpdateQuestionnaire
  | UpdateQuestionnaireSuccess
  | UpdateQuestionnaireError
  | UpdateQuestionnaireReset
  | GetAboutMe
  | GetAboutMeSuccess
  | GetAboutMeError
  | UpdateAboutMe
  | UpdateAboutMeSuccess
  | UpdateAboutMeError
  | UpdateAboutMeReset
  | DeleteQualification
  | UploadProfilePicture
  | UploadProfilePictureSuccess
  | UploadProfilePictureError
  | GetDocuments
  | GetDocumentsSuccess
  | GetDocumentsError
  | DeleteDocuments
  | DeleteDocumentsSuccess
  | DeleteDocumentsError
  | GetDocumentsByID
  | GetDocumentsByIDSuccess
  | GetDocumentsByIDError
  | BulkUploadDocuments
  | BulkUploadDocumentsSuccess
  | BulkUploadDocumentsError
  | SetShowSpecialtyPopup
  | SendVerificationEmail
  | SendVerificationEmailSuccess
  | SendVerificationEmailError
  | SendVerificationSms
  | SendVerificationSmsSuccess
  | SendVerificationSmsError
  | SetVerificationType
  | SetVerificationTypeSuccess
  | SetVerificationTypeError
  | CheckVerificationKey
  | CheckVerificationKeySuccess
  | CheckVerificationKeyError
  | SendVerificationSupportEmail
  | SendVerificationSupportEmailSuccess
  | SendVerificationSupportEmailError
  | DeleteUserRequest
  | DeleteUserRequestSuccess
  | DeleteUserRequestError
  | DeleteUser
  | DeleteUserSuccess
  | DeleteUserError
  | GetContactPreferences
  | GetContactPreferencesSuccess
  | GetContactPreferencesError
  | UpdateContactPreferences
  | UpdateContactPreferencesSuccess
  | UpdateContactPreferencesError
  | PostRegistrationCheck
  | PostRegistrationCheckSuccess
  | PostRegistrationCheckError;
