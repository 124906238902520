<ng-container *ngIf="{ fluVaccineLoading: fluVaccineLoading$ | async, fluVaccineSaving: updateFluVaccineSaving$ | async } as obs">
  <app-loading-screen-spinner [show]="obs.fluVaccineLoading"></app-loading-screen-spinner>

  <div *ngIf="!obs.fluVaccineLoading" class="tasks-container tasks-full-height">
    <div class="tasks-desc-section" [ngClass]="{ 'tasks-form-grey': obs.fluVaccineLoading }" fxLayout="column" fxLayoutGap="16px">
      <hc-body>Please provide a photo or digital copy of your vaccine for the current flu season.</hc-body>
      <ul class="ul-margin">
        <hc-body [size]="smallBodySize" styles="font-weight: bold">Document Requirements:</hc-body>
        <li class="li-margin">Must be visible</li>
        <li class="li-margin">Must be readable</li>
      </ul>
    </div>
    <app-base-form
      *ngIf="form"
      #baseForm
      [form]="form"
      [takeUpFullHeight]="true"
      [isLoadingObs]="fluVaccineLoading$"
      [isSavingObs]="updateFluVaccineSaving$"
      [formHeaderAttributes]="formHeaderAttributes"
      (saveableEmitter)="onSave($event)"
    >
      <ng-container ngProjectAs="[formControls]">
        <hc-file-upload
          id="FileUploadValidId"
          size="default"
          class="tasks-upload-button"
          [label]="isDesktop ? 'Upload Documents' : 'Take photo or upload'"
          acceptedExtensions=".jpg, .jpeg, .png, .doc, .docx, .pdf"
          aboveLabel="Photo or digital copy of vaccine *"
          [errorMessage]="baseForm.getErrorMessage(form?.controls.fileUpload, 'Document')"
          [allowMultipleFiles]="false"
          [required]="true"
          [control]="form?.controls.fileUpload"
          [filenameMappingFunction]="fileTruncationFunction | async"
          [prepopulateWithFiles]="hasPrepopulatedFile() ? [prepopulatedFile] : []"
          [enableFilePreviewPopup]="false"
          [disabled]="obs.fluVaccineSaving || obs.fluVaccineLoading"
          [disableClearFileAction]="obs.fluVaccineSaving"
          (valueChanged)="handleFileInput($event)"
        ></hc-file-upload>
      </ng-container>

      <ng-container ngProjectAs="[formButtons]">
        <div class="tasks-form-buttons-wrapper">
          <app-flow-handler
            [recordObs]="hasPrepopulatedFile() ? fluVaccine$ : updateFluVaccine$"
            [form]="form"
            [isSavingObs]="updateFluVaccineSaving$"
            [showRegularSaveButton]="true"
            (saveClickEmitter)="hasPrepopulatedFile() ? null : baseForm.saveCheck()"
          ></app-flow-handler>
        </div>
      </ng-container>
    </app-base-form>
  </div>
</ng-container>
