export class WorkHistory {
  id?: string; // GUID
  employerName?: string;
  employerCity?: string;
  employerState?: string; // GUID
  employerStreet1?: string;
  employerStreet2?: string;
  employerZip?: string;
  country?: string;
  startDate?: Date;
  endDate?: Date;
  currentPosition?: string | boolean;
  description1?: string;
  specialtyId?: string; // GUID
  specialty?: any; // GUID
  profession?: string;
  professionTitle?: string;
  professionType?: number;
  patientRatio?: string;
  float?: string | boolean;
  lapseReason?: string;
  facilityBed?: string;
  traumaLevel?: string;
  chargeExperience?: string | boolean;
  preceptorExperience?: string | boolean;
  shiftsPerMonth?: number;
  type?: number;
  isNeeded?: boolean;
  taskCreated?: boolean;
}

export class HcinWorkHistory {
  id?: string;
  employerName?: string;
  employerCity?: string;
  employerState?: string;
  country?: string;
  startDate?: Date;
  endDate?: Date;
  currentPosition?: string | boolean;
  description1?: string;
  specialtyId?: string;
  profession?: string;
  professionTitle?: string;
}

export function mapToHcinWorkHistory(workHistory: WorkHistory): HcinWorkHistory {
  return {
    id: workHistory.id,
    employerName: workHistory.employerName,
    employerCity: workHistory.employerCity,
    employerState: workHistory.employerState,
    country: workHistory.country,
    startDate: workHistory.startDate,
    endDate: workHistory.endDate,
    currentPosition: workHistory.currentPosition,
    description1: workHistory.description1,
    specialtyId: workHistory.specialtyId,
    profession: workHistory.profession,
    professionTitle: workHistory.professionTitle
  };
}
