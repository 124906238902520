export interface IDataState<T = any> {
  data: T;
  loading: boolean;
  error: Error;
  expirationDate?: Date;
  requestId?: string;
  previousData?: T;
}

export function initializeData(): IDataState {
  return {
    data: null,
    loading: false,
    error: null
  };
}
