import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatestWith, Observable, Subject, Subscription } from 'rxjs';
import { IImmigrationStageLookupModel, ILookup, UserContextIN } from 'src/app/common';
import { selectUserDataIN } from 'src/app/store/userContext/userContext.selectors';
import { selectINLookupsImmigrationStages } from 'src/app/store/lookups/lookups.selectors';
import { VisaStep } from 'src/app/common/constants';
import { StepItem } from '../progress-tracker/progress-tracker.component';

export interface StepIcon {
  icon: string;
  step: any;
}

@Component({
  selector: 'app-immigration-tracker',
  templateUrl: './immigration-tracker.component.html',
  styleUrls: ['./immigration-tracker.component.scss']
})
export class ImmigrationTrackerComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();

  userIN$: Observable<UserContextIN> = this._store.select(selectUserDataIN);

  immigrationStages$: Observable<Map<number, ILookup<IImmigrationStageLookupModel>>> = this._store.select(selectINLookupsImmigrationStages);

  immigrationStages: StepItem[];

  stageDataSubscription: Subscription;

  currentStageId: number;

  visaStepIcons: StepIcon[] = [
    { icon: 'chat', step: VisaStep.InterviewSignAgreement },
    { icon: 'folder', step: VisaStep.VisaFilingPrep },
    { icon: 'plagiarism', step: VisaStep.DocumentApproval },
    { icon: 'draw', step: VisaStep.ETAReview },
    { icon: 'history_edu', step: VisaStep.I140DraftingandReview },
    { icon: 'send', step: VisaStep.VisaPetitionSent },
    { icon: 'check_circle', step: VisaStep.VisaPetitionFiled },
    { icon: 'thumb_up_alt', step: VisaStep.VisaPetitionApproved },
    { icon: 'verified', step: VisaStep.VisaFeeBillsIssued },
    { icon: 'attach_money', step: VisaStep.VisaFeeBillsPaid },
    { icon: 'attach_email', step: VisaStep.NVCDocumentsSubmitted },
    { icon: 'verified_user', step: VisaStep.EmbassyAppointment },
    { icon: 'perm_contact_calendar', step: VisaStep.USVisaIssued },
    { icon: 'file_copy', step: VisaStep.DocumentarilyQualified }
  ];

  infoDialogContent = {
    stages: [
      {
        icon: 'chat',
        icon_class: 'blue-icon',
        title: '1. Interview and Sign Agreement',
        // eslint-disable-next-line max-len
        text: 'Learn more and interview with an International Recruiter to see if you qualify for the PassportUSA program. Sign an agreement which outlines our commitment to work with each other.'
      },
      {
        icon: 'folder',
        icon_class: 'blue-icon',
        title: '2. Visa Filing Prep',
        // eslint-disable-next-line max-len
        text: 'Your International Program Associate (IPA) will help you collect documents for your visa petition, coaching you through Licensure or English exams if needed. We will get your visa petition filed with USCIS (US Citizenship and Immigration Services) as soon as possible.'
      },
      {
        icon: 'plagiarism',
        icon_class: 'blue-icon',
        title: '3. Document Approval',
        // eslint-disable-next-line max-len
        text: 'Your visa documents have been approved by our legal team. Health Carousel is your US Employer Sponsor. Our experienced legal team will review your documents, credentials and the I-140 Petition multiple times before it is sent to USCIS.'
      },
      {
        icon: 'draw',
        icon_class: 'blue-icon',
        title: '4. ETA Review',
        text: 'Our legal team has sent your IPA a document for your review and signature. Please work back with your IPA to provide any corrections or updates.'
      },
      {
        icon: 'history_edu',
        icon_class: 'blue-icon',
        title: '5. I-140 Drafting and Review',
        // eslint-disable-next-line max-len
        text: 'Our legal team has received all of your documentation and is in the final stages of drafting and reviewing the I-140 Petition. Your IPA will let you know when the I-140 Petition is sent to USCIS.'
      },
      {
        icon: 'send',
        icon_class: 'blue-icon',
        title: '6. VISA Petition Sent',
        // eslint-disable-next-line max-len
        text: 'The I-140 Petition we file on your behalf has been sent to USCIS. Your IPA will provide an estimate of your visa timeline based on your individual circumstances. The length of time depends on many factors like your country of origin and the US Immigration System.'
      },
      {
        icon: 'check_circle',
        icon_class: 'blue-icon',
        title: '7. VISA Petition Filed',
        // eslint-disable-next-line max-len
        text: 'The I-140 Petition has been received by USCIS and is in process. If you did not have a previous priority date, the date your case was received by USCIS will be your priority date once the case is approved. Your priority date represents your “place in line” to obtain your visa. You can use your USCIS Receipt Number to check the case status.'
      },
      {
        icon: 'thumb_up_alt',
        icon_class: 'blue-icon',
        title: '8. VISA Petition Approved',
        // eslint-disable-next-line max-len
        text: 'Your I-140 Petition has been approved and your case will be forwarded to the National Visa Center (NVC). Your IPA will provide you with the next steps to proceed through immigration, credentialing, and deployment.'
      },
      {
        icon: 'verified',
        icon_class: 'blue-icon',
        title: '9. VISA Fee Bills Issued',
        // eslint-disable-next-line max-len
        text: 'The NVC has issued your fee bills. Your IDA (International Deployment Associate) will review with you the information you will need to submit and will coordinate with our legal team to pay the fee bills. '
      },
      {
        icon: 'attach_money',
        icon_class: 'blue-icon',
        title: '10. VISA Bills Paid',
        text: 'Your NVC Fee Bills have been paid. You will now submit your DS-260 visa application online. Your IDA will provide additional information.'
      },
      { icon: 'attach_email', icon_class: 'blue-icon', title: '11. NVC Documents Submitted', text: 'Our legal team has submitted your documents to the NVC to be reviewed.' },
      {
        icon: 'verified_user',
        icon_class: 'blue-icon',
        title: '12. Documentarily Qualified',
        // eslint-disable-next-line max-len
        text: 'The NVC has reviewed your documents and completed the case. NVC will now work with the Embassy to schedule interview dates on a first-come, first-serve basis. It’s important for you to complete credentials and state licensure requirements at this time.'
      },
      {
        icon: 'perm_contact_calendar',
        icon_class: 'blue-icon',
        title: '13. Embassy Appointment',
        text: 'The embassy has scheduled your VISA interview. Your IDA will help you complete all credentialing and licensing requirements and prepare for travel.'
      },
      {
        icon: 'file_copy',
        icon_class: 'blue-icon',
        title: '14. US VISA Issued',
        text: 'Your VISA has been issued! Congratulations! Your IDA will help coordinate your arrival to the US.'
      }
    ]
  };

  constructor(private _store: Store) {}

  addStageDataSubscription() {
    this.stageDataSubscription = this.userIN$.pipe(combineLatestWith(this.immigrationStages$)).subscribe(([userINData, stages]) => {
      if (userINData && stages) {
        this.immigrationStages = [];
        stages?.forEach(stage => {
          this.immigrationStages.push({
            name: stage.name,
            id: stage.id,
            icon: this.visaStepIcons.find(icon => icon.step == stage.id).icon
          });
        });

        if (userINData.immigrationRecords.length > 0) {
          this.currentStageId = userINData.immigrationRecords[0].visaTimelineStage;
        } else {
          this.currentStageId = this.immigrationStages[0].id;
        }
      }
    });
  }

  ngOnInit(): void {
    this.addStageDataSubscription();
  }

  ngOnDestroy(): void {
    this.stageDataSubscription.unsubscribe();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
