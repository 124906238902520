import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ILookup, Technology } from 'src/app/common';
import { FormHeaderService, NavHelper } from 'src/app/services';
import { selectTechnologyLookup, selectProficiencyLookup } from 'src/app/store/lookups/lookups.selectors';
import { selectTechnology, selectTechnologyLoading } from 'src/app/store/technologies/technology.selectors';
import { Store } from '@ngrx/store';
import { IAppState } from 'src/app/store/app/app.state';
import { Subscription, Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { CallToActionClicked, TechnologiesViewed } from 'src/app/store/segment/segment.actions';
import { environment } from 'src/environments/environment';
import { IRecordCardConfig } from 'hc-design-system-lib/lib/components/cards/cards.interfaces';
import { CardElevation, DialogService, IDialogParameters } from 'hc-design-system-lib';
import { GetTechnologyByID } from 'src/app/store/technologies/technology.actions';
import { TechnologyModalComponent } from '../technology-modal/technology-modal.component';

@Component({
  selector: 'app-technology',
  templateUrl: './technology.component.html',
  styleUrls: ['./technology.component.scss']
})
export class TechnologyComponent implements OnInit, OnDestroy {
  readonly technologyImage = '/assets/img/empty-states/Technology.png';
  readonly addButtonIcon = 'add';

  techList$: Observable<Technology[]> = this._store.select(selectTechnology);

  techLoading$: Observable<boolean> = this._store.select(selectTechnologyLoading);

  proficiencyLookup: Map<number, ILookup<number>>;
  technologyLookup: Map<string, ILookup<string>>;

  technologyLookupSubscription: Subscription;
  technologyLookup$: Observable<Map<string, ILookup<string>>> = this._store.select(selectTechnologyLookup);

  proficiencyLookupSubscription: Subscription;
  proficiencyLookup$: Observable<Map<number, any>> = this._store.select(selectProficiencyLookup);

  @ViewChild('technologyModal')
  technologyModal: TemplateRef<TechnologyModalComponent>;

  constructor(
    private _navHelper: NavHelper,
    private _route: ActivatedRoute,
    private _router: Router,
    public _formHeaderService: FormHeaderService,
    private _store: Store<IAppState>,
    private _dialogService: DialogService
  ) {}

  ngOnInit() {
    this.addProficiencyLookupsSubscription();
    this.addTechnologyLookupsSubscription();
    this._formHeaderService.setTitle('EMR Software');
    this._store.dispatch(new TechnologiesViewed());
  }

  ngOnDestroy(): void {
    this.proficiencyLookupSubscription?.unsubscribe();
    this.technologyLookupSubscription?.unsubscribe();
  }

  addProficiencyLookupsSubscription(): void {
    this.proficiencyLookupSubscription = this.proficiencyLookup$.subscribe((proficiencyLookup: Map<number, ILookup<number>>) => {
      this.proficiencyLookup = proficiencyLookup;
    });
  }

  addTechnologyLookupsSubscription(): void {
    this.technologyLookupSubscription = this.technologyLookup$.subscribe((technologyLookup: Map<string, ILookup<string>>) => {
      this.technologyLookup = technologyLookup;
    });
  }

  navigateToAddTechnology(): void {
    const pageUrl = environment.appBaseUrl + this._router.url;
    this._store.dispatch(
      new CallToActionClicked({
        pageURL: pageUrl,
        pageTitle: this._route.routeConfig.title as string,
        destinationURL: pageUrl + '/add',
        ctaText: 'Add EMR Software'
      })
    );
    this._navHelper.goToTechnologyAdd();
  }

  goToTechnologyEdit(item): void {
    this._navHelper.goToTechnologyEdit(item.id);
  }

  getProficiencyName(tech: Technology): string {
    if (tech.proficiency) {
      return this.proficiencyLookup.get(tech.proficiency).name;
    } else {
      return '';
    }
  }

  getTechnologyName(tech: Technology): string {
    if (tech.technology) {
      return this.technologyLookup.get(tech.technology).systemTechnology;
    } else {
      return '';
    }
  }

  getRecordCardConfig(tech: Technology): IRecordCardConfig {
    return {
      title: tech.technology ? this.technologyLookup.get(tech.technology).systemTechnology : '',
      firstLine: tech.proficiency ? this.proficiencyLookup.get(tech.proficiency).name + ' - ' + this.proficiencyLookup.get(tech.proficiency).Description : '',
      secondLine: '',
      icon: 'edit'
    };
  }

  editTechnology(item: Technology) {
    this._store.dispatch(new GetTechnologyByID(item?.id));
    const dialogData: IDialogParameters = {
      title: `${item ? 'Edit' : 'Add'} EMR Software`,
      text: '',
      showCloseIcon: true,
      elevation: CardElevation.Default,
      icon: undefined,
      template: this.technologyModal,
      separatedHeader: true,
      noStyling: true,
      isResponsive: true,
      useCustomCloseLogic: true
    };
    this._dialogService.showDialog(dialogData, true, true, false);
  }
}
