import { Action } from '@ngrx/store';
import { License, LicenseIN, TaskCompletionReturnObject } from 'src/app/common';

export enum ELicensesActions {
  GetLicense = '[LICENSE] get license',
  GetLicenseSuccess = '[LICENSE] get license success',
  GetLicenseError = '[LICENSE] get license error',
  GetLicenses = '[LICENSES] get licenses',
  GetLicensesSuccess = '[LICENSES] get licenses success',
  GetLicensesError = '[LICENSES] get licenses error',
  GetLicensesIN = '[LICENSES] get licenses IN',
  GetLicensesINSuccess = '[LICENSES] get licenses IN success',
  GetLicensesINError = '[LICENSES] get licenses IN error',
  CreateLicense = '[LICENSE] create license',
  CreateLicenseSuccess = '[LICENSE] create license success',
  CreateLicenseError = '[LICENSE] create license error',
  GetCreateDocumentObservable = '[LICENSE] get create document observable',
  CreateLicenseFileError = '[LICENSE] create license file error',
  DeleteLicense = '[LICENSE] delete license',
  DeleteLicenseSuccess = '[LICENSE] delete license success',
  DeleteLicenseError = '[LICENSE] delete license error',
  DeleteQualification = '[LICENSE] delete qualification',
  UpdateLicense = '[LICENSE] update license',
  UpdateLicenseSuccess = '[LICENSE] update license success',
  UpdateLicenseError = '[LICENSE] update license error',
  UpdateLicenseFileError = '[LICENSE] update license file error',
  GetUpdateDocumentObservable = '[LICENSE] get license update document observable',
  NursysAdd = '[LICENSE] nursys add',
  NursysAddSuccess = '[LICENSE] nursys add success',
  NursysAddError = '[LICENSE] nursys add error',
  NursysSync = '[LICENSE] nursys sync',
  NursysUpdate = '[LICENSE] nursys update',
  NursysUpdateSuccess = '[LICENSE] nursys update success',
  NursysUpdateError = '[LICENSE] nursys update error',
  GetLicenseDocument = '[LICENSE] get license document',
  GetLicenseDocumentInitiated = '[LICENSE] get license document initiated',
  ResetLicenseExpiryDate = '[LICENSE] reset license expiration date'
}

export class GetLicense implements Action {
  public readonly type = ELicensesActions.GetLicense;

  constructor(public id: string) {}
}

export class GetLicenseSuccess implements Action {
  public readonly type = ELicensesActions.GetLicenseSuccess;

  constructor(public payload: { license: License; expiration: Date; fileUrl?: string }) {}
}

export class GetLicenseError implements Action {
  public readonly type = ELicensesActions.GetLicenseError;

  constructor(public error: Error) {}
}

export class GetLicenses implements Action {
  public readonly type = ELicensesActions.GetLicenses;

  constructor() {}
}

export class GetLicensesSuccess implements Action {
  public readonly type = ELicensesActions.GetLicensesSuccess;

  constructor(public payload: { licenses: License[]; expiration: Date }) {}
}

export class GetLicensesError implements Action {
  public readonly type = ELicensesActions.GetLicensesError;

  constructor(public error: Error) {}
}

export class GetLicensesIN implements Action {
  public readonly type = ELicensesActions.GetLicensesIN;
}

export class GetLicensesINSuccess implements Action {
  public readonly type = ELicensesActions.GetLicensesINSuccess;

  constructor(public payload: { licenses: LicenseIN[]; expiration: Date }) {}
}

export class GetLicensesINError implements Action {
  public readonly type = ELicensesActions.GetLicensesINError;

  constructor(public error: Error) {}
}

export class CreateLicense implements Action {
  public readonly type = ELicensesActions.CreateLicense;

  constructor(public payload: { license: License; files: File[] }) {}
}

export class CreateLicenseSuccess implements Action {
  public readonly type = ELicensesActions.CreateLicenseSuccess;

  constructor(public payload: { license: License }) {}
}

export class CreateLicenseError implements Action {
  public readonly type = ELicensesActions.CreateLicenseError;

  constructor(public error: Error) {}
}

export class GetCreateDocumentObservable implements Action {
  public readonly type = ELicensesActions.GetCreateDocumentObservable;

  constructor(public payload: { license: License; files: File[] }) {}
}

export class CreateLicenseFileError implements Action {
  public readonly type = ELicensesActions.CreateLicenseFileError;

  constructor(
    public payload: { license: License; files?: File[] },
    public error: Error
  ) {}
}

export class DeleteLicense implements Action {
  public readonly type = ELicensesActions.DeleteLicense;

  constructor(public payload: { id: string; qualificationId?: string }) {}
}

export class DeleteLicenseSuccess implements Action {
  public readonly type = ELicensesActions.DeleteLicenseSuccess;

  constructor(public id: string) {}
}

export class DeleteLicenseError implements Action {
  public readonly type = ELicensesActions.DeleteLicenseError;

  constructor(
    public id: string,
    public error: Error
  ) {}
}

export class DeleteQualification implements Action {
  public readonly type = ELicensesActions.DeleteQualification;

  constructor(public payload: { qualificationId: string; licenseId: string }) {}
}

export class UpdateLicense implements Action {
  public readonly type = ELicensesActions.UpdateLicense;

  constructor(
    public payload: {
      license: License;
      files?: File[];
      isDeletingFile?: boolean;
    }
  ) {}
}

export class UpdateLicenseSuccess implements Action {
  public readonly type = ELicensesActions.UpdateLicenseSuccess;

  constructor(
    public payload: {
      license: License;
      updateResponse: TaskCompletionReturnObject;
    }
  ) {}
}

export class UpdateLicenseError implements Action {
  public readonly type = ELicensesActions.UpdateLicenseError;

  constructor(public error: Error) {}
}

export class UpdateLicenseFileError implements Action {
  public readonly type = ELicensesActions.UpdateLicenseFileError;

  constructor(
    public error: Error,
    public payload: {
      license: License;
      updateResponse: TaskCompletionReturnObject;
    }
  ) {}
}

export class GetUpdateDocumentObservable implements Action {
  public readonly type = ELicensesActions.GetUpdateDocumentObservable;

  constructor(
    public payload: {
      license: License;
      files: File[];
      isDeleting?: boolean;
      updateResponse: TaskCompletionReturnObject;
    }
  ) {}
}

export class NursysAdd implements Action {
  public readonly type = ELicensesActions.NursysAdd;

  constructor() {}
}

export class NursysAddSuccess implements Action {
  public readonly type = ELicensesActions.NursysAddSuccess;

  constructor(public result: boolean) {}
}

export class NursysAddError implements Action {
  public readonly type = ELicensesActions.NursysAddError;

  constructor(public error: Error) {}
}

export class NursysSync implements Action {
  public readonly type = ELicensesActions.NursysSync;

  constructor() {}
}

export class NursysUpdate implements Action {
  public readonly type = ELicensesActions.NursysUpdate;

  constructor(public payload: any) {}
}

export class NursysUpdateSuccess implements Action {
  public readonly type = ELicensesActions.NursysUpdateSuccess;

  constructor(public result: number) {}
}

export class NursysUpdateError implements Action {
  public readonly type = ELicensesActions.NursysUpdateError;

  constructor(public error: Error) {}
}

export class GetLicenseDocument implements Action {
  public readonly type = ELicensesActions.GetLicenseDocument;

  constructor(public payload: { document: string; qualificationId?: string }) {}
}

export class GetLicenseDocumentInitiated implements Action {
  public readonly type = ELicensesActions.GetLicenseDocumentInitiated;

  constructor() {}
}

export class ResetLicenseExpiryDate implements Action {
  public readonly type = ELicensesActions.ResetLicenseExpiryDate;

  constructor() {}
}

export type LicensesActions =
  | GetLicense
  | GetLicenseSuccess
  | GetLicenseError
  | GetLicenses
  | GetLicensesSuccess
  | GetLicensesError
  | GetLicensesIN
  | GetLicensesINSuccess
  | GetLicensesINError
  | CreateLicense
  | CreateLicenseSuccess
  | CreateLicenseError
  | GetCreateDocumentObservable
  | CreateLicenseFileError
  | DeleteLicense
  | DeleteLicenseSuccess
  | DeleteLicenseError
  | DeleteQualification
  | UpdateLicense
  | UpdateLicenseSuccess
  | UpdateLicenseError
  | UpdateLicenseFileError
  | GetUpdateDocumentObservable
  | NursysAdd
  | NursysAddSuccess
  | NursysAddError
  | NursysSync
  | NursysUpdate
  | NursysUpdateSuccess
  | NursysUpdateError
  | GetLicenseDocument
  | GetLicenseDocumentInitiated
  | ResetLicenseExpiryDate;
