<ng-container
  *ngIf="{
    covidVaccineLoading: covidVaccineLoading$ | async,
    covidVaccineSaving: updateCovidVaccineSaving$ | async
  } as obs"
>
  <app-loading-screen-spinner *ngIf="covidVaccineLoading$ | async" [show]="true"></app-loading-screen-spinner>

  <div class="tasks-container tasks-full-height">
    <div class="tasks-desc-section" [ngClass]="{ 'tasks-form-grey': obs.fluVaccineLoading }" fxLayout="column" fxLayoutGap="16px">
      <hc-body>
        Please provide <b>EITHER</b> a photo, scanned copy of your vaccination card <b>OR </b>
        <span class="fil-link" (click)="showAlternateProofModal()">alternative proof.</span>
      </hc-body>
      <ul class="ul-margin">
        <hc-body [size]="smallBodySize" styles="font-weight: bold">Vaccination Card Requirements:</hc-body>
        <li class="li-margin">Must show dates of vaccinations</li>
        <li class="li-margin">Include booster shot records, if applicable</li>
      </ul>
      <hc-body [size]="microBodySize"> <b>Note:</b> Facilities vary on whether your vaccine is required to apply or required before your start date. </hc-body>
    </div>

    <app-base-form
      *ngIf="form"
      #baseForm
      [form]="form"
      [takeUpFullHeight]="true"
      [isLoadingObs]="covidVaccineLoading$"
      [isSavingObs]="updateCovidVaccineSaving$"
      [formHeaderAttributes]="formHeaderAttributes"
      (saveableEmitter)="onSave($event)"
    >
      <ng-container ngProjectAs="[formControls]">
        <hc-file-upload
          id="FileUploadValidId"
          size="default"
          class="tasks-upload-button"
          [label]="isDesktop ? 'Upload Documents' : 'Take photo or upload'"
          acceptedExtensions=".jpg, .jpeg, .png, .doc, .docx, .pdf"
          aboveLabel="Photo or digital copy of vaccine *"
          [errorMessage]="baseForm.getErrorMessage(form?.controls.fileUpload, 'Document')"
          [allowMultipleFiles]="false"
          [required]="true"
          [control]="form?.controls?.fileUpload"
          [prepopulateWithFiles]="hasPrepopulatedFile() ? [prepopulatedFile] : []"
          [filenameMappingFunction]="fileTruncationFunction | async"
          [enableFilePreviewPopup]="false"
          [disabled]="obs.covidVaccineSaving || obs.covidVaccineLoading"
          [disableClearFileAction]="obs.covidVaccineSaving"
          (valueChanged)="handleFileInput($event)"
        ></hc-file-upload>
      </ng-container>
      <ng-container ngProjectAs="[formButtons]">
        <div class="tasks-form-buttons-wrapper">
          <app-flow-handler
            [recordObs]="hasPrepopulatedFile() ? covidVaccine$ : updateCovidVaccine$"
            [form]="form"
            [isSavingObs]="updateCovidVaccineSaving$"
            [segmentDestinationUrl]="'/covid-vaccine'"
            [showRegularSaveButton]="true"
            (saveClickEmitter)="hasPrepopulatedFile() ? null : baseForm.saveCheck()"
          ></app-flow-handler>
        </div>
      </ng-container>
    </app-base-form>

    <ng-template #alternateProofTemplate>
      <hc-body class="top-margin"> Copy of proof of declination for religious or medical exemptions. </hc-body>
      <hc-body> For more details, connect with your recruiter.</hc-body>
    </ng-template>
  </div>
</ng-container>
