<ng-container
  *ngIf="{
    formLoading: formLoading$ | async,
    professionalOverviewSaving: updateSummarySaving$ | async,
    summaryPrompts: summaryPrompts$ | async
  } as observables"
>
  <ng-container>
    <app-loading-screen-spinner [show]="observables.formLoading || !form"></app-loading-screen-spinner>

    <div *ngIf="!observables.formLoading && form" class="tasks-container new-allied-flow">
      <div class="tasks-desc-section">
        <hc-body
          [size]="bodyTextSize"
          [ngClass]="{
            'tasks-form-grey-text': observables.professionalOverviewSaving
          }"
        >
          This provides facility hiring managers with an overview of you and your skills. It also enables job recommendations tailored to your unique professional experience.
        </hc-body>
      </div>
      <app-base-form
        *ngIf="form"
        #baseForm
        [form]="form"
        [isLoadingObs]="formLoading$"
        [isSavingObs]="updateSummarySaving$"
        [formHeaderAttributes]="formHeaderAttributes"
        (saveableEmitter)="onSave($event)"
      >
        <ng-container ngProjectAs="[formControls]">
          <div class="tasks-form-controls-wrapper" fxFlex fxLayout="column">
            <div fxFlex fxLayout="column">
              <hc-heading [size]="headingTextSize" class="overview-form-section-heading">Profession & Specialty</hc-heading>
              <hc-radio-button
                #professionSector
                id="profession-sector"
                label="Profession Sector*"
                required="true"
                [options]="radioOptions"
                [control]="professionSectorControl"
              ></hc-radio-button>
              <hc-hierarchical-multi-select-checkbox
                #professionHierarchy
                id="professionHierarchyInput"
                label="Profession"
                title="Select a Profession"
                placeholder="Search"
                [required]="true"
                [depth]="2"
                [data]="existingProfessionalHierarchy"
                [selectMultiple]="false"
                [control]="form.controls.professionHierarchy"
                [errorMessage]="baseForm.getErrorMessage(form.controls.professionHierarchy, 'Profession')"
                (selectionChanged)="professionChanged()"
              ></hc-hierarchical-multi-select-checkbox>

              <hc-dropdown
                *ngIf="specialties?.length"
                #specialtyDropdown
                id="specialty-dropdown"
                label="Specialty"
                placeholder="Select a Specialty"
                errorMessage="Specialty Required"
                [control]="form?.controls?.specialtyDto"
                [data]="specialties ?? []"
                [required]="specialtyRequired"
                (valueChanged)="specialtyChanged($event)"
              ></hc-dropdown>

              <hc-dropdown
                id="profession-yoe-dropdown"
                label="Years of Experience in Profession"
                placeholder="Years of Experience"
                errorMessage="Years of Experience in Profession Required"
                [control]="form?.controls?.workExperience"
                [required]="true"
                [data]="yearsOfExperienceValues"
              ></hc-dropdown>

              <hc-dropdown
                *ngIf="specialtyRequired && form.controls.specialtyDto.value"
                id="specialty-yoe-dropdown"
                label="Years of Experience in Specialty"
                placeholder="Years of Experience"
                errorMessage="Years of Experience in Specialty Required"
                [control]="form?.controls?.specialtyExperience"
                [required]="specialtyRequired"
                [data]="yearsOfExperienceValues"
              ></hc-dropdown>

              <hc-dropdown
                id="traveling-yoe-dropdown"
                label="How many years of experience do you have as a traveling medical professional?"
                placeholder="Years of Traveling Experience"
                errorMessage="Years of Traveling Experience Required"
                [control]="form?.controls?.travelExperience"
                [required]="true"
                [data]="travelExperienceValues"
              ></hc-dropdown>
            </div>
            <div fxFlex fxLayout="column">
              <hc-heading [size]="headingTextSize" class="overview-form-section-heading">Professional Summary</hc-heading>
              <hc-body [size]="bodyTextSize">
                Please include information such as types of patients you’ve worked with, settings you’ve worked in, relevant technical or procedural experience, and
                cross-training or courses you have taken (minimum of 150 characters).
              </hc-body>
              <div *ngIf="observables.summaryPrompts?.length" id="summary-prompts" fxLayout="column" fxLayoutGap="4px">
                <hc-body [size]="bodyTextSize">Related Questions:</hc-body>
                <hc-body *ngFor="let prompt of observables.summaryPrompts; let i = index" [size]="bodyTextSize" class="summary-prompt">{{ i + 1 }}. {{ prompt }}</hc-body>
              </div>
              <hc-textarea
                id="summary"
                [control]="form?.controls?.sizzle"
                placeholder="Please type a response"
                maxlength="2000"
                errorMessage="You must have a minimum of 150 characters in your summary."
                initialRowCount="5"
              >
              </hc-textarea>
              <mat-hint
                fxFlexAlign="end"
                class="hint-text"
                [ngClass.lt-sm]="{
                  'mobile-error': form?.controls?.sizzle?.errors
                }"
              >
                {{ form?.controls?.sizzle?.value?.length ?? 0 }}/2000
              </mat-hint>
            </div>
          </div>
        </ng-container>

        <ng-container ngProjectAs="[formButtons]">
          <div class="tasks-form-buttons-wrapper">
            <app-flow-handler
              [recordObs]="updateSummary$"
              [form]="form"
              [isSavingObs]="updateSummarySaving$"
              [segmentDestinationUrl]="'/professional-overview'"
              [showRegularSaveButton]="true"
              (saveClickEmitter)="baseForm.saveCheck()"
            ></app-flow-handler>
          </div>
        </ng-container>
      </app-base-form>
    </div>
  </ng-container>
</ng-container>
