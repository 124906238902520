import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit, QueryList, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { AccordionComponent, CardElevation, DialogService, IDialogParameters } from 'hc-design-system-lib';
import { ButtonAppearance } from 'hc-design-system-lib/lib/components/button/button.enums';
import { IRadioButtonOption } from 'hc-design-system-lib/lib/components/form/form.interfaces';
import { HcEvent } from 'hc-design-system-lib/lib/models/hc-event';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { exhaustMap, filter, map, skipWhile, take, takeUntil } from 'rxjs/operators';
import { NurseModel } from 'src/app/common';
import { ErrorMessages } from 'src/app/common/error-messages';
import { CandidateSkill } from 'src/app/common/models/candidate-skill';
import { CandidateSkillSummary } from 'src/app/common/models/candidate-skill-summary-model';
import { Skill } from 'src/app/common/models/skill';
import { SkillQuestion } from 'src/app/common/models/skill-question';
import {
  CreateCandidateSkillRequest,
  SkillForm,
  SkillFormGroupModel,
  SkillFormQuestionModel,
  SkillSignatureForm,
  UpdateCandidateSkillQuestionAnswer,
  UpdateCandidateSkillQuestionGroup
} from 'src/app/common/models/skill-question-form';
import { SkillQuestionGroup } from 'src/app/common/models/skill-question-group';
import { FormHeaderAttributes, NavHelper } from 'src/app/services';
import { ErrorTrackingNotificationService } from 'src/app/services/error-tracking-notification.service';
import { featureFlagNames } from 'src/app/services/feature-flags.service';
import { GetVariant } from 'src/app/store/flags/flags.actions';
import { selectFlagsLoading, selectIsSpecificFlagOn } from 'src/app/store/flags/flags.selectors';
import { SkillChecklistViewed } from 'src/app/store/segment/segment.actions';
import { GetCandidateSkill, GetSkill, UpdateSkillQuestions } from 'src/app/store/skillsChecklist/skillsChecklist.actions';
import {
  selectCandidateSkill,
  selectCandidateSkillError,
  selectCandidateSkillLoading,
  selectCandidateSkillsData,
  selectSkill,
  selectSkillError,
  selectSkillLoading,
  selectUpdateSkillQuestionsData,
  selectUpdateSkillQuestionsLoading
} from 'src/app/store/skillsChecklist/skillsChecklist.selectors';
import { selectIsMobile } from 'src/app/store/ui/ui.selectors';
import { selectNurseData } from 'src/app/store/userContext/userContext.selectors';
import { noSpecialCharacters } from '../../../../common/validators/nameValidator';

@Component({
  selector: 'app-skill-assessment-redesign',
  templateUrl: './skill-assessment-redesign.component.html',
  styleUrls: ['./skill-assessment-redesign.component.scss']
})
export class SkillAssessmentRedesignComponent implements OnInit, OnDestroy {
  private readonly destroy$: Subject<void> = new Subject();

  @ViewChildren(AccordionComponent) accordions!: QueryList<AccordionComponent>;

  @ViewChild('saveAndSignTemplate')
  saveAndSignTemplate: TemplateRef<any>;

  @ViewChild('missingResponsesTemplate')
  missingResponsesTemplate: TemplateRef<any>;

  constructor(
    private _store: Store,
    private _route: ActivatedRoute,
    private _fb: FormBuilder,
    private _notificationService: ErrorTrackingNotificationService,
    private _navHelper: NavHelper,
    private _dialogService: DialogService,
    private _location: Location
  ) {}

  primaryButtonAppearance = ButtonAppearance.Primary;
  secondaryButtonAppearance = ButtonAppearance.Secondary;
  skillId: number;
  skillName: string;
  mobileSkillOptions: IRadioButtonOption[] = [
    { disabled: false, text: 'Limited or no experience', value: 1 },
    { disabled: false, text: 'May need review or supervision', value: 2 },
    { disabled: false, text: 'Able to function independently', value: 3 }
  ];
  nonMobileSkillOptions: IRadioButtonOption[] = [
    { disabled: false, text: '', value: 1 },
    { disabled: false, text: '', value: 2 },
    { disabled: false, text: '', value: 3 }
  ];

  form: FormGroup<SkillForm>;
  signatureForm: FormGroup<SkillSignatureForm>;
  formHeaderAttributes: FormHeaderAttributes;
  isSigning = false;
  useGuestServices = false;
  questionScrollId: number;

  useGuestServices$: Observable<boolean> = combineLatest([
    this._store.select(selectFlagsLoading),
    this._store.select(selectIsSpecificFlagOn(featureFlagNames.guestServiceSkillsChecklist))
  ]).pipe(
    skipWhile(([flagsLoading, _useGuestServices]) => flagsLoading),
    map(([_, useGuestServices]) => useGuestServices)
  );

  isMobile$: Observable<boolean> = this._store.select(selectIsMobile);
  nurseData$: Observable<NurseModel> = this._store.select(selectNurseData);

  candidateSkillMap$: Observable<Map<number, CandidateSkillSummary>> = this._store.select(selectCandidateSkillsData);

  skillLoading$: Observable<boolean> = combineLatest([this._store.select(selectSkillLoading), this._store.select(selectCandidateSkillLoading)]).pipe(
    map(([skillLoading, candidateSkillLoading]) => skillLoading || candidateSkillLoading)
  );

  skill$: Observable<Skill> = this._store.select(selectSkill);
  skillError$: Observable<Error> = this._store.select(selectSkillError);

  candidateSkill$: Observable<CandidateSkill> = this._store.select(selectCandidateSkill);
  candidateSkillError$: Observable<Error> = this._store.select(selectCandidateSkillError);

  skillSavedResult$: Observable<CandidateSkill> = this._store.select(selectUpdateSkillQuestionsData).pipe(filter(result => !!result));
  skillSaving$: Observable<boolean> = this._store.select(selectUpdateSkillQuestionsLoading);

  ngOnInit(): void {
    this.skillId = +this._route.snapshot.paramMap.get('id');
    this._store.dispatch(new GetSkill(this.skillId));

    combineLatest([this.useGuestServices$, this.candidateSkillMap$])
      .pipe(
        skipWhile(([, candidateSkillMap]) => !candidateSkillMap),
        take(1),
        exhaustMap(([useGuestServices, candidateSkillMap]) => {
          if (useGuestServices && candidateSkillMap.has(this.skillId)) {
            const candidateSkillId = candidateSkillMap.get(this.skillId).id;
            this._store.dispatch(new GetCandidateSkill(candidateSkillId));
          }

          return combineLatest([
            of(useGuestServices),
            this.nurseData$,
            this.skill$,
            this.skillError$,
            useGuestServices ? this.candidateSkill$ : of(null),
            useGuestServices ? this.candidateSkillError$ : of(null)
          ]).pipe(
            filter(
              ([useGuestServices, nurseData, skill, skillError, candidateSkill, candidateSkillError]) =>
                !!nurseData && !!(skill || skillError) && (useGuestServices && candidateSkillMap.has(this.skillId) ? !!(candidateSkill || candidateSkillError) : true)
            ),
            takeUntil(this.destroy$)
          );
        }),
        takeUntil(this.destroy$)
      )
      .subscribe(([useGuestServices, nurse, skill, skillError, candidateSkill, candidateSkillError]: [boolean, NurseModel, Skill, Error, CandidateSkill, Error]) => {
        this.useGuestServices = useGuestServices;

        if (skillError || candidateSkillError) {
          this._notificationService.showNotification(ErrorMessages.httpError, 'error');
          this._navHelper.goToDashBoard();
        }

        this.form = this._fb.group({
          id: this._fb.control({
            value: useGuestServices ? candidateSkill?.id : '',
            disabled: true
          }),
          skillId: this._fb.control({ value: this.skillId, disabled: true }),
          email: this._fb.control({ value: nurse.email, disabled: true }),
          skillVersion: this._fb.control({
            value: skill.version,
            disabled: true
          })
        });
        this.signatureForm = this._fb.group({
          signature: this._fb.control('', [Validators.required, noSpecialCharacters]),
          signatureDate: this._fb.control({
            value: new Date(),
            disabled: true
          })
        });
        this.skillName = skill.name;

        this.addFormQuestionGroups(skill, candidateSkill);

        this.formHeaderAttributes = {
          form: this.form,
          title: 'Skills: ' + skill.name,
          showSaveButton: false
        };

        this._store.dispatch(new SkillChecklistViewed(skill));
      });

    this.skillSavedResult$
      .pipe(
        map(result => {
          this.form.markAsPristine();
          if (!this.form?.controls?.id?.value && result?.id) {
            this.form.controls.id.setValue(result.id);
          }
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
    this._store.dispatch(new GetVariant(featureFlagNames.guestServiceSkillsChecklist));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  trackGroup(_: number, group: SkillQuestionGroup) {
    return group.groupId;
  }

  trackQuestion(_: number, question: SkillQuestion) {
    return question.questionId;
  }

  addFormQuestionGroups(skill: Skill, candidateSkill: CandidateSkill) {
    skill.questionGroups.forEach((group: SkillQuestionGroup) => {
      const groupControl = this._fb.group<SkillFormGroupModel>({
        hasExperience: this._fb.control(true),
        groupId: this._fb.control({
          value: group.groupId,
          disabled: true
        }),
        text: this._fb.control({ value: group.text, disabled: true })
      });

      group.questions.forEach((question: SkillQuestion) => {
        this.addFormQuestions(question, candidateSkill, group, groupControl);
      });

      if (groupControl.controls.questions.controls.some(q => q.controls.score.value)) {
        groupControl.controls.hasExperience.setValue(groupControl.controls.questions.controls.some(q => q.controls.score.value > 1));
      }

      if (!this.form.controls.questionGroups) {
        this.form.controls.questionGroups = this._fb.array([groupControl]);
      } else {
        this.form.controls.questionGroups.push(groupControl);
      }
    });
  }

  addFormQuestions(question: SkillQuestion, candidateSkill: CandidateSkill, group: SkillQuestionGroup, groupControl: FormGroup<SkillFormGroupModel>) {
    const questionControl = this._fb.group<SkillFormQuestionModel>({
      score: this._fb.control(question.score),
      questionId: this._fb.control({
        value: question.questionId,
        disabled: true
      }),
      text: this._fb.control({
        value: question.text,
        disabled: true
      }),
      id: this._fb.control({ value: question.id, disabled: true }),
      versionId: this._fb.control({
        value: question.versionId,
        disabled: true
      })
    });

    if (this.useGuestServices && candidateSkill) {
      const candidateSkillScore =
        candidateSkill.questionGroups
          ?.find(candidateGroup => candidateGroup.groupId === group.groupId)
          ?.questions?.find(candidateQuestion => candidateQuestion.questionId === question.questionId)?.score ?? null;

      questionControl.controls.score.setValue(candidateSkillScore);
    }

    if (!groupControl.contains('questions')) {
      groupControl.controls.questions = this._fb.array([questionControl]);
    } else {
      groupControl.controls.questions.push(questionControl);
    }
  }

  questionsAnswered(questions: FormGroup<SkillFormQuestionModel>[]) {
    return questions.filter(q => q.controls.score.value)?.length ?? 0;
  }

  questionsTotalNotAnsweredCount() {
    return (
      this.form.controls.questionGroups?.controls?.reduce(
        (count, questionGroup) => count + (questionGroup?.controls?.questions?.controls?.filter(q => !q?.controls.score?.value)?.length ?? 0),
        0
      ) ?? 0
    );
  }

  radioSelected(event: HcEvent, group: FormGroup<SkillFormGroupModel>, groupIndex: number) {
    this.form.markAsDirty();
    if (event.eventValue !== 1 && !group.controls.hasExperience.value) {
      group.controls.hasExperience.setValue(true);
    } else if (event.eventValue === 1 && group.controls.questions.controls.every(q => q.controls.score.value === 1)) {
      group.controls.hasExperience.setValue(false);
    }

    if (group.controls.questions.controls.every(q => q.controls.score.value)) {
      const nextAccordion = this.accordions.get(groupIndex + 1);
      if (nextAccordion && !nextAccordion.expanded) {
        nextAccordion.emitAccordionClickEvent(true);
      }
    }
  }

  hasExperienceChange(group: FormGroup<SkillFormGroupModel>, groupIndex: number) {
    this.form.markAsDirty();
    group.controls.questions?.controls.forEach(question => {
      if (group.controls.hasExperience.value) {
        question.controls.score.setValue(null);
      } else {
        question.controls.score.setValue(1);
      }
    });

    if (!group.controls.hasExperience.value) {
      const nextAccordion = this.accordions.get(groupIndex + 1);
      if (nextAccordion && !nextAccordion.expanded) {
        nextAccordion.emitAccordionClickEvent(true);
      }
    }
  }

  accordionClick(panelBody: TemplateRef<any>, event: HcEvent) {
    panelBody['expanded'] = event.event['isAccordionOpen'];
  }

  accordionExpansion(event: HcEvent) {
    if (this.questionScrollId && event.event['isAccordionOpen']) {
      document.getElementById('question-' + this.questionScrollId)?.scrollIntoView({ behavior: 'smooth', block: 'center' });
      this.questionScrollId = null;
    }
  }

  onSave(isValid: boolean) {
    if (isValid) {
      const formValue = this.form.getRawValue();
      const signatureFormValue = this.signatureForm.getRawValue();

      if (this.isSigning) {
        if (this.questionsTotalNotAnsweredCount() === 0) {
          this.showSaveAndSignModal();
        } else {
          this.showMissingResponsesModal();
        }
      } else {
        let candidateSkillRequest: CreateCandidateSkillRequest | CandidateSkill;
        if (this.useGuestServices) {
          candidateSkillRequest = {
            email: formValue.email,
            skillId: formValue.skillId,
            signature: signatureFormValue.signature,
            questionGroups: [
              ...formValue.questionGroups
                .filter(qg => qg.questions.some(q => q.score))
                .map(
                  qg =>
                    ({
                      groupId: qg.groupId,
                      questions: [
                        ...qg.questions
                          .filter(q => q.score)
                          .map(
                            q =>
                              ({
                                questionId: q.questionId,
                                score: q.score
                              }) as UpdateCandidateSkillQuestionAnswer
                          )
                      ]
                    }) as UpdateCandidateSkillQuestionGroup
                )
            ]
          } as CreateCandidateSkillRequest;
        } else {
          candidateSkillRequest = formValue as unknown as CandidateSkill;
          candidateSkillRequest.skillName = this.skillName;
          candidateSkillRequest.signature = this.signatureForm.controls.signature.value;
        }
        this._store.dispatch(new UpdateSkillQuestions(candidateSkillRequest as CandidateSkill, formValue.id, formValue.skillVersion));
      }
    } else {
      this._notificationService.showFormErrorNotification('addLicenseForm', this.form);
    }
  }

  showSaveAndSignModal() {
    this.isSigning = false;
    this.signatureForm.controls.signature.addValidators(Validators.required);
    this.signatureForm.controls.signature.markAsUntouched();

    this.signatureForm.controls.signatureDate.disable();

    const dialogData: IDialogParameters = {
      title: 'Save and Sign',
      text: '',
      showCloseIcon: true,
      elevation: CardElevation.Default,
      icon: undefined,
      template: this.saveAndSignTemplate,
      isResponsive: true
    };
    this._dialogService
      .showDialog(dialogData)
      .pipe(
        take(1),
        exhaustMap(resp => {
          this.signatureForm.controls.signature.removeValidators(Validators.required);
          this.signatureForm.controls.signature.updateValueAndValidity();

          if (resp === 'true') {
            this.onSave(true);

            return this.skillSaving$;
          } else {
            return of(null);
          }
        }),
        skipWhile(response => response === true),
        map(response => {
          if (response === false) {
            this._location.back();
          }
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  agreeAndSign() {
    this.signatureForm.markAsDirty();
    if (this.signatureForm.valid) {
      this.onSave(true);

      this.skillSaving$
        .pipe(
          skipWhile(response => response === true),
          map(response => {
            if (response === false) {
              this._location.back();
            }
          }),
          takeUntil(this.destroy$)
        )
        .subscribe();
    }
  }

  showMissingResponsesModal() {
    this.isSigning = false;

    const dialogData: IDialogParameters = {
      title: 'Missing Responses',
      text: '',
      showCloseIcon: true,
      elevation: CardElevation.Default,
      icon: undefined,
      template: this.missingResponsesTemplate,
      options: {
        primary: { returnValue: 'true', text: 'Complete Skills' },
        secondary: { returnValue: 'false', text: 'Save and Complete Later' }
      }
    };
    this._dialogService
      .showDialog(dialogData)
      .pipe(
        take(1),
        exhaustMap(response => {
          if (response === 'false') {
            this.onSave(true);

            return this.skillSaving$;
          } else if (response === 'true') {
            return of('complete skills');
          } else {
            return of(null);
          }
        }),
        skipWhile(response => response === true),
        map(response => {
          if (response === false) {
            this._location.back();
          } else if (response === 'complete skills') {
            const questionGroupIndex = this.form.controls.questionGroups.controls.findIndex(qg => qg.controls.questions.controls.some(q => !q.controls.score.value));

            this.questionScrollId = this.form.controls.questionGroups.controls[questionGroupIndex].controls.questions.controls.find(
              q => !q.controls.score.value
            )?.controls.id.value;

            const accordion = this.accordions.get(questionGroupIndex);
            if (accordion.expanded) {
              accordion.emitAccordionExpandEvent(true);
            } else {
              accordion.emitAccordionClickEvent(true);
            }
          }
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  getErrorMessage(control: AbstractControl, fieldName: string): string {
    if (!this.signatureForm || !control) {
      return '';
    }
    if (control.hasError('nospecialornum')) {
      return `${fieldName} cannot contain numbers or special characters`;
    }
    if (control.hasError('required')) {
      return `${fieldName} required`;
    }
  }
}
