import { createSelector } from '@ngrx/store';
import { checkIfDateIsAfter } from 'src/app/common/functions/date-manipulations';
import { IAppState } from 'src/app/store/app/app.state';
import { TechnologyState } from './technology.state';

const selectTechnologyState = (state: IAppState) => state.technology;

export const selectTechnology = createSelector(selectTechnologyState, (state: TechnologyState) => state.technologyData.data);

export const selectTechnologyLoading = createSelector(selectTechnologyState, (state: TechnologyState) => state.technologyData.loading);

export const selectAddTechnologyResult = createSelector(selectTechnologyState, (state: TechnologyState) => state.addTechnologyData.data);

export const selectAddTechnologyLoading = createSelector(selectTechnologyState, (state: TechnologyState) => state.addTechnologyData.loading);

export const selectDeleteTechnologyResult = createSelector(selectTechnologyState, (state: TechnologyState) => state.deleteTechnologyData.data);

export const selectDeleteTechnologyLoading = createSelector(selectTechnologyState, (state: TechnologyState) => state.deleteTechnologyData.loading);

export const selectUpdateTechnologyResult = createSelector(selectTechnologyState, (state: TechnologyState) => state.updateTechnologyData.data);

export const selectUpdateTechnologyLoading = createSelector(selectTechnologyState, (state: TechnologyState) => state.updateTechnologyData.loading);

export const selectTechnologyByIDResult = createSelector(selectTechnologyState, (state: TechnologyState) => state.technologyDataByID.data);
export const selectTechnologyByIDExpired = createSelector(selectTechnologyState, (state: TechnologyState) =>
  checkIfDateIsAfter(new Date(), state.technologyDataByID.expirationDate)
);
export const selectTechnologyByIDExpirationDate = createSelector(selectTechnologyState, (state: TechnologyState) => state.technologyDataByID.expirationDate);
export const selectTechnologyByIDLoading = createSelector(selectTechnologyState, (state: TechnologyState) => state.technologyDataByID.loading);
export const selectTechnologySaving = createSelector(
  selectAddTechnologyLoading,
  selectUpdateTechnologyLoading,
  selectDeleteTechnologyLoading,
  (addLoading: boolean, updateLoading: boolean, deleteLoading: boolean) => addLoading || updateLoading || deleteLoading
);
