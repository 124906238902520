import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { IJob, ILookup } from 'src/app/common';
import { JobAreaContext } from 'src/app/services/job-area-context.service';
import { PayDisplay } from '../../available-jobs/specific-job/pay/pay-display-calculator';
import { CardComponentConfig } from '../card-template/card-template.component';
import { Store } from '@ngrx/store';
import { IAppState } from 'src/app/store/app/app.state';
import { selectSpecialtyLookup, selectProfessionLookup, selectStateLookup, selectYesNoLookup } from 'src/app/store/lookups/lookups.selectors';
import { Observable, Subscription, combineLatest } from 'rxjs';
import { JobBadgeData } from 'src/app/common/models/job-badge-data';
import { ContractType } from 'src/app/common/contracts/contract-type';
import { mapJobToCardConfig } from '../../job-card-utils/job-card-utils';
import { LOCAL_BADGE, TRAVEL_BADGE } from 'src/app/common/models/badge';
import { ICardConfig } from 'hc-design-system-lib/lib/components/cards/cards.interfaces';

@Component({
  selector: 'app-job-card',
  templateUrl: './job-card.component.html',
  styleUrls: ['./job-card.component.scss']
})
export class JobCardComponent implements OnInit, OnDestroy {
  @Input() job: IJob;
  @Input() id: string;
  @Input() jobConfig: CardComponentConfig;

  @Output() toggleJobSave = new EventEmitter<IJob>();
  @Output() jobSelected = new EventEmitter<string>();

  cardConfig: ICardConfig;
  jobName: string;
  jobBadges: JobBadgeData[] = [];
  isSaving = false;
  yesNoLookup: Map<string, ILookup<string>>;
  specialtyLookup: Map<string, ILookup<string>>;
  professionLookup: Map<string, ILookup<string>>;
  stateLookup: Map<string, ILookup<string>>;
  payDisplay: PayDisplay;

  lookupsSubscription: Subscription;

  yesNoLookup$: Observable<Map<string, ILookup<string>>> = this._store.select(selectYesNoLookup);

  specialtyLookup$: Observable<Map<string, ILookup<string>>> = this._store.select(selectSpecialtyLookup);

  professionLookup$: Observable<Map<string, ILookup<string>>> = this._store.select(selectProfessionLookup);

  stateLookup$: Observable<Map<string, ILookup<string>>> = this._store.select(selectStateLookup);

  constructor(
    public _ctx: JobAreaContext,
    private _store: Store<IAppState>
  ) {}

  ngOnInit(): void {
    this.addLookupsSubscription();
  }

  ngOnDestroy(): void {
    this.lookupsSubscription?.unsubscribe();
  }

  addLookupsSubscription(): void {
    this.lookupsSubscription = combineLatest([this.yesNoLookup$, this.specialtyLookup$, this.professionLookup$, this.stateLookup$]).subscribe(
      ([yesNoLookup, specialtyLookup, professionLookup, stateLookup]) => {
        this.yesNoLookup = yesNoLookup;
        this.specialtyLookup = specialtyLookup;
        this.professionLookup = professionLookup;
        this.stateLookup = stateLookup;
        this.buildCardConfig();
      }
    );
  }

  buildCardConfig(): void {
    const { yesNoLookup, specialtyLookup, professionLookup, stateLookup } = this;
    const jobBadges = this._ctx
      .getJobBadges(false, this.job)
      .map(badge => badge.badge)
      .filter(badge => ![ContractType.Local.toString(), ContractType.Travel.toString()].includes(badge.title));

    this.cardConfig = mapJobToCardConfig(
      this.job,
      { yesNoLookup, specialtyLookup, professionLookup, stateLookup },
      jobBadges,
      this.job.contractType === ContractType.Local ? LOCAL_BADGE : TRAVEL_BADGE,
      true
    );
  }

  saveToggle(job: IJob) {
    this.toggleJobSave.emit(job);
  }

  onJobSelected(): void {
    if (this.jobConfig.useEmitter) {
      this.jobSelected.emit(this.id);
    }
  }
}
