import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { EUiActions, setMenuItems, setNavigationProfile, setScreenWidth, setTabBarItems, UiActions } from './ui.actions';
import { selectCanSeeInternational, selectCanSeeTravel, selectNurseData, selectNurseINData } from '../userContext/userContext.selectors';
import { selectEnvironmentId, selectIsMobile, selectTabbarData } from './ui.selectors';
import { selectIsSpecificFlagOn } from 'src/app/store/flags/flags.selectors';
import { featureFlagNames } from 'src/app/services/feature-flags.service';
import { EFlagsActions } from '../flags/flags.actions';
import { settingsNavItem, toolDefaultItems } from 'src/app/components/main-components/main/main.constants';
import { intitialMenuState, ITabBarState } from './ui.state';
import { NurseModel } from 'src/app/common/models/nurse-model';
import { NavigationProfile } from 'hc-design-system-lib/lib/components/navigation/navigation.interfaces';
import { EnvironmentId } from 'src/app/common';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { EUserContextActions } from '../userContext/userContext.actions';
import { GetRecruiter } from '../recruiter/recruiter.actions';
import { docsNavItem, jobsItems, jobsItemsIN, profileNavItem, taskListItem } from '../../components/main-components/main/main.constants';

@Injectable({
  providedIn: 'root'
})
export class UiEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private _route: ActivatedRoute,
    private _sanitizer: DomSanitizer
  ) {}

  setOrUpdateTabBar$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<UiActions>(EUiActions.SetScreenWidth, EUiActions.SetTabbarActiveItemIndex),
      concatLatestFrom(() => [this.store.select(selectTabbarData), this.store.select(selectIsMobile)]),
      map(([actions, tabBarData, isMobile]: [UiActions, ITabBarState, boolean]) => ({ tabBarData, isMobile })),
      distinctUntilChanged((prev, curr) => prev.isMobile === curr.isMobile),
      map(({ tabBarData, isMobile }) => {
        let tabBarItems = tabBarData.tabBarItems;

        if (!isMobile) {
          if (!tabBarItems.includes(settingsNavItem)) {
            tabBarItems = [...tabBarItems, settingsNavItem];
          }
        } else {
          tabBarItems = tabBarItems.filter(item => item !== settingsNavItem);
        }
        return setTabBarItems({ tabBarItems });
      })
    );
  });

  setMenuItems$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<UiActions>(EUiActions.SetEnvironmentId, EUiActions.SetScreenWidth, EFlagsActions.GetFlagsSuccess),
      concatLatestFrom(() => [
        this.store.select(selectIsMobile),
        this.store.select(selectEnvironmentId),
        this.store.select(selectIsSpecificFlagOn(featureFlagNames.jobPreferencesIN)),
        this.store.select(selectCanSeeInternational),
        this.store.select(selectCanSeeTravel)
      ]),
      map(([, isMobile, environmentId, jobsINFlagEnabled, canSeeInternational, canSeeTravel]: [UiActions, boolean, EnvironmentId, boolean, boolean, boolean]) => {
        let menuItems = canSeeTravel ? [profileNavItem, docsNavItem, taskListItem, ...jobsItems] : intitialMenuState;

        if (canSeeInternational && jobsINFlagEnabled && !menuItems.includes(jobsItemsIN[0])) {
          menuItems = [...menuItems, ...jobsItemsIN];
        }

        if (environmentId !== EnvironmentId.Training && environmentId !== EnvironmentId.Production) {
          if (!menuItems.includes(toolDefaultItems[0])) {
            menuItems = [...menuItems, ...toolDefaultItems];
          }
        }

        if (isMobile) {
          if (!menuItems.includes(settingsNavItem)) {
            menuItems = [...menuItems, settingsNavItem];
          }
        } else {
          menuItems = menuItems.filter(item => item !== settingsNavItem);
        }

        return setMenuItems({ menuItems });
      })
    );
  });

  setNavigationProfile$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<UiActions>(EUserContextActions.GetNurseSuccess, EUserContextActions.GetNurseINSuccess),
      concatLatestFrom(() => [this.store.select(selectNurseData), this.store.select(selectNurseINData), this.store.select(selectCanSeeInternational)]),
      map(([, tnNurseData, inNurseData, canSeeInternational]: [UiActions, NurseModel, NurseModel, boolean]) => {
        const nurseData = canSeeInternational ? inNurseData : tnNurseData;
        if (nurseData) {
          //Not on the registration path
          if (!this._route.snapshot.queryParamMap.get('registrationPath')) {
            const { firstName, lastName, email } = nurseData;
            const fullName = firstName && lastName ? `${firstName} ${lastName}` : null;
            let profilePicture = null;
            if (nurseData?.profilePicture) {
              profilePicture = this._sanitizer.bypassSecurityTrustUrl('data:image/png;base64,' + nurseData.profilePicture);
            }

            let initials = '';
            if (firstName && lastName) {
              initials = firstName[0] + lastName[0];
            } else if (email) {
              initials = email.slice(0, 2);
            }
            const navigationProfile = {
              profileImage: profilePicture,
              initials: initials,
              fullName: fullName
            } as NavigationProfile;
            return setNavigationProfile({ navigationProfile });
          }
        }
        return setNavigationProfile({ navigationProfile: null });
      })
    );
  });

  setScreenWidthOnMainComponentLoaded$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<UiActions>(EUiActions.MainComponentLoaded),
      map(() => {
        const screenWidth = window.innerWidth; // Get the current screen width
        return setScreenWidth({ screenWidth });
      })
    );
  });

  getRecruiterOnMainComponentLoaded$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<UiActions>(EUiActions.MainComponentLoaded),
      map(() => {
        return new GetRecruiter(); // Dispatch the GetRecruiter action
      })
    );
  });
}
