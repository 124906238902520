<div class="popup mat-elevation-z2">
  <div fxFlex fxLayout="column" fxLayoutAlign="space-between start" fxLayoutGap="20px">
    <div class="popup-text">
      <span>Thank you for reporting an issue. Technical support is working to resolve it and will reach out within 24 business hours.<br /></span>
    </div>
    <div fxLayoutAlign="end end" fxLayoutGap="20px" fxFlex class="button-area">
      <button id="issueConfirmToHome" mat-button class="tertiary-colored-btn full-width" (click)="confirm()">TAKE ME BACK HOME</button>
    </div>
  </div>
</div>
