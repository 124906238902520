<div class="page" fxFlex fxLayout="column">
  <form class="form" [formGroup]="form" fxLayout="column">
    <div fxLayout="row" fxLayoutGap="6px">
      <mat-form-field fxFlex>
        <input matInput placeholder="First Name" formControlName="firstName" />
      </mat-form-field>
      <mat-form-field fxFlex>
        <input matInput placeholder="Last Name" formControlName="lastName" />
      </mat-form-field>
    </div>
    <div>
      <mat-form-field fxFlex>
        <input matInput placeholder="Email" formControlName="email" />
      </mat-form-field>
    </div>
    <mat-form-field>
      <mat-select formControlName="businessUnit" placeholder="Business Unit">
        <mat-option [value]="null"></mat-option>
        <mat-option *ngFor="let item of businessUnits" [value]="item">{{ item.name }}</mat-option>
      </mat-select>
    </mat-form-field>
    <div fxLayout="row">
      <button id="contactSearch" fxFlex mat-raised-button color="primary" (click)="search()">search</button>
      <button id="contactSearchClear" mat-icon-button (click)="clear()">
        <mat-icon>clear</mat-icon>
      </button>
    </div>
  </form>
  <div fxFlex fxLayout="column" class="contact-list">
    <div *ngFor="let contact of contacts" (click)="select(contact)" fxLayoutAlign="start center" fxLayout="row" class="contact">
      <div fxFlex fxLayout="column">
        <div fxFlex fxLayout="row">
          <span class="mat-body-strong">{{ contact.firstName }} {{ contact.lastName }}</span>
          <div class="tag">
            {{ getBusinessUnitName(contact) }}
          </div>
        </div>
        <span class="mat-body">{{ contact.email }}</span>
        <span class="mat-body" (click)="trackPhoneClick(contact)">{{ contact.mobilePhone | phone }}</span>
      </div>
    </div>
  </div>
</div>
