import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { NurseModel, IUserModel } from '../common';
import { Angulartics2 } from 'angulartics2';
import { ApiClient } from '../shared/api-client.service';
@Injectable({
  providedIn: 'root'
})
export class UserContext {
  nurse: NurseModel;
  user: IUserModel;
  constructor(
    private _api: ApiClient,
    private _angulartics2: Angulartics2
  ) {}

  refreshNurse(): Observable<any> {
    return this._api.get<NurseModel>('users/me').pipe(
      map(o => {
        const nm = new NurseModel();
        Object.assign(nm, o);
        this.nurse = nm;
        this._angulartics2.setUsername.next(this.nurse.merlinId);
        return o;
      })
    );
  }
}
