import { initializeData, IDataState } from 'src/app/store/app/app.models';
import { Education } from 'src/app/common';

export interface EducationState {
  educations: IDataState<Education[]>;
  education: IDataState<Education>;
  addEducationResult: IDataState<string>;
  updateEducationResult: IDataState<string>;
  deleteEducationResult: IDataState<string>;
  fileUrl: IDataState<string>;
}

export const initialEducationState: EducationState = {
  educations: initializeData(),
  education: initializeData(),
  addEducationResult: initializeData(),
  updateEducationResult: initializeData(),
  deleteEducationResult: initializeData(),
  fileUrl: initializeData()
};
