import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { NotificationService } from 'hc-design-system-lib';
import { of } from 'rxjs';
import { map, exhaustMap, catchError, tap } from 'rxjs/operators';
import { ReferencesResponse, TaskCompletionReturnObject } from 'src/app/common';
import { NursePortalApi } from 'src/app/services';
import { EWorkHistoryContextActions } from '../workHistory/workHistoryContext.actions';
import {
  AddReference,
  AddReferenceError,
  AddReferenceSuccess,
  DeleteReference,
  DeleteReferenceError,
  DeleteReferenceSuccess,
  EReferencesActions,
  GetReferenceEmails,
  GetReferenceEmailsError,
  GetReferenceEmailsSuccess,
  GetReferences,
  GetReferencesError,
  GetReferencesSuccess,
  ReferencesActions,
  UpdateReference,
  UpdateReferenceError,
  UpdateReferenceSuccess
} from './references.actions';

@Injectable({
  providedIn: 'root'
})
export class ReferencesEffects {
  constructor(
    private actions$: Actions,
    private _nursePortalApi: NursePortalApi,
    private _notificationService: NotificationService
  ) {}

  getReferences$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<ReferencesActions>(
        EReferencesActions.GetReferences,
        EReferencesActions.DeleteReferenceSuccess,
        EReferencesActions.UpdateReferenceSuccess,
        EReferencesActions.AddReferenceSuccess,
        EWorkHistoryContextActions.AddWorkHistory
      ),
      map((action: GetReferences) => action),
      exhaustMap(() =>
        this._nursePortalApi.getReferences().pipe(
          map((data: ReferencesResponse) => new GetReferencesSuccess(data)),
          catchError((error: Error) => of(new GetReferencesError(error)))
        )
      )
    );
  });

  getReferenceEmails$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<ReferencesActions>(
        EReferencesActions.GetReferenceEmails,
        EReferencesActions.GetReferences,
        EReferencesActions.DeleteReferenceSuccess,
        EReferencesActions.UpdateReferenceSuccess,
        EReferencesActions.AddReferenceSuccess
      ),
      map((action: GetReferenceEmails) => action),
      exhaustMap(() =>
        this._nursePortalApi.getReferenceEmails().pipe(
          map((data: string[]) => new GetReferenceEmailsSuccess(data)),
          catchError((error: Error) => of(new GetReferenceEmailsError(error)))
        )
      )
    );
  });

  addReference$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<ReferencesActions>(EReferencesActions.AddReference),
      exhaustMap((action: AddReference) =>
        this._nursePortalApi.addReference(action.reference).pipe(
          map((response: TaskCompletionReturnObject) => new AddReferenceSuccess(response, action.reference)),
          catchError((error: Error) => of(new AddReferenceError(error)))
        )
      )
    );
  });

  updateReference$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<ReferencesActions>(EReferencesActions.UpdateReference),
      exhaustMap((action: UpdateReference) =>
        this._nursePortalApi.updateReference(action.reference).pipe(
          map((response: TaskCompletionReturnObject) => new UpdateReferenceSuccess(response)),
          catchError((error: Error) => of(new UpdateReferenceError(error)))
        )
      )
    );
  });

  deleteReference$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<ReferencesActions>(EReferencesActions.DeleteReference),
      exhaustMap((action: DeleteReference) =>
        this._nursePortalApi.deleteReference(action.id).pipe(
          map((response: TaskCompletionReturnObject) => new DeleteReferenceSuccess(response)),
          catchError((error: Error) => of(new DeleteReferenceError(error)))
        )
      )
    );
  });

  deleteReferenceSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType<ReferencesActions>(EReferencesActions.DeleteReferenceSuccess),
        tap(() => this._notificationService.showNotification('Your reference was deleted.', 'success'))
      );
    },
    { dispatch: false }
  );

  deleteReferenceError$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType<ReferencesActions>(EReferencesActions.DeleteReferenceError),
        tap(() => this._notificationService.showNotification('Failed to delete reference.', 'error'))
      );
    },
    { dispatch: false }
  );
}
