import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
  AboutMeModel,
  API_ROUTES,
  ApplicationReviewModel,
  AppUserSkill,
  CandidateQualification,
  Certification,
  Education,
  EmailFormModel,
  FileCategoriesNames,
  IFileUploadOptions,
  IJobFilterAPI,
  IRegistrationModel,
  License,
  NewReference,
  NurseModel,
  PreEmploymentQuestionnaire,
  ProfessionalOverviewModel,
  Reference,
  SimilarJobSearchModel,
  TaskCompletionReturnObject,
  Technology,
  UpdateAssignmentModel,
  UpdatedReference,
  WorkHistory
} from '../common';
import { Question } from '../common/models/question';
import { tap } from 'rxjs/operators';
import { Recruiter } from '../common/models/recruiter';
import { Interview } from '../common/models/interview-model';
import { RecruiterTaskCreationModel } from '../common/models/recruiter-task-creation-model';
import { Store } from '@ngrx/store';
import { IAppState } from '../store/app/app.state';
import { DocumentUploaded } from '../store/segment/segment.actions';
import { ContractType } from '../common/contracts/contract-type';
import { v4 as uuidv4 } from 'uuid';
import { IResourceCardConfig } from 'hc-design-system-lib/lib/components/cards/cards.interfaces';
import { IN_RESOURCES, RESOURCES } from '../modules/dashboard/pages/dashboard/dashboard.constants';

export class ApiOptions {
  showBusyIndicator = true;
  useServiceWorker = true;

  ignoreBusy(): ApiOptions {
    this.showBusyIndicator = false;
    return this;
  }

  byPassServiceWorker(): ApiOptions {
    this.useServiceWorker = false;
    return this;
  }
}

@Injectable()
export class NursePortalApi {
  constructor(
    private _http: HttpClient,
    private _store: Store<IAppState>
  ) {}

  public fileUpload(options: IFileUploadOptions, files: Array<File>): Observable<any> {
    const apiOptions = new ApiOptions().byPassServiceWorker();
    const formData = new FormData();
    const myHeaders = this._getHeaders(apiOptions);
    formData.append('options', JSON.stringify(options));
    files.forEach((o, counter) => {
      const newFileName = this._determineFileName(options, o, counter);
      formData.append('uploadedFile', o, newFileName);
    });
    return this._http.post<any>(`${API_ROUTES.files}/`, formData, { headers: myHeaders }).pipe(
      tap(response => {
        if (response.returnValue || response.result) {
          const docInfos = response.returnValue || response.result;
          if (Array.isArray(docInfos)) {
            docInfos.forEach(docInfo => {
              if (docInfo) {
                const category = FileCategoriesNames.get(docInfo.category);
                const payload = {
                  documentId: docInfo.id,
                  documentName: docInfo.fileName,
                  documentType: category,
                  documentURL: docInfo.fileDownloadUrl
                };
                this._store.dispatch(new DocumentUploaded(payload));
              }
            });
          } else if (docInfos.id) {
            const category = FileCategoriesNames.get(docInfos.category);
            const payload = {
              documentId: docInfos.id,
              documentName: docInfos.fileName,
              documentType: category,
              documentURL: docInfos.fileDownloadUrl
            };
            this._store.dispatch(new DocumentUploaded(payload));
          }
        }
      })
    );
  }

  private _determineFileName(options: IFileUploadOptions, file: File, counter: number): string {
    if (file.name.length > 50) {
      const fileExt = file.name.split('.').pop();
      if (options.itemId) {
        return `${options.itemId}_${counter}.${fileExt}`;
      } else {
        return `${uuidv4()}.${fileExt}`;
      }
    }
    return file.name;
  }

  private _getHeaders(options?: ApiOptions): HttpHeaders {
    let headers = new HttpHeaders();

    if (options) {
      if (options.showBusyIndicator === false) {
        headers = headers.append('ignoreProgressBar', '');
      }

      if (options.useServiceWorker === false) {
        headers = headers.append('ngsw-bypass', 'true');
      }
    }

    return headers;
  }

  updateQuestionnaire(questionnaire: PreEmploymentQuestionnaire) {
    return this._http.put(`${API_ROUTES.background}`, questionnaire);
  }

  getMe(): Observable<NurseModel> {
    return this._http.get<NurseModel>(`${API_ROUTES.users}me`);
  }

  getMyRecruiter(): Observable<Recruiter> {
    return this._http.get<Recruiter>(API_ROUTES.support);
  }

  getPayRecruiter(): Observable<Recruiter> {
    return this._http.get<Recruiter>(`${API_ROUTES.support}get-pay-recruiter`);
  }

  getRecruiterPopup(): Observable<any> {
    return this._http.get<any>(`${API_ROUTES.dashboard}/recruiter-popup`);
  }

  getAboutMe(): Observable<AboutMeModel> {
    return this._http.get<AboutMeModel>(`${API_ROUTES.profile}/about-me`);
  }

  getProfessionalOverview(): Observable<any> {
    return this._http.get<any>(`${API_ROUTES.profile}/professional-overview`);
  }

  getApplicationReview(): Observable<ApplicationReviewModel> {
    return this._http.get<ApplicationReviewModel>(`${API_ROUTES.profile}/application-review`);
  }

  getSummaryPrompts(): Observable<any> {
    return this._http.get<any>(`${API_ROUTES.profile}/prompts`);
  }

  updateSummary(model: ProfessionalOverviewModel): Observable<any> {
    return this._http.put<any>(`${API_ROUTES.profile}/professional-overview`, model);
  }

  getQuestionnaire(id: string = ''): Observable<any> {
    if (id === '') {
      return this._http.get<any>(API_ROUTES.background);
    } else {
      return this._http.get<any>(`${API_ROUTES.background}/${id}`);
    }
  }

  getZipCodeInfo(zipCode: string): Observable<any> {
    return this._http.get<any>(`${API_ROUTES.background}/zip-code?zipCode=${zipCode}`);
  }

  sendVerifyEmail(): Observable<any> {
    return this._http.get<any>(`${API_ROUTES.verify}/send`);
  }

  resendVerifyEmail(): Observable<any[]> {
    return this._http.get<any>(`${API_ROUTES.verify}/resend`);
  }

  sendVerifySMS(): Observable<any> {
    return this._http.get<any>(`${API_ROUTES.verify}/sms-send`);
  }

  resendVerifySMS(): Observable<any> {
    return this._http.get<any>(`${API_ROUTES.verify}/sms-resend`);
  }

  editEmailAddress(): Observable<any[]> {
    return this._http.post<any>(`${API_ROUTES.verify}/editEmail`, {});
  }

  sendVerifySupportReport(model: any): Observable<any> {
    return this._http.post<any>(`${API_ROUTES.verify}/support`, model);
  }

  editUserStatus(userId: number, inactive: boolean): Observable<any> {
    const model = {
      appUserId: userId,
      inactive
    };
    return this._http.post(`${API_ROUTES.admin}/edit-user-status`, model);
  }

  updateProfile(model: any): Observable<any> {
    return this._http.put<any>(API_ROUTES.profile, model);
  }

  updateProfessionalOverview(model: ProfessionalOverviewModel): Observable<any> {
    return this._http.put<any>(`${API_ROUTES.profile}/professional-overview`, model);
  }

  updateProfileReviewData(model: ApplicationReviewModel): Observable<any> {
    return this._http.put<any>(`${API_ROUTES.profile}/review-update`, model);
  }

  saveRegistration(model: IRegistrationModel): Observable<any> {
    return this._http.put<any>(API_ROUTES.registration, model);
  }

  getLicenses(): Observable<any> {
    return this._http.get<any>(API_ROUTES.licenses);
  }

  getLicense(id: string = ''): Observable<any> {
    return this._http.get<any>(`${API_ROUTES.licenses}/${id}`);
  }

  addLicense(model: License): Observable<any> {
    return this._http.post<any>(API_ROUTES.licenses, model);
  }

  getStatePopulate(id: string = ''): Observable<any> {
    return this._http.get<any>(`${API_ROUTES.licenses}populate/${id}`);
  }

  getCertifications(id: string = ''): Observable<any> {
    if (id === '') {
      return this._http.get<any>(API_ROUTES.certifications);
    } else {
      return this._http.get<any>(`${API_ROUTES.certifications}/${id}`);
    }
  }

  getCertificationPopulate(id: string = ''): Observable<any> {
    return this._http.get<any>(`${API_ROUTES.certifications}populate/${id}`);
  }

  addCertification(model: Certification): Observable<any> {
    return this._http.post<any>(API_ROUTES.certifications, model);
  }

  updateProfilePicture(model: any): Observable<any> {
    return this._http.post<any>(API_ROUTES.files, model);
  }

  getReferences(id: string = ''): Observable<any> {
    if (id === '') {
      return this._http.get<any>(`${API_ROUTES.references}`);
    } else {
      return this._http.get<any>(`${API_ROUTES.references}${id}`);
    }
  }

  getReferenceEmails(): Observable<any> {
    return this._http.get<any>(`${API_ROUTES.references}/emails`);
  }

  getReferenceForQuestionnaire(id: string): Observable<any> {
    return this._http.get<any>(`${API_ROUTES.questionnaire}/Questionnaire/${id}`);
  }

  getEducation(id: string = ''): Observable<any> {
    if (id === '') {
      return this._http.get<any>(`${API_ROUTES.education}`);
    } else {
      return this._http.get<any>(`${API_ROUTES.education}?id=${id}`);
    }
  }

  getDegreePopulate(id: string = ''): Observable<any> {
    return this._http.get<any>(`${API_ROUTES.education}populate/${id}`);
  }

  getVaccinationFile(vaccineType: string = ''): Observable<any> {
    return this._http.get<any>(`${API_ROUTES.files}/vaccination/${vaccineType}`);
  }

  getTechnology(id: string = ''): Observable<any> {
    if (id === '') {
      return this._http.get<any>(`${API_ROUTES.technology}`);
    } else {
      return this._http.get<any>(`${API_ROUTES.technology}${id}`);
    }
  }

  getTechnologyPopulate(id: string = ''): Observable<any> {
    return this._http.get<any>(`${API_ROUTES.technology}populate/${id}`);
  }

  getContactByReference(id: string): Observable<any> {
    return this._http.get<any>(`${API_ROUTES.questionnaire}/Nurse/${id}`);
  }

  addReference(model: NewReference): Observable<any> {
    return this._http.post<any>(API_ROUTES.references, model);
  }

  addReferences(models: Array<Reference>): Observable<any> {
    return this._http.post<any>(`${API_ROUTES.references}/bulk`, models);
  }

  addEducation(model: Education): Observable<any> {
    return this._http.post<any>(API_ROUTES.education, model);
  }

  addBulkTechnology(model: Array<Technology>): Observable<any> {
    return this._http.post<any>(`${API_ROUTES.technology}/bulk`, model);
  }

  updateReference(model: UpdatedReference): Observable<any> {
    return this._http.put<any>(API_ROUTES.references, model);
  }

  updateReferenceForQuestionnaire(model: Reference): Observable<any> {
    return this._http.put<any>(API_ROUTES.questionnaire, model);
  }

  updateEducation(model: Education): Observable<any> {
    return this._http.put<any>(API_ROUTES.education, model);
  }

  updateTechnology(model: Technology): Observable<any> {
    return this._http.put<any>(API_ROUTES.technology, model);
  }

  updateLicense(model: License): Observable<any> {
    return this._http.put<any>(API_ROUTES.licenses, model);
  }

  updateCertification(model: Certification): Observable<any> {
    return this._http.put<any>(API_ROUTES.certifications, model);
  }

  updateWorkHistory(model: WorkHistory): Observable<any> {
    return this._http.put<any>(API_ROUTES.work, model);
  }

  deleteLicense(id: string): Observable<any> {
    return this._http.delete<any>(`${API_ROUTES.licenses}/${id}`);
  }

  deleteCertification(id: string): Observable<any> {
    return this._http.delete<any>(`${API_ROUTES.certifications}/${id}`);
  }

  deleteQualification(id: string): Observable<any> {
    return this._http.delete<any>(`${API_ROUTES.files}/qual/${id}`);
  }

  deleteReference(id: string): Observable<any> {
    return this._http.delete<any>(`${API_ROUTES.references}/${id}`);
  }

  deleteWorkHistory(id: string): Observable<any> {
    return this._http.delete<any>(`${API_ROUTES.work}/${id}`);
  }

  deleteEducation(id: string): Observable<any> {
    return this._http.delete<any>(`${API_ROUTES.education}/${id}`);
  }

  deleteTechnology(id: string): Observable<any> {
    return this._http.delete<any>(`${API_ROUTES.technology}/${id}`);
  }

  getUserSkills(): Observable<AppUserSkill[]> {
    return this._http.get<AppUserSkill[]>(`${API_ROUTES.skills}UserSkills`);
  }

  getAllQuestions(): Observable<Question[]> {
    return this._http.get<Question[]>(`${API_ROUTES.skills}QuestionsList`);
  }

  getQuestions(id: string): Observable<Question[]> {
    return this._http.get<Question[]>(`${API_ROUTES.skills}SkillQuestions?skillId=${id}`);
  }

  resetSkillCheckList(skillVersionId: number): Observable<number> {
    const model = { skillVersionId: skillVersionId };

    return this._http.post<number>(`${API_ROUTES.skills}Reset`, model);
  }

  savequestionnaire(questions: Question[], id: number, signature: string): Observable<TaskCompletionReturnObject> {
    return this._http.post<TaskCompletionReturnObject>(`${API_ROUTES.skills}SaveSkill?SkillVersionId=${id}&signature=${signature}`, questions);
  }

  getWorkHistory(id: string = ''): Observable<any> {
    if (id === '') {
      return this._http.get<any>(`${API_ROUTES.work}`);
    } else {
      return this._http.get<any>(`${API_ROUTES.work}/${id}`);
    }
  }

  addWorkHistory(model: WorkHistory): Observable<any> {
    return this._http.post<any>(API_ROUTES.work, model);
  }

  updateNursysSetup(model: any): Observable<any> {
    return this._http.post<any>(`${API_ROUTES.nursys}setup`, model);
  }

  nursysAdd(): Observable<any> {
    return this._http.post<any>(`${API_ROUTES.nursys}nurse`, {});
  }

  nursysSync(): Observable<any> {
    return this._http.post<any>(`${API_ROUTES.nursys}sync`, {});
  }

  sendReferenceEmail(id: string, email: string, fullName: string): Observable<any> {
    const model = `
        {
            "Id": "${id}",
            "Email": "${email}",
            "FullName": "${fullName}"
        }
        `;

    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this._http.post<any>(`${API_ROUTES.references}/Email`, model, headers);
  }

  getAccountsByName(name: string): Observable<any> {
    return this._http.get<any>(`${API_ROUTES.work}/GetFacilities?name=${name}`);
  }

  getLocationsByName(name: string): Observable<any> {
    return this._http.get<any>(`${API_ROUTES.job}/get-locations?name=${name}`);
  }

  getMyTasks(includePendingTasks: boolean): Observable<any> {
    return this._http.get<any>(`${API_ROUTES.task}/my?includePending=${includePendingTasks}`);
  }

  getMyTasksForPay(): Observable<any> {
    return this._http.get<any>(`${API_ROUTES.task}/my-payments`);
  }

  getAvailableJobs(model: IJobFilterAPI): Observable<any> {
    return this._http.post(`${API_ROUTES.job}available-jobs`, model);
  }

  getCupidJobs(model: IJobFilterAPI): Observable<any> {
    return this._http.post(`${API_ROUTES.job}v2/available-jobs`, model);
  }

  getSimilarJobs(model: SimilarJobSearchModel): Observable<any> {
    return this._http.post(`${API_ROUTES.job}similar-jobs`, model);
  }

  getSimilarJobsByJobId(id: string): Observable<any> {
    const model = { id: id };
    return this._http.post(`${API_ROUTES.job}similar-job`, model);
  }

  getJob(id: string): Observable<any> {
    return this._http.get(`${API_ROUTES.job}specific-job?id=${id}`);
  }

  getMostRecentSearch(): Observable<any> {
    return this._http.get(`${API_ROUTES.job}last-search`);
  }

  getSavedJobs(): Observable<any> {
    return this._http.get(`${API_ROUTES.job}saved-jobs`);
  }

  getMatchedJobs(): Observable<any> {
    return this._http.get(`${API_ROUTES.job}matched-jobs`);
  }

  getRecommendedMatchedJobs(): Observable<any> {
    return this._http.get(`${API_ROUTES.job}recommended-matched-jobs`);
  }

  getCupidRecommendedJobs(): Observable<any> {
    return this._http.get(`${API_ROUTES.job}recommended`);
  }

  getCupidRecommendedJobsByPreference(): Observable<any> {
    return this._http.get(`${API_ROUTES.job}recommendedByPreference`);
  }

  addCupidEventRecordJobSaved(jobId: string): Observable<any> {
    const model = { jobId: jobId };
    return this._http.post(`${API_ROUTES.cupid}jobSaved`, model);
  }

  setSavedJob(id: string, save: boolean = true, contractType?: ContractType): Observable<any> {
    const model = {
      jobId: id,
      save: save,
      contractType
    };
    return this._http.post(`${API_ROUTES.job}save-job`, model, this.hideProgressBarOptions());
  }

  emailJob(id: string): Observable<any> {
    const model = {
      jobId: id
    };
    return this._http.post(`${API_ROUTES.job}interested-job`, model);
  }

  updateJobNotifications(merlinId: string, value: boolean): Observable<any> {
    const model = {
      merlinId: merlinId,
      value: value
    };
    return this._http.post<any>(`${API_ROUTES.job}update-job-notifications`, model);
  }

  resetUser(): Observable<any> {
    return this._http.post<any>(`${API_ROUTES.task}reset-user`, null);
  }

  getMyDocuments(): Observable<CandidateQualification[]> {
    return this._http.get<CandidateQualification[]>(`${API_ROUTES.files}`);
  }

  setDocumentToInactive(id: string): Observable<any> {
    return this._http.delete<any>(`${API_ROUTES.files}/${id}`);
  }

  getDocument(id: string): Observable<any> {
    const url = `${API_ROUTES.files}/${id}`;
    return this._http.get(url, {
      headers: new HttpHeaders({ 'Content-Type': 'blob' }),
      observe: 'response',
      responseType: 'arraybuffer'
    });
  }

  getHospitalPlacesData(hospital: string): Observable<any> {
    return this._http.get<any>(`${API_ROUTES.google}GetGooglePlaceData?placeName=${hospital}`);
  }

  getHospitalMapUrl(hospitalLocation: string): Observable<any> {
    return this._http.get<any>(`${API_ROUTES.google}GetGoogleMapsUrl?address=${hospitalLocation}`);
  }

  getJobsChecklist(merlinId: string): Observable<any> {
    return this._http.get<any>(`${API_ROUTES.job}job-checklist?id=${merlinId}`);
  }

  apply(id: string, rto: string, contractType: ContractType, completedTasks: boolean = true, recommendedJob = false): Observable<any> {
    const model = {
      jobId: id,
      requestedTimeOff: rto,
      completedTasks: completedTasks,
      recommendedJob: recommendedJob,
      contractType: contractType
    };
    return this._http.post(`${API_ROUTES.job}apply`, model);
  }

  updateApplication(id: string, rto: string, completedTasks: boolean = true): Observable<any> {
    const model = {
      jobId: id,
      requestedTimeOff: rto,
      completedTasks: completedTasks
    };
    return this._http.post(`${API_ROUTES.job}updateApplication`, model);
  }

  getDashboardInfo(): Observable<any> {
    return this._http.get(`${API_ROUTES.dashboard}/info`);
  }

  getJobsForHospital(id: string): Observable<any> {
    return this._http.get<any>(`${API_ROUTES.hospital}/jobs/${id}`);
  }

  submitBugReport(model: any): Observable<any> {
    return this._http.post<any>(`${API_ROUTES.support}/bug`, model);
  }

  /**
   * @deprecated - This API call has been moved to Users API. Please use `UsersApiService.registrationCheck()` instead.
   */
  registrationCheck(): Observable<any> {
    return this._http.post<any>(`${API_ROUTES.registration}/check`, null);
  }

  unlockRegistationLock(): Observable<any> {
    return this._http.patch<any>(`${API_ROUTES.registration}/unlock`, null);
  }

  clearRegistationLock(): Observable<any> {
    return this._http.delete<any>(`${API_ROUTES.registration}/clear`);
  }

  updateAppUserToHaveSeenDashboard(): Observable<any> {
    return this._http.post<any>(`${API_ROUTES.users}/seen`, null);
  }

  getAllRecruiters(): Observable<any> {
    return this._http.get<any>(`${API_ROUTES.registration}/recruiters`);
  }

  getMyAssignments(): Observable<any> {
    return this._http.get(`${API_ROUTES.Assignments}`);
  }

  getHasAutoOfferAssignments(): Observable<any> {
    return this._http.get(`assignments/auto-offer-assignments`);
  }

  getMySubmittals(): Observable<any> {
    return this._http.get(`${API_ROUTES.submittals}`);
  }

  archiveSubmittal(id: string): Observable<any> {
    const model = { assignmentId: id };
    return this._http.post(`${API_ROUTES.submittals}archive`, model);
  }

  updateSubmittal(value: any): Observable<any> {
    return this._http.post(`${API_ROUTES.submittals}update`, value);
  }

  updateAllSubmittals(value: UpdateAssignmentModel): Observable<any> {
    return this._http.post(`${API_ROUTES.submittals}update-all`, value);
  }

  contactRecruiter(model: EmailFormModel): Observable<any> {
    return this._http.post(`${API_ROUTES.support}contact-recruiter`, model);
  }

  contactSearch(input: any): Observable<any> {
    return this._http.post(`${API_ROUTES.contacts}search`, input);
  }

  contactSelect(input: any): Observable<any> {
    return this._http.post(`${API_ROUTES.contacts}select`, input);
  }

  contactSelectReset(input: any): Observable<any> {
    return this._http.post(`${API_ROUTES.contacts}select-reset`, input);
  }

  createPayPackageTask(model: RecruiterTaskCreationModel): Observable<any> {
    return this._http.post(`${API_ROUTES.support}pay-package-task`, model);
  }

  getZendeskToken(): Promise<string> {
    return this._http.post<string>(`${API_ROUTES.support}chat`, {}).toPromise();
  }

  getZendeskActive(): Observable<boolean> {
    return this._http.get<boolean>(`${API_ROUTES.support}chat`, {});
  }

  updateInterviewNotes(interview: Interview): Observable<any> {
    return this._http.put(`${API_ROUTES.submittals}update-interview`, interview);
  }

  clearCache(): Observable<any> {
    return this._http.get('app/clear-cache');
  }

  sendWorkHistoryLapseReason(id: string, reason: string): Observable<any> {
    const model = { workHistoryId: id, reason: reason };
    return this._http.put<any>(`${API_ROUTES.work}/reason`, model);
  }

  /**
   * @deprecated - This API call has been moved to Users API. Please use `UsersApiService.getRegistration()` instead.
   */
  getRegistration(): Observable<IRegistrationModel> {
    return this._http.get<IRegistrationModel>(API_ROUTES.registration);
  }

  recruiterSelect(recruiterId: string): Observable<any> {
    const model = { recruiterId: recruiterId };
    return this._http.post<any>(`${API_ROUTES.registration}/recruiter-select`, model);
  }

  onMultipleAccountsFound(): Observable<any> {
    return this._http.post<any>(`${API_ROUTES.registration}/multiple-accounts-found`, null);
  }

  getSkillAreaInfo(): Observable<any> {
    return this._http.get<any>(`${API_ROUTES.lookups}/skills`);
  }

  postSignIn(): Observable<any> {
    return this._http.post<any>(`${API_ROUTES.users}/login`, null);
  }

  isPayVisible(): Observable<any> {
    return this._http.get<any>(`${API_ROUTES.job}/pay-visible`);
  }

  getRequiredPayTasksCount(): Observable<any> {
    return this._http.get<any>(`${API_ROUTES.task}/required-pay-tasks-count`);
  }

  getLookups(): Observable<any> {
    return this._http.get<any>(`${API_ROUTES.lookups}`);
  }

  getProfessions(bu: string = null): Observable<any> {
    return this._http.get<any>(`${API_ROUTES.lookups}professions/${bu}`);
  }

  // Hides the small progress bar at the top of the screen.
  // Quality of life for things not dependent on http requests (such as favoriting a job)
  private hideProgressBarOptions() {
    return { headers: { ignoreProgressBar: 'true' } };
  }

  getJobPreferences() {
    return this._http.get<any>(`${API_ROUTES.jobPreferences}`);
  }

  saveJobPreferences(preferences: any): Observable<any> {
    return this._http.post<any>(`${API_ROUTES.jobPreferences}`, preferences);
  }

  getResources(canSeeTravel: boolean, canSeeInternational: boolean): Observable<IResourceCardConfig[]> {
    // return this._http.get<Resource[]>(`${API_ROUTES.dashboard}/resources`);
    const resources: IResourceCardConfig[] = [];
    if (canSeeTravel) {
      resources.push(...RESOURCES);
    }
    if (canSeeInternational) {
      resources.push(...IN_RESOURCES);
    }
    return of(resources);
  }
}
