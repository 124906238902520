import { Action } from '@ngrx/store';
import { IFileUploadOptions, WorkHistory } from 'src/app/common';
import { GenericCompletionReturnObject } from 'src/app/common/models/generic-completion-return-model';
import { Facility } from 'src/app/common/models/get-facilities-result';

export enum EWorkHistoryContextActions {
  GetWorkHistoryList = '[WORK HISTORY CONTEXT] get work history list',
  GetWorkHistoryListSuccess = '[WORK HISTORY CONTEXT] get work history list success',
  GetWorkHistoryListError = '[WORK HISTORY CONTEXT] get work history list error',

  GetWorkHistoryById = '[WORK HISTORY CONTEXT] get work history by id',
  GetWorkHistoryByIdSuccess = '[WORK HISTORY CONTEXT] get work history by id success',
  GetWorkHistoryByIdError = '[WORK HISTORY CONTEXT] get work history by id error',

  DeleteWorkHistory = '[WORK HISTORY CONTEXT] delete work history',
  DeleteWorkHistorySuccess = '[WORK HISTORY CONTEXT] delete work history success',
  DeleteWorkHistoryError = '[WORK HISTORY CONTEXT] delete work history error',

  UpdateWorkHistory = '[WORK HISTORY CONTEXT] update work history',
  UpdateWorkHistorySuccess = '[WORK HISTORY CONTEXT] update work history success',
  UpdateWorkHistoryError = '[WORK HISTORY CONTEXT] update work history error',

  AddWorkHistory = '[WORK HISTORY CONTEXT] add work history',
  AddWorkHistorySuccess = '[WORK HISTORY CONTEXT] add work history success',
  AddWorkHistoryError = '[WORK HISTORY CONTEXT] add work history error',

  AddWorkHistoryLapseReason = '[WORK HISTORY CONTEXT] add work history lapse reason',
  AddWorkHistoryLapseReasonSuccess = '[WORK HISTORY CONTEXT] add work history lapse reason success',
  AddWorkHistoryLapseReasonError = '[WORK HISTORY CONTEXT] add work history lapse reason error',

  GetFacilityList = '[WORK HISTORY CONTEXT] get facility list',
  GetFacilityListSuccess = '[WORK HISTORY CONTEXT] get facility list success',
  GetFacilityListError = '[WORK HISTORY CONTEXT] get facility list error',
  ClearFacilityList = '[WORK HISTORY CONTEXT] clear facility list',

  UploadResume = '[WORK HISTORY CONTEXT] upload resume',
  UploadResumeSuccess = '[WORK HISTORY CONTEXT] upload resume success',
  UploadResumeError = '[WORK HISTORY CONTEXT] upload resume error',

  ClearWorkHistory = '[WORK HISTORY CONTEXT] clear work history'
}

export class GetWorkHistoryList implements Action {
  public readonly type = EWorkHistoryContextActions.GetWorkHistoryList;
  constructor(public forceUpdate: boolean = false) {}
}
export class GetWorkHistoryListSuccess implements Action {
  public readonly type = EWorkHistoryContextActions.GetWorkHistoryListSuccess;
  constructor(public payload: { workHistoryList: WorkHistory[]; expirationDate: Date }) {}
}
export class GetWorkHistoryListError implements Action {
  public readonly type = EWorkHistoryContextActions.GetWorkHistoryListError;
  constructor(public error: Error) {}
}

export class GetWorkHistoryById implements Action {
  public readonly type = EWorkHistoryContextActions.GetWorkHistoryById;
  constructor(public id: string) {}
}
export class GetWorkHistoryByIdSuccess implements Action {
  public readonly type = EWorkHistoryContextActions.GetWorkHistoryByIdSuccess;
  constructor(public payload: { workHistory: WorkHistory; expirationDate: Date }) {}
}
export class GetWorkHistoryByIdError implements Action {
  public readonly type = EWorkHistoryContextActions.GetWorkHistoryByIdError;
  constructor(public error: Error) {}
}

export class DeleteWorkHistory implements Action {
  public readonly type = EWorkHistoryContextActions.DeleteWorkHistory;
  constructor(
    public id: string,
    public workHistoryToUpdate?: WorkHistory
  ) {}
}
export class DeleteWorkHistorySuccess implements Action {
  public readonly type = EWorkHistoryContextActions.DeleteWorkHistorySuccess;
  constructor(
    public payload: { delete: GenericCompletionReturnObject<number>; workHistory: boolean | GenericCompletionReturnObject<number> },
    public itemId: string = null
  ) {}
}
export class DeleteWorkHistoryError implements Action {
  public readonly type = EWorkHistoryContextActions.DeleteWorkHistoryError;
  constructor(public error: Error) {}
}

export class UpdateWorkHistory implements Action {
  public readonly type = EWorkHistoryContextActions.UpdateWorkHistory;
  constructor(public workHistory: WorkHistory) {}
}
export class UpdateWorkHistorySuccess implements Action {
  public readonly type = EWorkHistoryContextActions.UpdateWorkHistorySuccess;
  constructor(public payload: GenericCompletionReturnObject<number>) {}
}
export class UpdateWorkHistoryError implements Action {
  public readonly type = EWorkHistoryContextActions.UpdateWorkHistoryError;
  constructor(public error: Error) {}
}

export class AddWorkHistory implements Action {
  public readonly type = EWorkHistoryContextActions.AddWorkHistory;
  constructor(public workHistory: WorkHistory) {}
}
export class AddWorkHistorySuccess implements Action {
  public readonly type = EWorkHistoryContextActions.AddWorkHistorySuccess;
  constructor(
    public payload: GenericCompletionReturnObject<string>,
    public request: WorkHistory = null
  ) {}
}
export class AddWorkHistoryError implements Action {
  public readonly type = EWorkHistoryContextActions.AddWorkHistoryError;
  constructor(public error: Error) {}
}

export class AddWorkHistoryLapseReason implements Action {
  public readonly type = EWorkHistoryContextActions.AddWorkHistoryLapseReason;
  constructor(
    public newHistoryId: string,
    public lapseReason: any
  ) {}
}
export class AddWorkHistoryLapseReasonSuccess implements Action {
  public readonly type = EWorkHistoryContextActions.AddWorkHistoryLapseReasonSuccess;
  constructor(public payload: any) {}
}
export class AddWorkHistoryLapseReasonError implements Action {
  public readonly type = EWorkHistoryContextActions.AddWorkHistoryLapseReasonError;
  constructor(public error: Error) {}
}

export class GetFacilityList implements Action {
  public readonly type = EWorkHistoryContextActions.GetFacilityList;
  constructor(public payload: string) {}
}
export class GetFacilityListSuccess implements Action {
  public readonly type = EWorkHistoryContextActions.GetFacilityListSuccess;
  constructor(public payload: Facility[]) {}
}
export class GetFacilityListError implements Action {
  public readonly type = EWorkHistoryContextActions.GetFacilityListError;
  constructor(public error: Error) {}
}
export class ClearFacilityList implements Action {
  public readonly type = EWorkHistoryContextActions.ClearFacilityList;
}

export class UploadResume implements Action {
  public readonly type = EWorkHistoryContextActions.UploadResume;
  constructor(
    public options: IFileUploadOptions,
    public files: File[]
  ) {}
}
export class UploadResumeSuccess implements Action {
  public readonly type = EWorkHistoryContextActions.UploadResumeSuccess;
  constructor(public payload: any) {}
}
export class UploadResumeError implements Action {
  public readonly type = EWorkHistoryContextActions.UploadResumeError;
  constructor(public error: Error) {}
}
export class ClearWorkHistory implements Action {
  public readonly type = EWorkHistoryContextActions.ClearWorkHistory;
}

export type WorkHistoryContextActions =
  | GetWorkHistoryList
  | GetWorkHistoryListSuccess
  | GetWorkHistoryListError
  | GetWorkHistoryById
  | GetWorkHistoryByIdSuccess
  | GetWorkHistoryByIdError
  | DeleteWorkHistory
  | DeleteWorkHistorySuccess
  | DeleteWorkHistoryError
  | UpdateWorkHistory
  | UpdateWorkHistorySuccess
  | UpdateWorkHistoryError
  | AddWorkHistory
  | AddWorkHistorySuccess
  | AddWorkHistoryError
  | AddWorkHistoryLapseReason
  | AddWorkHistoryLapseReasonSuccess
  | AddWorkHistoryLapseReasonError
  | GetFacilityList
  | GetFacilityListSuccess
  | GetFacilityListError
  | ClearFacilityList
  | UploadResume
  | UploadResumeSuccess
  | UploadResumeError
  | ClearWorkHistory;
