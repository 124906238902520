<div fxFlex fxLayout="column" id="main-content">
  <div fxLayout="column" class="top-section">
    <div fxLayoutAlign="space-between center">
      <hc-heading [size]="headingSizeH5">Pay Details</hc-heading>
      <button id="disclaimerClose" mat-icon-button class="global-x-btn">
        <mat-icon (click)="cancel()">close</mat-icon>
      </button>
    </div>
  </div>
  <div class="main">
    <hc-accordion title="Pay" [expanded]="false" [isList]="true">
      <hc-body>
        The pay displayed in On Demand is an estimate, does not include travel reimbursement and should not be construed as quotes or guaranteed sum. The pay provided is on a
        best endeavor basis and is subject to change based on various factors, including, without limitation, correction or modification of inaccurate information, submission of
        additional information, assignment modification or alteration, and permanent tax residence declaration. The following items are factored into the estimated pay
        background check, employee testing, drug screenings, credentialing costs, and licensure reimbursement.
      </hc-body>
    </hc-accordion>
    <hc-accordion title="Overtime" [expanded]="false" [isList]="true">
      <hc-body>
        The displayed Overtime rate is an estimate and may be adjusted (usually up) when building the final pay package. Learn more about Overtime from the Department of Labor.
      </hc-body>
    </hc-accordion>
  </div>
</div>
