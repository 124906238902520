<div id="#main-content">
  <div mat-dialog-content fxLayout="column" fxLayoutGap="4px" fxLayoutAlign="center center">
    <mat-icon class="check material-icons md-48">check_circle</mat-icon>
    <div class="top-header">
      {{ data.fileCount }} {{ data.fileCount > 1 ? 'documents have' : 'document has' }} been successfully uploaded.
      <span *ngIf="canSeeTravel$ | async">We will review your documents and update your Profile in 2 business days</span>
    </div>
  </div>
  <div mat-dialog-actions fxLayoutAlign="end center">
    <button id="bulkUploadConfirm" mat-button color="primary" (click)="close()" cdkFocusInitial>Ok</button>
  </div>
</div>
