import { Component, EventEmitter, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-basic-snack-bar',
  templateUrl: './basic-snack-bar.component.html',
  styleUrls: ['./basic-snack-bar.component.scss']
})
export class BasicSnackBarComponent implements OnInit {
  @Input() displayEvent: EventEmitter<any>;
  show: boolean;
  text: string;

  ngOnInit() {
    this.displayEvent.subscribe(e => {
      this.text = e.text;
      this.show = true;

      setTimeout(() => (this.show = false), e.timeout ? e.timeout : 2000);
    });
  }
}
