export class Nav {
  name: string;
  nav: string;
  condition?: NavCondition;
}

export enum NavCondition {
  TRAVEL = 'canSeeTravel',
  INTERNATIONAL = 'canSeeInternational'
}
