import { IRecordCardConfig } from 'hc-design-system-lib/lib/components/cards/cards.interfaces';
import { SkillSummary } from './skill-summary';
import { CandidateSkillSummary } from './candidate-skill-summary-model';
import { SkillStatus } from '../constants';
import { BodyColor } from 'hc-design-system-lib';

export class SkillDictionary {
  checklists: SkillCardConfig[];
  accordion: Map<string, AccordionConfig[]>;

  constructor(candidateSkillsMap: Map<number, CandidateSkillSummary>, skillList: SkillSummary[], requiredSkillMap: Map<number, boolean>) {
    this.checklists = [] as SkillCardConfig[];
    this.accordion = new Map<string, AccordionConfig[]>();

    this.mapSkillList(candidateSkillsMap, skillList, requiredSkillMap);
  }

  public mapSkillList(candidateSkillsMap: Map<number, CandidateSkillSummary>, skillList: SkillSummary[], requiredSkillMap: Map<number, boolean>) {
    skillList.forEach(skill => {
      const skillId: number = skill?.skillId;
      const skillData = candidateSkillsMap.get(skillId);
      const progress = this.getSkillProgress(skillData);
      const required = requiredSkillMap.get(skillId);
      if (required || progress) {
        const recordCard = this.buildRecordCard(skill.name, skillData, progress, required);
        this.addChecklistSkill(recordCard, skillId);
      }

      this.addAccordionSkill(skill, skillId);
    });
  }

  public addChecklistSkill(config: IRecordCardConfig, id: number) {
    this.checklists.push({ config, id });
  }

  public addAccordionSkill(config: SkillSummary, id: number) {
    const firstLetter = config.name.charAt(0);
    if (!this.accordion.has(firstLetter)) {
      this.accordion.set(firstLetter, [{ config, id }]);
    } else {
      this.accordion.set(firstLetter, [...this.accordion.get(firstLetter), { config, id }]);
    }
  }

  private buildRecordCard(skillName: string, skillData: CandidateSkillSummary, progress: number, isRequired: boolean): IRecordCardConfig {
    const status = this.getSkillStatus(progress);
    const score = this.getSkillScoreText(skillData);
    return {
      status: status,
      statusColor: this.getStatusColor(status),
      title: skillName,
      firstLine: `Required: ${isRequired ? 'Yes' : 'No'}`,
      secondLine: score,
      icon: 'edit',
      firstLineBold: true
    };
  }

  private getSkillStatus(progress: number): SkillStatus {
    if (progress) {
      return progress < 1 ? SkillStatus.InProgress : SkillStatus.Complete;
    }
    return SkillStatus.NotStarted;
  }

  private getSkillScoreText(skillData: CandidateSkillSummary): string {
    return skillData ? `(${skillData.answeredQuestions}/${skillData.availableQuestions})` : null;
  }

  private getStatusColor(status: string): BodyColor {
    switch (status) {
      case SkillStatus.NotStarted:
        return BodyColor.orange;
      case SkillStatus.InProgress:
        return BodyColor.purple;
      case SkillStatus.Complete:
        return BodyColor.green;
    }
  }

  private getSkillProgress(skillData: CandidateSkillSummary): number {
    const endDate = skillData?.endDate ? 1 : 0;
    return skillData ? (skillData.answeredQuestions + endDate) / (skillData.availableQuestions + 1) : undefined;
  }
}

export class SkillConfig {
  config: any;
  id: number;
}

export class AccordionConfig extends SkillConfig {
  config: SkillSummary;
}

export class SkillCardConfig extends SkillConfig {
  config: IRecordCardConfig;
}
