import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable, forkJoin, of } from 'rxjs';
import { NavHelper, NursePortalApi } from 'src/app/services';
import { Store } from '@ngrx/store';
import { IAppState } from 'src/app/store/app/app.state';
import { selectLicenses } from 'src/app/store/licenses/licenses.selectors';
import { filter, skipWhile, switchMap, take, withLatestFrom } from 'rxjs/operators';
import { selectStateLookup, selectYesNoOnlyLookup } from 'src/app/store/lookups/lookups.selectors';
import { AboutMeModel, ILookup, License } from 'src/app/common';
import { NotificationService } from 'hc-design-system-lib';
import { selectFlags, selectFlagsLoading, selectIsSpecificFlagOn } from 'src/app/store/flags/flags.selectors';
import { featureFlagNames } from 'src/app/services/feature-flags.service';
import { GetVariant } from 'src/app/store/flags/flags.actions';

export interface EditNursysSetupResolveData {
  nurse: AboutMeModel;
  licenses: License[];
  stateLookup: Map<string, ILookup<string>>;
  yesNoOnlyLookup: Map<string, ILookup<string>>;
}

@Injectable()
export class EditNursysSetupResolve implements Resolve<EditNursysSetupResolveData> {
  constructor(
    private _api: NursePortalApi,
    private _store: Store<IAppState>,
    private _navHelper: NavHelper,
    private _notificationService: NotificationService
  ) {}

  resolve(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<EditNursysSetupResolveData> {
    return this._store.select(selectFlagsLoading).pipe(
      withLatestFrom(this._store.select(selectFlags)),
      skipWhile(([loading, data]) => loading || data == null),
      switchMap(() => this._store.select(selectIsSpecificFlagOn(featureFlagNames.useNursys))),
      switchMap(useNursys => {
        this._store.dispatch(new GetVariant(featureFlagNames.useNursys));
        if (!useNursys) {
          const params = _route.queryParamMap;

          this._notificationService.showNotification(
            'Nursys access through On Demand is currently down to due technical issues. Please upload any licenses directly in to On Demand and we will review manually',
            'error'
          );
          this._navHelper.goToManualLicenseAdd(params.get('fromTask') === 'true', params.get('licenseNum'), params.get('lienseState'), false, params.get('lastTask') === 'true');
          return of(null);
        } else {
          const nurse$ = this._api.getAboutMe();
          const licenses$ = this._store.select(selectLicenses).pipe(
            filter(licenses => !!licenses),
            take(1)
          );
          const stateLookup$ = this._store.select(selectStateLookup).pipe(
            filter(stateLookup => !!stateLookup),
            take(1)
          );
          const yesNoOnlyLookup$ = this._store.select(selectYesNoOnlyLookup).pipe(
            filter(yesNoOnlyLookup => !!yesNoOnlyLookup),
            take(1)
          );

          return forkJoin({
            nurse: nurse$,
            licenses: licenses$,
            stateLookup: stateLookup$,
            yesNoOnlyLookup: yesNoOnlyLookup$
          });
        }
      })
    );
  }
}
