import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { from } from 'rxjs';
import { AppUrls } from 'src/app/app-urls';
import { AuthenticationService, AppState } from 'src/app/services';

@Component({
  selector: 'app-internal-login',
  templateUrl: './internal-login.component.html',
  styleUrls: ['./internal-login.component.scss']
})
export class InternalLoginComponent implements OnInit {
  isLoggedIn = false;
  constructor(
    private _authService: AuthenticationService,
    private _router: Router,
    private _state: AppState
  ) {}

  ngOnInit() {
    this._state.setSpinnerState(false);
    this._state.isLoaded = true;

    from(
      this._authService.isAuthenticated().then(isAuthenticated => {
        this.isLoggedIn = isAuthenticated;
      })
    );
  }

  login() {
    this._authService.login();
  }
  logout() {
    this._authService.logout();
  }
  selectContact() {
    this._router.navigateByUrl(AppUrls.CONTACT_SELECT);
  }
}
