import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, exhaustMap, filter, map, switchMap } from 'rxjs/operators';
import { getDateInFutureWithMinutes } from 'src/app/common/functions/date-manipulations';
import { DashboardObject } from 'src/app/components';
import { NursePortalApi } from 'src/app/services';
import {
  DashboardActions,
  EDashboardActions,
  GetDashboardInfo,
  GetDashboardInfoError,
  GetDashboardInfoSuccess,
  GetResourcesError,
  GetResourcesSuccess,
  UpdateUserSeenDashboardError,
  UpdateUserSeenDashboardSuccess
} from 'src/app/store/dashboard/dashboard.actions';
import { IAppState } from '../app/app.state';
import { selectDashboardObject, selectIsDashboardObjectExpirationDate, selectIsDashboardObjectExpired } from './dashboard.selectors';
import { IResourceCardConfig } from 'hc-design-system-lib/lib/components/cards/cards.interfaces';
import { selectCanSeeInternational, selectCanSeeTravel } from '../userContext/userContext.selectors';

@Injectable({
  providedIn: 'root'
})
export class DashboardEffects {
  constructor(
    private actions$: Actions,
    private _api: NursePortalApi,
    private _store: Store<IAppState>
  ) {}

  getDashboardInfo$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<DashboardActions>(EDashboardActions.GetDashboardInfo),
      map((action: GetDashboardInfo) => action.forceUpdateCache),
      concatLatestFrom(() => [
        this._store.select(selectDashboardObject),
        this._store.select(selectIsDashboardObjectExpired),
        this._store.select(selectIsDashboardObjectExpirationDate),
        this._store.select(selectCanSeeTravel)
      ]),
      filter(([forceUpdateCache, dashboardObject, isExpired, expiration, canSeeTravel]: [boolean, DashboardObject, boolean, Date, boolean]) => canSeeTravel),
      switchMap(([forceUpdateCache, dashboardObject, isExpired, expiration, canSeeTravel]: [boolean, DashboardObject, boolean, Date, boolean]) => {
        if (!forceUpdateCache && !isExpired) {
          return of(new GetDashboardInfoSuccess({ dashboardObject, expiration }));
        }
        return this._api.getDashboardInfo().pipe(
          switchMap((dashboardInfo: DashboardObject) => {
            const expirationDate = getDateInFutureWithMinutes(5);
            const result = {
              dashboardObject: dashboardInfo,
              expiration: expirationDate
            };
            return of(new GetDashboardInfoSuccess(result));
          }),
          catchError((error: Error) => of(new GetDashboardInfoError(error)))
        );
      })
    );
  });

  updateUserSeenDashboard$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<DashboardActions>(EDashboardActions.UpdateUserSeenDashboard),
      exhaustMap(() =>
        this._api.updateAppUserToHaveSeenDashboard().pipe(
          map((response: any) => new UpdateUserSeenDashboardSuccess(response)),
          catchError((error: Error) => of(new UpdateUserSeenDashboardError(error)))
        )
      )
    );
  });

  getResources$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<DashboardActions>(EDashboardActions.GetResources),
      concatLatestFrom(() => [this._store.select(selectCanSeeTravel), this._store.select(selectCanSeeInternational)]),
      exhaustMap(([, canSeeTravel, canSeeInternational]) => {
        return this._api.getResources(canSeeTravel, canSeeInternational).pipe(
          map((response: IResourceCardConfig[]) => new GetResourcesSuccess({ resources: response })),
          catchError((error: Error) => of(new GetResourcesError(error)))
        );
      })
    );
  });
}
