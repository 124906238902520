import { IFeatureFlag } from 'src/app/common/contracts/feature-flag';
import { IDataState, initializeData } from 'src/app/store/app/app.models';

export interface IFlagsState {
  appFlags: IDataState<Map<string, IFeatureFlag>>;
}

export const initialFlagsState: IFlagsState = {
  appFlags: initializeData()
};
