<div fxFlex fxLayout="column" fxLayoutAlign="center center" class="empty-background" fxLayoutGap="10px">
  <ng-content> </ng-content>
  <div class="empty-area" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px">
    <div class="mat-body-2">You haven't added any {{ word }}. {{ extended }}</div>
    <div class="global-body1">
      {{ optionalText }}
    </div>
    <div>
      <button id="emptyListButtonClick" mat-flat-button class="tertiary-colored-btn" (click)="onButtonClick()">
        {{ getButtonText() }}
      </button>
    </div>
    <div *ngIf="hasSecondaryAction()">
      <div class="divider">OR</div>
      <button id="emptyListAddManually" mat-button color="primary" (click)="addManually()">{{ secondaryActionText }}</button>
    </div>
  </div>
</div>
