import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { NurseProfileModel } from 'src/app/common';
import { FormHeaderService, NavHelper } from 'src/app/services';
import { JobAreaContext } from 'src/app/services/job-area-context.service';
import { IAppState } from 'src/app/store/app/app.state';
import { selectProfessionalOverview } from 'src/app/store/userContext/userContext.selectors';
import { ContactRecruiterType } from '../../shared/contact-recruiter-pop-up/contact-recruiter-pop-up.component';
import { Recruiter } from 'src/app/common/models/recruiter';
import { selectRecruiterData } from 'src/app/store/recruiter/recruiter.selectors';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-main-job-area',
  templateUrl: './main-job-area.component.html',
  styleUrls: ['./main-job-area.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MainJobAreaComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();

  @ViewChild('tabGroup') tabGroup;

  profile: NurseProfileModel;
  index: any;
  onInit: boolean = true;
  elizaAutoOpened: boolean = false;

  professionalOverview$: Observable<NurseProfileModel> = this._store.select(selectProfessionalOverview);
  recruiter$: Observable<Recruiter> = this._store.select(selectRecruiterData);

  constructor(
    private _route: ActivatedRoute,
    private _nav: NavHelper,
    private router: Router,
    public _ctx: JobAreaContext,
    public _formHeaderService: FormHeaderService,
    private _store: Store<IAppState>
  ) {}

  get contactRecruiterType(): ContactRecruiterType {
    // This is in place temporarily so that the Eliza Bot only shows for nurses without recruiters
    switch (this.index) {
      case '0':
      case undefined:
        //@ts-ignore
        try {
          //@ts-ignore
          zE('messenger', 'show');

          if (this.elizaAutoOpened == false) {
            this.elizaAutoOpened = true;
            var timeout;

            var openEliza = function () {
              //@ts-ignore
              zE('messenger', 'open');
              window.removeEventListener('mousemove', mouseMoveListener);
              window.removeEventListener('keydown', keydownListener);
            };

            function startInactiveTimeout() {
              timeout = setTimeout(openEliza, 8000); // 8 seconds in milliseconds
            }

            function mouseMoveListener() {
              clearTimeout(timeout);
              startInactiveTimeout();
            }

            window.addEventListener('mousemove', mouseMoveListener);

            function keydownListener() {
              clearTimeout(timeout);
              startInactiveTimeout();
            }

            window.addEventListener('keydown', keydownListener);

            startInactiveTimeout();
          }
        } catch {
          // ZenDesk Not Loaded Yet
        }
        break;
      default:
        //@ts-ignore
        try {
          //@ts-ignore
          zE('messenger', 'hide');
        } catch {
          // ZenDesk Not Loaded Yet
        }
        break;
    }
    switch (this.index) {
      case '0':
      case undefined:
        return ContactRecruiterType.JobSearch;
      case '1':
        return ContactRecruiterType.JobFavorites;
      default:
        return ContactRecruiterType.JobSearch;
    }
  }

  ngOnInit() {
    this.getProfessionalOverview();
    // this is used so that if you are on favorite and navigate to job alerts from the side nav, it will actually change MAGGIE
    // this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.events.pipe(takeUntil(this.destroy$)).subscribe(o => {
      if (o instanceof NavigationEnd) {
        this.loadIndex();
      }
    });
    this._formHeaderService.resetFormHeaderAttributes({
      title: `Job Search`,
      showBackButton: false
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getProfessionalOverview() {
    this.professionalOverview$.pipe(takeUntil(this.destroy$)).subscribe(professionalOverview => {
      if (professionalOverview) {
        this.profile = professionalOverview;
        if (this.onInit) {
          this.loadIndex();
          this.onInit = false;
        }
      }
    });
  }

  loadIndex(): void {
    this.index = this._route.snapshot.queryParams['index'];
  }

  scrollTabs(event): void {
    const children = this.tabGroup._tabHeader._elementRef.nativeElement.children;
    const back = children[0];
    const forward = children[1];
    if (event.deltaY > 0) {
      forward.click();
    } else {
      back.click();
    }
  }

  onMainClick(): void {
    this._ctx.selectedJob = null;
  }

  onLinkClick(som: any): void {
    this.index = som.event?.index ?? som.index;
    this._nav.goToJobSearchSpecific(this.index);
  }

  setTabIndex = (index: any): void => {
    this.index = index;
  };
}
