import { Action } from '@ngrx/store';

export enum ELocationActions {
  GetLocation = '[LOCATION] get location',
  GetLocationSuccess = '[LOCATION] get location success',
  GetLocationError = '[LOCATION] get location error'
}

export class GetLocation implements Action {
  public readonly type = ELocationActions.GetLocation;
  constructor(public input: string) {}
}

export class GetLocationSuccess implements Action {
  public readonly type = ELocationActions.GetLocationSuccess;

  constructor(public location: string[]) {}
}

export class GetLocationError implements Action {
  public readonly type = ELocationActions.GetLocationError;

  constructor(public error: Error) {}
}

export type LocationActions = GetLocation | GetLocationSuccess | GetLocationError;
