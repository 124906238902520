import { Injectable } from '@angular/core';
import { NavHelper } from '../services';
import { combineLatest, Observable } from 'rxjs';
import { map, filter, skipWhile, tap, take } from 'rxjs/operators';
import { AppUrls } from '../app-urls';
import { IAppState } from 'src/app/store/app/app.state';
import { Store } from '@ngrx/store';
import { selectAccountStateData, selectAdUserResultData, selectInitializeUserLoading, selectRegistrationSubmitData } from 'src/app/store/userContext/userContext.selectors';
import { IAccountStateModel } from 'src/app/common/models/account-state-model';
import * as Sentry from '@sentry/angular-ivy';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router';
import {
  GetAccountState,
  GetContactPreferences,
  GetDocuments,
  GetNurse,
  GetNurseIN,
  GetProfessionalOverview,
  GetQuestionnaire,
  GetSummaryPrompts,
  GetUser
} from 'src/app/store/userContext/userContext.actions';
import {
  GetJobSubmittals,
  GetRecommendedMatchedJobs,
  GetRecommendedJobsByPreference,
  GetSavedJobs,
  GetHasAutoOfferAssignments,
  GetJobSubmittalsIN
} from 'src/app/store/jobs/jobs.actions';
import { GetPayRecruiter } from 'src/app/store/recruiter/recruiter.actions';
import { GetQuestionLookups, GetSkillAreaInfoLookups } from 'src/app/store/lookups/lookups.actions';
import { IAdUserResponseModel, ILookups, IRegistrationResponseModel } from 'src/app/common';
import { selectLookups } from 'src/app/store/lookups/lookups.selectors';
import { GetEducationList } from '../store/education/education.actions';
import { GetCovidVaccine, GetFluVaccine, GetTasks } from '../store/tasks/tasks.actions';
import { GetCertifications } from '../store/certifications/certifications.actions';
import { GetLicenses, GetLicensesIN } from '../store/licenses/licenses.actions';
import { GetCandidateSkills, GetSkills } from '../store/skillsChecklist/skillsChecklist.actions';
import { selectIsSpecificFlagOn } from '../store/flags/flags.selectors';
import { featureFlagNames } from '../services/feature-flags.service';
import { GetWorkHistoryList } from '../store/workHistory/workHistoryContext.actions';
import { GetTechnology } from '../store/technologies/technology.actions';
import { GetReferenceEmails, GetReferences } from '../store/references/references.actions';
import { SegmentReady } from '../store/segment/segment.actions';
import { setEnvironmentId } from '../store/ui/ui.actions';
import { environment } from 'src/environments/environment';

@Injectable()
export class MainGuard implements CanActivate {
  initializeUserLoading$: Observable<boolean> = this._store.select(selectInitializeUserLoading);
  accountState$: Observable<IAccountStateModel> = this._store.select(selectAccountStateData);

  createAdUser$: Observable<IAdUserResponseModel> = this._store.select(selectAdUserResultData);

  postRegistration$: Observable<IRegistrationResponseModel> = this._store.select(selectRegistrationSubmitData);

  lookups$: Observable<ILookups> = this._store.select(selectLookups);

  useGuestServiceSkills$: Observable<boolean> = this._store.select(selectIsSpecificFlagOn(featureFlagNames.guestServiceSkillsChecklist));

  constructor(
    private readonly _router: Router,
    private readonly _navHelper: NavHelper,
    private readonly _store: Store<IAppState>
  ) {}

  dispatchActionsOnUserData(userId): void {
    Segment.ready(() => this._store.dispatch(new SegmentReady()));
    this._store.dispatch(new GetHasAutoOfferAssignments());
    this._store.dispatch(new GetPayRecruiter());
    this._store.dispatch(new GetQuestionLookups());
    this._store.dispatch(new GetSkillAreaInfoLookups());
    this._store.dispatch(new GetTasks(true));
    this._store.dispatch(new GetFluVaccine());
    this._store.dispatch(new GetCovidVaccine());
    this._store.dispatch(new GetJobSubmittals());
    this._store.dispatch(new GetJobSubmittalsIN());
    this._store.dispatch(new GetRecommendedMatchedJobs());
    this._store.dispatch(new GetCertifications());
    this._store.dispatch(new GetRecommendedJobsByPreference());
    this._store.dispatch(new GetEducationList());
    this._store.dispatch(new GetTechnology());
    this._store.dispatch(new GetWorkHistoryList());
    this._store.dispatch(new GetReferences());
    this._store.dispatch(new GetReferenceEmails());
    this._store.dispatch(new GetSavedJobs());
    this._store.dispatch(new GetContactPreferences());
    this._store.dispatch(new GetNurseIN());
  }

  dispatchActionsOnLoggedInUser(): void {
    this._store.dispatch(new GetProfessionalOverview());
    this._store.dispatch(new GetSummaryPrompts());
    this._store.dispatch(new GetLicenses());
    this._store.dispatch(new GetLicensesIN());
    this._store.dispatch(new GetQuestionnaire());
    this._store.dispatch(new GetDocuments());
    this._store.dispatch(new GetSkills());
    this._store.dispatch(new GetCandidateSkills());
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | UrlTree {
    console.log('MainGuard.canActivate');

    this.initializeUserLoading$
      .pipe(
        skipWhile((loading: boolean) => loading),
        tap(() => {
          this._store.dispatch(setEnvironmentId(environment));
          this._store.dispatch(new GetAccountState());
          this._store.dispatch(new GetUser());
          this._store.dispatch(new GetNurse());
        })
      )
      .subscribe();

    return combineLatest([this.accountState$, this.postRegistration$, this.createAdUser$]).pipe(
      filter(([accountStateData]) => accountStateData !== null),
      map(([accountStateData, postRegistrationResult, createAdUserResult]: [IAccountStateModel, IRegistrationResponseModel, IAdUserResponseModel]) => {
        this.dispatchActionsOnUserData(accountStateData.merlinId);
        Sentry.setUser({
          id: accountStateData.merlinId,
          email: accountStateData.email
        });

        if (this.handleMarketingEnrollFlow(createAdUserResult, postRegistrationResult)) {
          return true;
        }

        if (accountStateData.inactive) {
          return this._router.parseUrl(AppUrls.INACTIVE_USER);
        }

        if (accountStateData.isInternal) {
          if (!accountStateData.hasContactSelect) {
            return this._router.parseUrl(AppUrls.CONTACT_SELECT);
          }
          return this._router.parseUrl(AppUrls.INTERNAL_LOGIN);
        }

        if (!postRegistrationResult?.nurse?.isWelcomed && !accountStateData.isWelcomed) {
          this._navHelper.startingUrl = state.url.toString();
          return this._router.createUrlTree([AppUrls.REGISTRATION], { queryParams: next.queryParams });
        }

        if (!accountStateData.isVerified && !state.url.includes('verify')) {
          return this.handleVerificationFlow(accountStateData);
        }

        if (!accountStateData.isVerified && state.url.includes('verify')) {
          return true;
        }

        if (
          (!accountStateData.canSeeInternational && !accountStateData.canSeeTravel) ||
          (accountStateData.canSeeInternational && !accountStateData.canSeeTravel && accountStateData.isNew)
        ) {
          return this._router.parseUrl(AppUrls.LOGIN_ERROR);
        }

        this.dispatchActionsOnLoggedInUser();
        return true;
      })
    );
  }

  private handleMarketingEnrollFlow(createAdUserResult: IAdUserResponseModel, postRegistrationResult: IRegistrationResponseModel): boolean {
    if (createAdUserResult !== null && postRegistrationResult == null) {
      this.postRegistration$
        .pipe(
          filter(postRegistrationRes => postRegistrationRes != null),
          take(1)
        )
        .subscribe(() => {
          this.dispatchActionsOnLoggedInUser();
        });

      return true;
    }
    return false;
  }

  private handleVerificationFlow(accountStateData: IAccountStateModel): UrlTree {
    if (localStorage.getItem('url')?.includes('verify/check')) {
      let key = localStorage.getItem('url').substring(localStorage.getItem('url').indexOf('?') + 1);
      localStorage.removeItem('url');
      return this._router.parseUrl(`verify/check?${key}`);
    } else {
      if (accountStateData.verificationType === 2) {
        return this._router.parseUrl(`verify/${AppUrls.VERIFY_SMS}`);
      }
      return this._router.parseUrl(`verify/${AppUrls.VERIFY_USER}`);
    }
  }
}
