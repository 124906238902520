<div class="scroll full-height" fxLayout="column">
  <app-loading-screen-spinner [show]="hasAutoOfferAssignmentsLoading$ | async"></app-loading-screen-spinner>
  <div *ngIf="(hasAutoOfferAssignments$ | async) === false" fxLayout="column" fxLayoutAlign="center center" fxFlex fxLayoutGap="16px">
    <div class="image" fxLayoutAlign="center center">
      <div class="image-text">!</div>
    </div>
    <div fxLayout="column" fxLayoutAlign="stretch start" fxLayoutGap="8px">
      <div class="title-alignment">This is an <b>Auto-Offer</b> agreement:</div>
      <ul>
        <li class="list-item">
          There is <u><b>NO</b></u> Interview.
        </li>
        <li class="list-item">The first qualified candidate to submit receives an offer quickly.</li>
        <li class="list-item">If offered, you're expected to accept.</li>
      </ul>
    </div>
    <div class="banner">NOTE: Declining the offer could mean you won't be allowed to apply to Auto-Offers in the future.</div>
    <div fxFlex></div>
    <div class="agreement">Do you agree with these expectations?</div>
    <div fxLayout="column" class="button-positions" fxLayoutGap="16px">
      <button [disabled]="specificJobLoading$ | async" id="autoOfferConfirmYes" class="button" mat-flat-button color="primary" fxFlex (click)="continue()">
        Yes, I agree. Continue
      </button>
      <button
        segmentTrackCTA
        [disabled]="payRecruiterLoading$ | async"
        id="autoOfferConfirmContactRecruiter"
        class="button"
        mat-stroked-button
        color="primary"
        fxFlex
        (click)="contactRecruiter()"
      >
        Contact Recruiter
      </button>
      <button id="autoOfferConfirmNo" class="button" mat-button color="primary" fxFlex (click)="back()">I no longer want to continue.</button>
    </div>
  </div>
  <!-- The page if they have an auto offer -->
  <div *ngIf="hasAutoOfferAssignments$ | async" fxLayout="column" fxLayoutAlign="center center" fxFlex fxLayoutGap="16px">
    <div class="image" fxLayoutAlign="center center">
      <div class="image-text">!</div>
    </div>
    <div class="title-alignment">You've applied to another <b>Auto-Offer</b>.</div>
    <div class="banner">If you're offered both, you'll need to decline one, potentially disqualifying you from applying to that facility or network in the future.</div>
    <div fxFlex></div>
    <div class="agreement">Do you understand the implications of applying to multiple Auto-Offers?</div>
    <div fxLayout="column" class="button-positions" fxLayoutGap="16px">
      <button [disabled]="specificJobLoading$ | async" id="autoOfferYes" class="button" mat-flat-button color="primary" fxFlex (click)="continue()">
        Yes, I understand. Continue
      </button>
      <button
        segmentTrackCTA
        [disabled]="payRecruiterLoading$ | async"
        id="autoOfferContactRecruiter"
        class="button"
        mat-stroked-button
        color="primary"
        fxFlex
        (click)="contactRecruiter()"
      >
        Contact Recruiter
      </button>
      <button id="autoOfferNo" class="button" mat-button color="primary" fxFlex (click)="back()">I no longer want to continue.</button>
    </div>
  </div>
</div>
