<div [@.disabled]="disableAnimation">
  <div class="title" mat-dialog-title fxLayout="row" fxLayoutAlign="space-between center">
    <div>Application Process Overview</div>
    <button id="statusInfoPopupClose" mat-icon-button (click)="cancel()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div mat-dialog-content>
    <mat-accordion displayMode="flat">
      <mat-expansion-panel class="statusInfo mat-elevation-z0 pending" hideToggle="true" #inReview [expanded]="data === portalStatuses.PendingReview">
        <mat-expansion-panel-header>
          <div fxLayout="row" fxLayoutAlign="center center">
            <mat-panel-title> Pending Review </mat-panel-title>
            <mat-panel-description fxLayoutAlign="end">
              <mat-icon *ngIf="!inReview.expanded">keyboard_arrow_down</mat-icon>
              <mat-icon *ngIf="inReview.expanded">keyboard_arrow_up</mat-icon>
            </mat-panel-description>
          </div>
        </mat-expansion-panel-header>
        <p>
          Your application is being reviewed by our Quality Assurance Team, a standard practice within the travel industry. This is to ensure that when your packet is sent to
          the facility it meets all the necessary requirements and gives you the best shot at getting the job.
        </p>
      </mat-expansion-panel>

      <mat-expansion-panel class="statusInfo mat-elevation-z0 action-required" hideToggle="true" #actionReq [expanded]="data === portalStatuses.ActionRequired">
        <mat-expansion-panel-header>
          <div fxLayout="row" fxLayoutAlign="center center">
            <mat-panel-title> Candidate Action Needed </mat-panel-title>
            <mat-panel-description fxLayoutAlign="end">
              <mat-icon *ngIf="!actionReq.expanded">keyboard_arrow_down</mat-icon>
              <mat-icon *ngIf="actionReq.expanded">keyboard_arrow_up</mat-icon>
            </mat-panel-description>
          </div>
        </mat-expansion-panel-header>
        <p>Your application requires your attention. Below are some of the items we noticed that will help you win the job.</p>
      </mat-expansion-panel>

      <mat-expansion-panel class="statusInfo mat-elevation-z0 hospital-review" hideToggle="true" #hospital [expanded]="data === portalStatuses.HospitalReview">
        <mat-expansion-panel-header>
          <div fxLayout="row" fxLayoutAlign="center center">
            <mat-panel-title> Hospital Review </mat-panel-title>
            <mat-panel-description fxLayoutAlign="end">
              <mat-icon *ngIf="!hospital.expanded">keyboard_arrow_down</mat-icon>
              <mat-icon *ngIf="hospital.expanded">keyboard_arrow_up</mat-icon>
            </mat-panel-description>
          </div>
        </mat-expansion-panel-header>
        <p>
          Your application has been submitted to the facility. At this time, you may hear back from the facility before we do. In the event we receive any feedback, we will pass
          it along ASAP.
        </p>
      </mat-expansion-panel>

      <mat-expansion-panel class="statusInfo mat-elevation-z0 offered" hideToggle="true" #offered [expanded]="data === portalStatuses.Offered">
        <mat-expansion-panel-header>
          <div fxLayout="row" fxLayoutAlign="center center">
            <mat-panel-title> Offer Extended </mat-panel-title>
            <mat-panel-description fxLayoutAlign="end">
              <mat-icon *ngIf="!offered.expanded">keyboard_arrow_down</mat-icon>
              <mat-icon *ngIf="offered.expanded">keyboard_arrow_up</mat-icon>
            </mat-panel-description>
          </div>
        </mat-expansion-panel-header>
        <p>The facility has made an offer of employment! We will be sending additional details with compensation shortly.</p>
      </mat-expansion-panel>

      <mat-expansion-panel class="statusInfo mat-elevation-z0 accepted" hideToggle="true" #accepted [expanded]="data === portalStatuses.OfferAccepted">
        <mat-expansion-panel-header>
          <div fxLayout="row" fxLayoutAlign="center center">
            <mat-panel-title> Offer Accepted </mat-panel-title>
            <mat-panel-description fxLayoutAlign="end">
              <mat-icon *ngIf="!accepted.expanded">keyboard_arrow_down</mat-icon>
              <mat-icon *ngIf="accepted.expanded">keyboard_arrow_up</mat-icon>
            </mat-panel-description>
          </div>
        </mat-expansion-panel-header>
        <p>You’ve accepted the offer of employment, congratulations!!</p>
      </mat-expansion-panel>

      <mat-expansion-panel class="statusInfo mat-elevation-z0 closed" hideToggle="true" #declined [expanded]="data === portalStatuses.OfferDeclined">
        <mat-expansion-panel-header>
          <div fxLayout="row" fxLayoutAlign="center center">
            <mat-panel-title> Offer Declined </mat-panel-title>
            <mat-panel-description fxLayoutAlign="end">
              <mat-icon *ngIf="!declined.expanded">keyboard_arrow_down</mat-icon>
              <mat-icon *ngIf="declined.expanded">keyboard_arrow_up</mat-icon>
            </mat-panel-description>
          </div>
        </mat-expansion-panel-header>
        <p>Looks like you’ve declined this offer of employment. Keep searching for the job that is a right fit for you!</p>
      </mat-expansion-panel>

      <mat-expansion-panel class="statusInfo mat-elevation-z0 closed" hideToggle="true" #notAvail [expanded]="data === portalStatuses.NoLongerAvailable">
        <mat-expansion-panel-header>
          <div fxLayout="row" fxLayoutAlign="center center">
            <mat-panel-title> No Longer Available </mat-panel-title>
            <mat-panel-description fxLayoutAlign="end">
              <mat-icon *ngIf="!notAvail.expanded">keyboard_arrow_down</mat-icon>
              <mat-icon *ngIf="notAvail.expanded">keyboard_arrow_up</mat-icon>
            </mat-panel-description>
          </div>
        </mat-expansion-panel-header>
        <p>
          This job posting is no longer available. The travel industry moves extremely fast! Did you know that average job order is only open for less than seven days? Keep
          applying to job postings that interest you.
        </p>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>
