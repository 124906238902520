import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CandidateQualification } from 'src/app/common/models/candidate-qualification';

@Component({
  selector: 'app-delete-document-pop-up',
  templateUrl: './delete-document-pop-up.component.html',
  styleUrls: ['./delete-document-pop-up.component.scss']
})
export class DeleteDocumentPopUpComponent implements OnInit {
  item: CandidateQualification;

  constructor(
    private _dialog: MatDialogRef<DeleteDocumentPopUpComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.item = this.data.key;
  }

  cancel(): void {
    this._dialog.close(false);
  }

  onRemove(): void {
    this._dialog.close(true);
  }
}
