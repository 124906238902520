<div fxLayout="row" class="main-content">
  <div class="job-list-area" fxLayout="column" fxFlex>
    <div class="job-list">
      <div *ngFor="let job of jobs" fxLayout="column">
        <app-job-card fxFill="50" [job]="job" [jobConfig]="jobConfig" [showFavoriteIcon]="false"></app-job-card>
      </div>
      <div fxLayoutAlign="center center" class="no-jobs" *ngIf="jobs === undefined || jobs.length === 0">
        <app-empty-jobs fxFlex [header]="'No Matches found'" [text]="'You have not been matched to any jobs yet'">
          <img height="150" src="/assets/img/matched-jobs.svg" />
        </app-empty-jobs>
      </div>
    </div>
  </div>
</div>
