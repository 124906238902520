<div class="content-container">
  <div class="dialog-image">
    <img src="/assets/img/value-carousel/Success.svg" />
  </div>
  <div class="dialog-content">
    <hc-heading> Success! </hc-heading>

    <hc-body>
      Your application for {{ contractType }} {{ specialty.name }} {{ profession.name }} at {{ jobDetails.hospitalInfo.name }} ({{ jobDetails.city }},
      {{ jobDetails.stateName }}) has been submitted for review. You can track your application status on the Applications page.
    </hc-body>

    <hc-body> Please note that local and travel pay estimates are contingent on your tax home address's proximity to the job </hc-body>

    <div class="submit-buttons">
      <hc-button segmentTrackCTA destinationUrl="/submittals" (click)="goToApplications()" [alwaysDisplayFullWidth]="true"> View My Applications </hc-button>
      <hc-button segmentTrackCTA destinationUrl="/job-search" [appearance]="secondaryButtonAppearance" (click)="goToJobSearch()" [alwaysDisplayFullWidth]="true">
        Go to Job Search
      </hc-button>
    </div>
  </div>
</div>
