<ng-container>
  <app-loading-screen-spinner [show]="isSaving || !form?.controls"></app-loading-screen-spinner>
  <div class="registration new-allied-flow">
    <hc-heading [size]="headingSize">Thanks for joining us</hc-heading>
    <hc-body [size]="bodySize"> We're glad you're here. Answer a few questions and find your perfect assignment </hc-body>
    <form class="registration-form" [formGroup]="form" *ngIf="form?.controls">
      <hc-input
        id="firstNameInput"
        label="First Name"
        placeholder="Enter first name"
        [errorMessage]="getErrorMessage(form.controls.firstName, 'First name')"
        [control]="form.controls.firstName"
        [type]="inputType"
        [required]="true"
      ></hc-input>
      <hc-input
        id="lastNameInput"
        label="Last Name"
        placeholder="Enter last name"
        [errorMessage]="getErrorMessage(form.controls.lastName, 'Last name')"
        [control]="form.controls.lastName"
        [type]="inputType"
        [required]="true"
      ></hc-input>
      <hc-masked-input
        id="phoneNumberInput"
        label="Phone Number"
        placeholder="+1"
        [errorMessage]="getErrorMessage(form.controls.mobilePhone, 'Phone number')"
        [control]="form.controls.mobilePhone"
        [type]="inputType"
        [required]="true"
      ></hc-masked-input>
      <div class="tooltip-group">
        <hc-label>Why are we asking for this?</hc-label>
        <mat-icon hcTooltip="Your phone number is needed for account verification purposes."> info </mat-icon>
      </div>
      <hc-radio-button
        id="professionSelectorInput"
        label="Profession Sector*"
        [options]="professionSectorOptions"
        [control]="form.controls.professionSector"
        [displayVertically]="false"
        [errorMessage]="'Please make a selection'"
      ></hc-radio-button>
      <hc-hierarchical-multi-select-checkbox
        #professionHierarchy
        id="professionHierarchyInput"
        label="Profession"
        title="Select your Profession"
        placeholder="Search"
        [required]="true"
        [depth]="professionHierarchyDepth"
        [data]="existingProfessionalHierarchy ?? [{ type: 'Please select a Profession Sector first.' }]"
        [selectMultiple]="false"
        [control]="form.controls.professionHierarchy"
        [errorMessage]="getErrorMessage(form.controls.professionHierarchy, 'Profession')"
        (selectionChanged)="professionChanged()"
      ></hc-hierarchical-multi-select-checkbox>
      <hc-dropdown
        *ngIf="specialties?.length"
        #specialtyDropdown
        id="specialtyInput"
        label="Specialty"
        placeholder="Select your Specialty"
        [errorMessage]="getErrorMessage(form.controls.specialty, 'Specialty')"
        [control]="form.controls.specialty"
        [data]="specialties ?? []"
        [required]="specialtyRequired"
        [isDataPrimitive]="false"
      ></hc-dropdown>
      <hc-radio-button
        label="Do you have prior experience as a traveling medical professional?*"
        [options]="radioOptions"
        [control]="form.controls.travelExperience"
        [displayVertically]="false"
      ></hc-radio-button>
      <hc-dropdown
        id="availabilityInput"
        label="When would you like to start?"
        placeholder="Select Availability"
        [errorMessage]="getErrorMessage(form.controls.availability, 'Availability')"
        [required]="true"
        [control]="form.controls.availability"
        [data]="availabilityData"
        [isDataPrimitive]="true"
      ></hc-dropdown>
      <hc-radio-button label="Did someone refer you to us?*" [options]="referralOptions" [control]="form.controls.referralStatus" [displayVertically]="false"></hc-radio-button>
      <div *ngIf="form.controls.referralStatus.value">
        <hc-input
          id="referralText"
          label="Who referred you?"
          placeholder="Enter referrer's name"
          [control]="form.controls.referralText"
          [errorMessage]="getErrorMessage(form.controls.referralText, 'Referrer\'s name')"
          type="text"
          [required]="true"
        ></hc-input>
      </div>
      <button type="submit" disabled style="display: none" aria-hidden="true"></button>
    </form>

    <hc-button segmentTrackCTA destinationUrl="/job-preferences" id="submitButton" [type]="buttonType" [size]="buttonSize" (buttonClick)="save()" [alwaysDisplayFullWidth]="true"
      >Continue</hc-button
    >
  </div>
</ng-container>
