<ng-container
  *ngIf="{
    jobSubmittalsLoading: jobSubmittalsLoading$ | async,
    submittalCards: submittalCards$ | async,
    canSeeTravel: canSeeTravel$ | async
  } as obs"
>
  <hc-tile-card
    *ngIf="obs.jobSubmittalsLoading || obs.submittalCards?.length"
    [cardConfig]="getCurrentSubmissionTileDetails(obs.canSeeTravel)"
    [templateReference]="submissionJobCarousel"
    (promptClicked)="showFirstInLineModal()"
  >
    <div bodyContent>
      <hc-body [size]="bodySize" *ngIf="obs.canSeeTravel">
        {{ weeklyRatesReminder }}
      </hc-body>
    </div>
  </hc-tile-card>

  <ng-template #submissionJobCarousel>
    <hc-card-carousel
      [isLoading]="obs.jobSubmittalsLoading"
      [skeletonCardsCount]="3"
      [cardData]="obs.submittalCards"
      (cardClicked)="jobCardClicked($event)"
      (cardActionClicked)="submittalCardHelp($event)"
      (cardLinkClicked)="submittalCardLinkClicked($event)"
    ></hc-card-carousel>
  </ng-template>
  <ng-template #filDialogTemplate>
    <app-fil-pop-up></app-fil-pop-up>
  </ng-template>
</ng-container>
