import { LocationActions, ELocationActions } from './location.actions';
import { initialLocationState, LocationState } from './location.state';

export function locationReducer(state = initialLocationState, action: LocationActions): LocationState {
  switch (action.type) {
    case ELocationActions.GetLocation:
      return {
        ...state,
        locationData: {
          ...state.locationData,
          error: null,
          loading: true
        }
      };
    case ELocationActions.GetLocationSuccess:
      return {
        ...state,
        locationData: {
          data: action.location,
          error: null,
          loading: false
        }
      };
    case ELocationActions.GetLocationError:
      return {
        ...state,
        locationData: {
          data: null,
          error: action.error,
          loading: false
        }
      };
    default:
      return state;
  }
}
