import { IRadioButtonOption } from 'hc-design-system-lib/lib/components/form/form.interfaces';
import { ILookup } from '../contracts/lookup';

export function convertIntoRadioGroupData(v: ILookup<string>): IRadioButtonOption {
  return {
    text: v.name,
    value: v.id,
    disabled: false
  };
}
