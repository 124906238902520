export class NurseDocument {
  id: number;
  name: string;
  url: string;
  createdDate: Date;
  isInactive: Boolean;
  category: string;
}

export enum categoryNames {
  BackgroundAndVerifications = 948050001,
  Employment = 948050005,
  FacilitySpecificRequirements = 948050004,
  LicenseAndCertifications = 948050000,
  MedicalAndOccupationalHealth = 948050003,
  SkillsTestingAndTraining = 948050002,
  Other = 0
}
