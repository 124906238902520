import { initialSkillsChecklistState, ISkillsChecklistState } from './skillsChecklist.state';
import { ESkillsChecklistActions, SkillsChecklistActions } from './skillsChecklist.actions';

export function skillsChecklistReducer(state = initialSkillsChecklistState, action: SkillsChecklistActions): ISkillsChecklistState {
  switch (action.type) {
    case ESkillsChecklistActions.GetSkill:
      return {
        ...state,
        skill: {
          data: null,
          loading: true,
          error: null
        },
        candidateSkill: {
          data: null,
          loading: false,
          error: null
        },
        updateSkillQuestions: {
          data: null,
          error: null,
          loading: false
        }
      };
    case ESkillsChecklistActions.GetSkillSuccess:
      return {
        ...state,
        skill: {
          data: action.response,
          loading: false,
          error: null
        }
      };
    case ESkillsChecklistActions.GetSkillError:
      return {
        ...state,
        skill: {
          data: null,
          loading: false,
          error: action.error
        }
      };

    case ESkillsChecklistActions.GetSkills:
      return {
        ...state,
        skills: {
          data: null,
          error: null,
          loading: true
        }
      };
    case ESkillsChecklistActions.GetSkillsSuccess:
      return {
        ...state,
        skills: {
          data: action.payload,
          error: null,
          loading: false
        }
      };
    case ESkillsChecklistActions.GetSkillsError:
      return {
        ...state,
        skills: {
          data: null,
          error: action.error,
          loading: false
        }
      };

    case ESkillsChecklistActions.GetCandidateSkill:
      return {
        ...state,
        candidateSkill: {
          data: null,
          loading: true,
          error: null
        }
      };
    case ESkillsChecklistActions.GetCandidateSkillSuccess:
      return {
        ...state,
        candidateSkill: {
          data: action.response,
          loading: false,
          error: null
        }
      };
    case ESkillsChecklistActions.GetCandidateSkillError:
      return {
        ...state,
        candidateSkill: {
          data: null,
          loading: false,
          error: action.error
        }
      };

    case ESkillsChecklistActions.GetCandidateSkills:
      return {
        ...state,
        candidateSkills: {
          data: null,
          error: null,
          loading: true
        }
      };
    case ESkillsChecklistActions.GetCandidateSkillsSuccess:
      return {
        ...state,
        candidateSkills: {
          data: action.payload,
          error: null,
          loading: false
        }
      };
    case ESkillsChecklistActions.GetCandidateSkillsError:
      return {
        ...state,
        candidateSkills: {
          data: null,
          error: action.error,
          loading: false
        }
      };

    case ESkillsChecklistActions.GetSkillDictionary:
      return {
        ...state,
        skillDictionary: {
          data: null,
          error: null,
          loading: true
        }
      };
    case ESkillsChecklistActions.GetSkillDictionarySuccess:
      return {
        ...state,
        skillDictionary: {
          data: action.payload,
          error: null,
          loading: false
        }
      };
    case ESkillsChecklistActions.GetSkillDictionaryError:
      return {
        ...state,
        skillDictionary: {
          data: null,
          error: action.error,
          loading: false
        }
      };

    case ESkillsChecklistActions.GetNurseApiSkillQuestions:
      return {
        ...state,
        nurseApiSkillQuestions: {
          data: null,
          loading: true,
          error: null
        }
      };
    case ESkillsChecklistActions.GetNurseApiSkillQuestionsSuccess:
      return {
        ...state,
        nurseApiSkillQuestions: {
          data: action.response,
          loading: false,
          error: null
        }
      };
    case ESkillsChecklistActions.GetNurseApiSkillQuestionsError:
      return {
        ...state,
        nurseApiSkillQuestions: {
          data: null,
          loading: false,
          error: action.error
        }
      };

    case ESkillsChecklistActions.UpdateSkillQuestions:
      return {
        ...state,
        updateSkillQuestions: {
          data: null,
          loading: true,
          error: null
        }
      };
    case ESkillsChecklistActions.UpdateSkillQuestionsSuccess:
      return {
        ...state,
        updateSkillQuestions: {
          data: action.response,
          loading: false,
          error: null
        }
      };
    case ESkillsChecklistActions.UpdateSkillQuestionsError:
      return {
        ...state,
        updateSkillQuestions: {
          data: null,
          loading: false,
          error: action.error
        }
      };

    case ESkillsChecklistActions.UpdateSkillDictionaryFilter:
      return {
        ...state,
        skillDictionaryFilter: {
          data: action.filter,
          error: null,
          loading: true
        }
      };

    default:
      return state;
  }
}
