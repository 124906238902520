import { AfterContentChecked, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { CardElevation, DialogService, IDialogParameters } from 'hc-design-system-lib';
import { IFacilityCardConfig } from 'hc-design-system-lib/lib/components/cards/cards.interfaces';
import { HcEvent } from 'hc-design-system-lib/lib/models/hc-event';
import { combineLatest, Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { PostFacilityApplication } from 'src/app/store/facilities/facilities.actions';
import {
  selectCandidateFacilitiesCount,
  selectCandidateFacilitiesLoading,
  selectFacilityApplicationsLoading,
  selectFacilityCardConfigs,
  selectFacilityParameters
} from 'src/app/store/facilities/facilities.selectors';
import { FacilityProximitySearch } from '../../../common/contracts/facility-proximity-search';
import { HeadingSize } from 'hc-design-system-lib/lib/typography/components/heading/heading.component';
import { selectIsMobile } from '../../../store/ui/ui.selectors';
import { NavHelper } from '../../../services';
import { SetJobFilterV2 } from '../../../store/jobs/jobs.actions';

@Component({
  selector: 'app-facilities-carousel',
  templateUrl: './facilities-carousel.component.html',
  styleUrls: ['./facilities-carousel.component.scss']
})
export class FacilitiesCarouselComponent implements OnInit, OnDestroy, AfterContentChecked {
  @ViewChild('popupTemplate') popupTemplate: TemplateRef<any>;

  // inputs
  @Input() successful = true;

  // constants
  grid = {
    xs: 1,
    sm: 1,
    md: 1,
    lg: 1,
    xl: 1,
    all: 0
  };
  gridBreakpoints = {
    sm: 768,
    md: 992,
    lg: 1200,
    xl: 1350
  };
  h6 = HeadingSize.H6;

  // variables
  facilityCards: IFacilityCardConfig[] = [];
  facilityParameters: FacilityProximitySearch = {
    location: null,
    facility: null
  };

  // selectors
  facilitiesLoading$: Observable<boolean> = this._store.select(selectCandidateFacilitiesLoading);
  facilityApplicationsLoading$: Observable<boolean> = this._store.select(selectFacilityApplicationsLoading);
  facilityCardConfigs$: Observable<IFacilityCardConfig[]> = this._store.select(selectFacilityCardConfigs);
  facilityParameters$: Observable<FacilityProximitySearch> = this._store.select(selectFacilityParameters);
  candidateFacilitiesCount$: Observable<number> = this._store.select(selectCandidateFacilitiesCount);
  isMobile$: Observable<boolean> = this._store.select(selectIsMobile);

  // subscriptions
  readonly destroy$: Subject<void> = new Subject<void>();

  constructor(
    public _store: Store,
    private _dialogService: DialogService,
    private ref: ChangeDetectorRef,
    private _navHelper: NavHelper
  ) {}

  ngOnInit(): void {
    this.addFacilitiesSubscription();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  // Resolves ExpressionChangedAfterItHasBeenCheckedError error in testing
  ngAfterContentChecked() {
    this.ref.detectChanges();
  }

  addFacilitiesSubscription() {
    combineLatest([this.facilitiesLoading$, this.facilityApplicationsLoading$, this.facilityCardConfigs$, this.facilityParameters$])
      .pipe(
        filter(
          ([facilitiesLoading, facilityApplicationsLoading, facilityCardConfigs]) => !facilitiesLoading && !facilityApplicationsLoading && facilityCardConfigs?.length >= 0
        ),
        takeUntil(this.destroy$)
      )
      .subscribe(([, , facilityCardConfigs, facilityParameters]) => {
        this.facilityCards = facilityCardConfigs;
        this.facilityParameters = facilityParameters;
      });
  }

  postFacilityApplication(event?: HcEvent) {
    const facilityId = event ? event?.data?.facilityCardData?.facilityCardConfig['facilityDetails']?.id : this.facilityParameters.facility;
    if (facilityId) {
      const dialogData: IDialogParameters = {
        title: `Let the facility know you're interested`,
        text: undefined,
        showCloseIcon: true,
        template: this.popupTemplate,
        icon: undefined,
        width: '344px',
        elevation: CardElevation.Default,
        isResponsive: false,
        options: {
          primary: { returnValue: 'true', text: 'Send profile to the facility' },
          secondary: { returnValue: 'false', text: 'Cancel' }
        }
      };
      this._dialogService.showDialog(dialogData).subscribe(response => {
        if (response === 'true') {
          this._store.dispatch(new PostFacilityApplication(facilityId));
        }
      });
    }
  }

  getFacilityById(id: string) {
    return this.facilityCards.find(card => card.facilityDetails.id === id) ?? this.facilityCards[0];
  }

  backToSearch() {
    this._navHelper.goToJobSearch();
    // for job search v2
    this._store.dispatch(new SetJobFilterV2(null));
  }
}
