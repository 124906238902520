<ng-container
  *ngIf="{
    technologyResultLoading: techLoading$ | async,
    technologyList: techList$ | async
  } as observables"
>
  <app-loading-screen-spinner [show]="observables.technologyResultLoading"></app-loading-screen-spinner>
  <div fxLayout="column" fxLayoutAlign="space-between" class="full-height tasks-container">
    <div>
      <div class="tasks-desc-section">
        <hc-body>Please add your Electronic Medical Record (EMR) software experience.</hc-body>
      </div>
      <div class="tasks-add-button">
        <hc-icon-prompt-button segmentTrackCTA [ctaText]="'Add EMR Software'" id="technologyListAdd" (click)="editTechnology()" icon="add_circle">
          Add EMR Software
        </hc-icon-prompt-button>
      </div>
      <div class="tasks-list-section">
        <hc-record-card *ngFor="let item of observables.technologyList" (cardClicked)="editTechnology(item)" [recordCardConfig]="getRecordCardConfig(item)"> </hc-record-card>
      </div>
    </div>
    <app-flow-handler [isListPage]="true" [showRegularSaveButton]="false"></app-flow-handler>
  </div>
  <ng-template #technologyModal>
    <app-technology-modal></app-technology-modal>
  </ng-template>
</ng-container>
