<ng-template #loginErrorTemplate>
  <div class="login-error-container">
    <div class="text-container">
      <mat-icon class="error-icon">error</mat-icon>
      <div class="text-container">
        <hc-heading [size]="heading3">Login Error</hc-heading>
        <hc-body>
          We encountered a problem accessing or creating your account. <span style="font-weight: bold">Please contact your current representative</span> to resolve this issue.
        </hc-body>
      </div>
    </div>

    <hc-button [appearance]="buttonAppearance" (buttonClick)="logout()">Close</hc-button>
  </div>
</ng-template>
