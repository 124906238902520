import { ETasksActions, TasksActions } from './tasks.actions';
import { initialTasksState, TasksState } from './tasks.state';

export function tasksReducer(state = initialTasksState, action: TasksActions): TasksState {
  switch (action.type) {
    case ETasksActions.GetTasks:
      return {
        ...state,
        tasks: {
          ...state.tasks,
          error: null,
          loading: true,
          expirationDate: null
        }
      };
    case ETasksActions.GetTasksSuccess:
      const previousData = state.tasks.data ? [...state.tasks.data] : null;
      return {
        ...state,
        tasks: {
          data: action.payload.tasks,
          error: null,
          loading: false,
          expirationDate: action.payload.expiration,
          previousData: previousData
        }
      };
    case ETasksActions.GetTasksError:
      return {
        ...state,
        tasks: {
          ...state.tasks,
          error: action.error,
          loading: false,
          expirationDate: null
        }
      };

    case ETasksActions.GetFluVaccine:
      return {
        ...state,
        fluVaccine: {
          ...state.fluVaccine,
          error: null,
          loading: true
        }
      };
    case ETasksActions.GetFluVaccineSuccess:
      return {
        ...state,
        fluVaccine: {
          data: action.task,
          error: null,
          loading: false
        },
        updateFluVaccine: {
          data: null,
          error: null,
          loading: false
        }
      };
    case ETasksActions.GetFluVaccineError:
      return {
        ...state,
        fluVaccine: {
          ...state.fluVaccine,
          error: action.error,
          loading: false
        }
      };

    case ETasksActions.UploadFluVaccine:
      return {
        ...state,
        updateFluVaccine: {
          ...state.fluVaccine,
          error: null,
          loading: true
        }
      };
    case ETasksActions.UploadFluVaccineSuccess:
      return {
        ...state,
        updateFluVaccine: {
          data: action.payload,
          error: null,
          loading: false
        },
        fluVaccine: {
          ...state.fluVaccine,
          data: action.payload
        }
      };
    case ETasksActions.UploadFluVaccineError:
      return {
        ...state,
        updateFluVaccine: {
          ...state.fluVaccine,
          error: action.error,
          loading: false
        }
      };

    case ETasksActions.GetCovidVaccine:
      return {
        ...state,
        covidVaccine: {
          ...state.covidVaccine,
          error: null,
          loading: true
        }
      };
    case ETasksActions.UploadCovidVaccine:
      return {
        ...state,
        updateCovidVaccine: {
          ...state.covidVaccine,
          error: null,
          loading: true
        }
      };
    case ETasksActions.GetCovidVaccineSuccess:
      return {
        ...state,
        covidVaccine: {
          data: action.task,
          error: null,
          loading: false
        },
        updateCovidVaccine: {
          data: null,
          error: null,
          loading: false
        }
      };
    case ETasksActions.GetCovidVaccineError:
      return {
        ...state,
        covidVaccine: {
          ...state.covidVaccine,
          error: action.error,
          loading: false
        }
      };

    case ETasksActions.UploadCovidVaccineError:
      return {
        ...state,
        updateCovidVaccine: {
          ...state.covidVaccine,
          error: action.error,
          loading: false
        }
      };

    case ETasksActions.UploadCovidVaccineSuccess:
      return {
        ...state,
        updateCovidVaccine: {
          data: action.payload,
          error: null,
          loading: false
        },
        covidVaccine: {
          ...state.covidVaccine,
          data: action.payload
        }
      };

    default:
      return state;
  }
}
