import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormArray, UntypedFormControl } from '@angular/forms';
import { ILookup } from 'src/app/common';

@Component({
  selector: 'app-checkbox-form-array',
  templateUrl: './checkbox-form-array.component.html',
  styleUrls: ['./checkbox-form-array.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CheckboxFormArrayComponent implements OnInit {
  // Form Array from parent form
  @Input() checkboxFormArray: UntypedFormArray;
  // Lookup values | all options available to check
  @Input() lookup: ILookup<number | string>[];
  // Checked checkboxes based on id array passed in
  @Input() arrayValues: number[];

  @Input() newStyling: boolean = false;

  checkboxItemArray: CheckboxValue[] = [];

  ngOnInit(): void {
    this.lookup.forEach(o => {
      const id = Number(o.id);
      const checkbox: CheckboxValue = {
        id: id,
        name: o.name,
        value: this.arrayValues[id] === id
      };
      this.checkboxItemArray.push(checkbox);
    });
    // When loading a second time we dont want to push the same values again.
    // Length is 0 at the initialization but not when opening again with filters
    if (this.checkboxFormArray.length === 0) {
      this.checkboxItemArray.forEach(o => {
        if (this.arrayValues) {
          if (this.arrayValues.indexOf(o.id) !== -1) {
            o.value = true;
          }
        }
      });
      this.checkboxItemArray.forEach(o => {
        this.checkboxFormArray.push(new UntypedFormControl(o.value));
      });
    }
  }

  updateCheckbox(event: any, index: number, formArray: UntypedFormArray) {
    formArray[index].value = event.eventValue;
  }
}

export class CheckboxValue {
  id: number;
  name: string;
  value: boolean;
}
