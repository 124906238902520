import moment from 'moment';

export function getDateInFutureWithMinutes(minutes: number = 1): Date {
  return moment(new Date()).add(minutes, 'm').toDate();
}

export function checkIfDateIsAfter(dateToCheck: Date, dateToCheckAgainst: Date): boolean {
  if (dateToCheck && dateToCheckAgainst) {
    const toCheckMoment = moment(dateToCheck);
    const checkAgainstMoment = moment(dateToCheckAgainst);
    return toCheckMoment.isAfter(checkAgainstMoment);
  } else if (!dateToCheckAgainst) {
    return true;
  } else {
    return false;
  }
}
