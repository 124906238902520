import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { ILookup, NurseProfileModel, Reference } from 'src/app/common';
import { AppState, NursePortalApi } from 'src/app/services';
import {
  selectProfessionLookup,
  selectPositionTitleLookup,
  selectPerformanceScaleLookup,
  selectYesNoLookup,
  selectStateLookup,
  selectLookupsLoading
} from 'src/app/store/lookups/lookups.selectors';
import { Store } from '@ngrx/store';
import { IAppState } from 'src/app/store/app/app.state';
import { Subscription, Observable, combineLatest } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-reference-questionnaire',
  templateUrl: './reference-questionnaire.component.html',
  styleUrls: ['./reference-questionnaire.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ReferenceQuestionnaireComponent implements OnInit, OnDestroy {
  form: UntypedFormGroup;
  states: Array<ILookup<string>>;
  professions: Array<ILookup<string>>;
  performanceScale: Array<ILookup<string>>;
  reference: Reference;
  contact: NurseProfileModel;
  professionLookup: Map<string, ILookup<string>>;
  stateLookup: Map<string, ILookup<string>>;
  yesNoLookup: Map<string, ILookup<string>>;
  performanceScaleLookup: Map<string, ILookup<string>>;
  positionTitleLookup: Map<number, any>;

  createFormSubscription: Subscription;
  lookupsLoading$: Observable<boolean> = this._store.select(selectLookupsLoading);

  professionLookupSubscription: Subscription;
  professionLookup$: Observable<Map<string, ILookup<string>>> = this._store.select(selectProfessionLookup);

  stateLookupSubscription: Subscription;
  stateLookup$: Observable<Map<string, ILookup<string>>> = this._store.select(selectStateLookup);

  yesNoLookupSubscription: Subscription;
  yesNoLookup$: Observable<Map<string, ILookup<string>>> = this._store.select(selectYesNoLookup);

  performanceScaleLookupSubscription: Subscription;
  performanceScaleLookup$: Observable<Map<string, ILookup<string>>> = this._store.select(selectPerformanceScaleLookup);

  positionTitleLookupSubscription: Subscription;
  positionTitleLookup$: Observable<Map<number, any>> = this._store.select(selectPositionTitleLookup);

  isInvalid = false;
  errorMessage: string;
  positionTitles: any[];
  yesNo: any[];
  constructor(
    private _route: ActivatedRoute,
    private _fb: UntypedFormBuilder,
    private _api: NursePortalApi,
    private _state: AppState,
    private _store: Store<IAppState>
  ) {}

  ngOnInit() {
    this.addProfessionLookupSubscription();
    this.addStateLookupSubscription();
    this.addYesNoLookupSubscription();
    this.addPerformanceScaleLookupSubscription();
    this.addPositionTitleLookupSubscription();
    this._state.setSpinnerState(false);
    this.reference = this._route.snapshot.data['data'][0];
    this.contact = this._route.snapshot.data['data'][1];
    if (typeof this._route.snapshot.data['data'][0] === typeof 'string') {
      this.isInvalid = true;
      this.errorMessage = this._route.snapshot.data['data'][0];
    }
    this.createFormSubscription = combineLatest([
      this.lookupsLoading$,
      this.professionLookup$,
      this.stateLookup$,
      this.yesNoLookup$,
      this.performanceScaleLookup$,
      this.positionTitleLookup$
    ])
      .pipe(
        filter(
          ([loading, , , , ,]: [
            boolean,
            Map<string, ILookup<string>>,
            Map<string, ILookup<string>>,
            Map<string, ILookup<string>>,
            Map<string, ILookup<string>>,
            Map<number, any>
          ]) => loading === false
        )
      )
      .subscribe(() => this._createForm());
  }

  ngOnDestroy(): void {
    this.professionLookupSubscription?.unsubscribe();
    this.stateLookupSubscription?.unsubscribe();
    this.yesNoLookupSubscription?.unsubscribe();
    this.performanceScaleLookupSubscription?.unsubscribe();
    this.positionTitleLookupSubscription?.unsubscribe();
  }

  addPositionTitleLookupSubscription(): void {
    this.positionTitleLookupSubscription = this.positionTitleLookup$.subscribe(positionTitleLookup => {
      this.positionTitleLookup = positionTitleLookup;
      if (this.positionTitleLookup) {
        this.positionTitles = Array.from(this.positionTitleLookup.values());
      }
    });
  }

  addPerformanceScaleLookupSubscription(): void {
    this.performanceScaleLookupSubscription = this.performanceScaleLookup$.subscribe(performanceScaleLookup => {
      this.performanceScaleLookup = performanceScaleLookup;
      if (this.performanceScaleLookup) {
        this.performanceScale = Array.from(this.performanceScaleLookup.values());
      }
    });
  }

  addYesNoLookupSubscription(): void {
    this.yesNoLookupSubscription = this.yesNoLookup$.subscribe(yesNoLookup => {
      this.yesNoLookup = yesNoLookup;
      if (this.yesNoLookup) {
        this.yesNo = Array.from(this.yesNoLookup.values()).filter(o => o.name !== 'NA');
      }
    });
  }

  addProfessionLookupSubscription(): void {
    this.professionLookupSubscription = this.professionLookup$.subscribe(professionLookup => {
      this.professionLookup = professionLookup;
      if (this.professionLookup) {
        this.professions = Array.from(professionLookup.values());
      }
    });
  }

  addStateLookupSubscription(): void {
    this.stateLookupSubscription = this.stateLookup$.subscribe(stateLookup => {
      this.stateLookup = stateLookup;
      if (this.stateLookup) {
        this.states = Array.from(this.stateLookup.values());
      }
    });
  }

  private _createForm() {
    this.form = this._fb.group({
      id: new UntypedFormControl(this.reference.id, []),
      candidateId: new UntypedFormControl(this.reference.candidateId, []),
      fullName: new UntypedFormControl(`${this.reference.firstName} ${this.reference.lastName}`, []),
      positionCode: new UntypedFormControl({ value: this.reference.positionCode, disabled: true }, []),
      email: new UntypedFormControl(this.reference.email, []),
      phone: new UntypedFormControl(this.reference.phone, []),
      candidateFullName: new UntypedFormControl(this.contact.firstName + ' ' + this.contact.lastName, []),
      candidateEmail: new UntypedFormControl(this.contact.email, []),
      candidatePhone: new UntypedFormControl(this.contact.mobilePhone, []),
      facilityName: new UntypedFormControl(this.reference.facilityName),
      facilityCity: new UntypedFormControl(this.reference.facilityCity),
      facilityState: new UntypedFormControl({ value: this.reference.facilityState, disabled: true }, []),
      startDate: new UntypedFormControl({ value: this.reference.startDate, disabled: false }, []),
      endDate: new UntypedFormControl({ value: this.reference.endDate, disabled: false }, []),
      candidateProfession: new UntypedFormControl({ value: this.contact.profession, disabled: true }, []),
      clinicalCompetence: new UntypedFormControl(this.reference.clinicalCompetence, [Validators.required]),
      patientCare: new UntypedFormControl(this.reference.followsPlanOfPatientcare, [Validators.required]),
      safety: new UntypedFormControl(this.reference.safety, [Validators.required]),
      policies: new UntypedFormControl(this.reference.policies, [Validators.required]),
      quality: new UntypedFormControl(this.reference.quality, [Validators.required]),
      adaptability: new UntypedFormControl(this.reference.adaptability, [Validators.required]),
      communication: new UntypedFormControl(this.reference.communication, [Validators.required]),
      attendance: new UntypedFormControl(this.reference.attendance, [Validators.required]),
      attitude: new UntypedFormControl(this.reference.attitude, [Validators.required]),
      workWithOthers: new UntypedFormControl(this.reference.workWithOthers, [Validators.required]),
      professionalism: new UntypedFormControl(this.reference.professionalism, [Validators.required]),
      comments: new UntypedFormControl(this.reference.comments),
      workHistoryId: new UntypedFormControl(this.reference.workHistoryId),
      currentPosition: new UntypedFormControl('', [Validators.required]),
      rehire: new UntypedFormControl('', [Validators.required]),
      hireFire: new UntypedFormControl('', [])
    });
  }

  onSubmit(): void {
    const model = this._prepareSave();
    this._api.updateReferenceForQuestionnaire(model).subscribe(o => {});
    this.isInvalid = true;
    this.errorMessage = 'Thank you for submitting your Questionnaire';
  }

  close(): void {
    location.reload();
  }

  private _prepareSave(): Reference {
    const yes = this.yesNoLookup.get('Yes').id;
    const no = this.yesNoLookup.get('No').id;

    const model = this.form.getRawValue() as Reference;
    model.firstName = this.reference.firstName;
    model.lastName = this.reference.lastName;
    model.hireFire = model.hireFire === true ? yes : no;

    return model;
  }
}
