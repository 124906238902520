import { Action } from '@ngrx/store';
import { Hospital, ILookup } from 'src/app/common';
import { CandidateSkill } from 'src/app/common/models/candidate-skill';
import { Skill } from 'src/app/common/models/skill';
import { UserTraits } from 'src/app/common/models/user-traits';

export enum ESegmentActions {
  SegmentReady = '[SEGMENT] segment ready',
  SegmentIdentify = '[SEGMENT] report segment identify',
  SegmentSoftRegister = '[SEGMENT] report segment soft register',
  OnboardingStepViewed = '[SEGMENT] report segment onboarding step viewed',
  OnboardingStepCompleted = '[SEGMENT] report segment onboarding step completed',
  OnboardingCompleted = '[SEGMENT] report segment onboarding completed',
  FacilityViewed = '[SEGMENT] report segment facility viewed',
  SavedJobsViewed = '[SEGMENT] report segment saved jobs viewed',
  NavigationItemClicked = '[SEGMENT] report basic navigation item clicked',
  PhoneNumberClicked = '[SEGMENT] report phone number clicked',
  CallToActionClicked = '[SEGMENT] report call to action clicked',
  ExternalLinkClicked = '[SEGMENT] report external link clicked',
  InternalLinkClicked = '[SEGMENT] report internal link clicked',
  SignedUp = '[SEGMENT] report signed up',
  SignedIn = '[SEGMENT] report signed in',
  SignedOut = '[SEGMENT] report signed out',
  ProfileTasksViewed = '[SEGMENT] report profile tasks viewed',
  TasksViewed = '[SEGMENT] report tasks viewed',
  LicenseAdded = '[SEGMENT] report license added',
  LicensesViewed = '[SEGMENT] report license viewed',
  LicenseDeleted = '[SEGMENT] report license deleted',
  LicenseVerified = '[SEGMENT] report license verified',
  DocumentUploaded = '[SEGMENT] report document uploaded',
  EducationDeleted = '[SEGMENT] report education deleted',
  EducationsViewed = '[SEGMENT] report educations viewed',
  WorkHistoryAdded = '[SEGMENT] report work history added',
  WorkHistoriesViewed = '[SEGMENT] report work histories viewed',
  SkillChecklistViewed = '[SEGMENT] report skill checklist viewed',
  SkillChecklistCompleted = '[SEGMENT] report skill checklist completed',
  ApplicationStarted = '[SEGMENT] application started',
  ApplicationsViewed = '[SEGMENT] applications viewed',
  TechnologiesViewed = '[SEGMENT] technologies viewed',
  TechnologyAdded = '[SEGMENT] technology added',
  TechnologyDeleted = '[SEGMENT] technology deleted'
}

export class SegmentReady implements Action {
  public readonly type = ESegmentActions.SegmentReady;
}

export class SegmentIdentify implements Action {
  public readonly type = ESegmentActions.SegmentIdentify;

  constructor(
    public id: string,
    public traits?: UserTraits
  ) {}
}

export class SegmentSoftRegister implements Action {
  public readonly type = ESegmentActions.SegmentSoftRegister;

  constructor(public email: string) {}
}

export class OnboardingStepViewed implements Action {
  public readonly type = ESegmentActions.OnboardingStepViewed;

  constructor(
    public payload: {
      stepName: string;
      stepNumber: number;
    }
  ) {}
}

export class OnboardingStepCompleted implements Action {
  public readonly type = ESegmentActions.OnboardingStepCompleted;

  constructor(
    public payload: {
      stepName: string;
      stepNumber: number;
      skipped: boolean;
    }
  ) {}
}

export class OnboardingCompleted implements Action {
  public readonly type = ESegmentActions.OnboardingCompleted;

  constructor(
    public payload: {
      stepName: string;
      stepNumber: number;
    }
  ) {}
}

export class FacilityViewed implements Action {
  public readonly type = ESegmentActions.FacilityViewed;

  constructor(public payload: Hospital) {}
}

export class NavigationItemClicked implements Action {
  public readonly type = ESegmentActions.NavigationItemClicked;

  constructor(
    public payload: {
      navigationText: string;
      navigationPlacement: string;
      navigationUrl: string;
    }
  ) {}
}

export class PhoneNumberClicked implements Action {
  public readonly type = ESegmentActions.PhoneNumberClicked;

  constructor(public payload: { location: string; phoneType: string; phoneNumber: string }) {}
}

export class CallToActionClicked implements Action {
  public readonly type = ESegmentActions.CallToActionClicked;

  constructor(
    public payload: {
      pageURL: string;
      pageTitle: string;
      destinationURL: string;
      ctaText: string;
    }
  ) {}
}

export class ExternalLinkClicked implements Action {
  public readonly type = ESegmentActions.ExternalLinkClicked;

  constructor(
    public pageURL: string,
    public ctaText: string,
    public clickURL: string
  ) {}
}

export class InternalLinkClicked implements Action {
  public readonly type = ESegmentActions.InternalLinkClicked;

  constructor(
    public pageURL: string,
    public ctaText: string,
    public clickURL: string
  ) {}
}

export class SignedUp implements Action {
  public readonly type = ESegmentActions.SignedUp;

  constructor() {}
}

export class SignedIn implements Action {
  public readonly type = ESegmentActions.SignedIn;

  constructor() {}
}

export class SignedOut implements Action {
  public readonly type = ESegmentActions.SignedOut;

  constructor() {}
}

export class ProfileTasksViewed implements Action {
  public readonly type = ESegmentActions.ProfileTasksViewed;

  constructor() {}
}

export class TasksViewed implements Action {
  public readonly type = ESegmentActions.TasksViewed;

  constructor(public tasksType: 'pay' | 'apply' | 'all') {}
}

export class LicenseAdded implements Action {
  public readonly type = ESegmentActions.LicenseAdded;

  constructor() {}
}

export class LicensesViewed implements Action {
  public readonly type = ESegmentActions.LicensesViewed;

  constructor() {}
}

export class LicenseDeleted implements Action {
  public readonly type = ESegmentActions.LicenseDeleted;

  constructor(public itemID: string) {}
}

export class LicenseVerified implements Action {
  public readonly type = ESegmentActions.LicenseVerified;

  constructor() {}
}

export class DocumentUploaded implements Action {
  public readonly type = ESegmentActions.DocumentUploaded;

  constructor(
    public payload: {
      documentId: string;
      documentName: string;
      documentType: string;
      documentURL: string;
    }
  ) {}
}

export class EducationsViewed implements Action {
  public readonly type = ESegmentActions.EducationsViewed;

  constructor() {}
}

export class EducationDeleted implements Action {
  public readonly type = ESegmentActions.EducationDeleted;

  constructor(public itemID: string) {}
}

export class WorkHistoryAdded implements Action {
  public readonly type = ESegmentActions.WorkHistoryAdded;
  constructor(
    public employerName: string,
    public employerCity: string,
    public employerState: string,
    public positionTitle: string,
    public specialty: string,
    public employmentType: string,
    public patientRatio: string,
    public startDate: Date,
    public endDate: Date,
    public currentPosition: string,
    public jobDescription: string
  ) {}
}

export class WorkHistoriesViewed implements Action {
  public readonly type = ESegmentActions.WorkHistoriesViewed;
  constructor() {}
}
export class SavedJobsViewed implements Action {
  public readonly type = ESegmentActions.SavedJobsViewed;

  constructor() {}
}

export class SkillChecklistViewed implements Action {
  public readonly type = ESegmentActions.SkillChecklistViewed;

  constructor(public payload: Skill | ILookup<string>) {}
}

export class SkillChecklistCompleted implements Action {
  public readonly type = ESegmentActions.SkillChecklistCompleted;

  constructor(public payload: Skill | CandidateSkill | ILookup<string>) {}
}

export class ApplicationStarted implements Action {
  public readonly type = ESegmentActions.ApplicationStarted;
  public jobId: string;

  constructor(jobId: string) {
    this.jobId = jobId;
  }
}

export class ApplicationsViewed implements Action {
  public readonly type = ESegmentActions.ApplicationsViewed;
  public numberOfApplications: number;

  constructor(numberOfApplications: number) {
    this.numberOfApplications = numberOfApplications;
  }
}

export class TechnologiesViewed implements Action {
  public readonly type = ESegmentActions.TechnologiesViewed;

  constructor() {}
}

export class TechnologyAdded implements Action {
  public readonly type = ESegmentActions.TechnologyAdded;

  constructor(public payload: { technologySkill: string; proficiency: string }) {}
}

export class TechnologyDeleted implements Action {
  public readonly type = ESegmentActions.TechnologyDeleted;

  constructor(public itemId: string) {}
}

export type SegmentActions =
  | SegmentReady
  | SegmentIdentify
  | SegmentSoftRegister
  | OnboardingStepViewed
  | OnboardingStepCompleted
  | OnboardingCompleted
  | FacilityViewed
  | SavedJobsViewed
  | NavigationItemClicked
  | CallToActionClicked
  | PhoneNumberClicked
  | ExternalLinkClicked
  | InternalLinkClicked
  | SignedUp
  | SignedIn
  | SignedOut
  | ProfileTasksViewed
  | TasksViewed
  | LicenseAdded
  | LicensesViewed
  | LicenseDeleted
  | LicenseVerified
  | DocumentUploaded
  | EducationDeleted
  | EducationsViewed
  | WorkHistoryAdded
  | WorkHistoriesViewed
  | SkillChecklistViewed
  | SkillChecklistCompleted
  | ApplicationStarted
  | ApplicationsViewed
  | TechnologiesViewed
  | TechnologyAdded
  | TechnologyDeleted;
