import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, Subject, combineLatest } from 'rxjs';
import { skipWhile, takeUntil, tap } from 'rxjs/operators';
import { BodySize, CardElevation, DialogService, HeadingSize, IDialogParameters, TaskStatus } from 'hc-design-system-lib';
import { ButtonAppearance } from 'hc-design-system-lib/lib/components/button/button.enums';
import { FormHeaderService, NavHelper } from 'src/app/services';
import { IJob, ILookup } from 'src/app/common';
import { NurseTask } from 'src/app/common/models/db-objects';
import { TaskStatusConstants } from 'src/app/common/models/task-status';
import { IAppState } from 'src/app/store/app/app.state';
import { GetSpecificJob } from 'src/app/store/jobs/jobs.actions';
import { selectApplyProcessSaving, selectApplyToJobResult, selectSpecificJob, selectSpecificJobLoading } from 'src/app/store/jobs/jobs.selectors';
import { selectProfessionLookup, selectSpecialtyLookup } from 'src/app/store/lookups/lookups.selectors';
import { GetTasks } from 'src/app/store/tasks/tasks.actions';
import { selectApplyTasks, selectTasksLoading } from 'src/app/store/tasks/tasks.selectors';
import { ApplicationReviewComponent } from '../application-review/application-review.component';
import { TaskNavigatorService } from 'src/app/services/task-navigator.service';

@Component({
  selector: 'app-application-tasks',
  templateUrl: './application-tasks.component.html',
  styleUrls: ['./application-tasks.component.scss']
})
export class ApplicationTasksComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();

  @ViewChild('reviewApplicationDialogTemplate')
  reviewApplicationDialogTemplate: TemplateRef<ApplicationReviewComponent>;

  @ViewChild('applicationWarningTemplate')
  applicationWarningDialogTemplate: TemplateRef<any>;

  @ViewChild('applicationSavedTemplate')
  applicationSavedDialogTemplate: TemplateRef<any>;

  readonly mapStatus = new Map<TaskStatusConstants, TaskStatus>([
    [TaskStatusConstants.inProgress, TaskStatus.InProgress],
    [TaskStatusConstants.pending, TaskStatus.Pending],
    [TaskStatusConstants.complete, TaskStatus.Completed]
  ]);

  primaryButtonAppearance = ButtonAppearance.Primary;
  secondaryButtonAppearance = ButtonAppearance.Secondary;
  bodySize = BodySize.Body;
  bodySizeSmall = BodySize.Small;
  bodySizeMicro = BodySize.Micro;
  headingSizeH6 = HeadingSize.H6;
  headingSizeH5 = HeadingSize.H5;

  applicationTasks$: Observable<NurseTask[]> = this._store.select(selectApplyTasks);
  tasksLoading$: Observable<boolean> = this._store.select(selectTasksLoading);
  professionLookup$: Observable<Map<string, ILookup<string>>> = this._store.select(selectProfessionLookup);
  specialtyLookup$: Observable<Map<string, ILookup<string>>> = this._store.select(selectSpecialtyLookup);

  applicationResult$: Observable<any> = this._store.select(selectApplyToJobResult);
  applicationSaving$: Observable<boolean> = this._store.select(selectApplyProcessSaving);

  specificJob$: Observable<IJob> = this._store.select(selectSpecificJob).pipe(tap(job => this.populateSpecificJob(job)));
  specificJobLoading$: Observable<boolean> = this._store.select(selectSpecificJobLoading);

  jobName: string;
  navString: string;

  constructor(
    private _route: ActivatedRoute,
    private _store: Store<IAppState>,
    private _dialogService: DialogService,
    private _location: Location,
    private _navHelper: NavHelper,
    private _formHeaderService: FormHeaderService,
    private _taskNav: TaskNavigatorService
  ) {}

  ngOnInit(): void {
    this._store.dispatch(new GetTasks(true));

    combineLatest([this.specificJob$, this.specificJobLoading$, this.professionLookup$, this.specialtyLookup$])
      .pipe(
        skipWhile(([job, jobLoading]) => jobLoading || !job || (job && !this.specificJobInStoreMatchesParam(job))),
        tap(([job, , professionLookup, specialtyLookup]) => {
          this.setJobName(job, professionLookup, specialtyLookup);

          this._formHeaderService.resetFormHeaderAttributes({
            title: `Apply to ${this.jobName}`,
            backAction: () => this.showApplicationWarningDialog(),
            navAction: navString => {
              this.navString = navString;
              this.showApplicationWarningDialog();
            }
          });
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  populateSpecificJob(job: IJob): void {
    if (!this.specificJobInStoreMatchesParam(job)) {
      this._store.dispatch(new GetSpecificJob(this._route.snapshot.params['id']));
    }
  }

  specificJobInStoreMatchesParam(job: IJob): boolean {
    return job?.id.toLowerCase() === this._route.snapshot.params['id'];
  }

  discard() {
    this._dialogService.closeAll();
    if (this.navString) {
      this._formHeaderService.navAction = null;
      this._formHeaderService.backAction = null;
      this._formHeaderService.navigate(this.navString);
    } else {
      this._location.back();
    }
  }

  nonPendingTasks(tasks: NurseTask[]) {
    return tasks?.filter(task => task.status !== TaskStatusConstants.pending);
  }

  pendingTasks(tasks: NurseTask[]) {
    return tasks?.filter(task => task.status === TaskStatusConstants.pending);
  }

  completedTasks(tasks: NurseTask[]) {
    return tasks?.filter(task => task.status === TaskStatusConstants.complete || task.status === TaskStatusConstants.pending);
  }

  getProgressValue(tasks: NurseTask[]): number {
    const totalItems = tasks?.length ?? 0;
    const completedItems = this.completedTasks(tasks)?.length ?? 0;

    if (totalItems && completedItems) {
      return (completedItems / totalItems) * 100;
    } else {
      return 0;
    }
  }

  setJobName(job: IJob, professionLookup: Map<string, ILookup<string>>, specialtyLookup: Map<string, ILookup<string>>): void {
    const profession = professionLookup.get(job.profession);
    const specialty = specialtyLookup.get(job.specialty);
    if (profession && specialty) {
      this.jobName = `${specialty.name} Travel ${profession.shortName}`;
    } else {
      this.jobName = `Travel ${profession?.shortName}`;
    }
  }

  goToApplications(): void {
    this._dialogService.closeAll();
    this._navHelper.goToSubmittals();
  }

  goToSearch(): void {
    this._dialogService.closeAll();
    this._navHelper.goToJobSearch();
  }

  goToTask(task: NurseTask, tasks: NurseTask[], jobId: string): void {
    let isLastTask = tasks?.length === 1;
    this._taskNav.doTask(task, isLastTask, jobId);
  }

  showReviewApplicationDialog(): void {
    const dialogData: IDialogParameters = {
      title: `Apply to ${this.jobName}`,
      text: '',
      showCloseIcon: true,
      elevation: CardElevation.Default,
      icon: undefined,
      template: this.reviewApplicationDialogTemplate,
      separatedHeader: true,
      noStyling: true,
      isResponsive: true
    };
    this._dialogService.showDialog(dialogData, true);
  }

  showApplicationWarningDialog(): void {
    const dialogData: IDialogParameters = {
      title: `Continue this application?`,
      text: '',
      showCloseIcon: true,
      elevation: CardElevation.Default,
      icon: undefined,
      template: this.applicationWarningDialogTemplate,
      separatedHeader: false,
      noStyling: true,
      isResponsive: true
    };
    this._dialogService.showDialog(dialogData, true);
  }

  closeApplicationWarning(): void {
    this._dialogService.closeAll();
  }

  showApplicationSavedDialog(): void {
    const dialogData: IDialogParameters = {
      title: `Application Saved!`,
      text: '',
      showCloseIcon: true,
      elevation: CardElevation.Default,
      icon: undefined,
      template: this.applicationSavedDialogTemplate,
      separatedHeader: false,
      noStyling: true,
      isResponsive: true
    };
    this._dialogService.showDialog(dialogData, true);
  }
}
