import { HcinContactModel } from '../models/hcin-api-contact-model';
import { NurseModel } from '../models/nurse-model';
import { AboutMeModel } from '../models/nurse-profile-model';

export function getInitials(nurse: NurseModel): string {
  if (nurse.firstName && nurse.lastName) {
    return nurse.firstName?.substring(0, 1) + nurse.lastName?.substring(0, 1);
  } else {
    return nurse.email?.substring(0, 2);
  }
}

export function mapNurseINDataToTN(nurseINData: HcinContactModel) {
  let newNurseData: NurseModel = new NurseModel();
  for (const key in nurseINData) {
    switch (key) {
      case 'primarySpecialtyId':
        newNurseData.specialtyId = nurseINData[key];
        break;
      case 'primarySubSpecialtyId':
        newNurseData.subSpecialtyId = nurseINData[key];
        break;
      case 'recruiter':
        newNurseData.recruiterId = nurseINData[key];
        break;
      case 'lifeCycleStageId':
        newNurseData.lifeCycleStage = nurseINData[key];
        break;
      case 'lifeCycleSubStageId':
        newNurseData.lifeCycleSubStage = nurseINData[key];
        break;
      case 'contactId':
        newNurseData.hcContactId = nurseINData[key];
        break;
      case 'emailAddress':
        newNurseData.email = nurseINData[key];
        break;
      case 'phoneNumber':
        newNurseData.mobileNumber = nurseINData[key];
        break;
      case 'id':
        newNurseData.merlinId = nurseINData[key];
        break;
      default:
        newNurseData[key] = nurseINData[key];
    }
  }
  return newNurseData;
}

export function mapNurseDataOrAboutMeToINContact(data: NurseModel | AboutMeModel, existingINContact: HcinContactModel = null) {
  let updatedNurse = existingINContact ?? new HcinContactModel();
  for (const key in data) {
    switch (key) {
      case 'currentAssignee': // We skip current assignee as it's not used when updating the API for a put /contact. Sending it causes a 500 as the field is readonly.
        break;
      case 'specialtyId':
        updatedNurse.primarySpecialtyId = data[key];
        break;
      case 'subSpecialtyId':
        updatedNurse.primarySubSpecialtyId = data[key];
        break;
      case 'recruiterId':
        updatedNurse.recruiter = data[key];
        break;
      case 'lifeCycleStage':
        updatedNurse.lifeCycleStageId = data[key];
        break;
      case 'lifeCycleSubStage':
        updatedNurse.lifeCycleSubStageId = data[key];
        break;
      case 'hcContactId':
        updatedNurse.contactId = data[key];
        break;
      case 'email':
        updatedNurse.emailAddress = data[key];
        break;
      case 'mobileNumber':
      case 'mobilePhone':
        updatedNurse.phoneNumber = data[key];
        break;
      case 'merlinId':
        updatedNurse.id = data[key];
        break;
      default:
        updatedNurse[key] = data[key];
    }
  }
  return updatedNurse;
}

export function updateINContactDataWithAboutMe(aboutMe: AboutMeModel, nurseData: NurseModel) {
  let existingINContactData: HcinContactModel = mapNurseDataOrAboutMeToINContact(nurseData);
  let updatedINContactData: HcinContactModel = mapNurseDataOrAboutMeToINContact(aboutMe, existingINContactData);
  return updatedINContactData;
}
