import { Nav, NavCondition } from 'src/app/common/models/nav';
import { AppUrls } from 'src/app/app-urls';

export const allProfileNavItems: Nav[] = [
  {
    name: 'About Me',
    nav: `/${AppUrls.ABOUT_ME}`
  },
  {
    name: 'Professional Overview',
    nav: `/${AppUrls.PROFESSIONAL_OVERVIEW}`,
    condition: NavCondition.TRAVEL
  },
  {
    name: 'Licenses',
    nav: `/${AppUrls.LICENSES}`
  },
  {
    name: 'Certifications',
    nav: `/${AppUrls.CERTIFICATIONS}`
  },
  {
    name: 'Education',
    nav: `/${AppUrls.EDUCATION}`
  },
  {
    name: 'Work History',
    nav: `/${AppUrls.WORK_HISTORY}`
  },
  {
    name: 'References',
    nav: `/${AppUrls.REFERENCES}`,
    condition: NavCondition.TRAVEL
  },
  {
    name: 'EMR Software',
    nav: `/${AppUrls.TECHNOLOGY}`,
    condition: NavCondition.TRAVEL
  },
  {
    name: 'Skills Checklist',
    nav: `/${AppUrls.SKILLS}`,
    condition: NavCondition.TRAVEL
  },
  {
    name: 'Background Info',
    nav: `/${AppUrls.QUESTIONNAIRE}`,
    condition: NavCondition.TRAVEL
  }
];
