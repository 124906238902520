import { TaskCompletionReturnObject } from 'src/app/common';
import { License, LicenseIN } from 'src/app/common/models/license';
import { IDataState, initializeData } from '../app/app.models';

export interface LicensesState {
  license: IDataState<License>;
  licenses: IDataState<License[]>;
  licensesIN: IDataState<LicenseIN[]>;
  nursysAdd: IDataState<boolean>;
  nursysUpdate: IDataState<number>;
  updateResponse: IDataState<TaskCompletionReturnObject>;
  fileUrl: IDataState<string>;
}

export const initialLicensesState: LicensesState = {
  license: initializeData(),
  licenses: initializeData(),
  licensesIN: initializeData(),
  nursysAdd: initializeData(),
  nursysUpdate: initializeData(),
  updateResponse: initializeData(),
  fileUrl: initializeData()
};
