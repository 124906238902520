import { Location } from '@angular/common';
import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, HostBinding } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { BodySize, CardElevation, DialogService, HeadingSize, IDialogParameters } from 'hc-design-system-lib';
import { ButtonAppearance } from 'hc-design-system-lib/lib/components/button/button.enums';
import { Observable, Subject, combineLatest } from 'rxjs';
import { take, takeUntil, tap } from 'rxjs/operators';
import { FormHeaderAttributes, FormHeaderService } from 'src/app/services';

@Component({
  selector: 'app-base-form',
  templateUrl: './base-form.component.html',
  styleUrls: ['./base-form.component.scss']
})
export class BaseFormComponent implements OnInit, OnDestroy {
  private readonly destroy$: Subject<void> = new Subject<void>();
  @Input() isSavingObs: Observable<boolean>;
  @Input() isLoadingObs: Observable<boolean>;
  @Input() form: FormGroup;
  @Input() formHeaderAttributes: FormHeaderAttributes;
  @Input() isModal: boolean = false;
  @Input() @HostBinding('class.full-height') takeUpFullHeight: boolean = false;

  @Output() saveableEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

  primaryButtonAppearance = ButtonAppearance.Primary;
  bodyTextSize = BodySize.Small;
  headingTextSize = HeadingSize.H6;

  constructor(
    private _formHeaderService: FormHeaderService,
    private _dialogService: DialogService,
    private _location: Location
  ) {}

  ngOnInit(): void {
    if (!this.isModal) {
      if (!this.formHeaderAttributes.backAction) {
        this.formHeaderAttributes.backAction = () => this.headerGoBack();
      }
      this._formHeaderService.resetFormHeaderAttributes(this.formHeaderAttributes);
    }

    combineLatest([this.isSavingObs, this.isLoadingObs])
      .pipe(
        tap(([saving, loading]) => (saving || loading ? this.form.disable() : this.form.enable())),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  headerGoBack(): void {
    if (this.form?.dirty) {
      const dialogData: IDialogParameters = {
        title: 'Leave without saving?',
        text: 'Any unsaved changes will be lost.',
        options: {
          primary: { text: 'Leave this page', returnValue: 'primary' },
          secondary: { text: 'Cancel', returnValue: 'secondary' }
        },
        showCloseIcon: true,
        elevation: CardElevation.Default,
        icon: undefined
      };
      this._dialogService
        .showDialog(dialogData)
        .pipe(take(1))
        .subscribe(response => {
          if (response === 'primary') {
            this._location.back();
          }
        });
    } else {
      this._location.back();
    }
  }

  public saveCheck(): void {
    if (this.form) {
      this.form.markAllAsTouched();
      this.form.updateValueAndValidity();
      if (!this.form.valid) {
        document.querySelector('mat-form-field.ng-invalid')?.scrollIntoView({ behavior: 'smooth' });
        this.saveableEmitter.emit(false);
      } else {
        this.saveableEmitter.emit(true);
      }
    }
  }

  public getErrorMessage(control: AbstractControl, fieldName: string, comparisonFieldName?: string): string {
    if (!control) {
      return '';
    }
    if (control.hasError('matDatepickerParse')) {
      return `${fieldName} must be in MM/DD/YYYY`;
    }
    if (control.hasError('required')) {
      return `${fieldName} required`;
    }
    if (control.hasError('maxlength')) {
      return `${fieldName} has a max length of ${control.errors.maxlength.requiredLength}`;
    }
    if (control.hasError('minlength')) {
      return `${fieldName} has a min length of ${control.errors.minlength.requiredLength}`;
    }
    if (control.hasError('forbiddenName')) {
      return `Invalid ${fieldName}`;
    }
    if (control.hasError('validateZipCode')) {
      return `Invalid ${fieldName}`;
    }
    if (control.hasError('pattern')) {
      return `Not a valid ${fieldName}`;
    }
    if (control.hasError('invalidFile')) {
      return 'Invalid file. Please use an accepted file format.';
    }
    if (control.hasError('cannotBeAfter')) {
      return `${fieldName} cannot be after ${comparisonFieldName}`;
    }
    if (control.hasError('cannotBeBefore')) {
      return `${fieldName} cannot be before ${comparisonFieldName}`;
    }
    if (control.hasError('validateBirthDate')) {
      return `Age must be between 18 and 100 years old`;
    }
  }
}
