import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { BodySize, HeadingSize } from 'hc-design-system-lib';

@Component({
  templateUrl: './disclaimer-popup.component.html',
  styleUrls: ['./disclaimer-popup.component.scss']
})
export class DisclaimerPopupComponent {
  constructor(public dialogRef: MatDialogRef<DisclaimerPopupComponent>) {}

  bodySizeSmall = BodySize.Small;
  headingSizeH5 = HeadingSize.H5;

  cancel(): void {
    this.dialogRef.close();
  }
}
