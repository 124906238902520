import { Component, ElementRef, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AboutMeModel, NurseModel, PreEmploymentQuestionnaire, TaskCompletionReturnObject } from 'src/app/common';
import { combineLatest, Observable, Subject } from 'rxjs';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { DocumentHelperService, FormHeaderAttributes } from 'src/app/services';
import { filter, skipWhile, takeUntil, tap } from 'rxjs/operators';
import { autocompleteValidator } from 'src/app/common/validators/autocompleteValidator';
import { DomSanitizer } from '@angular/platform-browser';
import { IDropdownData, IRadioButtonOption } from 'hc-design-system-lib/lib/components/form/form.interfaces';
import { ButtonAppearance } from 'hc-design-system-lib/lib/components/button/button.enums';

import { Store } from '@ngrx/store';
import { IAppState } from 'src/app/store/app/app.state';
import { UpdateAboutMe, UploadProfilePicture } from 'src/app/store/userContext/userContext.actions';
import {
  selectCanSeeInternational,
  selectCanSeeTravel,
  selectNurseAvatarCardDetails,
  selectNurseAvatarConfiguration,
  selectNurseData,
  selectNurseDataLoading,
  selectNurseINData,
  selectNurseINDataLoading,
  selectQuestionnaireData,
  selectUpdateAboutMeTask,
  selectUpdateAboutMeTaskLoading
} from 'src/app/store/userContext/userContext.selectors';
import { selectStateDropdowns, selectYesNoOnlyRadios } from 'src/app/store/lookups/lookups.selectors';
import { genderOptions, PHONE_MASK, SSN_MASK } from 'src/app/common/constants';
import { convertIntoDropdownData } from 'src/app/common/functions/dropdown-helpers';
import { IAvatarCardDetails, IAvatarConfig } from 'hc-design-system-lib/lib/components/avatar-card/avatar-card.interfaces';
import { GetZipCodeInfo } from 'src/app/store/sharedData/sharedData.actions';
import { selectZipCodeDropdowns } from 'src/app/store/sharedData/sharedData.selectors';
import { AboutMeForm } from 'src/app/common/models/about-me-form';
import { IDataState } from 'src/app/store/app/app.models';
import { birthDateValidator } from 'src/app/common/validators/birthDateValidator';
import { Camera, CameraResultType } from '@capacitor/camera';
import { Capacitor } from '@capacitor/core';
import { HcEvent } from 'hc-design-system-lib/lib/models/hc-event';
import { BodySize } from 'hc-design-system-lib/lib/typography/components/body/body.component';
import { HeadingSize } from 'hc-design-system-lib/lib/typography/components/heading/heading.component';
import { CardElevation, DialogService, IDialogParameters } from 'hc-design-system-lib';
import { ActivatedRoute, Router } from '@angular/router';
import { FlowHandlerComponent } from '../shared/flow-handler/flow-handler.component';
import { selectIsMobileNonTablet } from 'src/app/store/ui/ui.selectors';

@Component({
  selector: 'app-about-me',
  templateUrl: './about-me.component.html',
  styleUrls: ['./about-me.component.scss'],
  providers: [DocumentHelperService]
})
export class AboutMeComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();
  private ssnRegex = /^(?!666|000|9\d{2})\d{3} (?!00)\d{2} (?!0{4})\d{4}$/;
  private ssnViewRegex = /^(?!666|000|9\d{2})\d{3} (?!00)\d{2} (?!0{4})\d{4}$|^\*{3} \*{2} (?!0{4})\d{4}$/;
  private genderOptions: IRadioButtonOption[] = genderOptions;

  @ViewChild('profileInput') profileInput: ElementRef;
  @ViewChild('profileInputMobile') profileInputMobile: ElementRef;
  @ViewChild('taxHomeAddressDialog') taxHomeAddressDialog: TemplateRef<any>;
  @ViewChild('flowHandlerComponent') flowHandlerComponent: FlowHandlerComponent;

  primaryButtonAppearance = ButtonAppearance.Primary;
  phoneMask = PHONE_MASK;
  ssnMask = SSN_MASK;
  isNative: boolean = Capacitor.isNativePlatform();
  small = BodySize.Small;
  h4 = HeadingSize.H4;
  taxAddressDeclared: number = undefined;
  taxAddress: string;
  showAddressFields = true;
  yesId: number;
  noId: number;
  questionnaire: PreEmploymentQuestionnaire;
  states: IDropdownData[];
  nurse: NurseModel;

  form: FormGroup<AboutMeForm>;
  isMailingSameAsTaxAddressControl: FormControl<boolean>;
  formHeaderAttributes: FormHeaderAttributes;

  showContactInformation = false;

  statesDropdown$: Observable<IDropdownData[]> = this._store.select(selectStateDropdowns);
  zipCodeDropdown$: Observable<IDropdownData[]> = this._store.select(selectZipCodeDropdowns);
  nurse$: Observable<NurseModel> = this._store.select(selectNurseData);
  nurseLoading$: Observable<boolean> = this._store.select(selectNurseDataLoading);
  nurseIN$: Observable<NurseModel> = this._store.select(selectNurseINData);
  nurseINLoading$: Observable<boolean> = this._store.select(selectNurseINDataLoading);
  avatarCardDetails$: Observable<IAvatarCardDetails> = this._store.select(selectNurseAvatarCardDetails);
  avatarConfiguration$: Observable<IAvatarConfig> = this._store.select(selectNurseAvatarConfiguration(this.sanitizer));
  updateAboutMeTask$: Observable<IDataState<TaskCompletionReturnObject>> = this._store.select(selectUpdateAboutMeTask);
  updateAboutMeTaskSaving$: Observable<boolean> = this._store.select(selectUpdateAboutMeTaskLoading);
  yesNoOnlyRadios$: Observable<IRadioButtonOption[]> = this._store.select(selectYesNoOnlyRadios);
  questionnaireData$: Observable<PreEmploymentQuestionnaire> = this._store.select(selectQuestionnaireData);
  showInternationalContent$: Observable<boolean> = this._store.select(selectCanSeeInternational);
  showTravelContent$: Observable<boolean> = this._store.select(selectCanSeeTravel);
  isMobile$ = this._store.select(selectIsMobileNonTablet);
  showInternational: boolean;
  showTravel: boolean;

  constructor(
    private _fb: FormBuilder,
    private _documentHelperService: DocumentHelperService,
    private sanitizer: DomSanitizer,
    private _store: Store<IAppState>,
    private _dialogService: DialogService,
    private _router: Router,
    private _route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.initializePageObservables();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private _prepareSave(): AboutMeModel {
    const model = this.form.getRawValue() as unknown as AboutMeModel;
    model.state = this.form.controls.state.value?.key;
    model.zipCode = this.form.controls.zipCode.value?.key;
    model.merlinId = this.nurse.merlinId;

    return model;
  }

  onSave(isValid: boolean) {
    if (isValid) {
      const model = this._prepareSave();
      this._store.dispatch(new UpdateAboutMe(model));
    }
  }

  initializePageObservables(): void {
    combineLatest([
      this.nurse$,
      this.nurseIN$,
      this.nurseLoading$,
      this.nurseINLoading$,
      this.statesDropdown$,
      this.questionnaireData$,
      this.yesNoOnlyRadios$,
      this.showInternationalContent$,
      this.showTravelContent$
    ])
      .pipe(
        filter(([nurse, nurseIN, nurseLoading, nurseINLoading, states, questionnaire, yesNoOnlyRadios, showInternational, showTravel]) => {
          return (!!nurse || !!nurseIN) && !nurseLoading && !nurseINLoading && !!states && !!questionnaire && !!yesNoOnlyRadios.length && (showInternational || showTravel);
        }),
        tap(([nurse, nurseIN, nurseLoading, nurseINLoading, states, questionnaire, yesNoOnlyRadios, showInternational, showTravel]) => {
          this.states = states;
          this.yesId = yesNoOnlyRadios.find(x => x.text === 'Yes').value;
          this.noId = yesNoOnlyRadios.find(x => x.text === 'No').value;

          this.showInternational = showInternational;
          this.showTravel = showTravel;

          if (this.questionnaire !== questionnaire) {
            this.questionnaire = questionnaire;
            // set tax address variables
            this.taxAddressDeclared = questionnaire.taxResidence;
            if (this.taxAddressDeclared === this.yesId) {
              this.taxAddress = [
                questionnaire.streetAddress1,
                questionnaire.streetAddress2,
                questionnaire.city,
                this.states.find(x => x.value.id === questionnaire.state).value.code,
                questionnaire.zip.zipCode
              ]
                .filter(value => !!value)
                .join(', ');
            }
          }

          if (showInternational && this.nurse !== nurseIN) {
            this.nurse = nurseIN;
            this._createForm(this.nurse);
            this.observeZipCodeValueChanges();
          } else if (showTravel && this.nurse !== nurse) {
            this.nurse = nurse;
            this._createForm(this.nurse);
            this.observeZipCodeValueChanges();
          }
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  observeZipCodeValueChanges(): void {
    this.form.controls.zipCode.valueChanges
      .pipe(
        filter(zipDropdown => !!zipDropdown?.value?.state),
        tap(zipDropdown => {
          const state = this.states.find((s: IDropdownData) => s.value.code === zipDropdown.value.state);
          if (zipDropdown.value.city) {
            this.form.controls.city.setValue(zipDropdown.value.city);
          }
          this.form.controls.state.setValue(state ?? null);
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  _createForm(nurse: NurseModel) {
    if (nurse.mailingZipCode) {
      this._store.dispatch(new GetZipCodeInfo(nurse.mailingZipCode));
    }
    const state = this.states.find(x => x.value.name === nurse.mailingState);
    this.form = this._fb.group({
      firstName: new FormControl(nurse.firstName, [Validators.required]),
      lastName: new FormControl(nurse.lastName, [Validators.required]),
      mobilePhone: new FormControl(nurse.mobileNumber?.replace(/\W/g, ''), [Validators.required, Validators.pattern('\\(?\\d{3}\\)? ?\\d{3}-?\\d{4}')]),
      email: new FormControl({ value: nurse.email, disabled: true }),
      streetAddress1: new FormControl(nurse.mailingAddress1, this.showTravel ? [Validators.required] : []),
      streetAddress2: new FormControl(nurse.mailingAddress2),
      city: new FormControl(nurse.mailingCity, this.showTravel ? [Validators.required] : []),
      state: new FormControl(state, this.showTravel ? [Validators.required, autocompleteValidator(this.states)] : []),
      zipCode: new FormControl(
        nurse.mailingZipCode ? { key: nurse.mailingZipCode, value: nurse.mailingZipCode } : null,
        this.showTravel ? [Validators.required, this.validateZipCode()] : []
      ),
      dateOfBirth: new FormControl(nurse.dateOfBirth, this.showTravel ? [birthDateValidator()] : []),
      ssnViewValue: new FormControl(nurse.ssn ? '*** ** ' + nurse.ssn : null, this.showTravel ? [Validators.pattern(this.ssnViewRegex)] : []),
      ssn: new FormControl(nurse.ssn),
      preferredName: new FormControl(nurse.preferredName),
      gender: new FormControl(nurse.gender)
    });

    this.form.controls.ssnViewValue.valueChanges
      .pipe(
        skipWhile(value => value && !this.ssnRegex.test(value)),
        takeUntil(this.destroy$)
      )
      .subscribe(value => {
        this.form.controls.ssn.setValue(value ?? '');
      });

    this.formHeaderAttributes = {
      form: this.form,
      title: 'About Me',
      showSaveButton: false
    };

    // checkbox won't disable when part of main form group
    this.isMailingSameAsTaxAddressControl = new FormControl({
      value: nurse.isMailingAddressSameAsTax && !!nurse.mailingAddress1,
      disabled: this.taxAddressDeclared !== this.yesId
    });
    if (nurse.isMailingAddressSameAsTax === true && !!nurse.mailingAddress1) {
      this.showAddressFields = false;
    }
  }

  showSsn(): void {
    this.form.controls.ssnViewValue.setValue(null);
  }

  hideSsn(): void {
    const cleanValue = this.form.controls.ssn.value;
    if (cleanValue.length === 9) {
      this.form.controls.ssnViewValue.setValue('*** ** ' + cleanValue.substring(5));
    }
  }

  enableContactInformationSection(): void {
    this.form.controls.email.disable();
    this.showContactInformation = true;
  }

  validateZipCode(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      if (value && typeof value === typeof 'string') {
        return { validateZipCode: true };
      }
      return null;
    };
  }

  updateZipDropdown(event: HcEvent) {
    const zip = event.eventValue;
    if (zip.length > 2 && typeof zip === 'string') {
      this._store.dispatch(new GetZipCodeInfo(zip));
    }
  }

  handleFileInput(fileList: FileList, input: HTMLInputElement): void {
    const profilePicture = this._documentHelperService.handleProfileInput(fileList, input);
    if (profilePicture[0] && profilePicture[0].size < 31457280) {
      this._store.dispatch(new UploadProfilePicture(profilePicture));
    }
  }

  async takePicture(input: HTMLInputElement) {
    // Only allows a single photo instead of a list
    const image = await Camera.getPhoto({
      quality: 90,
      allowEditing: false,
      resultType: CameraResultType.Uri,
      webUseInput: true
    });
    // Fetch the photo, read as a blob, then convert to file format
    const response = await fetch(image.webPath);
    const blob = await response.blob();
    const filename = image.webPath.split('/').pop();
    // Can this be converted from a File to FileList and make use of handleFileInput?
    const imageFile = new File([blob], filename, { type: 'image/jpg' });
    const profilePicture = this._documentHelperService.handleProfilePictureInput(imageFile, input);
    if (profilePicture[0] && profilePicture[0].size < 31457280) {
      this._store.dispatch(new UploadProfilePicture(profilePicture));
    }
  }
  openInfoModal() {
    const dialogData: IDialogParameters = {
      title: 'Tax Home Address',
      text: '',
      showCloseIcon: true,
      elevation: CardElevation.Default,
      icon: undefined,
      template: this.taxHomeAddressDialog
    };
    this._dialogService.showDialog(dialogData);
  }

  goToQuestionnaire() {
    if (this.form.dirty || this.isMailingSameAsTaxAddressControl.dirty) {
      const dialogData: IDialogParameters = {
        title: 'Leave without saving?',
        text: 'Any unsaved changes will be lost.',
        options: {
          primary: { text: 'Leave this page', returnValue: 'primary' },
          secondary: { text: 'Cancel', returnValue: 'secondary' }
        },
        showCloseIcon: false,
        elevation: CardElevation.Default,
        icon: undefined
      };
      this._dialogService.showDialog(dialogData).subscribe(response => {
        if (response === 'primary') {
          const params =
            (this.flowHandlerComponent.jobId ? `?jobId=${this.flowHandlerComponent.jobId}` : '') +
            (this._route.snapshot.queryParams['fromTask'] === 'true' ? `${this.flowHandlerComponent.jobId ? '&' : '?'}fromTask=true` : '');
          this._router.navigateByUrl(`questionnaire${params}`);
        }
      });
    } else {
      const params =
        (this.flowHandlerComponent.jobId ? `?jobId=${this.flowHandlerComponent.jobId}` : '') +
        (this._route.snapshot.queryParams['fromTask'] === 'true' ? `${this.flowHandlerComponent.jobId ? '&' : '?'}fromTask=true` : '');
      this._router.navigateByUrl(`questionnaire${params}`);
    }
  }

  sameAsTaxAddressChanged(event) {
    // same as tax address
    if (event.eventValue) {
      const state = this.states.find(x => x.value.id === this.questionnaire.state);
      let zip: any = this.questionnaire.zip;
      if (zip) {
        zip = convertIntoDropdownData(this.questionnaire.zip, 'zipCode');
      }
      this.form.controls.streetAddress1.setValue(this.questionnaire.streetAddress1);
      this.form.controls.streetAddress2.setValue(this.questionnaire.streetAddress2);
      this.form.controls.city.setValue(this.questionnaire.city);
      this.form.controls.state.setValue(state);
      this.form.controls.zipCode.setValue(zip);

      this.showAddressFields = false;
    } else {
      this.form.controls.streetAddress1.setValue('');
      this.form.controls.streetAddress2.setValue('');
      this.form.controls.city.setValue('');
      this.form.controls.state.setValue(undefined);
      this.form.controls.zipCode.setValue(undefined);

      this.showAddressFields = true;
    }
  }
}
