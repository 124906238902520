<mat-toolbar class="form-header">
  <div class="full-width" fxLayout="row" fxLayoutAlign="space-between center">
    <div fxFlex="75" fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="start center">
      <div *ngIf="showBackButton | async">
        <button id="globalBack" mat-icon-button (click)="back()">
          <mat-icon>arrow_back</mat-icon>
        </button>
      </div>

      <div class="dot-dot-dot">
        <h4>{{ title | async }}</h4>
        <hc-body class="form-header-subheader" *ngIf="subTitle">{{ subTitle | async }}</hc-body>
      </div>
    </div>

    <div>
      <ng-container *ngIf="customContent | async" [ngTemplateOutlet]="customContent | async"></ng-container>
    </div>
    <div *ngIf="showFormActions" fxLayout="row" fxLayoutAlign="end center" class="full-width">
      <hc-button segmentTrackCTA id="globalSave" *ngIf="showSaveButton | async" [disabled]="disableActions | async" (buttonClick)="sendSave()" [size]="buttonSize">
        SAVE
      </hc-button>
      <button id="formOpenExtraOptions" *ngIf="showEditButton | async" mat-icon-button [disabled]="disableActions | async" (click)="sendOpenOptions()">
        <mat-icon>{{ editButtonIcon | async }}</mat-icon>
      </button>
    </div>
  </div>
</mat-toolbar>
