<ng-container
  *ngIf="{
    tabBar: tabBar$ | async,
    nurse: nurse$ | async,
    showTravel: showTravelContent$ | async,
    showInternational: showInternationalContent$ | async,
    isMobile: isMobile$ | async,
    isTablet: isTablet$ | async,
    user: userData$ | async,
    menuItems: menuItems$ | async,
    navigationProfile: navigationProfile$ | async
  } as obs"
>
  <hc-navigation
    class="hc-main-navigation"
    [logoImageSource]="setLogo(obs.showInternational, obs.isMobile, obs.isTablet)"
    [profile]="hideProfile ? null : obs.navigationProfile"
    [profileMenuItems]="obs.menuItems"
    [signOutItem]="signOutItem"
    [tabbarItems]="obs.tabBar.tabBarItems"
    [tabbarActiveItemIndex]="obs.tabBar.tabbarActiveItemIndex"
    [showNavbar]="!hideNavbar"
    [showTabbar]="!hideTabBar && !isOnRegistrationPath"
    [showProfileMenu]="!hideProfile && !isOnRegistrationPath"
    [showFormHeader]="showFormHeader"
    [secondaryAction]="supportButton"
    (profileMenuItemClick)="navigate($event, 'Top Navigation', obs.nurse, obs.user)"
    (tabbarItemClick)="navigate($event, 'Tabbar Navigation', obs.nurse, obs.user)"
    (secondaryActionClick)="navigate(supportButtonClickEvent, 'Top Navigation', obs.nurse, obs.user)"
    [class.sidenav-content-padding-0]="shouldDisableNavContentPadding(obs.isMobile)"
  >
    <app-form-header form-header #headerRef> </app-form-header>

    <hc-card page [styles]="cardStyles">
      <div fxFlex fxLayout="column" class="main-content">
        <div class="loading-overlay" *ngIf="loading">
          <app-loading-screen-spinner [show]="true"></app-loading-screen-spinner>
          <md-progress-bar mode="indeterminate"></md-progress-bar>
        </div>
        <router-outlet *ngIf="!loading" (activate)="onActivate($event)" class="navigation-router"></router-outlet>
      </div>
    </hc-card>
  </hc-navigation>

  <app-delete-confirmation [onConfirm]="deleteConfirmationEvent"></app-delete-confirmation>
  <app-basic-snack-bar [displayEvent]="basicSnackBarEmitter"></app-basic-snack-bar>

  <input class="no-display" id="hidden-name" *ngIf="nurse$ | async" [ngModel]="(nurse$ | async)?.firstName + ' ' + (nurse$ | async)?.lastName" />
  <input class="no-display" id="hidden-merlinId" *ngIf="nurse$ | async" [ngModel]="(nurse$ | async)?.merlinId" />
  <input class="no-display" id="hidden-hubSpotId" *ngIf="nurse$ | async" [ngModel]="(nurse$ | async)?.hubSpotId" />
  <input class="no-display" id="hidden-email" *ngIf="nurse$ | async" [ngModel]="(nurse$ | async)?.email" />
  <ng-template #specialtyPopup>
    <app-specialty-popup></app-specialty-popup>
  </ng-template>
</ng-container>
