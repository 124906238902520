export class Reference {
  id: string;
  candidateId: string;
  firstName: string;
  lastName: string;
  positionCode: number;
  email: string;
  phone: string;
  facilityName: string;
  facilityCity: string;
  facilityState: string;
  startDate: Date;
  endDate: Date;
  clinicalCompetence?: number;
  followsPlanOfPatientcare?: number;
  safety?: number;
  policies?: number;
  quality?: number;
  adaptability?: number;
  communication?: number;
  attendance?: number;
  attitude?: number;
  workWithOthers?: number;
  professionalism?: number;
  workHistoryId: string;
  comments?: string;
  currentPosition?: boolean;
  rehire?: boolean;
  hireFire?: string | boolean;
  status: number;
}

export class ReferencesResponse {
  completedReferences: Reference[];
  references: Reference[];
}

export enum ReferenceStatusCodes {
  Complete = 948050002,
  CouldNotReach_Active = 948050008,
  CouldNotReach_Inactive = 948050010,
  Duplicate_Active = 948050007,
  Duplicate_Inactive = 948050009,
  Inactive = 2,
  InProgress = 948050001,
  NotStarted = 948050000,
  Pending = 948050006
}

export enum ReferenceRoleCode {
  ChargeNurse = 948050006,
  CNO = 948050004,
  Director = 948050002,
  MD = 948050003,
  ShiftSupervisor = 948050000,
  Supervisor = 948050005,
  UnitManager = 948050001
}

export class NewReference {
  firstName: string;
  lastName: string;
  positionCode: number;
  email: string;
  phone: string;
  workHistoryId: string;
}

export class UpdatedReference {
  firstName: string;
  lastName: string;
  positionCode: number;
  email: string;
  phone: string;
  id: string;
  workHistoryId: string;
  facilityName: string;
  facilityCity: string;
  facilityState: string;
}
