import { NgModule } from '@angular/core';
import { RouterModule, Routes, Route } from '@angular/router';
import { SkillsCheckListComponent } from './components/skills/skills-check-list/skills-check-list.component';
import { IRouteOptions } from './common';
import { NotFoundComponent } from './components/external-components/not-found/not-found.component';
import { NotFoundResolve } from './components/external-components/not-found/not-found.resolve';
import { MatchedJobsComponent } from './components/jobs/matched-jobs/matched-jobs-list/matched-jobs.component';
import { ProfileMenuComponent } from './components/main-components/profile-menu/profile-menu.component';
import { AppUrls, RouteTitles } from './app-urls';
import { AboutMeComponent } from './components/about-me/about-me.component';
import { SupportComponent } from './components/main-components/support/support.component';
import { InactiveUserComponent } from './components/main-components/registration/inactive-user/inactive-user.component';
import { AutoOfferConfirmationComponent } from './components/jobs/auto-offer-confirmation/auto-offer-confirmation.component';
import { ProfessionalOverviewComponent } from './components/about-me/professional-overview/professional-overview.component';
import { SettingsComponent } from './components/main-components/settings/settings.component';
import { ZendeskSupportResolver } from './components/main-components/support/zendesk-support.resolver';
import { InternalLoginComponent } from './components/internal-login/internal-login.component';
import {
  MainComponent,
  ReferenceQuestionnaireResolve,
  ReferenceQuestionnaireComponent,
  EducationComponent,
  QuestionnaireComponent,
  SpecificJobComponent,
  SavedJobsComponent,
  BulkUploadComponent,
  DocumentsComponent,
  MainJobAreaComponent,
  TasksListComponent,
  ContactSelectComponent,
  MultipleFoundComponent,
  ApplicationReviewComponent,
  CovidVaccineComponent,
  FluVaccineComponent,
  SimilarJobsListComponent,
  ResetPasswordComponent,
  EnrollComponent,
  LoginRedirectComponent
} from './components';
import { MainGuard, AppInitGuard, InactiveUserGuard, ContactSelectGuard, TravelGuard } from './guards';
import { AutoOfferConfirmationGuard } from './guards/auto-offer-confirmation.guard';
import { EnrollGuard } from './guards/enroll.guard';
import { AuthGuard } from './guards/auth-guard';
import { NewSubmittalsComponent } from './components/jobs/new-submittals/new-submittals.component';
import { ApplicationTasksComponent } from './components/jobs/application-tasks/application-tasks.component';
import { NoConnectionComponent } from './components/no-connection/no-connection.component';
import { SkillAssessmentRedesignComponent } from './components/skills/skill-assessment/redesign/skill-assessment-redesign.component';
import { SanitizeParamGuard } from './guards/sanitize-params.guard';
import { LogoutComponent } from './components/logout/logout.component';
import { LoginErrorComponent } from './components/login-error/login-error.component';
import { OnAssignmentGuard } from './guards/on-assignment.guard';
import { SpecificJobGuard } from './guards/specific-job.guard';

const resetPassword = {
  path: AppUrls.RESET_PASSWORD,
  title: RouteTitles.RESET_PASSWORD,
  component: ResetPasswordComponent
};

const multpleFound = {
  path: AppUrls.MULTIPLE_ACCOUNTS_FOUND,
  title: RouteTitles.MULTIPLE_ACCOUNTS_FOUND,
  component: MultipleFoundComponent
};

const aboutMe = {
  path: AppUrls.ABOUT_ME,
  title: RouteTitles.ABOUT_ME,
  component: AboutMeComponent
};

const professionalOverview = {
  path: AppUrls.PROFESSIONAL_OVERVIEW,
  title: RouteTitles.PROFESSIONAL_OVERVIEW,
  component: ProfessionalOverviewComponent,
  canActivate: [TravelGuard]
};

const profile_menu = {
  path: AppUrls.PROFILEMENU,
  title: RouteTitles.PROFILEMENU,
  component: ProfileMenuComponent,
  data: {
    options: <IRouteOptions>{ showBackButton: false }
  }
};

const jobDetail = {
  path: `${AppUrls.JOBS}/:id`,
  title: RouteTitles.JOB,
  component: SpecificJobComponent,
  canActivate: [SpecificJobGuard]
};

const jobs = {
  path: `${AppUrls.JOBS}`,
  redirectTo: `${AppUrls.JOB_SEARCH}`
};

const savedJobs = {
  path: AppUrls.SAVED_JOBS,
  title: RouteTitles.SAVED_JOBS,
  component: SavedJobsComponent,
  canActivate: [TravelGuard]
};

const similarJobs = {
  path: `${AppUrls.SIMILAR_JOBS}/:id`,
  title: RouteTitles.SIMILAR_JOBS,
  component: SimilarJobsListComponent,
  data: {
    options: <IRouteOptions>{ showBackButton: true }
  },
  canActivate: [TravelGuard]
};

const applicationReview = {
  path: `${AppUrls.APPLICATION_REVIEW}/:id`,
  title: RouteTitles.APPLICATION_REVIEW,
  component: ApplicationReviewComponent
};

const applicationTasks = {
  path: `${AppUrls.APPLICATION}/:id`,
  title: RouteTitles.APPLICATION,
  component: ApplicationTasksComponent
};

const autoOfferConfirmation = {
  path: `${AppUrls.JOBS}/auto-offer-confirmation/:id`,
  title: RouteTitles.AUTO_OFFER_CONFIRMATION,
  component: AutoOfferConfirmationComponent,
  canActivate: [AutoOfferConfirmationGuard]
};

const mySubmittals = {
  path: AppUrls.MY_SUBMITTALS,
  title: RouteTitles.MY_SUBMITTALS,
  component: NewSubmittalsComponent
};

const matchedJobs = {
  path: AppUrls.MATCHED_JOBS,
  title: RouteTitles.MATCHED_JOBS,
  component: MatchedJobsComponent,
  canActivate: [TravelGuard]
};

const covidVaccine = {
  path: `${AppUrls.COVID}`,
  title: RouteTitles.COVID,
  component: CovidVaccineComponent
};

const fluVaccine = {
  path: `${AppUrls.FLU}`,
  title: RouteTitles.FLU,
  component: FluVaccineComponent
};

const education = {
  path: AppUrls.EDUCATION,
  title: RouteTitles.EDUCATION,
  component: EducationComponent,
  data: {
    options: <IRouteOptions>{
      backUrl: AppUrls.PROFILEMENU,
      showBackButton: true
    }
  }
};

const skillAssessment = {
  path: `${AppUrls.SKILLS}/:id`,
  title: RouteTitles.SKILL_ASSESSMENT,
  component: SkillAssessmentRedesignComponent,
  canActivate: [TravelGuard]
};

const skillsCheckList = {
  path: AppUrls.SKILLS,
  title: RouteTitles.SKILLS,
  component: SkillsCheckListComponent,
  data: {
    options: <IRouteOptions>{
      backUrl: AppUrls.PROFILEMENU,
      showBackButton: true
    }
  },
  canActivate: [TravelGuard]
};

const questionnaire = {
  path: AppUrls.QUESTIONNAIRE,
  title: RouteTitles.QUESTIONNAIRE,
  component: QuestionnaireComponent,
  canActivate: [TravelGuard]
};

const bulkUpload = {
  path: AppUrls.DROP_AND_GO,
  title: RouteTitles.DROP_AND_GO,
  component: BulkUploadComponent
};

const notFoundPage = {
  path: AppUrls.ERROR,
  title: RouteTitles.ERROR,
  component: NotFoundComponent,
  resolve: {
    data: NotFoundResolve
  }
};

const logout = {
  path: AppUrls.LOGOUT,
  title: 'logout',
  component: LogoutComponent
};

// This route is used as the redirectUri
const loginRedirect = {
  path: AppUrls.REDIRECT,
  title: RouteTitles.REDIRECT,
  component: LoginRedirectComponent
};

const documents = {
  path: AppUrls.DOCUMENTS,
  title: RouteTitles.DOCUMENTS,
  component: DocumentsComponent,
  canActivate: [TravelGuard]
};

const jobSearch = {
  path: `${AppUrls.JOB_SEARCH}`,
  title: RouteTitles.JOB_SEARCH,
  component: MainJobAreaComponent,
  data: {
    options: <IRouteOptions>{ hideFormHeader: true }
  },
  canActivate: [TravelGuard]
};

const tasksList: Route = {
  path: AppUrls.TASKS,
  title: RouteTitles.TASKS,
  component: TasksListComponent,
  canActivate: [TravelGuard]
};

const internalLoginRoute = {
  path: AppUrls.INTERNAL_LOGIN,
  title: RouteTitles.INTERNAL_LOGIN,
  component: InternalLoginComponent
};

const contactSelect = {
  path: AppUrls.CONTACT_SELECT,
  title: RouteTitles.CONTACT_SELECT,
  canActivate: [ContactSelectGuard],
  component: ContactSelectComponent
};
const support: Route = {
  path: AppUrls.SUPPORT,
  title: RouteTitles.SUPPORT,
  pathMatch: 'full',
  component: SupportComponent,
  resolve: {
    zendesk: ZendeskSupportResolver
  }
};

const inactiveUser = {
  path: AppUrls.INACTIVE_USER,
  title: RouteTitles.INACTIVE_USER,
  canActivate: [InactiveUserGuard],
  component: InactiveUserComponent
};

const settings = {
  path: AppUrls.SETTINGS,
  title: RouteTitles.SETTINGS,
  component: SettingsComponent,
  data: {
    options: <IRouteOptions>{ showBackButton: false }
  }
};

const enroll = {
  path: AppUrls.ENROLL,
  title: RouteTitles.ENROLL,
  component: EnrollComponent,
  canActivate: [EnrollGuard]
};

const noConnection = {
  path: AppUrls.NO_CONNECTION,
  title: RouteTitles.NO_CONNECTION,
  component: NoConnectionComponent
};

const loginError = {
  path: AppUrls.LOGIN_ERROR,
  title: RouteTitles.LOGIN_ERROR,
  component: LoginErrorComponent
};

// main content of the app. user is verified and welcomed
const registeredShell: Route = {
  path: '',
  children: [
    {
      path: AppUrls.DASHBOARD,
      title: RouteTitles.DASHBOARD,
      loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule)
    },
    {
      path: AppUrls.JOB_PREFERENCES,
      title: RouteTitles.PREFERENCES,
      loadChildren: () => import('./modules/preferences/job-preferences/job-preferences.module').then(m => m.JobPreferencesModule)
    },
    {
      path: AppUrls.PREFERENCES,
      title: RouteTitles.PREFERENCES,
      loadChildren: () => import('./modules/preferences/preferences.module').then(m => m.PreferencesModule)
    },
    {
      path: AppUrls.WORK_HISTORY,
      title: RouteTitles.WORK_HISTORY,
      loadChildren: () => import('./modules/work-history/work-history.module').then(m => m.WorkHistoryModule)
    },
    {
      path: AppUrls.REFERENCES,
      title: RouteTitles.REFERENCES,
      loadChildren: () => import('./modules/references/references.module').then(m => m.ReferenceModule),
      canActivate: [TravelGuard]
    },
    {
      path: AppUrls.LICENSES,
      title: RouteTitles.LICENSES,
      loadChildren: () => import('./modules/licenses/licenses.module').then(m => m.LicensesModule)
    },
    {
      path: AppUrls.CERTIFICATIONS,
      title: RouteTitles.CERTIFICATIONS,
      loadChildren: () => import('./modules/certifications/certifications.module').then(m => m.CertificationsModule)
    },
    {
      path: AppUrls.TECHNOLOGY,
      title: RouteTitles.TECHNOLOGY,
      loadChildren: () => import('./modules/technology/technology.module').then(m => m.TechnologyModule),
      canActivate: [TravelGuard]
    },
    jobs,
    covidVaccine,
    fluVaccine,
    skillsCheckList,
    education,
    questionnaire,
    savedJobs,
    bulkUpload,
    documents,
    jobSearch,
    matchedJobs,
    tasksList,
    applicationReview,
    applicationTasks,
    autoOfferConfirmation,
    mySubmittals,
    profile_menu,
    jobDetail,
    aboutMe,
    support,
    skillAssessment,
    professionalOverview,
    settings,
    similarJobs,
    {
      path: 'on-assignment',
      loadChildren: () => import('./onassignment-ui-wrapper.module').then(m => m.OnAssignmentUiWrapperModule),
      canActivate: [OnAssignmentGuard]
    },
    {
      path: 'time-tracking',
      redirectTo: 'on-assignment/time-tracking'
    },
    {
      path: 'expense-management',
      redirectTo: 'on-assignment/expense-management'
    },
    {
      path: AppUrls.NURSYS_SETUP,
      redirectTo: `${AppUrls.LICENSES}/${AppUrls.NURSYS_SETUP}`
    },
    {
      path: '',
      redirectTo: AppUrls.DASHBOARD,
      pathMatch: 'full'
    },
    {
      path: '**',
      redirectTo: AppUrls.DASHBOARD,
      pathMatch: 'full'
    }
  ]
};

const nurseContent = {
  path: '',
  component: MainComponent,
  canActivate: [MainGuard],
  canActivateChild: [SanitizeParamGuard],
  children: [
    {
      path: '',
      children: [
        {
          path: AppUrls.VERIFY,
          loadChildren: () => import('./modules/verification/verification.module').then(m => m.VerificationModule)
        },
        registeredShell
      ]
    }
  ]
};

const authenticatedShell = {
  path: '',
  canActivate: [AppInitGuard],
  children: [
    {
      path: AppUrls.REGISTRATION,
      loadChildren: () => import('./modules/registration/registration.module').then(m => m.RegistrationModule)
    },
    loginError,
    inactiveUser,
    multpleFound,
    contactSelect,
    nurseContent
  ]
};

const referenceQuestionnaire: Route = {
  path: 'referenceQuestionnaire',
  component: ReferenceQuestionnaireComponent,
  resolve: {
    data: ReferenceQuestionnaireResolve
  },
  canActivate: [TravelGuard]
};

const authGuarded: Route = {
  path: '',
  canActivate: [AuthGuard],
  children: [authenticatedShell]
};

const pastAppDown: Route = {
  path: '',
  children: [loginRedirect, logout, referenceQuestionnaire, notFoundPage, internalLoginRoute, resetPassword, enroll, authGuarded, noConnection]
};

const routes: Routes = [pastAppDown];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'disabled',
      relativeLinkResolution: 'legacy'
    })
  ], // if enabled make sure appinsights tracks for navigation correctly
  exports: [RouterModule]
})
export class AppRoutingModule {}
