import { AppUrls } from 'src/app/app-urls';
import { API_ROUTES } from '../api-routes';

export enum TaskEntities {
  overlap = 1,
  gap = 2,
  contactAboutMe = 948050000,
  contactProfessionalOverview = 948050014,
  education = 948050001,
  candidateTechnologySkill = 948050002,
  certification = 948050003,
  license = 948050004,
  backgroundQuestionnaire = 948050005,
  employmentHistory = 948050006,
  reference = 948050012,
  desiredLocations = 948050009,
  candidateQualifications = 948050008,
  portalDetails = 948050013,
  skills = -1,
  covidVaccine = 948050015,
  jobPreference = 948050016,
  fluVaccine = 948050017
}

export const taskURLMap = new Map<number, string>()
  .set(TaskEntities.overlap, `/${AppUrls.WORK_HISTORY}/edit/`)
  .set(TaskEntities.gap, `/${AppUrls.WORK_HISTORY}`)
  .set(TaskEntities.contactAboutMe, AppUrls.ABOUT_ME)
  .set(TaskEntities.contactProfessionalOverview, AppUrls.PROFESSIONAL_OVERVIEW)
  .set(TaskEntities.education, `/${AppUrls.EDUCATION}`)
  .set(TaskEntities.candidateTechnologySkill, `/${AppUrls.TECHNOLOGY}`)
  .set(TaskEntities.license, `/${AppUrls.LICENSES}`)
  .set(TaskEntities.certification, `/${AppUrls.CERTIFICATIONS}`)
  .set(TaskEntities.backgroundQuestionnaire, `/${AppUrls.QUESTIONNAIRE}`)
  .set(TaskEntities.employmentHistory, `/${AppUrls.WORK_HISTORY}`)
  .set(TaskEntities.reference, `/${AppUrls.REFERENCES}`)
  .set(TaskEntities.desiredLocations, `/${AppUrls.JOBS}`)
  .set(TaskEntities.skills, `/${AppUrls.SKILLS}`)
  .set(TaskEntities.covidVaccine, `/${AppUrls.COVID}`)
  .set(TaskEntities.jobPreference, `/${AppUrls.PREFERENCES}/${AppUrls.JOB_PREFERENCES}`)
  .set(TaskEntities.fluVaccine, `/${AppUrls.FLU}`);

export const taskEndpoints = [
  API_ROUTES.work, // for work history
  `/about-me`,
  `/professional-overview`,
  API_ROUTES.education,
  API_ROUTES.technology,
  API_ROUTES.licenses,
  API_ROUTES.certifications,
  API_ROUTES.background, //for questionnaire
  API_ROUTES.references,
  API_ROUTES.skills,
  API_ROUTES.jobPreferences,
  API_ROUTES.files // for covid and flu
];
