<button class="close" mat-button (click)="closeHelpModal()" tabindex="-1">
  <mat-icon class="close-icon">close</mat-icon>
</button>
<div fxFlex fxLayout="column" id="main-content" fxLayoutGap="10px">
  <div fxFlex fxLayout="column">
    <h3 class="hc-h3 no-margin">{{ data.title }}</h3>
    <div class="hc-body margin-top-13-px">{{ data.text }}</div>
    <div class="hc-small hc-subdued">{{ data.subtext }}</div>
  </div>
  <div fxFlex fxLayout="row" *ngFor="let paragraph of data.paragraphs">
    <div fxLayoutAlign="center start" fxFlex="10%">
      <mat-icon class="{{ paragraph.icon_class }}">{{ paragraph.icon }}</mat-icon>
    </div>
    <div fxFlex="90%" class="hc-body">
      <h4 class="hc-h4 no-margin">{{ paragraph.title }}</h4>
      <span>{{ paragraph.text }}</span>
    </div>
  </div>
  <div *ngIf="data.badges" fxLayout="column">
    <h3 class="hc-h3 no-margin-top">{{ data.badgeTitle }}</h3>
    <div fxFlex fxLayout="row" *ngFor="let badge of data.badges">
      <div fxFlex fxLayout="column">
        <div class="status {{ badge.css_class }}" fxLayout="column" fxLayoutAlign="start center">{{ badge.badge }}</div>
        <div class="hc-body margin-bottom" fxLayout="column">{{ badge.text }}</div>
      </div>
    </div>
  </div>
  <div *ngIf="data.statuses" fxLayout="column">
    <h3 class="hc-h3 margin-top-10-px">{{ data.statusTitle }}</h3>
    <div fxFlex fxLayout="row" *ngFor="let status of data.statuses">
      <div fxFlex fxLayout="column">
        <div class="status modal-status {{ status.css_class }}" fxLayout="column" fxLayoutAlign="start center">{{ status.status }}</div>
        <div class="hc-body margin-bottom" fxLayout="column">{{ status.text }}</div>
      </div>
    </div>
  </div>
</div>
