import { IUserModel, NurseModel } from 'src/app/common';
import { EUserContextActions, UserContextActions } from 'src/app/store/userContext/userContext.actions';
import { RecruiterActions } from 'src/app/store/recruiter/recruiter.actions';
import { initialUserContextState, IUserContextState } from 'src/app/store/userContext/userContext.state';
import { mapNurseINDataToTN } from 'src/app/common/functions/nurse-helpers';

export function userContextReducer(state = initialUserContextState, action: UserContextActions | RecruiterActions): IUserContextState {
  switch (action.type) {
    case EUserContextActions.NukeState:
      return initialUserContextState;

    case EUserContextActions.GetAccountState:
      return {
        ...state,
        accountStateData: {
          ...state.accountStateData,
          error: null,
          loading: true
        }
      };

    case EUserContextActions.GetAccountStateSuccess:
      return {
        ...state,
        accountStateData: {
          data: action.payload,
          error: null,
          loading: false
        }
      };

    case EUserContextActions.GetAccountStateError:
      return {
        ...state,
        accountStateData: {
          ...state.accountStateData,
          error: action.error,
          loading: false
        }
      };

    case EUserContextActions.GetUser:
      return {
        ...state,
        userData: {
          ...state.userData,
          error: null,
          loading: true
        }
      };

    case EUserContextActions.GetUserSuccess:
      return {
        ...state,
        userData: {
          data: action.payload,
          error: null,
          loading: false
        }
      };

    case EUserContextActions.GetUserError:
      return {
        ...state,
        userData: {
          ...state.userData,
          error: action.error,
          loading: false
        }
      };

    case EUserContextActions.GetUserIN:
      return {
        ...state,
        userDataIN: {
          ...state.userDataIN,
          error: null,
          loading: true
        }
      };

    case EUserContextActions.GetUserINSuccess:
      return {
        ...state,
        userDataIN: {
          data: action.payload,
          error: null,
          loading: false
        }
      };

    case EUserContextActions.GetUserINError:
      return {
        ...state,
        userDataIN: {
          ...state.userDataIN,
          error: action.error,
          loading: false
        }
      };

    case EUserContextActions.CreateAdUser:
      return {
        ...state,
        userAdResult: {
          data: null,
          error: null,
          loading: true
        }
      };

    case EUserContextActions.CreateAdUserSuccess:
      return {
        ...state,
        userAdResult: {
          data: action.payload,
          error: null,
          loading: false
        }
      };

    case EUserContextActions.CreateAdUserError:
      return {
        ...state,
        userAdResult: {
          data: null,
          error: action.error,
          loading: false
        }
      };

    case EUserContextActions.SetUserJobNotificationsActive:
      return {
        ...state,
        userData: {
          ...state.userData,
          data: {
            ...state.userData.data,
            jobNotificationsActive: action.payload
          }
        }
      };

    case EUserContextActions.GetNurse:
      return {
        ...state,
        nurseData: {
          ...state.nurseData,
          error: null,
          loading: true
        }
      };

    case EUserContextActions.GetNurseSuccess:
      return {
        ...state,
        nurseData: {
          data: action.payload,
          error: null,
          loading: false
        }
      };

    case EUserContextActions.GetNurseError:
      return {
        ...state,
        nurseData: {
          ...state.nurseData,
          error: action.error,
          loading: false
        }
      };

    case EUserContextActions.GetNurseIN:
      return {
        ...state,
        nurseINData: {
          ...state.nurseINData,
          error: null,
          loading: true
        }
      };

    case EUserContextActions.GetNurseINSuccess:
      return {
        ...state,
        nurseINData: {
          data: mapNurseINDataToTN(action.payload),
          error: null,
          loading: false
        }
      };

    case EUserContextActions.GetNurseINError:
      return {
        ...state,
        nurseINData: {
          ...state.nurseINData,
          error: action.error,
          loading: false
        }
      };

    case EUserContextActions.ContactSelectReset:
      return {
        ...state,
        contactSelect: {
          ...state.contactSelect,
          error: null,
          loading: true
        }
      };

    case EUserContextActions.ContactSelectResetSuccess:
      return {
        ...state,
        contactSelect: {
          data: action.response,
          error: null,
          loading: false
        }
      };

    case EUserContextActions.ContactSelectResetError:
      return {
        ...state,
        contactSelect: {
          data: null,
          error: action.error,
          loading: false
        }
      };

    case EUserContextActions.GetRegistration:
      return {
        ...state,
        registrationGetData: {
          ...state.registrationGetData,
          error: null,
          loading: true
        }
      };

    case EUserContextActions.GetRegistrationSuccess:
      return {
        ...state,
        registrationGetData: {
          data: action.payload,
          error: null,
          loading: false
        }
      };

    case EUserContextActions.GetRegistrationError:
      return {
        ...state,
        registrationGetData: {
          ...state.registrationGetData,
          error: action.error,
          loading: false
        }
      };

    case EUserContextActions.SubmitRegistration:
      return {
        ...state,
        registrationResult: {
          ...state.registrationResult,
          error: null,
          loading: true
        }
      };

    case EUserContextActions.SubmitRegistrationSuccess:
      return {
        ...state,
        registrationResult: {
          data: action.payload,
          error: null,
          loading: false
        },
        nurseData: {
          data: null,
          error: null,
          loading: false
        },
        userData: {
          data: null,
          error: null,
          loading: false
        },
        accountStateData: {
          data: null,
          error: null,
          loading: false
        }
      };

    case EUserContextActions.SubmitRegistrationError:
      return {
        ...state,
        registrationResult: {
          ...state.registrationResult,
          error: action.error,
          loading: false
        }
      };

    case EUserContextActions.UnlockRegistration:
      return {
        ...state,
        unlockRegistrationResult: {
          ...state.unlockRegistrationResult,
          error: null,
          loading: true
        }
      };

    case EUserContextActions.UnlockRegistrationSuccess:
      return {
        ...state,
        unlockRegistrationResult: {
          ...state.unlockRegistrationResult,
          error: null,
          loading: false
        }
      };

    case EUserContextActions.UnlockRegistrationError:
      return {
        ...state,
        unlockRegistrationResult: {
          ...state.unlockRegistrationResult,
          error: action.error,
          loading: false
        }
      };

    case EUserContextActions.SetNurseProfilePicture:
      return {
        ...state,
        nurseData: {
          data: {
            ...state.nurseData.data,
            profilePicture: action.payload
          } as NurseModel,
          error: null,
          loading: false
        },
        nurseINData: {
          data: {
            ...state.nurseINData.data,
            profilePicture: action.payload
          } as NurseModel,
          error: null,
          loading: false
        }
      };

    case EUserContextActions.SetNurseIsVerified:
      return {
        ...state,
        nurseData: {
          ...state.nurseData,
          data: {
            ...state.nurseData.data,
            isVerified: action.payload
          } as NurseModel
        }
      };

    case EUserContextActions.LogOut:
      return {
        ...state,
        userData: {
          data: { ...state.userData.data } as IUserModel,
          error: null,
          loading: true
        },
        nurseData: {
          data: { ...state.nurseData.data } as NurseModel,
          error: null,
          loading: true
        }
      };

    case EUserContextActions.SetUserIsNewToFalse:
      return {
        ...state,
        nurseData: {
          ...state.nurseData,
          data: { ...state.nurseData.data, isNew: false } as NurseModel
        }
      };

    case EUserContextActions.LogOutSuccess:
      return {
        ...state,
        userData: {
          data: null,
          error: null,
          loading: false
        },
        nurseData: {
          data: null,
          error: null,
          loading: false
        }
      };

    case EUserContextActions.LogOutError:
      return {
        ...state,
        userData: {
          data: { ...state.userData.data } as IUserModel,
          error: action.error,
          loading: false
        },
        nurseData: {
          data: { ...state.nurseData.data } as NurseModel,
          error: action.error,
          loading: false
        }
      };

    case EUserContextActions.InitializeUser:
      return {
        ...state,
        userInitialized: {
          ...state.userInitialized,
          loading: true
        }
      };

    case EUserContextActions.InitializeUserSuccess:
      return {
        ...state,
        userInitialized: {
          error: null,
          data: true,
          loading: false
        }
      };

    case EUserContextActions.InitializeUserError:
      return {
        ...state,
        userInitialized: {
          error: action.error,
          data: false,
          loading: false
        }
      };

    case EUserContextActions.SetIsReferred:
      return {
        ...state,
        isReferred: {
          data: action.isReferred,
          error: null,
          loading: false
        }
      };

    case EUserContextActions.GetProfessionalOverview:
      return {
        ...state,
        professionalOverview: {
          ...state.professionalOverview,
          error: null,
          loading: true
        }
      };

    case EUserContextActions.GetProfessionalOverviewSuccess:
      return {
        ...state,
        professionalOverview: {
          data: action.profileData,
          error: null,
          loading: false
        }
      };

    case EUserContextActions.GetProfessionalOverviewError:
      return {
        ...state,
        professionalOverview: {
          ...state.professionalOverview,
          error: action.error,
          loading: false
        }
      };

    case EUserContextActions.UpdateSummaryData:
      return {
        ...state,
        updateSummaryData: {
          ...state.updateSummaryData,
          error: null,
          loading: true
        }
      };

    case EUserContextActions.UpdateSummarySuccess:
      return {
        ...state,
        updateSummaryData: {
          data: action.summaryData,
          error: null,
          loading: false
        }
      };

    case EUserContextActions.UpdateSummaryError:
      return {
        ...state,
        updateSummaryData: {
          ...state.updateSummaryData,
          loading: false,
          error: action.error
        }
      };

    case EUserContextActions.UpdateSummaryReset:
      return {
        ...state,
        updateSummaryData: {
          data: null,
          loading: false,
          error: null
        }
      };

    case EUserContextActions.GetSummaryPrompts:
      return {
        ...state,
        summaryPrompts: {
          ...state.summaryPrompts,
          error: null,
          loading: true
        }
      };

    case EUserContextActions.GetSummaryPromptsSuccess:
      return {
        ...state,
        summaryPrompts: {
          data: action.summaryPrompts,
          error: null,
          loading: false
        }
      };

    case EUserContextActions.GetSummaryPromptsError:
      return {
        ...state,
        summaryPrompts: {
          ...state.summaryPrompts,
          loading: false,
          error: action.error
        }
      };

    case EUserContextActions.SendResetPasswordEmail:
      return {
        ...state,
        sendResetPasswordEmailResult: {
          ...state.sendResetPasswordEmailResult,
          error: null,
          loading: true
        }
      };

    case EUserContextActions.SendResetPasswordEmailSuccess:
      return {
        ...state,
        sendResetPasswordEmailResult: {
          ...state.sendResetPasswordEmailResult,
          error: null,
          loading: false
        }
      };

    case EUserContextActions.SendResetPasswordEmailError:
      return {
        ...state,
        sendResetPasswordEmailResult: {
          ...state.sendResetPasswordEmailResult,
          error: action.error,
          loading: false
        }
      };

    case EUserContextActions.GetQuestionnaire:
      return {
        ...state,
        questionnaire: {
          ...state.questionnaire,
          error: null,
          loading: true
        }
      };

    case EUserContextActions.GetQuestionnaireSuccess:
      return {
        ...state,
        questionnaire: {
          data: action.data,
          error: null,
          loading: false
        }
      };

    case EUserContextActions.GetQuestionnaireError:
      return {
        ...state,
        questionnaire: {
          ...state.questionnaire,
          error: action.error,
          loading: false
        }
      };

    case EUserContextActions.UpdateQuestionnaire:
      return {
        ...state,
        updateQuestionnaire: {
          ...state.updateQuestionnaire,
          error: null,
          loading: true
        }
      };

    case EUserContextActions.UpdateQuestionnaireSuccess:
      return {
        ...state,
        updateQuestionnaire: {
          data: action.response,
          error: null,
          loading: false
        },
        questionnaire: {
          ...state.questionnaire,
          data: action.data
        }
      };

    case EUserContextActions.UpdateQuestionnaireError:
      return {
        ...state,
        updateQuestionnaire: {
          ...state.updateQuestionnaire,
          error: action.error,
          loading: false
        }
      };

    case EUserContextActions.UpdateQuestionnaireReset:
      return {
        ...state,
        updateQuestionnaire: {
          data: null,
          loading: false,
          error: null
        }
      };

    case EUserContextActions.GetAboutMe:
      return {
        ...state,
        aboutMe: {
          ...state.aboutMe,
          error: null,
          loading: true
        }
      };

    case EUserContextActions.GetAboutMeSuccess:
      return {
        ...state,
        aboutMe: {
          data: action.payload,
          error: null,
          loading: false
        }
      };

    case EUserContextActions.GetAboutMeError:
      return {
        ...state,
        aboutMe: {
          ...state.aboutMe,
          error: action.error,
          loading: false
        }
      };

    case EUserContextActions.UpdateAboutMe:
      return {
        ...state,
        updateAboutMeTask: {
          data: null,
          loading: true,
          error: null
        }
      };

    case EUserContextActions.UpdateAboutMeSuccess:
      return {
        ...state,
        updateAboutMeTask: {
          data: action.response,
          error: null,
          loading: false
        }
      };

    case EUserContextActions.UpdateAboutMeError:
      return {
        ...state,
        updateAboutMeTask: {
          ...state.updateAboutMeTask,
          error: action.error,
          loading: false
        }
      };

    case EUserContextActions.UpdateAboutMeReset:
      return {
        ...state,
        updateAboutMeTask: {
          data: null,
          loading: false,
          error: null
        }
      };

    case EUserContextActions.UploadProfilePicture:
      return {
        ...state,
        uploadProfilePicture: {
          ...state.uploadProfilePicture,
          error: null,
          loading: true
        }
      };

    case EUserContextActions.UploadProfilePictureSuccess:
      return {
        ...state,
        uploadProfilePicture: {
          ...state.uploadProfilePicture,
          error: null,
          loading: false
        }
      };

    case EUserContextActions.UploadProfilePictureError:
      return {
        ...state,
        uploadProfilePicture: {
          ...state.uploadProfilePicture,
          error: action.error,
          loading: false
        }
      };

    case EUserContextActions.GetDocuments:
      return {
        ...state,
        documents: {
          ...state.documents,
          error: null,
          loading: true
        }
      };

    case EUserContextActions.GetDocumentsSuccess:
      return {
        ...state,
        documents: {
          data: action.payload,
          error: null,
          loading: false
        }
      };

    case EUserContextActions.GetDocumentsError:
      return {
        ...state,
        documents: {
          ...state.documents,
          error: action.error,
          loading: false
        }
      };

    case EUserContextActions.GetDocumentsByID:
      return {
        ...state,
        documentsByID: {
          ...state.documentsByID,
          error: null,
          loading: true
        }
      };

    case EUserContextActions.GetDocumentsByIDSuccess:
      return {
        ...state,
        documentsByID: {
          data: action.payload,
          error: null,
          loading: false
        }
      };

    case EUserContextActions.GetDocumentsByIDError:
      return {
        ...state,
        documentsByID: {
          ...state.documentsByID,
          error: action.error,
          loading: false
        }
      };
    case EUserContextActions.BulkUploadDocuments:
      return {
        ...state,
        bulkUploadDocuments: {
          data: action.payload,
          error: null,
          loading: true
        }
      };
    case EUserContextActions.BulkUploadDocumentsSuccess:
      return {
        ...state,
        bulkUploadDocuments: {
          ...state.bulkUploadDocuments,
          data: null,
          error: null,
          loading: false
        }
      };
    case EUserContextActions.BulkUploadDocumentsError:
      return {
        ...state,
        bulkUploadDocuments: {
          ...state.bulkUploadDocuments,
          error: action.error,
          loading: false
        }
      };
    case EUserContextActions.SetShowSpecialtyPopup:
      return {
        ...state,
        showSpecialtyPopup: {
          data: action.showSpecialtyPopup,
          error: null,
          loading: false
        }
      };

    case EUserContextActions.SendVerificationSupportEmail:
      return {
        ...state,
        verificationSupportEmailSent: {
          data: null,
          error: null,
          loading: true
        }
      };
    case EUserContextActions.SendVerificationSupportEmailSuccess:
      return {
        ...state,
        verificationSupportEmailSent: {
          data: null,
          error: null,
          loading: false
        }
      };
    case EUserContextActions.SendVerificationSupportEmailError:
      return {
        ...state,
        verificationSupportEmailSent: {
          data: null,
          error: action.error,
          loading: false
        }
      };

    case EUserContextActions.SendVerificationEmail:
      return {
        ...state,
        verificationEmailSent: {
          data: null,
          error: null,
          loading: true
        }
      };
    case EUserContextActions.SendVerificationEmailSuccess:
      return {
        ...state,
        verificationEmailSent: {
          data: null,
          error: null,
          loading: false
        }
      };
    case EUserContextActions.SendVerificationEmailError:
      return {
        ...state,
        verificationEmailSent: {
          data: null,
          error: action.error,
          loading: false
        }
      };

    case EUserContextActions.SendVerificationSms:
      return {
        ...state,
        verificationSmsSent: {
          data: null,
          error: null,
          loading: true
        }
      };
    case EUserContextActions.SendVerificationSmsSuccess:
      return {
        ...state,
        verificationSmsSent: {
          data: null,
          error: null,
          loading: false
        }
      };
    case EUserContextActions.SendVerificationSmsError:
      return {
        ...state,
        verificationSmsSent: {
          data: null,
          error: action.error,
          loading: false
        }
      };

    case EUserContextActions.SetVerificationType:
      return {
        ...state,
        verificationTypeResult: {
          data: null,
          error: null,
          loading: true
        }
      };
    case EUserContextActions.SetVerificationTypeSuccess:
      return {
        ...state,
        verificationTypeResult: {
          data: action.response,
          error: null,
          loading: false
        }
      };
    case EUserContextActions.SetVerificationTypeError:
      return {
        ...state,
        verificationTypeResult: {
          data: null,
          error: action.error,
          loading: false
        }
      };

    case EUserContextActions.CheckVerificationKey:
      return {
        ...state,
        verificationCheckResult: {
          data: null,
          error: null,
          loading: true
        }
      };
    case EUserContextActions.CheckVerificationKeySuccess:
      return {
        ...state,
        verificationCheckResult: {
          data: action.response,
          error: null,
          loading: false
        }
      };
    case EUserContextActions.CheckVerificationKeyError:
      return {
        ...state,
        verificationCheckResult: {
          data: null,
          error: action.error,
          loading: false
        }
      };

    case EUserContextActions.GetContactPreferences:
    case EUserContextActions.UpdateContactPreferences:
      return {
        ...state,
        contactPreferences: {
          ...state.contactPreferences,
          error: null,
          loading: true
        }
      };
    case EUserContextActions.GetContactPreferencesSuccess:
    case EUserContextActions.UpdateContactPreferencesSuccess:
      return {
        ...state,
        contactPreferences: {
          data: action.payload,
          error: null,
          loading: false
        }
      };
    case EUserContextActions.GetContactPreferencesError:
    case EUserContextActions.UpdateContactPreferencesError:
      return {
        ...state,
        contactPreferences: {
          ...state.contactPreferences,
          error: action.error,
          loading: false
        }
      };

    case EUserContextActions.PostRegistrationCheck:
      return {
        ...state,
        registrationCheck: {
          ...state.registrationCheck,
          error: null,
          loading: true
        },
        accountStateData: {
          data: null,
          error: null,
          loading: false
        }
      };
    case EUserContextActions.PostRegistrationCheckSuccess:
      return {
        ...state,
        registrationCheck: {
          data: action.payload,
          error: null,
          loading: false
        },
        accountStateData: {
          data: null,
          error: null,
          loading: false
        }
      };
    case EUserContextActions.PostRegistrationCheckError:
      return {
        ...state,
        registrationCheck: {
          ...state.registrationCheck,
          error: action.error,
          loading: false
        }
      };

    default:
      return state;
  }
}
