import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavHelper } from 'src/app/services';
import { JobAreaContext } from 'src/app/services/job-area-context.service';
import { IJob } from 'src/app/common';
import { CardComponentConfig } from '../../cards/card-template/card-template.component';

@Component({
  selector: 'app-matched-jobs',
  templateUrl: './matched-jobs.component.html',
  styleUrls: ['./matched-jobs.component.scss']
})
export class MatchedJobsComponent implements OnInit {
  @Input() jobs: Array<IJob>;
  jobConfig: CardComponentConfig;

  constructor(
    public _route: ActivatedRoute,
    public _ctx: JobAreaContext,
    private _nav: NavHelper
  ) {}

  ngOnInit() {
    this.jobConfig = {
      showStatus: false,
      showInfoDetails: true,
      showTransferApplication: false,
      showBadges: true,
      showLinks: false
    };
  }

  getSpecialty(job: IJob): string {
    return this._ctx.getSpecialty(job.specialty);
  }

  getJob(id: string) {
    this._nav.goToJobsSpecific(id);
  }
}
