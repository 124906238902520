import { Action } from '@ngrx/store';
import { ZipCodeModel } from 'src/app/common/models/zip-code-model';

export enum ESharedDataActions {
  GetZipCodeInfo = '[SHARED DATA] get zip code info',
  GetZipCodeInfoSuccess = '[SHARED DATA] get zip code info success',
  GetZipCodeInfoError = '[SHARED DATA] get zip code info error'
}

export class GetZipCodeInfo implements Action {
  public readonly type = ESharedDataActions.GetZipCodeInfo;

  constructor(public payload: string) {}
}

export class GetZipCodeInfoSuccess implements Action {
  public readonly type = ESharedDataActions.GetZipCodeInfoSuccess;

  constructor(public response: ZipCodeModel[]) {}
}

export class GetZipCodeInfoError implements Action {
  public readonly type = ESharedDataActions.GetZipCodeInfoError;

  constructor(public error: Error) {}
}

export type SharedDataActions = GetZipCodeInfo | GetZipCodeInfoSuccess | GetZipCodeInfoError;
