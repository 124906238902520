import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { takeWhile, filter } from 'rxjs/operators';

/*
 * Service to keep track of the first page that the user came to
 */
@Injectable({
  providedIn: 'root'
})
export class AppEntryService {
  private _hasNavigated = false;

  initialUrl: string;

  constructor(private router: Router) {}

  /*
   * This sets the value for the url that the user first came to, and listens for when the user navigates
   * to another page.
   *
   * @param the url of the first access route on the site (window.location.href)
   */
  public setInitial(url: string): void {
    this.initialUrl = url;

    // This code listens for the navigation to complete. If the page that we land on is different than
    // the initial url (set at app init), then we can assume that we've already navigated. So, we should
    // stop subscribing to the navigation after that
    this.router.events
      .pipe(filter(e => e instanceof NavigationEnd))
      // eslint-disable-next-line rxjs/no-ignored-takewhile-value
      .pipe(takeWhile(() => !this._hasNavigated))
      .subscribe((event: NavigationEnd) => {
        // Example
        // event.url = /jobs/(id)
        // initialUrl = http://(host)/jobs/(id)
        if (!this.initialUrl.endsWith(event.url)) {
          this._hasNavigated = true;
        }
      });
  }

  /**
   * True if the user has accessed a non-dashboard link fromouts
   *
   * @returns true if the user just came from outside
   */
  public justCameFromOutside(): boolean {
    if (this.initialUrl === undefined || this.initialUrl.endsWith('/dashboard') || this._hasNavigated) {
      return false;
    }

    return true;
  }
}
