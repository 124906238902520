import {
  AboutMeModel,
  CandidateQualification,
  IAdUserResponseModel,
  IRegistrationModel,
  IRegistrationResponseModel,
  IUserModel,
  NurseModel,
  NurseProfileModel,
  PreEmploymentQuestionnaire,
  TaskCompletionReturnObject,
  UserContextIN
} from 'src/app/common';
import { VerificationStatus } from 'src/app/common/constants';
import { IAccountStateModel } from 'src/app/common/models/account-state-model';
import { CommunicationPreferences } from 'src/app/common/models/communication-preferences';
import { GenericCompletionReturnObject } from 'src/app/common/models/generic-completion-return-model';
import { initializeData, IDataState } from 'src/app/store/app/app.models';

export interface IUserContextState {
  accountStateData: IDataState<IAccountStateModel>;
  userData: IDataState<IUserModel>;
  userAdResult: IDataState<IAdUserResponseModel>;
  userDataIN: IDataState<UserContextIN>;
  nurseData: IDataState<NurseModel>;
  nurseINData: IDataState<NurseModel>;
  contactSelect: IDataState<any>;
  registrationResult: IDataState<IRegistrationResponseModel>;
  registrationGetData: IDataState<IRegistrationModel>;
  userInitialized: IDataState<boolean>;
  unlockRegistrationResult: IDataState<null>;
  isReferred: IDataState<boolean>;
  professionalOverview: IDataState<NurseProfileModel>;
  updateSummaryData: IDataState<TaskCompletionReturnObject>;
  summaryPrompts: IDataState<string[]>;
  sendResetPasswordEmailResult: IDataState<null>;
  questionnaire: IDataState<PreEmploymentQuestionnaire>;
  updateQuestionnaire: IDataState<GenericCompletionReturnObject<number>>;
  aboutMe: IDataState<AboutMeModel>;
  updateAboutMeTask: IDataState<TaskCompletionReturnObject>;
  uploadProfilePicture: IDataState<null>;
  documents: IDataState<CandidateQualification[]>;
  documentsByID: IDataState<Response>;
  showSpecialtyPopup: IDataState<boolean>;
  verificationSupportEmailSent: IDataState<null>;
  verificationEmailSent: IDataState<null>;
  verificationSmsSent: IDataState<null>;
  verificationTypeResult: IDataState<number>;
  verificationCheckResult: IDataState<{
    message: string;
    status: VerificationStatus;
  }>;
  deleteUserData: IDataState<any>;
  contactPreferences: IDataState<CommunicationPreferences>;
  registrationCheck: IDataState<number>;
  bulkUploadDocuments: IDataState<any>;
}

export const initialUserContextState: IUserContextState = {
  accountStateData: initializeData(),
  userData: initializeData(),
  userAdResult: initializeData(),
  userDataIN: initializeData(),
  nurseData: initializeData(),
  nurseINData: initializeData(),
  contactSelect: initializeData(),
  registrationResult: initializeData(),
  registrationGetData: initializeData(),
  userInitialized: initializeData(),
  unlockRegistrationResult: initializeData(),
  isReferred: initializeData(),
  professionalOverview: initializeData(),
  updateSummaryData: initializeData(),
  summaryPrompts: initializeData(),
  sendResetPasswordEmailResult: initializeData(),
  questionnaire: initializeData(),
  updateQuestionnaire: initializeData(),
  aboutMe: initializeData(),
  updateAboutMeTask: initializeData(),
  uploadProfilePicture: initializeData(),
  documents: initializeData(),
  documentsByID: initializeData(),
  showSpecialtyPopup: initializeData(),
  verificationSupportEmailSent: initializeData(),
  verificationEmailSent: initializeData(),
  verificationSmsSent: initializeData(),
  verificationTypeResult: initializeData(),
  verificationCheckResult: initializeData(),
  deleteUserData: initializeData(),
  contactPreferences: initializeData(),
  registrationCheck: initializeData(),
  bulkUploadDocuments: initializeData()
};
