export const API_ROUTES = {
  my: 'my/',
  lookups: 'lookups/',
  users: 'users/',
  registration: 'registration/',
  licenses: 'license/',
  certifications: 'certifications/',
  skills: 'skills/',
  profile: 'profile/',
  references: 'references/',
  work: 'work/',
  nursys: 'nursys/',
  education: 'education/',
  technology: 'technology/',
  task: 'tasks/',
  token: 'token/',
  questionnaire: 'reference-questionnaire',
  job: 'job/',
  files: 'files',
  background: 'background-questionnaire',
  google: 'google/',
  dashboard: 'dashboard/',
  submittals: 'submittals/',
  hospital: 'hospital',
  support: 'support/',
  Assignments: 'assignments',
  App: 'app',
  admin: 'admin',
  verify: 'verify',
  cupid: 'cupid/',
  jobPreferences: 'job-preferences',
  contacts: 'contacts/',
  apply: 'apply',
  updateApplication: 'updateApplication',
  similarJob: 'similar-job'
};
