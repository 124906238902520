import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, skipWhile } from 'rxjs/operators';
import { IAppState } from 'src/app/store/app/app.state';
import { selectNurseData } from 'src/app/store/userContext/userContext.selectors';
import { AppUrls } from '../app-urls';
import { NurseModel } from '../common';

@Injectable()
export class OnAssignmentGuard implements CanActivate {
  constructor(
    private _router: Router,
    private _store: Store<IAppState>
  ) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | UrlTree {
    return this._store.select(selectNurseData).pipe(
      skipWhile(nurse => nurse == null),
      map((nurse: NurseModel) => (nurse.hasAssignment ? true : this._router.parseUrl(`/${AppUrls.DASHBOARD}`)))
    );
  }
}
