export class NurseTask {
  name: string;
  status: number;
  value: number[];
  id?: string;
  path: number;
  complianceRequirementId: string;
  licenseCertificationId: string;
  description: string;
  requiredForPay: boolean;
  requiredForApply: boolean;
  lastUpdatedDate?: Date;
  pendingTaskInfo?: string;
  friendlyName: string;
  portalCategoryCodes?: number[];
}

export class VaccineTask {
  qualificationID?: string; // GUID
  name: string;
  status: number;
  documentUrl: string;
}
