import { ZipCodeModel } from './zip-code-model';

export class HcinContactModel {
  id: string;
  emailAddress: string;
  merlinId: string;
  contactId: string;
  isWelcomed: boolean;
  isVerified: boolean;
  businessUnitId?: string;
  firstName: string;
  lastName: string;
  preferredName: string;
  pronoun: string;
  otherPronouns: string;
  gender: number;
  hasTimeMobile: boolean;
  isNew?: boolean;
  teamId?: string;
  nursys: any;
  hasExpenseManagement: boolean;
  isPayVisible: boolean;
  recruiter: string;
  inactive: boolean;
  professionId: string;
  primarySpecialtyId: string;
  secondarySpecialtyId: string;
  primarySubSpecialtyId: string;
  secondarySubSpecialtyId: string;
  hubSpotId: string;
  profilePicture: string;
  isReferred: boolean;
  phoneNumber: string;
  verificationType?: number;
  dateOfBirth?: Date;
  isMailingAddressSameAsTax = false;
  mailingAddress1: string;
  mailingAddress2?: string;
  mailingCity: string;
  mailingState: string;
  mailingZipCode: string;
  homeAddress1: string;
  homeAddress2: string;
  homeCity: string;
  homeState: string;
  homeZipCode: ZipCodeModel;
  createdDate?: Date;
  hasAssignment: boolean;
  lifeCycleStageId: string;
  lifeCycleSubStageId: string;
  portalStatus: string;
  ssn: string;
  ownerId: string;
  stateid: string;
  hasNotArrivedInUS: boolean;
}
