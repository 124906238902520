import { ECertificationsActions, CertificationsActions } from './certifications.actions';
import { initialCertificationsState, ICertificationsState } from './certifications.state';

export function certificationsReducer(state = initialCertificationsState(), action: CertificationsActions): ICertificationsState {
  switch (action.type) {
    case ECertificationsActions.GetCertifications:
      return {
        ...state,
        certifications: {
          ...state.certifications,
          error: null,
          loading: true
        }
      };
    case ECertificationsActions.GetCertificationsSuccess:
      return {
        ...state,
        certifications: {
          data: action.payload.certifications,
          expirationDate: action.payload.expirationDate,
          error: null,
          loading: false
        }
      };
    case ECertificationsActions.GetCertificationsError:
      return {
        ...state,
        certifications: {
          ...state.certifications,
          expirationDate: null,
          error: action.error,
          loading: false
        }
      };
    case ECertificationsActions.GetCertificationPopulate:
      return {
        ...state,
        populatedCertification: {
          data: null,
          requestId: action.input,
          error: null,
          loading: true
        }
      };
    case ECertificationsActions.GetCertificationPopulateSuccess:
      return {
        ...state,
        populatedCertification: {
          ...state.populatedCertification,
          data: action.payload,
          error: null,
          loading: false
        }
      };
    case ECertificationsActions.GetCertificationPopulateError:
      return {
        ...state,
        populatedCertification: {
          ...state.populatedCertification,
          error: action.error,
          loading: false
        }
      };
    case ECertificationsActions.AddCertification:
      return {
        ...state,
        certificationAction: {
          ...state.certificationAction,
          data: null,
          error: null,
          loading: true
        }
      };
    case ECertificationsActions.AddCertificationFile:
      return {
        ...state,
        certificationAction: {
          data: action.payload,
          error: null,
          loading: true
        }
      };
    case ECertificationsActions.AddCertificationSuccess:
      return {
        ...state,
        certificationAction: {
          ...state.certificationAction,
          data: action.payload,
          error: null,
          loading: false
        }
      };
    case ECertificationsActions.AddCertificationFileError:
      return {
        ...state,
        certificationAction: {
          ...state.certificationAction,
          data: action.payload,
          error: action.error,
          loading: false
        }
      };
    case ECertificationsActions.AddCertificationError:
      return {
        ...state,
        certificationAction: {
          ...state.certificationAction,
          data: null,
          error: action.error,
          loading: false
        }
      };
    case ECertificationsActions.UpdateCertification:
      return {
        ...state,
        certificationAction: {
          requestId: action.updateCertificationModel.model.id,
          data: null,
          error: null,
          loading: true
        }
      };
    case ECertificationsActions.UpdateCertificationSuccess:
      return {
        ...state,
        certificationAction: {
          ...state.certificationAction,
          data: action.payload,
          error: null,
          loading: false
        }
      };
    case ECertificationsActions.UpdateCertificationFileError:
      return {
        ...state,
        certificationAction: {
          ...state.certificationAction,
          data: {},
          error: action.error,
          loading: false
        }
      };
    case ECertificationsActions.UpdateCertificationError:
      return {
        ...state,
        certificationAction: {
          ...state.certificationAction,
          error: action.error,
          loading: false
        }
      };
    case ECertificationsActions.DeleteCertification:
      return {
        ...state,
        certificationAction: {
          data: null,
          requestId: action.input.id,
          error: null,
          loading: true
        }
      };
    case ECertificationsActions.DeleteCertificationSuccess:
      return {
        ...state,
        certificationAction: {
          ...state.certificationAction,
          data: action.payload,
          error: null,
          loading: false
        }
      };
    case ECertificationsActions.DeleteCertificationError:
      return {
        ...state,
        certificationAction: {
          ...state.certificationAction,
          error: action.error,
          loading: false
        }
      };
    default:
      return state;
  }
}
