import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder } from '@angular/forms';
import { NursePortalApi } from 'src/app/services';
import { IssueConfirmPopup } from './issue-confirmation/issue-confirm.popup';
import { MatDialog } from '@angular/material/dialog';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { AppUrls } from 'src/app/app-urls';
import { Recruiter } from 'src/app/common/models/recruiter';
import { NurseModel } from 'src/app/common';
import { Store } from '@ngrx/store';
import { AngularticsEventTrack } from 'src/app/store/angulartics2/angulartics2.actions';
import { PhoneNumberClicked } from 'src/app/store/segment/segment.actions';
import { Observable, Subject, combineLatest } from 'rxjs';
import { selectCanSeeInternational, selectNurseData } from 'src/app/store/userContext/userContext.selectors';
import { selectSupportContact, selectSupportContactLoading } from '../../../store/recruiter/recruiter.selectors';
import { selectNurseDataLoading } from '../../../store/userContext/userContext.selectors';
import { skipWhile, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss']
})
export class SupportComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();
  nurseData: NurseModel;
  isSaving = false;
  bugReport: any;
  zendeskEnabled = false;

  questionAnswer: any[] = [
    {
      question: 'When is my approved timesheet due?',
      answer: 'Your Approved Timesheet is due each Tuesday by 12:00pm'
    },
    {
      question: 'Will I know when my timesheet is approved?',
      answer: 'Yes, you will receive an email indicating the approval or denial of the time Timesheet'
    },
    {
      question: 'What if my approver denies my timesheet?',
      answer: "Open the timesheet, review the approver's notes, and make any changes neceesary, then re-submit"
    },
    {
      question: 'Who do I contact if I have additional questions?',
      answer: `Please contact your Internation Employee Supervisor or
      Employee Support Specialist with any questions concerning reporting your time`
    }
  ];

  nurse$: Observable<NurseModel> = this._store.select(selectNurseData);
  recruiter$: Observable<Recruiter> = this._store.select(selectSupportContact);
  isIN$: Observable<boolean> = this._store.select(selectCanSeeInternational);

  nurseDataLoading$: Observable<boolean> = this._store.select(selectNurseDataLoading);
  recruiterDataLoading$: Observable<boolean> = this._store.select(selectSupportContactLoading);

  constructor(
    private _formBuilder: UntypedFormBuilder,
    private _api: NursePortalApi,
    private _router: Router,
    private _dialog: MatDialog,
    private _store: Store,
    public _route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.zendeskEnabled = this._route.snapshot.data['zendesk'];
    this.bugReport = this._formBuilder.group({
      subject: '',
      description: ''
    });
    this.setupPageObservables();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();

    // this.removeZendesk();
  }

  setupPageObservables() {
    combineLatest([this.nurse$, this.nurseDataLoading$, this.recruiter$, this.recruiterDataLoading$])
      .pipe(
        skipWhile(([nurse, nurseLoading]) => !nurse && nurseLoading),
        takeUntil(this.destroy$)
      )
      .subscribe(([nurse]) => {
        this.openSupportWidgetForNewlyRegisteredNurses(nurse?.createdDate);
      });
  }

  // ngAfterViewInit() {
  //   /*
  //     //@ts-ignore
  //     zE('webWidget', 'chat:reauthenticate');
  //     const launcher: HTMLIFrameElement =
  //       document.querySelector('iframe#launcher');

  //     if (launcher) {
  //       launcher.style.transitionProperty = 'opacity, top, bottom, right';
  //       launcher.style.top = null;
  //       launcher.style.visibility = 'visible';

  //       launcher.addEventListener('transitionend', (e) => {
  //         const frame = e.target as HTMLIFrameElement;
  //         this.replaceZendeskLauncherStyles(frame);
  //       });

  //       //@ts-ignore
  //       zE('webWidget:on', 'close', () => {
  //         this.replaceZendeskLauncherStyles(launcher);
  //       });

  //       window.addEventListener('resize', () => {
  //         this.replaceZendeskLauncherStyles(launcher);
  //       });

  //       //@ts-ignore
  //       zE.show();
  //     }

  //     // Open chat window by default if the parameter is present
  //     if (this._route.snapshot.queryParams['openchat'] === 'true') {
  //       //@ts-ignore
  //       zE.activate();
  //     }
  //     */
  // }

  replaceZendeskLauncherStyles(frame: HTMLIFrameElement) {
    const appSupportSelector = document.querySelector('app-support');
    if (frame && appSupportSelector) {
      const frameRect = frame.getBoundingClientRect();
      const appSupport = appSupportSelector.getBoundingClientRect();

      let marginBottom = 0;
      if (frame.style.marginBottom.endsWith('px')) {
        marginBottom = parseFloat(frame.style.marginBottom.substring(0, frame.style.marginBottom.length - 2));
      }

      frame.style.right = appSupport.x + 'px';
      frame.style.bottom = Math.abs(frameRect.height + marginBottom) + 'px';
      setTimeout(() => {
        frame.style.transitionProperty = 'opacity, top, bottom';
      }, 250);
    }
  }

  removeZendesk() {
    //@ts-ignore
    // if(zE){
    //   //@ts-ignore
    //   zE.hide();
    // }
  }

  onSubmit(): void {
    if (this.bugReport.valid) {
      this._store.dispatch(new AngularticsEventTrack('Click', 'Support', 'Reported Issue'));
      this.isSaving = true;
      //TODO: Convert to NGRX
      const submitting = this._api.submitBugReport(this.bugReport.value);
      submitting.subscribe(done => {
        const dialogref = this._dialog.open(IssueConfirmPopup, {
          panelClass: 'custom-dialog-container'
        });
        // eslint-disable-next-line rxjs/no-nested-subscribe
        dialogref.afterClosed().subscribe(() => {
          this._router.navigate([`/${AppUrls.DASHBOARD}`]);
        });
      });
    }
  }

  contactRecruiter() {
    this._store.dispatch(new AngularticsEventTrack('Click', 'Support', 'Contact Recruiter'));
  }

  contactRecruiterPhone(recruiterPhoneNumber: string) {
    this._store.dispatch(new AngularticsEventTrack('Click', 'Support', 'Contact Recruiter'));
    this._store.dispatch(new PhoneNumberClicked({ location: 'Support', phoneType: 'Recruiter', phoneNumber: recruiterPhoneNumber }));
  }

  tabChange(tabChangeEvent: MatTabChangeEvent) {
    if (tabChangeEvent.index === 1) {
      this._store.dispatch(new AngularticsEventTrack('Click', 'Support', 'FAQ'));
    }
  }

  openSupportWidgetForNewlyRegisteredNurses(createdDate?: Date): void {
    const isUserRegisteredInLastSevenDays = new Date(createdDate).getTime() > Date.now() - 604800000;
    if (isUserRegisteredInLastSevenDays) {
      /*
        //@ts-ignore
        zE('webWidget', 'helpCenter:setSuggestions', { 
          labels: ['registered_candidate'] 
        });

        setTimeout(function() { 
          //@ts-ignore
          zE('webWidget', 'open');
        }, 1000)
        */
    }
  }
}
