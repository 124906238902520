import { AfterViewInit, Directive, ElementRef, HostListener, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { CallToActionClicked } from 'src/app/store/segment/segment.actions';
import { environment } from 'src/environments/environment';

@Directive({
  selector: '[segmentTrackCTA]'
})
export class SegmentTrackCTADirective implements AfterViewInit {
  @HostListener('click') onClick() {
    this.trackCtaClick();
  }

  @Input()
  ctaText: string;

  @Input()
  destinationUrl: string;

  title: string;
  pageUrl: string;

  constructor(
    private eleRef: ElementRef,
    private router: Router,
    private route: ActivatedRoute,
    private _store: Store
  ) {}

  ngAfterViewInit(): void {
    this.ctaText = this.ctaText ? this.ctaText : this.eleRef.nativeElement?.innerText;
    this.title = this.route.routeConfig?.title as string;
    this.pageUrl = environment.appBaseUrl + this.router.url;
    this.destinationUrl = this.destinationUrl ? environment.appBaseUrl + this.destinationUrl : this.pageUrl;
  }

  trackCtaClick(): void {
    this._store.dispatch(new CallToActionClicked({ pageURL: this.pageUrl, pageTitle: this.title, destinationURL: this.destinationUrl, ctaText: this.ctaText }));
  }
}
