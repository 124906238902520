import { ZipCodeModel } from './zip-code-model';

export class NurseModel {
  id: number;
  email: string;
  merlinId: string;
  hcContactId: string;
  isWelcomed: boolean;
  isVerified: boolean;
  businessUnitId?: string;
  firstName: string;
  lastName: string;
  preferredName: string;
  pronoun: string;
  otherPronouns: string;
  gender: number;
  hasTimeMobile: boolean;
  isNew?: boolean;
  teamId?: string;
  nursys: any;
  hasExpenseManagement: boolean;
  isPayVisible: boolean;
  recruiterId: string;
  inactive: boolean;
  professionId: string;
  specialtyId: string;
  subSpecialtyId: string;
  secondarySpecialtyId: string;
  secondarySubSpecialtyId: string;
  hubSpotId: string;
  profilePicture: string;
  isReferred: boolean;
  mobileNumber: string;
  verificationType?: number;
  dateOfBirth?: Date;
  isMailingAddressSameAsTax = false;
  mailingAddress1: string;
  mailingAddress2?: string;
  mailingCity: string;
  mailingState: string;
  mailingZipCode: string;
  homeAddress1: string;
  homeAddress2: string;
  homeCity: string;
  homeState: string;
  homeZipCode: ZipCodeModel;
  createdDate?: Date;
  hasAssignment: boolean;
  lifeCycleStage: string;
  lifeCycleSubStage: string;
  portalStatus: string;
  ssn: string;
  ownerId?: string;
  stateid: string;
  hasNotArrivedInUS: boolean;

  isRnOrLpn(): boolean {
    if (this.professionId) {
      const professions = ['EFE3171A-E4F2-E611-8103-E0071B66CFE1', '88E3171A-E4F2-E611-8103-E0071B66CFE1'];
      return professions.includes(this.professionId.toUpperCase());
    }

    return false;
  }
}
