import { HelpModalTemplate } from '../../../common/models/help-modal';

export const AssignmentsHelpModal: HelpModalTemplate = {
  title: 'Assignment',
  text: 'Learn more by viewing the badges associated to the facility as well as the statuses associated with the assignment below.',
  badgeTitle: 'Badges',
  badges: [
    {
      badge: 'Joint Comm. Accredited',
      css_class: 'incomplete w-fit-content',
      text: 'A facility recognized at the highest national standards for safety and quality of care, committed to improving patient care.'
    },
    {
      badge: 'Teaching Facility',
      css_class: 'incomplete w-fit-content',
      text: 'A facility that focuses on providing medical education and training to healthcare professionals.'
    },
    {
      badge: 'Magnet Hospital',
      css_class: 'incomplete w-fit-content',
      text: 'A facility certified by the American Nurses’ Credentialing Center (ANCC) as the gold standard for nursing practices.'
    },
    { badge: 'Trauma Center', css_class: 'incomplete w-fit-content', text: 'A trauma center is equipped to treat critical, high-risk injuries threatening life or limbs.' }
  ],
  statusTitle: 'Statuses',
  statuses: [
    { status: 'Interview Scheduled', css_class: 'action interview-scheduled', text: 'Your interview has been scheduled. Review details and prep materials to get ready.' },
    { status: 'Interview Completed', css_class: 'action interview-completed', text: 'Your interview is done. We will send you feedback when we hear back.' },
    { status: 'Offer Extended', css_class: 'action offer-extended', text: 'The facility has made you a job offer! Please review employment details.' },
    { status: 'Offer Accepted', css_class: 'action offer-accepted', text: 'Congrats, you’ve accepted a job offer!' },
    {
      status: 'On Assignment',
      css_class: 'action on-assignment',
      text: 'You are currently working at this job. Please contact your PassportUSA Expert if you have any questions or concerns.'
    },
    { status: 'Assignment Complete', css_class: 'action assignment-complete', text: 'You have completed your assignment with PassportUSA.' },
    {
      status: 'Offer Declined',
      css_class: 'action offer-declined',
      text: 'You declined a job offer. Our team will continue to help you search for a job that’s the right fit.'
    },
    {
      status: 'No Longer Available',
      css_class: 'action no-longer-available',
      text: 'The job is no longer available. Your PassportUSA Expert will follow up with additional details as needed.'
    }
  ]
};
