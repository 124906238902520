import { EventEmitter, Injectable, TemplateRef } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Location } from '@angular/common';
import { BehaviorSubject } from 'rxjs';
import { NavHelper } from './nav-helper.service';
import { CardElevation, DialogService, IDialogParameters } from 'hc-design-system-lib';
import { take } from 'rxjs/operators';

export interface FormHeaderAttributes {
  form?: UntypedFormGroup;
  title?: string;
  subTitle?: string;
  showBackButton?: boolean;
  showEditButton?: boolean;
  editButtonIcon?: string;
  showSaveButton?: boolean;
  disableActions?: boolean;
  customContent?: TemplateRef<any>;
  disableStayOnPagePopupOnNav?: boolean;
  backAction?: () => void;
  navAction?: (navString: string) => void;
}

@Injectable({
  providedIn: 'root'
})
export class FormHeaderService {
  private form: UntypedFormGroup;
  setForm(form: UntypedFormGroup) {
    this.form = form;
  }

  title: BehaviorSubject<string> = new BehaviorSubject(null);
  setTitle(value: string) {
    this.title.next(value);
  }

  subTitle: BehaviorSubject<string> = new BehaviorSubject(null);
  setSubTitle(value: string) {
    this.subTitle.next(value);
  }

  showBackButton: BehaviorSubject<boolean> = new BehaviorSubject(true);
  setShowBackButton(value: boolean) {
    this.showBackButton.next(value);
  }

  showEditButton: BehaviorSubject<boolean> = new BehaviorSubject(false);
  setShowEditButton(value: boolean) {
    this.showEditButton.next(value);
  }

  editButtonIcon: BehaviorSubject<string> = new BehaviorSubject('more_vert');
  setEditButtonIcon(value: string) {
    this.editButtonIcon.next(value);
  }

  showSaveButton: BehaviorSubject<boolean> = new BehaviorSubject(false);
  setShowSaveButton(value: boolean) {
    this.showSaveButton.next(value);
  }

  disableActions: BehaviorSubject<boolean> = new BehaviorSubject(false);
  setDisableActions(value: boolean) {
    this.disableActions.next(value);
  }

  customContent: BehaviorSubject<TemplateRef<any>> = new BehaviorSubject(null);
  setCustomContent(value: TemplateRef<any>) {
    this.customContent.next(value);
  }

  backAction: () => void;
  // By default if the form is marked as dirty and user tries to navigate, the stay on page popup shows up. Setting this to true will disable display of popup.
  disableStayOnPagePopupOnNav = false;

  navAction: (navString: string) => void;

  save = new EventEmitter();
  openOptions = new EventEmitter();

  constructor(
    private _location: Location,
    private _dialog: MatDialog,
    private _dialogService: DialogService,
    private _nav: NavHelper
  ) {}

  get showFormActions(): boolean {
    return this.form ? true : false;
  }

  resetFormHeaderAttributes(attributes: FormHeaderAttributes = {}): void {
    this.setForm(attributes.form);
    this.setTitle(attributes.title);
    this.setSubTitle(attributes.subTitle);
    this.setShowBackButton(attributes.showBackButton ?? true);
    this.setShowEditButton(attributes.showEditButton ?? false);
    this.setEditButtonIcon(attributes.editButtonIcon);
    this.setShowSaveButton(attributes.showSaveButton ?? false);
    this.setDisableActions(attributes.disableActions ?? false);
    this.setCustomContent(attributes.customContent);
    this.backAction = attributes.backAction;
    this.navAction = attributes.navAction;
    this.disableStayOnPagePopupOnNav = attributes.disableStayOnPagePopupOnNav || false;
  }

  navigate(navString: string): void {
    if (this.navAction) {
      this.navAction(navString);
    } else if (this.form) {
      if (!this.disableStayOnPagePopupOnNav && this.form.dirty) {
        const dialogData: IDialogParameters = {
          title: 'Leave without saving?',
          text: 'Any unsaved changes will be lost.',
          options: {
            primary: { text: 'Leave this page', returnValue: 'primary' },
            secondary: { text: 'Cancel', returnValue: 'secondary' }
          },
          showCloseIcon: true,
          elevation: CardElevation.Default,
          icon: undefined
        };
        this._dialogService
          .showDialog(dialogData)
          .pipe(take(1))
          .subscribe(response => {
            if (response === 'primary') {
              this._nav.goToUrl(navString);
            }
          });
      } else {
        this._nav.goToUrl(navString);
      }
    } else {
      this._nav.goToUrl(navString);
    }
  }

  goBack(): void {
    if (this.backAction) {
      this.backAction();
    } else {
      this._location.back();
    }
  }
}
