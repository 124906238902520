import { ILookup } from 'src/app/common';

export class SearchFilterService {
  tempLicenseState: boolean;
  isCrisis: boolean;
  location: string;
  profession: ILookup<string>;
  specialty: ILookup<string>;
  weeklyhours: number[];
  assignmentLength: number[];
  shift: number[];
  startDate: number[];
  isAdvanced: boolean;
  radius: number;

  private params: URLSearchParams;

  constructor(
    private professionLookup: Map<string, ILookup<string>>,
    private specialtyLookup: Map<string, ILookup<string>>
  ) {
    this.init();
  }

  init() {
    this.params = this.getCurrentUrlParams();

    // Single instance parameters
    this.tempLicenseState = this.params.has('walkthrough_states');

    this.isCrisis = this.params.has('crisis');

    if (this.params.has('location')) {
      this.location = this.params.get('location').toUpperCase();
    }

    if (this.params.has('radius')) {
      const srchRadius = this.params.get('radius');
      this.radius = Number(srchRadius);
    }

    if (this.params.has('profession')) {
      this.profession = this.professionLookup.get(this.params.get('profession'));
    }

    if (this.params.has('specialty')) {
      this.specialty = this.specialtyLookup.get(this.params.get('specialty'));
    }

    if (this.params.has('subspeciality')) {
      this.specialty = this.specialtyLookup.get(this.params.get('subspeciality'));
    }

    // Multiple instance parameters = paramName[<int value>]
    this.weeklyhours = this.getMultipleValues('weeklyhours', this.params);

    this.assignmentLength = this.getMultipleValues('length', this.params);

    this.startDate = this.getMultipleValues('startdate', this.params);

    this.shift = this.getMultipleValues('shift', this.params);

    this.isAdvanced = this.getSearchParams() !== '';
  }

  public getSearchParams(): string {
    // Copy of the URL parameters so we can only get the list we want
    const searchParams = new URLSearchParams(this.params.toString());

    const searchParamKeys = [
      'profession',
      'location',
      'subspeciality',
      'specialty',
      'crisis',
      'radius',
      'startdate',
      'length',
      'shift',
      'weeklyhours',
      'advanced_jobs',
      'walkthrough_states'
    ];

    // If the item doesn't match, remove it from the collection of key/value pairs
    this.params.forEach((v, k) => {
      const matches = searchParamKeys.filter(keyName => k.startsWith(keyName));
      if (!matches || matches.length === 0) {
        searchParams.delete(k);
      }
    });

    // Return the formatted query string
    return searchParams.toString();
  }

  private getCurrentUrlParams(): URLSearchParams {
    const url = window.location.href.toLocaleLowerCase();
    if (url.indexOf('?') > 0) {
      const parts = url.split('?');
      if (parts.length >= 2) {
        return new URLSearchParams(parts[1].toLowerCase());
      }
    }

    return new URLSearchParams(url);
  }

  private getMultipleValues(key: string, params: URLSearchParams): Array<number> {
    const items = [];

    params.forEach((v, k) => {
      const pattern = `${key}\\[(\\d+)\\]`;
      const matches = k.match(pattern);
      if (matches && matches.length > 1) {
        const num = Number(matches[1]);
        items.push(num);
      }
    });
    return items;
  }
}
