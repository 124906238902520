import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { Store } from '@ngrx/store';
import { Observable, Subject, combineLatest, of } from 'rxjs';
import { filter, map, switchMap, take, takeUntil } from 'rxjs/operators';
import { NavHelper } from 'src/app/services';
import { IAppState } from 'src/app/store/app/app.state';
import { selectTasks, selectTasksLoading, selectToDoApplyTasks } from 'src/app/store/tasks/tasks.selectors';
import { GetTasks } from 'src/app/store/tasks/tasks.actions';
import { NurseTask } from 'src/app/common/models/db-objects';
import { TaskNavigatorService } from 'src/app/services/task-navigator.service';
import { ButtonAppearance } from 'hc-design-system-lib/lib/components/button/button.enums';
import { taskURLMap } from 'src/app/common/models/task-entities';
import { FormGroup } from '@angular/forms';
import { CardElevation, DialogService, IDialogParameters } from 'hc-design-system-lib';
import { TaskCompletionReturnObject } from 'src/app/common';
import { IDataState } from 'src/app/store/app/app.models';

@Component({
  selector: 'app-flow-handler',
  templateUrl: './flow-handler.component.html',
  styleUrls: ['./flow-handler.component.scss']
})
export class FlowHandlerComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();
  primaryButton: ButtonAppearance = ButtonAppearance.Primary;
  secondaryButton: ButtonAppearance = ButtonAppearance.Secondary;

  @Input() recordObs: Observable<IDataState<TaskCompletionReturnObject>> = of({ data: {} as TaskCompletionReturnObject, error: null, loading: false });
  @Input() isSavingObs: Observable<boolean>;
  @Input() showRegularSaveButton: boolean = false;
  @Input() form?: FormGroup;
  @Input() segmentDestinationUrl?: string;
  @Input() navigationOverride?: () => void;
  @Input() cancelNavigationOverride?: () => void;
  @Input() saveButtonLabel?: string;
  @Input() isListPage?: boolean = false;

  @Output() saveClickEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() cancelClickEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

  currentTask: NurseTask;
  jobId: string;

  allTasks$: Observable<NurseTask[]> = this._store.select(selectTasks);
  toDoTasks$: Observable<NurseTask[]> = this._store.select(selectToDoApplyTasks);
  tasksLoading$: Observable<boolean> = this._store.select(selectTasksLoading);

  constructor(
    private _route: ActivatedRoute,
    private _location: Location,
    private _navHelper: NavHelper,
    private _store: Store<IAppState>,
    private _taskNavService: TaskNavigatorService,
    private _dialogService: DialogService
  ) {}

  ngOnInit(): void {
    this.jobId = this._route.snapshot.queryParamMap.get('jobId');
    combineLatest([this.allTasks$, this.tasksLoading$])
      .pipe(
        filter(([, loading]) => !loading),
        map(([tasks]) => {
          let pageTask = [...taskURLMap].filter(([, value]) => this._location.path().includes(value)).map(([key]) => key);
          if (pageTask.length > 0) {
            this.currentTask = tasks.find(task => task.value.some(taskVal => pageTask.includes(taskVal)));
          }
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  save(): void {
    this.saveClickEmitter.emit(true);

    this.recordObs
      .pipe(
        filter(record => record.data && !record.loading && !record.error),
        take(1),
        map(() => this._store.dispatch(new GetTasks(true, true))),
        switchMap(() => {
          return combineLatest([this.tasksLoading$, this.toDoTasks$]).pipe(
            filter(([tasksLoading]) => !tasksLoading),
            take(1)
          );
        }),
        map(([, toDoTasks]) => {
          if (this.navigationOverride) {
            this.navigationOverride();
            return;
          }
          if (this.jobId) {
            const nextTask = this.toDoTasksWithoutCurrentTask(toDoTasks).shift();
            if (nextTask) {
              this._taskNavService.doTask(nextTask, toDoTasks.length === 1, this.jobId);
            } else {
              this._navHelper.goToJobApplication(this.jobId);
            }
          } else if (this.isFromTask()) {
            this._navHelper.goToTasks();
          } else {
            this._navHelper.goToProfileMenu();
          }
        }),
        take(1)
      )
      .subscribe();
  }

  returnToApp(jobId: string): void {
    this.cancelClickEmitter.emit(true);
    if (this.form?.dirty) {
      const dialogData: IDialogParameters = {
        title: 'Leave this page?',
        text: 'Any unsaved changes will be lost.',
        options: {
          primary: { text: 'Leave this page', returnValue: 'primary' },
          secondary: { text: 'Cancel', returnValue: 'secondary' }
        },
        showCloseIcon: true,
        elevation: CardElevation.Default,
        icon: undefined
      };

      this._dialogService
        .showDialog(dialogData)
        .pipe(take(1))
        .subscribe(response => {
          if (response === 'primary') {
            if (this.cancelNavigationOverride) {
              this.cancelNavigationOverride();
            } else {
              this._navHelper.goToJobApplication(jobId);
            }
          }
        });
    } else {
      if (this.cancelNavigationOverride) {
        this.cancelNavigationOverride();
      } else {
        this._navHelper.goToJobApplication(jobId);
      }
    }
  }

  toDoTasksWithoutCurrentTask(remainingTasks: NurseTask[]): NurseTask[] {
    return [...remainingTasks].filter(task => task.complianceRequirementId !== this.currentTask?.complianceRequirementId);
  }

  protected isFromTask(): boolean {
    return this._route.snapshot.queryParamMap.get('fromTask') === 'true';
  }
}
