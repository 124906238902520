<ng-container
  *ngIf="{
    specificJob: specificJob$ | async,
    specificJobLoading: specificJobLoading$ | async,
    specificJobIsSaved: specificJobIsSaved$ | async,
    specificJobMapUrl: specificJobMapUrl$ | async,
    toDoApplyTasks: toDoApplyTasks$ | async,
    recruiter: recruiter$ | async,
    nurse: nurse$ | async,
    similarJobsByJobId: similarJobsByJobId$ | async,
    similarJobsByFacilityId: similarJobsByFacilityId$ | async,
    allApplyTasks: allApplyTasks$ | async,
    canSeeTravel: canSeeTravel$ | async,
    canSeeInternational: canSeeInternational$ | async,
    yesNoIdLookup: yesNoIdLookup$ | async,
    yesNoLookup: yesNoLookup$ | async,
    yesNoOnlyIdLookup: yesNoOnlyIdLookup$ | async,
    specificAssignment: specificAssignment$ | async,
    specificAssignmentLoading: specificAssignmentLoading$ | async,
    interviewTimeFrameLookup: interviewTimeframeLookup$ | async,
    interviewContactMethodLookup: interviewContactMethodLookup$ | async,
    timeZoneLookup: timezoneLookup$ | async
  } as obs"
>
  <div *ngIf="professionLookup && professionHierarchy" fxLayout="column" class="hidden full-height">
    <div fxLayout="column">
      <hc-banner
        *ngIf="obs.canSeeTravel"
        [isLoading]="(obs.specificJobLoading && !specificJobPreload) || obs.specificJob?.notFound"
        width="880px"
        [title]="jobName"
        [secondaryText]="estWeeklyPay"
        [tertiaryText]="jobLocation"
        [statusBarText]="jobBannerStatus"
        [ribbonText]="bannerRibbonText"
        [primaryBadge]="bannerPrimaryBadge"
        [badgeDetails]="jobBadges"
        [detailSectionConfig]="bannerDetailSectionConfig"
        [button1Config]="{
          text: obs.recruiter?.userId ? 'Contact Recruiter' : null,
          isPrimary: false,
          disabled: obs.specificJobLoading
        }"
        (button1Clicked)="contactRecruiter(obs.specificJob, obs.recruiter, obs.nurse, ContactRecruiterType.JobDetails)"
        [button2Config]="{
          text: primaryButtonText,
          disabled: obs.specificJobLoading
        }"
        (button2Clicked)="primaryButtonAction(obs.specificJob, obs.toDoApplyTasks, obs.allApplyTasks)"
      >
      </hc-banner>
      <hc-banner
        *ngIf="!obs.canSeeTravel && obs.canSeeInternational"
        [isLoading]="(obs.specificJobLoading && !specificJobPreload) || obs.specificJob?.notFound"
        width="880px"
        [title]="jobName"
        [tertiaryText]="jobLocation"
        [statusBarText]="jobBannerStatus"
        [badgeDetails]="jobBadges"
      >
      </hc-banner>
      <hc-tabs
        *ngIf="!obs.specificJobLoading && obs.specificJob"
        class="specific-job-tabs"
        [tabConfig]="tabConfig"
        [fillTabBackgroundColor]="false"
        [activeTabIndex]="0"
        (tabChanged)="tabChanged($event, obs.specificJob)"
      >
      </hc-tabs>

      <ng-container *ngIf="obs.specificJobLoading">
        <ng-container *ngTemplateOutlet="obs.canSeeTravel ? positionTabTemplate : facilityTabTemplate"></ng-container>
      </ng-container>
    </div>
  </div>

  <ng-template #positionTabTemplate>
    <div class="tab-contents">
      <div *ngIf="obs.specificJob?.rating === 948050000" class="limit-width">
        <div class="message-box">
          <hc-body [size]="microBodySize"> This employer typically responds within 48 hours!</hc-body>
        </div>
      </div>
      <div *ngIf="jobRequirements?.length || obs.specificJobLoading" class="limit-width requirements-section">
        <hc-heading class="section-heading" [size]="headingSizeH2">Requirements</hc-heading>
        <hc-card [hideBorder]="false" [elevation]="cardElevation">
          <div class="card-container extra-padding">
            <ng-container *ngIf="obs.specificJobLoading; else requirements">
              <div class="requirement-group requirement-group--loading">
                <div class="requirement--loading">
                  <hc-skeleton-loading-bar [height]="loadingHeightSmallest"></hc-skeleton-loading-bar>
                  <hc-skeleton-loading-bar [height]="loadingHeightSmallest"></hc-skeleton-loading-bar>
                  <hc-skeleton-loading-bar [height]="loadingHeightSmallest"></hc-skeleton-loading-bar>
                  <hc-skeleton-loading-bar [height]="loadingHeightSmallest"></hc-skeleton-loading-bar>
                  <hc-skeleton-loading-bar [height]="loadingHeightSmallest" class="loading-bar-75-percent"></hc-skeleton-loading-bar>
                </div>
                <div class="requirement--loading mobile-hide">
                  <hc-skeleton-loading-bar [height]="loadingHeightSmallest"></hc-skeleton-loading-bar>
                  <hc-skeleton-loading-bar [height]="loadingHeightSmallest"></hc-skeleton-loading-bar>
                  <hc-skeleton-loading-bar [height]="loadingHeightSmallest"></hc-skeleton-loading-bar>
                  <hc-skeleton-loading-bar [height]="loadingHeightSmallest"></hc-skeleton-loading-bar>
                  <hc-skeleton-loading-bar [height]="loadingHeightSmallest" class="loading-bar-75-percent"></hc-skeleton-loading-bar>
                </div>
              </div>
            </ng-container>
            <ng-template #requirements>
              <ng-container *ngIf="jobRequirements?.length">
                <div class="requirement-group" *ngFor="let group of jobRequirements">
                  <hc-heading [size]="headingSizeH6">{{ group.group }}:&nbsp;</hc-heading>
                  <hc-body *ngFor="let r of group.requirements">{{ r }}</hc-body>
                </div>
              </ng-container>
            </ng-template>
          </div>
        </hc-card>
      </div>
      <div *ngIf="paymentDisplayType === PayDisplay.PayIsVisible || obs.recruiter?.userId || obs.specificJobLoading" class="limit-width pay-section">
        <div class="section-heading">
          <hc-heading [size]="headingSizeH2">Pay Details *</hc-heading>
        </div>
        <div class="section-heading">
          <span class="sub-section-heading">* Pay estimates are contingent on your tax home address's proximity to the job</span>
        </div>
        <ng-container *ngIf="obs.specificJobLoading; else paymentDisplay">
          <hc-card [hideBorder]="false" [elevation]="cardElevation">
            <div class="card-container pay-details">
              <div class="est-gross-weekly-pay">
                <hc-skeleton-loading-bar [length]="loadingBarLong" [height]="loadingHeightMedium"></hc-skeleton-loading-bar>
                <hc-skeleton-loading-bar [length]="loadingBarMedium" [height]="loadingHeightMedium"></hc-skeleton-loading-bar>
              </div>
              <mat-divider></mat-divider>
              <div class="pay-info">
                <hc-skeleton-loading-bar class="contact-type-pay-title contact-type-pay-title--loading" [height]="loadingHeightMedium"></hc-skeleton-loading-bar>
                <hc-skeleton-loading-bar class="pay-info-subheading pay-info-subheading--loading" [height]="loadingHeightSmall"></hc-skeleton-loading-bar>
                <div class="pay-line">
                  <hc-skeleton-loading-bar class="pay-line-text--loading" [height]="loadingHeightSmall"></hc-skeleton-loading-bar>
                  <hc-skeleton-loading-bar [length]="loadingBarMedium" [height]="loadingHeightSmall"></hc-skeleton-loading-bar>
                </div>
                <div class="pay-line">
                  <hc-skeleton-loading-bar class="pay-line-text--loading" [height]="loadingHeightSmall"></hc-skeleton-loading-bar>
                  <hc-skeleton-loading-bar [length]="loadingBarMedium" [height]="loadingHeightSmall"></hc-skeleton-loading-bar>
                </div>
              </div>
              <mat-divider></mat-divider>
              <div class="pay-info">
                <hc-skeleton-loading-bar class="contact-type-pay-title contact-type-pay-title--loading" [height]="loadingHeightMedium"></hc-skeleton-loading-bar>
                <hc-skeleton-loading-bar class="pay-info-subheading pay-info-subheading--loading" [height]="loadingHeightSmall"></hc-skeleton-loading-bar>
                <div class="pay-line">
                  <hc-skeleton-loading-bar class="pay-line-text--loading" [height]="loadingHeightSmall"></hc-skeleton-loading-bar>
                  <hc-skeleton-loading-bar [length]="loadingBarMedium" [height]="loadingHeightSmall"></hc-skeleton-loading-bar>
                </div>
                <div class="pay-line">
                  <hc-skeleton-loading-bar class="pay-line-text--loading" [height]="loadingHeightSmall"></hc-skeleton-loading-bar>
                  <hc-skeleton-loading-bar [length]="loadingBarMedium" [height]="loadingHeightSmall"></hc-skeleton-loading-bar>
                </div>
              </div>
            </div>
          </hc-card>
        </ng-container>
        <ng-template #paymentDisplay>
          <hc-card *ngIf="paymentDisplayType === PayDisplay.PayIsVisible; else payNotSpecified" [hideBorder]="false" [elevation]="cardElevation">
            <div class="card-container pay-details">
              <div class="est-gross-weekly-pay">
                <hc-heading [size]="headingSizeH6">
                  <span class="purple">Est. gross weekly pay:</span>
                </hc-heading>
                <hc-heading [size]="headingSizeH6">
                  <span class="purple">{{ getPayValue(contractType, obs.specificJob) }}</span>
                </hc-heading>
              </div>
              <ng-container *ngIf="estHourlyPay">
                <mat-divider></mat-divider>
                <div class="pay-info">
                  <hc-heading class="contract-type-pay-title" [size]="headingSizeH6">
                    <span class="purple">{{ contractType === ContractType.Travel ? 'Traveler' : 'Local' }} pay details</span>
                    <button id="jobOpenDisclaimer" mat-button class="global-icon-btn-header info-btn" (click)="openDisclaimer()">
                      <mat-icon class="purple">info</mat-icon>
                    </button>
                  </hc-heading>
                  <hc-body class="pay-info-subheading" [size]="microBodySize">
                    <span class="gray-blue-500"> Based on the amount of contracted hours </span>
                  </hc-body>
                  <div class="pay-line">
                    <hc-body>Base pay</hc-body>
                    <hc-body>${{ estHourlyPay | number: '.2' }}/hr</hc-body>
                  </div>
                  <div *ngIf="estOvertimePay" class="pay-line">
                    <hc-body>Min. overtime pay</hc-body>
                    <hc-body>${{ estOvertimePay | number: '.2' }}/hr</hc-body>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="contractType === ContractType.Travel && (obs.specificJob?.estHousing || obs.specificJob?.estMIE)">
                <mat-divider></mat-divider>
                <div class="pay-info">
                  <hc-heading [size]="headingSizeH6">
                    <span class="purple">Tax-free stipend</span>
                  </hc-heading>
                  <hc-body class="pay-info-subheading" [size]="microBodySize">
                    <span class="gray-blue-500"> Housing, meals and incidentals </span>
                  </hc-body>
                  <div *ngIf="obs.specificJob?.estHousing" class="pay-line">
                    <hc-body>Housing</hc-body>
                    <hc-body>${{ obs.specificJob?.estHousing | number: '.2-2' }}/day</hc-body>
                  </div>
                  <div *ngIf="obs.specificJob?.estMIE" class="pay-line">
                    <hc-body>Meals &amp; incidentals</hc-body>
                    <hc-body>${{ obs.specificJob?.estMIE | number: '.2-2' }}/day</hc-body>
                  </div>
                </div>
              </ng-container>
            </div>
          </hc-card>
          <ng-template #payNotSpecified>
            <hc-action-card
              title="Pay isn't specified"
              description="The facility hasn't yet provided us this information. Let us know if you are interested and we can reach out for more details."
              buttonText="Request Pay Details"
              icon="monetization_on"
              (buttonClick)="contactRecruiter(obs.specificJob, obs.recruiter, obs.nurse, ContactRecruiterType.UnavailablePay)"
            >
            </hc-action-card>
          </ng-template>
        </ng-template>
      </div>
      <div class="limit-width benefits-section">
        <div class="section-heading">
          <hc-heading class="section-heading" [size]="headingSizeH2">Benefits</hc-heading>
          <hc-button id="jobGoToStateBoardSite" icon="open_in_new" [appearance]="buttonAppearanceText" [size]="buttonSizeNarrow" (buttonClick)="goToBenefits()">
            View Benefits Overview
          </hc-button>
        </div>
        <hc-card [hideBorder]="false" [elevation]="cardElevation">
          <div class="card-container extra-padding">
            <ng-container *ngIf="obs.specificJobLoading; else benefitsData">
              <div class="checkmark-group checkmark-group--loading">
                <div class="checkmark-container checkmark-container--loading">
                  <div class="checkmark-item checkmark-item--loading">
                    <hc-skeleton-loading-bar [length]="loadingCircleSmall"></hc-skeleton-loading-bar>
                    <hc-skeleton-loading-bar class="checkmark-item-text--loading" [height]="loadingHeightSmall"></hc-skeleton-loading-bar>
                  </div>
                  <div class="checkmark-item checkmark-item--loading">
                    <hc-skeleton-loading-bar [length]="loadingCircleSmall"></hc-skeleton-loading-bar>
                    <hc-skeleton-loading-bar class="checkmark-item-text--loading" [height]="loadingHeightSmall"></hc-skeleton-loading-bar>
                  </div>
                  <div class="checkmark-item checkmark-item--loading">
                    <hc-skeleton-loading-bar [length]="loadingCircleSmall"></hc-skeleton-loading-bar>
                    <hc-skeleton-loading-bar class="loading-bar-75-percent" [height]="loadingHeightSmall"></hc-skeleton-loading-bar>
                  </div>
                </div>
                <div class="checkmark-container checkmark-container--loading mobile-hide">
                  <div class="checkmark-item checkmark-item--loading">
                    <hc-skeleton-loading-bar [length]="loadingCircleSmall"></hc-skeleton-loading-bar>
                    <hc-skeleton-loading-bar class="checkmark-item-text--loading" [height]="loadingHeightSmall"></hc-skeleton-loading-bar>
                  </div>
                  <div class="checkmark-item checkmark-item--loading">
                    <hc-skeleton-loading-bar [length]="loadingCircleSmall"></hc-skeleton-loading-bar>
                    <hc-skeleton-loading-bar class="checkmark-item-text--loading" [height]="loadingHeightSmall"></hc-skeleton-loading-bar>
                  </div>
                  <div class="checkmark-item checkmark-item--loading">
                    <hc-skeleton-loading-bar [length]="loadingCircleSmall"></hc-skeleton-loading-bar>
                    <hc-skeleton-loading-bar class="loading-bar-75-percent" [height]="loadingHeightSmall"></hc-skeleton-loading-bar>
                  </div>
                </div>
              </div>
            </ng-container>
            <ng-template #benefitsData>
              <div>
                <div class="card-heading">
                  <hc-heading [size]="headingSizeH5"> Includes</hc-heading>
                </div>
                <div class="checkmark-group">
                  <div class="checkmark-container">
                    <div class="checkmark-item">
                      <mat-icon class="green">checkmark</mat-icon>
                      <div>1st Day Medical, Dental & Vision insurance</div>
                    </div>
                    <div class="checkmark-item">
                      <mat-icon class="green">checkmark</mat-icon>
                      <div>Free CEUs</div>
                    </div>
                    <div class="checkmark-item">
                      <mat-icon class="green">checkmark</mat-icon>
                      <div>Competitive weekly pay</div>
                    </div>
                    <div class="checkmark-item">
                      <mat-icon class="green">checkmark</mat-icon>
                      <div>401K match</div>
                    </div>
                    <div class="checkmark-item">
                      <mat-icon class="green">checkmark</mat-icon>
                      <div>Access to hundreds of corporate discounts</div>
                    </div>
                    <div class="checkmark-item">
                      <mat-icon class="green">checkmark</mat-icon>
                      <div>Access to CNO-led clinical team</div>
                    </div>
                    <div class="checkmark-item">
                      <mat-icon class="green">checkmark</mat-icon>
                      <div>Referral bonus</div>
                    </div>
                    <div class="checkmark-item">
                      <mat-icon class="green">checkmark</mat-icon>
                      <div>Online therapy options</div>
                    </div>
                    <div class="checkmark-item">
                      <mat-icon class="green">checkmark</mat-icon>
                      <div>Employee assistance programs</div>
                    </div>
                    <div class="checkmark-item">
                      <mat-icon class="green">checkmark</mat-icon>
                      <div>Disability & critical illness coverage</div>
                    </div>
                  </div>
                </div>
              </div>
              <mat-divider class="divider"></mat-divider>
              <div>
                <div class="card-heading">
                  <hc-heading [size]="headingSizeH5"> Reimburses</hc-heading>
                </div>
                <div class="checkmark-group">
                  <div class="checkmark-container">
                    <div class="checkmark-item">
                      <mat-icon class="green">checkmark</mat-icon>
                      <div>License</div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </div>
        </hc-card>
      </div>
      <div *ngIf="stateLicenseData.length || obs.specificJobLoading" class="limit-width licenses-section">
        <div class="section-heading">
          <hc-heading class="section-heading" [size]="headingSizeH2">Licenses</hc-heading>
          <hc-button
            *ngIf="obs.specificJob?.stateRequirement?.stateBoardLink"
            id="jobGoToStateBoardSite"
            icon="open_in_new"
            [appearance]="buttonAppearanceText"
            [size]="buttonSizeNarrow"
            (buttonClick)="goToStateBoardSite(obs.specificJob)"
          >
            Licensing Info
          </hc-button>
        </div>
        <hc-card [hideBorder]="false" [elevation]="cardElevation">
          <div class="card-container extra-padding">
            <ng-container *ngIf="obs.specificJobLoading; else licenseData">
              <div class="licenses-container--loading">
                <hc-skeleton-loading-bar [length]="loadingPercentLarge" [height]="loadingHeightSmallest"></hc-skeleton-loading-bar>
                <hc-skeleton-loading-bar [length]="loadingPercentLarge" [height]="loadingHeightSmallest"></hc-skeleton-loading-bar>
                <hc-skeleton-loading-bar class="loading-bar-75-percent" [height]="loadingHeightSmallest"></hc-skeleton-loading-bar>
              </div>
            </ng-container>
            <ng-template #licenseData>
              <div class="card-heading">
                <hc-heading [size]="headingSizeH5"> {{ obs.specificJob?.stateName }} Licensing</hc-heading>
              </div>
              <div fxLayout="column" fxLayoutGap="8px">
                <hc-body *ngFor="let x of stateLicenseData" class="license">
                  {{ x.title }}: <span class="bold">{{ x.data }}</span>
                </hc-body>
              </div>
            </ng-template>
          </div>
        </hc-card>
      </div>
      <div class="similar-jobs" *ngIf="obs.similarJobsByJobId?.length !== 0">
        <hc-heading class="limit-width section-heading" [size]="headingSizeH2">Similar Jobs</hc-heading>
        <app-similar-jobs-list class="similar-jobs-list" *ngIf="obs.specificJob?.id" [similarJobLookupType]="SimilarJobLookupType.ByJob" [id]="obs.specificJob?.id">
        </app-similar-jobs-list>
        <hc-card-carousel-v2 class="similar-jobs-list similar-jobs-list--loading" *ngIf="!obs.specificJob?.id" [isLoading]="true" [skeletonCardsCount]="5"></hc-card-carousel-v2>
      </div>
    </div>
  </ng-template>
  <ng-template #facilityTabTemplate>
    <div class="tab-contents">
      <hc-accordion
        class="limit-width location-section"
        [expanded]="obs.specificJobMapUrl !== null"
        [headerTemplate]="facilityHeader"
        [isRounded]="true"
        [iconColor]="accordionIconDarkGray"
      >
        <div class="map-image" fxLayout="column" fxLayoutAlign="center center">
          <div [fxHide]="obs.specificJobMapUrl !== null" fxLayout="row" fxLayoutAlign="center center">
            <mat-progress-bar [style.width]="'25%'" mode="indeterminate" color="primary"></mat-progress-bar>
          </div>
          <img [fxHide]="obs.specificJobMapUrl === null" id="hospital-map" [src]="obs.specificJobMapUrl" [alt]="obs.specificJob?.hospitalInfo?.fullAddress" />
        </div>
      </hc-accordion>
      <ng-template #facilityHeader>
        <div class="accordion-header">
          <hc-heading [size]="headingSizeH3">{{ obs.specificJob?.hospital }}</hc-heading>
          <div>{{ obs.specificJob?.hospitalInfo?.fullAddress }}</div>
        </div>
      </ng-template>

      <div class="limit-width facility-details" *ngIf="showFacilityDetailsSection(obs.specificJob)">
        <hc-heading class="section-heading" [size]="headingSizeH2">Details</hc-heading>
        <hc-card [hideBorder]="false" [elevation]="cardElevation">
          <div class="card-container extra-padding">
            <div class="details-container">
              <div *ngIf="obs.specificJob?.hospitalInfo?.hospitalType" fxLayout="row" fxLayoutAlign="space-between" class="facility-info">
                <div class="bold">Facility Type</div>
                <div>
                  {{ obs.specificJob?.hospitalInfo?.hospitalType }}
                </div>
              </div>
              <div *ngIf="specificJobRelatedData.traumaLevel?.name" fxLayout="row" fxLayoutAlign="space-between" class="facility-info">
                <div class="bold">Trauma Center Level</div>
                <div>
                  {{ specificJobRelatedData.traumaLevel?.name }}
                </div>
              </div>
            </div>
            <mat-divider
              *ngIf="
                obs.specificJob?.hospitalInfo?.staffedBeds ||
                obs.specificJob?.hospitalInfo?.emrSystem ||
                obs.specificJob?.hospitalInfo?.erVisitsPerYear ||
                obs.specificJob?.hospitalInfo?.surgeriesPerYear ||
                obs.specificJob?.hospitalInfo?.birthsPerYear ||
                (obs.canSeeInternational &&
                  (obs.specificJob?.hospitalInfo?.teachingFacility || obs.specificJob?.hospitalInfo?.awards || obs.specificJob?.hospitalInfo?.jointCommission != null))
              "
              class="divider"
            ></mat-divider>
            <div class="details-container">
              <div *ngIf="obs.specificJob?.hospitalInfo?.staffedBeds" fxLayout="row" fxLayoutAlign="space-between" class="facility-info">
                <div class="bold">Bed Size</div>
                <div>
                  {{ obs.specificJob?.hospitalInfo?.staffedBeds }}
                </div>
              </div>
              <div *ngIf="obs.specificJob?.hospitalInfo?.emrSystem" fxLayout="row" fxLayoutAlign="space-between" class="facility-info">
                <div class="bold">ERM System</div>
                <div>{{ obs.specificJob?.hospitalInfo?.emrSystem }}</div>
              </div>
              <div *ngIf="obs.specificJob?.hospitalInfo?.erVisitsPerYear" fxLayout="row" fxLayoutAlign="space-between" class="facility-info">
                <div class="bold">ER Visits / Year</div>
                <div>
                  {{ obs.specificJob?.hospitalInfo?.erVisitsPerYear }}
                </div>
              </div>
              <div *ngIf="obs.specificJob?.hospitalInfo?.surgeriesPerYear" fxLayout="row" fxLayoutAlign="space-between" class="facility-info">
                <div class="bold">Surgeries / Year</div>
                <div>
                  {{ obs.specificJob?.hospitalInfo?.surgeriesPerYear }}
                </div>
              </div>
              <div *ngIf="obs.specificJob?.hospitalInfo?.birthsPerYear" fxLayout="row" fxLayoutAlign="space-between" class="facility-info">
                <div class="bold">Births / Year</div>
                <div>
                  {{ obs.specificJob?.hospitalInfo?.birthsPerYear }}
                </div>
              </div>
              <div
                *ngIf="obs.canSeeInternational && obs.specificJob?.hospitalInfo?.teachingFacility"
                fxLayout="row"
                fxLayoutAlign="space-between"
                class="facility-info teaching-facility"
              >
                <div class="bold">Teaching Facility</div>
                <div>
                  {{ obs.yesNoIdLookup?.get(obs.specificJob?.hospitalInfo?.teachingFacility) }}
                </div>
              </div>
              <div
                *ngIf="obs.canSeeInternational && obs.specificJob?.hospitalInfo?.jointCommission != undefined"
                fxLayout="row"
                fxLayoutAlign="space-between"
                class="facility-info joint-comm"
              >
                <div class="bold">Joint Commission</div>
                <div>
                  {{ obs.specificJob?.hospitalInfo?.jointCommission ? 'Yes' : 'No' }}
                </div>
              </div>
              <div *ngIf="obs.canSeeInternational" fxLayout="row" fxLayoutAlign="space-between" class="facility-info awards">
                <div class="bold">Awards</div>
                <div>
                  {{ obs.specificJob?.hospitalInfo?.awards ?? 'N/A' }}
                </div>
              </div>
            </div>
          </div>
        </hc-card>
      </div>
      <div class="similar-jobs" *ngIf="obs.canSeeTravel && obs.similarJobsByFacilityId?.length !== 0">
        <hc-heading class="limit-width section-heading" [size]="headingSizeH2">{{ 'Other Jobs at ' + obs.specificJob?.hospital }}</hc-heading>
        <app-similar-jobs-list [similarJobLookupType]="SimilarJobLookupType.ByFacility" [id]="obs.specificJob?.hospitalInfo.id"></app-similar-jobs-list>
      </div>
    </div>
  </ng-template>
  <ng-template #interviewTabTemplate>
    <div class="tab-contents">
      <div class="limit-width interview-details" *ngIf="showInterviewDetailsSection(obs.specificAssignment)">
        <hc-heading class="section-heading" [size]="headingSizeH2">Details</hc-heading>
        <hc-card [hideBorder]="false" [elevation]="cardElevation">
          <div class="card-container extra-padding">
            <div class="details-container">
              <div fxLayout="row" fxLayoutAlign="space-between" class="interview-info confirmed">
                <div class="bold">Confirmed</div>
                <div class="interview-info-value">
                  {{ obs.specificAssignment?.interview?.candidateAccepted != null ? obs.yesNoOnlyIdLookup?.get(obs.specificAssignment?.interview?.candidateAccepted) : 'TBD' }}
                </div>
              </div>
              <div fxLayout="row" fxLayoutAlign="space-between" class="interview-info date">
                <div class="bold">Date</div>
                <div *ngIf="obs.specificAssignment?.interview?.date != null; then hasDate; else doesNotHaveData"></div>
                <ng-template #hasDate>
                  <div class="interview-info-value date">
                    {{ obs.specificAssignment?.interview?.date | date: 'longDate' }}
                  </div>
                </ng-template>
                <ng-template #doesNotHaveData>
                  <div class="interview-info-value date tbd">TBD</div>
                </ng-template>
              </div>
            </div>
            <mat-divider class="divider"></mat-divider>
            <div class="details-container">
              <div fxLayout="row" fxLayoutAlign="space-between" class="interview-info method">
                <div class="bold">Method</div>
                <div class="interview-info-value">
                  {{ obs.interviewContactMethodLookup?.get(obs.specificAssignment?.interview?.method)?.name ?? 'TBD' }}
                </div>
              </div>
              <div fxLayout="row" fxLayoutAlign="space-between" class="interview-info timezone">
                <div class="bold">Time Zone</div>
                <div class="interview-info-value">{{ obs.timeZoneLookup?.get(obs.specificAssignment?.interview?.timeZone)?.name ?? 'TBD' }}</div>
              </div>
              <div fxLayout="row" fxLayoutAlign="space-between" class="interview-info time">
                <div class="bold">Time</div>
                <div *ngIf="obs.specificAssignment?.interview?.date != null; then hasTime; else doesNotHaveData"></div>
                <ng-template #hasTime>
                  <div class="interview-info-value time">
                    {{ obs.specificAssignment?.interview?.date | date: 'shortTime' }}
                  </div>
                </ng-template>
                <ng-template #doesNotHaveData>
                  <div class="interview-info-value time tbd">TBD</div>
                </ng-template>
              </div>
              <div fxLayout="row" fxLayoutAlign="space-between" class="interview-info duration">
                <div class="bold interview-info-title">Duration</div>
                <div class="interview-info-value">
                  {{ obs.interviewTimeFrameLookup?.get(obs.specificAssignment?.interview?.timeFrame)?.name ?? 'TBD' }}
                </div>
              </div>
            </div>
          </div>
        </hc-card>
      </div>
    </div>
  </ng-template>

  <ng-template #reviewApplicationDialogTemplate>
    <app-application-review [isDialog]="true"></app-application-review>
  </ng-template>

  <ng-template #startApplicationDialogTemplate>
    <app-start-application-dialog [job]="obs.specificJob"></app-start-application-dialog>
  </ng-template>

  <ng-template #customHeaderTemplate>
    <div *ngIf="obs.canSeeTravel" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
      <button
        id="jobSaveJobIcon"
        mat-button
        class="global-icon-btn-header"
        (click)="saveJob(obs.specificJob)"
        [disabled]="isSaving"
        [fxHide]="obs.specificJobIsSaved || obs.specificJob?.closed"
        fxLayoutGap="4px"
      >
        <mat-icon class="heart-empty">favorite_border</mat-icon>
      </button>
      <button
        id="jobUnsaveJobIcon"
        mat-button
        class="global-icon-btn-header"
        (click)="unSaveJob(obs.specificJob)"
        [disabled]="isSaving"
        [fxHide]="!obs.specificJobIsSaved || obs.specificJob?.closed"
        fxLayoutGap="4px"
      >
        <mat-icon class="heart-filled">favorite</mat-icon>
      </button>
    </div>
  </ng-template>
</ng-container>
