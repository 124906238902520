import { Component } from '@angular/core';
import { DialogService, HeadingSize, LinkTarget } from 'hc-design-system-lib';

@Component({
  selector: 'app-recommended-jobs-popup',
  templateUrl: './recommended-jobs-popup.component.html',
  styleUrls: ['./recommended-jobs-popup.component.scss']
})
export class RecommendedJobsPopupComponent {
  headingSizeH6: HeadingSize = HeadingSize.H6;
  linkTarget: LinkTarget = LinkTarget.Self;

  constructor(public _dialogService: DialogService) {}
}
