import { Injectable, ApplicationRef } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { interval } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class VersionService {
  constructor(
    private _updates: SwUpdate,
    private applicationRef: ApplicationRef
  ) {}
  public init() {
    if (this._updates.isEnabled) {
      console.log('service worker enabled');
      this._setupUpdateService();
    } else {
      console.log('service worker disabled');
    }
  }
  private _setupUpdateService() {
    // check for a new version every 3 minutes
    const intervalObs = interval(180000);

    intervalObs.subscribe(() => {
      this._updates.checkForUpdate();
    });

    this._updates.versionUpdates.pipe(filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY')).subscribe(evt => {
      this._updates.activateUpdate().then(() => document.location.reload());
    });
  }
}
