import { initialFlagsState, IFlagsState } from 'src/app/store/flags/flags.state';
import { EFlagsActions, FlagsActions } from 'src/app/store/flags/flags.actions';

export function flagsReducer(state = initialFlagsState, action: FlagsActions): IFlagsState {
  switch (action.type) {
    case EFlagsActions.InitializeAndGetFlags:
      return {
        ...state,
        appFlags: {
          ...state.appFlags,
          error: null,
          loading: true
        }
      };
    case EFlagsActions.GetFlags:
      return {
        ...state,
        appFlags: {
          ...state.appFlags,
          error: null,
          loading: true,
          expirationDate: null
        }
      };

    case EFlagsActions.GetFlagsSuccess:
      return {
        ...state,
        appFlags: {
          ...state.appFlags,
          data: action.result.flags,
          error: null,
          loading: false,
          expirationDate: action.result.expiration
        }
      };

    case EFlagsActions.GetFlagsError:
      return {
        ...state,
        appFlags: {
          ...state.appFlags,
          error: action.error,
          loading: false,
          expirationDate: null
        }
      };

    default:
      return state;
  }
}
