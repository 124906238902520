import { AfterViewInit, Component, TemplateRef, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { CardElevation, DialogService, HeadingSize } from 'hc-design-system-lib';
import { ButtonAppearance } from 'hc-design-system-lib/lib/components/button/button.enums';
import { AppState } from 'src/app/services';
import { IAppState } from 'src/app/store/app/app.state';
import { ClearCacheLogOut } from 'src/app/store/userContext/userContext.actions';

@Component({
  selector: 'app-login-error',
  templateUrl: './login-error.component.html',
  styleUrls: ['./login-error.component.scss']
})
export class LoginErrorComponent implements AfterViewInit {
  @ViewChild('loginErrorTemplate')
  loginErrorTemplate: TemplateRef<any>;

  buttonAppearance = ButtonAppearance.Primary;
  heading3 = HeadingSize.H3;

  constructor(
    private appState: AppState,
    private dialogService: DialogService,
    private store: Store<IAppState>
  ) {}

  ngAfterViewInit(): void {
    this.appState.setSpinnerState(false);

    this.dialogService.showDialog(
      {
        text: '',
        template: this.loginErrorTemplate,
        elevation: CardElevation.Default,
        icon: null,
        showCloseIcon: false,
        isResponsive: true,
        title: '',
        useCustomCloseLogic: true
      },
      true
    );
  }

  logout() {
    this.store.dispatch(new ClearCacheLogOut());
  }
}
