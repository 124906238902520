import { EReferencesActions, ReferencesActions } from './references.actions';
import { initialReferencesState, ReferencesState } from './references.state';

export function referencesReducer(state = initialReferencesState, action: ReferencesActions): ReferencesState {
  switch (action.type) {
    case EReferencesActions.GetReferences:
      return {
        ...state,
        allReferences: {
          ...state.allReferences,
          error: null,
          loading: true
        }
      };
    case EReferencesActions.GetReferencesSuccess:
      return {
        ...state,
        allReferences: {
          data: action.payload,
          error: null,
          loading: false
        }
      };
    case EReferencesActions.GetReferencesError:
      return {
        ...state,
        allReferences: {
          ...state.allReferences,
          error: action.error,
          loading: false
        }
      };
    case EReferencesActions.GetReferenceEmails:
      return {
        ...state,
        referenceEmails: {
          ...state.referenceEmails,
          error: null,
          loading: true
        }
      };
    case EReferencesActions.GetReferenceEmailsSuccess:
      return {
        ...state,
        referenceEmails: {
          data: action.payload,
          error: null,
          loading: false
        }
      };
    case EReferencesActions.GetReferenceEmailsError:
      return {
        ...state,
        referenceEmails: {
          ...state.referenceEmails,
          error: action.error,
          loading: false
        }
      };

    case EReferencesActions.AddReference:
      return {
        ...state,
        referenceAction: {
          ...state.referenceAction,
          error: null,
          loading: true
        }
      };

    case EReferencesActions.AddReferenceSuccess:
      return {
        ...state,
        referenceAction: {
          data: action.payload,
          error: null,
          loading: false
        }
      };

    case EReferencesActions.AddReferenceError:
      return {
        ...state,
        referenceAction: {
          ...state.referenceAction,
          error: action.error,
          loading: false
        }
      };

    case EReferencesActions.UpdateReference:
      return {
        ...state,
        referenceAction: {
          ...state.referenceAction,
          error: null,
          loading: true
        }
      };

    case EReferencesActions.UpdateReferenceSuccess:
      return {
        ...state,
        referenceAction: {
          data: action.payload,
          error: null,
          loading: false
        }
      };

    case EReferencesActions.UpdateReferenceError:
      return {
        ...state,
        referenceAction: {
          ...state.referenceAction,
          error: action.error,
          loading: false
        }
      };

    case EReferencesActions.DeleteReference:
      return {
        ...state,
        referenceAction: {
          ...state.referenceAction,
          error: null,
          loading: true
        }
      };

    case EReferencesActions.DeleteReferenceSuccess:
      return {
        ...state,
        referenceAction: {
          data: action.payload,
          error: null,
          loading: false
        }
      };

    case EReferencesActions.DeleteReferenceError:
      return {
        ...state,
        referenceAction: {
          ...state.referenceAction,
          error: action.error,
          loading: false
        }
      };
    default:
      return state;
  }
}
