<ng-container
  *ngIf="{
    licenses: licenses$ | async,
    licensesLoading: licensesLoading$ | async,
    nursysDataLoading: nursysDataLoading$ | async,
    canSeeTravel: canSeeTravel$ | async
  } as observables"
>
  <app-loading-screen-spinner [show]="observables.licensesLoading"></app-loading-screen-spinner>
  <div class="tasks-container tasks-full-height">
    <div class="tasks-desc-section" fxLayoutGap="32px" fxLayoutGap.gt-sm="24px" fxLayout="column">
      <ng-container *ngIf="observables.canSeeTravel">
        <hc-body class="nursys-text">
          We've partnered with
          <hc-link segmentTrackLinkClick segmentEventType="External" [size]="linkSize" [target]="linkTarget" url="https://www.nursys.com/"> Nursys </hc-link>
          to instantly retrieve your licenses, eliminating the need for copies and review.
        </hc-body>
        <hc-button
          id="verify-button"
          class="verify-button"
          [size]="buttonSize"
          [alwaysDisplayFullWidth]="true"
          (buttonClick)="nursysCheck()"
          [disabled]="observables.nursysDataLoading"
        >
          Retrieve Licenses
        </hc-button>
        <hc-body class="manual-entry-text"> Or, enter your license information manually. </hc-body>
      </ng-container>
      <hc-icon-prompt-button icon="add_circle" (click)="showAddLicenseDialog()" [disabled]="observables.nursysDataLoading"> Add a License </hc-icon-prompt-button>
    </div>
    <div fxLayout="column" fxLayoutAlign="space-between" class="full-height">
      <div class="tasks-list-section">
        <hc-record-card
          [ngClass]="{ 'default-cursor': !observables.canSeeTravel }"
          *ngFor="let license of observables.licenses"
          [recordCardConfig]="fillCardDetails(license, observables.canSeeTravel)"
          (cardClicked)="showAddLicenseDialog(license, observables.canSeeTravel)"
        >
        </hc-record-card>
      </div>
      <app-flow-handler [showRegularSaveButton]="false" [isListPage]="true"></app-flow-handler>
    </div>
  </div>

  <ng-template #nursysDialogTemplate>
    <app-nursys-modal [license]="selectedLicense"></app-nursys-modal>
  </ng-template>
  <ng-template #addEditLicenseDialogTemplate>
    <app-add-edit-license-modal [license]="selectedLicense" (outputLicense)="switchToNursysDialog($event)"></app-add-edit-license-modal>
  </ng-template>
</ng-container>
