import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, retry } from 'rxjs/operators';
import { ZipCodeModel } from 'src/app/common/models/zip-code-model';
import { NursePortalApi } from 'src/app/services';
import { ESharedDataActions, GetZipCodeInfo, GetZipCodeInfoError, GetZipCodeInfoSuccess, SharedDataActions } from './sharedData.actions';

@Injectable({
  providedIn: 'root'
})
export class SharedDataEffects {
  constructor(
    private actions$: Actions,
    private _nursePortalApi: NursePortalApi
  ) {}

  getZipCodeInfo$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<SharedDataActions>(ESharedDataActions.GetZipCodeInfo),
      exhaustMap((action: GetZipCodeInfo) =>
        this._nursePortalApi.getZipCodeInfo(action.payload).pipe(
          retry(2),
          map((zips: ZipCodeModel[]) => new GetZipCodeInfoSuccess(zips)),
          catchError((error: Error) => of(new GetZipCodeInfoError(error)))
        )
      )
    );
  });
}
