import { Component, OnInit, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NavHelper } from 'src/app/services';
import { ILookup, Reference, ReferenceRoleCode, ReferenceStatusCodes } from 'src/app/common';
import { selectStateLookup, selectPositionTitleLookup } from 'src/app/store/lookups/lookups.selectors';
import { Store } from '@ngrx/store';
import { IAppState } from 'src/app/store/app/app.state';
import { Subscription, Observable } from 'rxjs';
import { CallToActionClicked } from 'src/app/store/segment/segment.actions';
import { environment } from 'src/environments/environment';
import {
  selectAllReferences,
  selectCompletedReferences,
  selectPendingNonChargeNurseReferences,
  selectPendingReferences,
  selectReferencesLoading
} from 'src/app/store/references/references.selectors';
import { BodyColor, CardElevation, DialogService, IDialogParameters } from 'hc-design-system-lib';
import { IRecordCardConfig } from 'hc-design-system-lib/lib/components/cards/cards.interfaces';

@Component({
  selector: 'app-references',
  templateUrl: './references.component.html',
  styleUrls: ['./references.component.scss']
})
export class ReferenceComponent implements OnInit, OnDestroy {
  @ViewChild('addReferenceForm')
  addReferenceFormTemplate: TemplateRef<any>;
  @ViewChild('editReferenceForm')
  editReferenceFormTemplate: TemplateRef<any>;

  readonly referencesImage = '/assets/img/empty-states/References.png';
  readonly addButtonIcon = 'add';

  currentlyEditingReference: Reference;
  completeStatus = ReferenceStatusCodes.Complete;
  chargeNurseRoleCode = ReferenceRoleCode.ChargeNurse;
  maxRefsAllowed = 4;
  referencesDetails: IRecordCardConfig;
  stateLookup: Map<string, ILookup<string>>;
  stateLookupSubscription: Subscription;
  positionTitleLookup: Map<number, ILookup<string>>;
  positionTitleLookupSubscription: Subscription;

  stateLookup$: Observable<Map<string, ILookup<string>>> = this._store.select(selectStateLookup);

  positionTitleLookup$: Observable<Map<number, any>> = this._store.select(selectPositionTitleLookup);

  pendingReferences$: Observable<Reference[]> = this._store.select(selectPendingReferences);
  completedReferences$: Observable<Reference[]> = this._store.select(selectCompletedReferences);
  allReferences$: Observable<Reference[]> = this._store.select(selectAllReferences);
  pendingNonChargeNurseReferences$: Observable<Reference[]> = this._store.select(selectPendingNonChargeNurseReferences);
  referencesLoading$: Observable<boolean> = this._store.select(selectReferencesLoading);

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _navHelper: NavHelper,
    private _store: Store<IAppState>,
    private _dialogService: DialogService
  ) {}

  ngOnInit() {
    this.addPositionTitleLookupsSubscription();
    this.addStateLookupsSubscription();
  }

  ngOnDestroy(): void {
    this.positionTitleLookupSubscription?.unsubscribe();
    this.stateLookupSubscription?.unsubscribe();
    this._dialogService.closeAll();
  }

  navigateToAddReference(): void {
    const pageUrl = environment.appBaseUrl + this._router.url;
    this._store.dispatch(
      new CallToActionClicked({
        pageURL: pageUrl,
        pageTitle: this._route.routeConfig.title as string,
        destinationURL: pageUrl + '/add',
        ctaText: 'Add References'
      })
    );
    this._navHelper.goToReferencesAdd();
  }

  onClick(reference: Reference) {
    // If Reference status is complete
    if (reference.status !== this.completeStatus) {
      this._navHelper.goToReferencesEdit(reference.id);
    }
  }

  getPositionCode(positionTitleLookup: Map<Number, any>, id: number): string {
    return positionTitleLookup?.get(id)?.name ?? '';
  }

  getState(stateLookup: Map<string, ILookup<string>>, state: string): string {
    return stateLookup?.get(state)?.name ?? '';
  }

  addStateLookupsSubscription(): void {
    this.stateLookupSubscription = this.stateLookup$.subscribe((stateLookup: Map<string, ILookup<string>>) => {
      this.stateLookup = stateLookup;
    });
  }

  addPositionTitleLookupsSubscription(): void {
    this.positionTitleLookupSubscription = this.positionTitleLookup$.subscribe((positionTitleLookup: Map<number, ILookup<string>>) => {
      this.positionTitleLookup = positionTitleLookup;
    });
  }

  fillCardDetails(reference: Reference, completed: Boolean) {
    this.referencesDetails = {
      title: reference.firstName + ' ' + reference.lastName,
      firstLine: this.getPositionCode(this.positionTitleLookup, reference.positionCode),
      secondLine: reference.facilityName + ' - ' + reference.facilityCity + ', ' + this.getState(this.stateLookup, reference.facilityState),
      status: completed ? 'Responded' : 'Pending Response',
      statusColor: completed ? BodyColor.green : BodyColor.purple,
      icon: completed ? '' : 'edit'
    };
    return this.referencesDetails;
  }

  openReferenceFormDialog(reference: Reference): void {
    this.currentlyEditingReference = reference;
    const title = reference ? 'Edit Reference' : 'Add Reference';
    const template = reference ? this.editReferenceFormTemplate : this.addReferenceFormTemplate;
    const dialogData: IDialogParameters = {
      title,
      text: '',
      showCloseIcon: true,
      elevation: CardElevation.Default,
      icon: null,
      template,
      separatedHeader: true,
      noStyling: true,
      isResponsive: true,
      useCustomCloseLogic: true
    };
    this._dialogService.showDialog(dialogData, true);
  }
}
