import { createReducer, on } from '@ngrx/store';
import { setEnvironmentId, setMenuItems, setNavigationProfile, setScreenWidth, setTabBarItems, setTabbarActiveItemIndex } from 'src/app/store/ui/ui.actions';
import { initialUiState, UiState } from 'src/app/store/ui/ui.state';

//New style of reducer with much reduced boilerplate
export const uiReducer = createReducer(
  initialUiState,
  on(setScreenWidth, (state: UiState, { screenWidth }: any): UiState => ({ ...state, screenWidth })),
  on(setTabBarItems, (state: UiState, { tabBarItems }): UiState => ({ ...state, tabBar: { ...state.tabBar, tabBarItems } })),
  on(setTabbarActiveItemIndex, (state: UiState, { tabbarActiveItemIndex }): UiState => ({ ...state, tabBar: { ...state.tabBar, tabbarActiveItemIndex } })),
  on(setMenuItems, (state: UiState, { menuItems }): UiState => ({ ...state, menuItems })),
  on(setEnvironmentId, (state: UiState, { environmentId }): UiState => ({ ...state, enviromentId: environmentId })),
  on(setNavigationProfile, (state: UiState, { navigationProfile }): UiState => ({ ...state, navigationProfile }))
);
