import { Action } from '@ngrx/store';
import { Certification } from 'src/app/common';

export enum ECertificationsActions {
  GetCertifications = '[Certifications] get certifications',
  GetCertificationsSuccess = '[Certifications] get certifications success',
  GetCertificationsError = '[Certifications] get certications error',
  GetCertificationById = '[Certifications] get certification by id',
  GetCertificationDocumentById = '[Certifications] get certification document by id',
  GetCertificationByIdSuccess = '[Certifications] get certification by id success',
  GetCertificationByIdError = '[Certifications] get certification by id error',
  GetCertificationPopulate = '[Certifications] get certification populate',
  GetCertificationPopulateSuccess = '[Certifications] get certification populate success',
  GetCertificationPopulateError = '[Certifications] get certification populate error',
  AddCertification = '[Certifications] Add certification',
  AddCertificationFile = '[Certifications] Add certification file',
  AddCertificationFileError = '[Certifications] Add certification file error',
  AddCertificationSuccess = '[Certifications] Add certification success',
  AddCertificationBlsSuccess = '[Certifications] Add certification bls success',
  AddCertificationError = '[Certifications] Add certication error',
  UpdateCertification = '[Certifications] Update certification',
  UpdateCertificationSuccess = '[Certifications] Update certification success',
  UpdateCertificationError = '[Certifications] Update certication error',
  UpdateCertificationFileError = '[Certifications] Update certication file error',
  DeleteCertification = '[Certifications] Delete certification',
  DeleteCertificationSuccess = '[Certifications] Delete certification success',
  DeleteCertificationError = '[Certifications] Delete certication error'
}

export class GetCertifications implements Action {
  public readonly type = ECertificationsActions.GetCertifications;
  constructor(public forceUpdate: boolean = false) {}
}

export class GetCertificationsSuccess implements Action {
  public readonly type = ECertificationsActions.GetCertificationsSuccess;
  constructor(public payload: { certifications: Certification[]; expirationDate: Date }) {}
}

export class GetCertificationsError implements Action {
  public readonly type = ECertificationsActions.GetCertificationsError;
  constructor(public error: Error) {}
}

export class GetCertificationById implements Action {
  public readonly type = ECertificationsActions.GetCertificationById;
  constructor(public input) {}
}

export class GetCertificationByIdSuccess implements Action {
  public readonly type = ECertificationsActions.GetCertificationByIdSuccess;
  constructor(public payload: { certification: Certification; expirationDate: Date }) {}
}

export class GetCertificationDocumentById implements Action {
  public readonly type = ECertificationsActions.GetCertificationDocumentById;
  constructor(public payload: { certification: Certification; expirationDate: Date }) {}
}

export class GetCertificationByIdError implements Action {
  public readonly type = ECertificationsActions.GetCertificationByIdError;
  constructor(public error: Error) {}
}

export class GetCertificationPopulate implements Action {
  public readonly type = ECertificationsActions.GetCertificationPopulate;
  constructor(public input) {}
}

export class GetCertificationPopulateSuccess implements Action {
  public readonly type = ECertificationsActions.GetCertificationPopulateSuccess;
  constructor(public payload) {}
}

export class GetCertificationPopulateError implements Action {
  public readonly type = ECertificationsActions.GetCertificationPopulateError;
  constructor(public error: Error) {}
}

export class AddCertification implements Action {
  public readonly type = ECertificationsActions.AddCertification;
  constructor(
    public payload: {
      model;
      files;
    }
  ) {}
}

export class AddCertificationFileError implements Action {
  public readonly type = ECertificationsActions.AddCertificationFileError;
  constructor(
    public payload: {
      model;
      files?;
    },
    public error: Error
  ) {}
}

export class AddCertificationFile implements Action {
  public readonly type = ECertificationsActions.AddCertificationFile;
  constructor(
    public payload: {
      model;
      files;
      isProfessionalCert: boolean;
      isIelts: boolean;
    }
  ) {}
}

export class AddCertificationSuccess implements Action {
  public readonly type = ECertificationsActions.AddCertificationSuccess;
  constructor(
    public payload,
    public forceUpdateCache = true
  ) {}
}

export class AddCertificationBlsSuccess implements Action {
  public readonly type = ECertificationsActions.AddCertificationBlsSuccess;
  constructor(public payload) {}
}

export class AddCertificationError implements Action {
  public readonly type = ECertificationsActions.AddCertificationError;
  constructor(public error: Error) {}
}

export class UpdateCertification implements Action {
  public readonly type = ECertificationsActions.UpdateCertification;
  constructor(
    public updateCertificationModel: {
      model: Certification;
      isDeletingDocument: boolean;
      isFileUpdated: boolean;
      frontFile?: Array<File>;
      backFile?: Array<File>;
    }
  ) {}
}

export class UpdateCertificationSuccess implements Action {
  public readonly type = ECertificationsActions.UpdateCertificationSuccess;
  constructor(
    public payload,
    public forceUpdateCache = true
  ) {}
}

export class UpdateCertificationError implements Action {
  public readonly type = ECertificationsActions.UpdateCertificationError;
  constructor(public error: Error) {}
}

export class UpdateCertificationFileError implements Action {
  public readonly type = ECertificationsActions.UpdateCertificationFileError;
  constructor(public error: Error) {}
}

export class DeleteCertification implements Action {
  public readonly type = ECertificationsActions.DeleteCertification;
  constructor(public input: Certification) {}
}

export class DeleteCertificationSuccess implements Action {
  public readonly type = ECertificationsActions.DeleteCertificationSuccess;
  constructor(
    public payload,
    public forceUpdateCache = true
  ) {}
}

export class DeleteCertificationError implements Action {
  public readonly type = ECertificationsActions.DeleteCertificationError;
  constructor(public error: Error) {}
}

export type CertificationsActions =
  | GetCertifications
  | GetCertificationsSuccess
  | GetCertificationsError
  | GetCertificationById
  | GetCertificationDocumentById
  | GetCertificationByIdSuccess
  | GetCertificationByIdError
  | GetCertificationPopulate
  | GetCertificationPopulateSuccess
  | GetCertificationPopulateError
  | AddCertification
  | AddCertificationFile
  | AddCertificationFileError
  | AddCertificationSuccess
  | AddCertificationError
  | UpdateCertification
  | UpdateCertificationSuccess
  | UpdateCertificationError
  | UpdateCertificationFileError
  | DeleteCertification
  | DeleteCertificationSuccess
  | DeleteCertificationError;
