import { AssetInterceptor } from './asset.interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ApiUrlInterceptor } from './api-url.interceptor';
import { RequestInterceptor } from './request.interceptor';
import { MerlinIdInterceptor } from './merlin-id.interceptor';
import { InternalUserInterceptor } from './internal-user-interceptor';
import { UserContext } from '../services';
import { BadJobInterceptor } from './bad-job.interceptor';
import { BadEntityInterceptor } from './bad-entity.interceptor';
import { AuthInterceptor } from './auth.interceptor';

/**
 *  The order of the 404 interceptors below is important.
 *  The Entity404Interceptor should be first, followed by any more specific 404 interceptors.
 *  This ensures that as the responses come back to the interceptors they are handled in the correct (reverse) order.
 *  For example, if the Job404Interceptor is last, it will handle requests last, but it will handle responses first.
 *  If a job request errors with a 404, the Job404Interceptor handles it and returns EMPTY,
 *  preventing further interceptor handling.  If the request was not a job request, it will pass through
 *  Job404Interceptor and be handled by the generic Entity404Interceptor.
 * */
const http404Interceptors = [
  { provide: HTTP_INTERCEPTORS, useClass: BadEntityInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: BadJobInterceptor, multi: true }
];

export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: ApiUrlInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: MerlinIdInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: InternalUserInterceptor, providers: UserContext, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: AssetInterceptor, multi: true },
  ...http404Interceptors
];
