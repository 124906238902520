import { createAction, props } from '@ngrx/store';
import { NavigationItem, NavigationProfile } from 'hc-design-system-lib/lib/components/navigation/navigation.interfaces';
import { EnvironmentId } from 'src/app/common';

export enum EUiActions {
  SetScreenWidth = '[UI] set screen width',
  SetTabBarItems = '[UI] Set TabBar Items',
  SetTabbarActiveItemIndex = '[UI] Set TabBar Active Item Index',
  SetMenuItems = '[UI] Set Menu Items',
  SetEnvironmentId = '[UI] Set Environment Id',
  SetNavigationProfile = '[UI] Set Navigation Profile',
  MainComponentLoaded = '[UI] Main Component Loaded'
}

export const setEnvironmentId = createAction(EUiActions.SetEnvironmentId, props<{ environmentId: EnvironmentId }>());

export const setMenuItems = createAction(EUiActions.SetMenuItems, props<{ menuItems: NavigationItem[] }>());

export const setScreenWidth = createAction(EUiActions.SetScreenWidth, props<{ screenWidth: number }>());

export const setTabBarItems = createAction(EUiActions.SetTabBarItems, props<{ tabBarItems: NavigationItem[] }>());

export const setTabbarActiveItemIndex = createAction(EUiActions.SetTabbarActiveItemIndex, props<{ tabbarActiveItemIndex: number }>());

export const setNavigationProfile = createAction(EUiActions.SetNavigationProfile, props<{ navigationProfile: NavigationProfile }>());

export const mainComponentLoaded = createAction(EUiActions.MainComponentLoaded);

export type UiActions =
  | typeof setScreenWidth
  | typeof setTabBarItems
  | typeof setTabbarActiveItemIndex
  | typeof setMenuItems
  | typeof setEnvironmentId
  | typeof setNavigationProfile
  | typeof mainComponentLoaded;
