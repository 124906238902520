import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, filter, switchMap, take, tap } from 'rxjs/operators';
import { FileUploadTargets, IFileUploadOptions, License, LicenseIN, TaskCompletionReturnObject } from 'src/app/common';
import { getDateInFutureWithMinutes } from 'src/app/common/functions/date-manipulations';
import { DocumentHelperService, HCINPortalAPIService, NursePortalApi } from 'src/app/services';
import { IAppState } from '../app/app.state';
import { EUserContextActions, GetNurse } from '../userContext/userContext.actions';
import {
  CreateLicenseFileError,
  CreateLicense,
  CreateLicenseError,
  CreateLicenseSuccess,
  DeleteLicense,
  DeleteLicenseError,
  DeleteLicenseSuccess,
  DeleteQualification,
  ELicensesActions,
  GetCreateDocumentObservable,
  GetLicense,
  GetLicenseDocument,
  GetLicenseDocumentInitiated,
  GetLicenseError,
  GetLicenses,
  GetLicensesError,
  GetLicensesSuccess,
  GetLicenseSuccess,
  GetUpdateDocumentObservable,
  LicensesActions,
  NursysAdd,
  NursysAddError,
  NursysAddSuccess,
  NursysSync,
  NursysUpdate,
  NursysUpdateError,
  NursysUpdateSuccess,
  UpdateLicense,
  UpdateLicenseError,
  UpdateLicenseFileError,
  UpdateLicenseSuccess,
  GetLicensesINSuccess,
  GetLicensesIN
} from './licenses.actions';
import {
  selectFileUrl,
  selectLicense,
  selectLicenseExpirationDate,
  selectLicenseExpired,
  selectLicenses,
  selectLicensesExpirationDate,
  selectLicensesExpired,
  selectLicensesIN,
  selectLicensesINExpirationDate,
  selectLicensesINExpired
} from './licenses.selectors';
import { NotificationService } from 'hc-design-system-lib';
import { selectCanSeeInternational, selectCanSeeTravel } from '../userContext/userContext.selectors';

@Injectable({
  providedIn: 'root'
})
export class LicensesEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly _store: Store<IAppState>,
    private readonly _api: NursePortalApi,
    private readonly _hcinApi: HCINPortalAPIService,
    private readonly _documentHelperService: DocumentHelperService,
    private readonly _notificationService: NotificationService
  ) {}

  getLicense$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<LicensesActions>(ELicensesActions.GetLicense),
      concatLatestFrom(() => [
        this._store.select(selectLicense),
        this._store.select(selectLicenseExpired),
        this._store.select(selectLicenseExpirationDate),
        this._store.select(selectFileUrl)
      ]),
      switchMap(([action, license, isExpired, expiration, fileUrl]: [GetLicense, License, boolean, Date, string]) =>
        !isExpired && action.id === license.id
          ? of(new GetLicenseSuccess({ license, expiration, fileUrl }))
          : this._api.getLicense(action.id).pipe(
              switchMap(license => {
                return license.sharepointURL
                  ? this._documentHelperService.getDocumentUrlSubject(license.sharepointURL, license.qualificationId).pipe(
                      switchMap(fileUrl =>
                        of(
                          new GetLicenseSuccess({
                            license,
                            expiration: getDateInFutureWithMinutes(10),
                            fileUrl
                          })
                        )
                      )
                    )
                  : of(
                      new GetLicenseSuccess({
                        license,
                        expiration: getDateInFutureWithMinutes(10)
                      })
                    );
              }),
              catchError(error => of(new GetLicenseError(error)))
            )
      )
    );
  });

  getLicenses$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<LicensesActions>(
        ELicensesActions.GetLicenses,
        ELicensesActions.CreateLicenseSuccess,
        ELicensesActions.CreateLicenseFileError,
        ELicensesActions.NursysAddSuccess,
        EUserContextActions.CheckVerificationKeySuccess
      ),
      concatLatestFrom(() => [this._store.select(selectLicenses), this._store.select(selectLicensesExpired), this._store.select(selectLicensesExpirationDate)]),
      switchMap(([_action, licenses, isExpired, expiration]: [GetLicenses, License[], boolean, Date]) =>
        !isExpired
          ? of(new GetLicensesSuccess({ licenses, expiration }))
          : this._api.getLicenses().pipe(
              switchMap(licenses =>
                of(
                  new GetLicensesSuccess({
                    licenses: licenses ?? [],
                    expiration: getDateInFutureWithMinutes(10)
                  })
                )
              ),
              catchError(error => of(new GetLicensesError(error)))
            )
      )
    );
  });

  getLicensesIN$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<LicensesActions>(ELicensesActions.GetLicensesIN, ELicensesActions.CreateLicenseSuccess, ELicensesActions.CreateLicenseFileError),
      concatLatestFrom(() => [
        this._store.select(selectLicensesIN),
        this._store.select(selectLicensesINExpired),
        this._store.select(selectLicensesINExpirationDate),
        this._store.select(selectCanSeeInternational)
      ]),
      filter(([action, licenses, isExpired, expiration, canSeeIN]) => canSeeIN),
      switchMap(([_action, licenses, isExpired, expiration, canSeeIN]: [GetLicensesIN, LicenseIN[], boolean, Date, boolean]) =>
        !isExpired && _action.type == ELicensesActions.GetLicensesIN
          ? of(new GetLicensesINSuccess({ licenses, expiration }))
          : this._hcinApi.getLicenses().pipe(
              switchMap(licenses =>
                of(
                  new GetLicensesINSuccess({
                    licenses: licenses ?? [],
                    expiration: getDateInFutureWithMinutes(10)
                  })
                )
              ),
              catchError(error => of(new GetLicensesError(error)))
            )
      )
    );
  });

  createLicense$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<LicensesActions>(ELicensesActions.CreateLicense),
      concatLatestFrom(() => [this._store.select(selectCanSeeTravel), this._store.select(selectCanSeeInternational)]),
      switchMap(([action, canSeeTravel, canSeeInternational]: [CreateLicense, boolean, boolean]) => {
        if (canSeeInternational && !canSeeTravel) {
          const tnLicense = action.payload.license;
          let hcinLicense = new LicenseIN();
          for (let key in tnLicense) {
            hcinLicense[key] = tnLicense[key];
          }
          return this._hcinApi.addLicense(hcinLicense).pipe(
            switchMap(createResponse =>
              of(
                new GetCreateDocumentObservable({
                  license: {
                    ...action.payload.license,
                    id: createResponse
                  },
                  files: action.payload.files
                })
              )
            )
          );
        }
        return this._api.addLicense(action.payload.license).pipe(
          switchMap(createResponse =>
            of(
              new GetCreateDocumentObservable({
                license: {
                  ...action.payload.license,
                  id: createResponse.returnValue
                },
                files: action.payload.files
              })
            )
          ),
          catchError(error => of(new CreateLicenseError(error)))
        );
      })
    );
  });

  getCreateDocumentObservable$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<LicensesActions>(ELicensesActions.GetCreateDocumentObservable),
      concatLatestFrom(() => [this._store.select(selectCanSeeInternational)]),
      switchMap(([action, canSeeInternational]: [GetUpdateDocumentObservable, boolean]) => {
        const options: IFileUploadOptions = {
          itemId: action.payload.license.id,
          target: FileUploadTargets.License
        };

        return this._documentHelperService._getDocumentObservable(options, action.payload.files, canSeeInternational).pipe(
          filter(response => !!response),
          take(1),
          switchMap(_response => of(new CreateLicenseSuccess({ license: action.payload.license }))),
          catchError(error => of(new CreateLicenseFileError({ license: action.payload.license }, error)))
        );
      })
    );
  });

  deleteLicense$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<LicensesActions>(ELicensesActions.DeleteLicense),
      switchMap((action: DeleteLicense) =>
        this._api.deleteLicense(action.payload.id).pipe(
          switchMap(_deleteLicenseResponse =>
            action.payload.qualificationId
              ? of(
                  new DeleteQualification({
                    qualificationId: action.payload.qualificationId,
                    licenseId: action.payload.id
                  })
                )
              : of(new DeleteLicenseSuccess(action.payload.id))
          ),
          catchError(error => of(new DeleteLicenseError(action.payload.id, error)))
        )
      )
    );
  });

  deleteQualification$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<LicensesActions>(ELicensesActions.DeleteQualification),
      switchMap((action: DeleteQualification) =>
        this._api.deleteQualification(action.payload.qualificationId).pipe(
          switchMap(() => of(new DeleteLicenseSuccess(action.payload.licenseId))),
          catchError(error => of(new DeleteLicenseError(action.payload.licenseId, error)))
        )
      )
    );
  });

  updateLicense$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<LicensesActions>(ELicensesActions.UpdateLicense),
      switchMap((action: UpdateLicense) =>
        this._api.updateLicense(action.payload.license).pipe(
          switchMap((updateResponse: TaskCompletionReturnObject) =>
            of(
              new GetUpdateDocumentObservable({
                license: action.payload.license,
                files: action.payload.files,
                isDeleting: action.payload.isDeletingFile,
                updateResponse
              })
            )
          ),
          catchError(error => {
            return of(new UpdateLicenseError(error));
          })
        )
      )
    );
  });

  getUpdateDocumentObservable$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<LicensesActions>(ELicensesActions.GetUpdateDocumentObservable),
      switchMap((action: GetUpdateDocumentObservable) => {
        const options: IFileUploadOptions = {
          itemId: action.payload.license.id,
          target: FileUploadTargets.License,
          isDeleting: action.payload.isDeleting,
          qualificationId: action.payload.license.qualificationId
        };

        return this._documentHelperService._getDocumentObservable(options, action.payload.files).pipe(
          filter(response => !!response),
          take(1),
          switchMap(_response => {
            const deleteOldFile = options?.isDeleting && options?.qualificationId;
            if (!deleteOldFile && (!action.payload.files || action.payload.files.length === 0)) {
              _response = {
                ..._response,
                returnValue: {
                  ..._response.returnValue,
                  fileDownloadUrl: action.payload.license.sharepointURL
                }
              };
            }
            return of(
              new UpdateLicenseSuccess({
                license: {
                  ...action.payload.license,
                  sharepointURL: _response?.returnValue?.fileDownloadUrl ?? ''
                },
                updateResponse: action.payload.updateResponse
              })
            );
          }),
          catchError(error => {
            return of(
              new UpdateLicenseFileError(error, {
                license: {
                  ...action.payload.license
                },
                updateResponse: action.payload.updateResponse
              })
            );
          })
        );
      })
    );
  });

  nursysAdd$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<LicensesActions>(ELicensesActions.NursysUpdateSuccess, ELicensesActions.NursysAdd),
      switchMap((_action: NursysAdd) =>
        this._api.nursysAdd().pipe(
          take(1),
          switchMap(addResponse => (addResponse ? of(new NursysSync()) : of(new NursysAddError(new Error('Failed to add license.'))))),
          catchError(error => of(new NursysAddError(error)))
        )
      )
    );
  });

  nursysSync$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<LicensesActions>(ELicensesActions.NursysSync),
      switchMap((_action: NursysSync) =>
        this._api.nursysSync().pipe(
          filter(response => !!response),
          take(1),
          switchMap(syncResponse => of(new NursysAddSuccess(syncResponse.returnValue))),
          catchError(error => of(new NursysAddError(error)))
        )
      )
    );
  });

  getNurse$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<LicensesActions>(ELicensesActions.NursysAddSuccess),
      switchMap((_action: NursysAddSuccess) => of(new GetNurse()))
    );
  });

  nursysUpdate$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<LicensesActions>(ELicensesActions.NursysUpdate),
      switchMap((action: NursysUpdate) =>
        this._api.updateNursysSetup(action.payload).pipe(
          take(1),
          switchMap((updateResponse: number) => of(new NursysUpdateSuccess(updateResponse))),
          catchError(error => of(new NursysUpdateError(error)))
        )
      )
    );
  });

  nursysSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType<LicensesActions>(ELicensesActions.NursysAddSuccess),
        tap(() => this._notificationService.showNotification('Your licenses were retrieved successfully!', 'success'))
      );
    },
    { dispatch: false }
  );

  nursysError$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType<LicensesActions>(ELicensesActions.NursysAddError, ELicensesActions.NursysUpdateError),
        tap(() => {
          this._notificationService.showNotification(`We could not retrieve your license. Please try again.`, 'error');
        })
      );
    },
    { dispatch: false }
  );

  getLicenseDocument$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<LicensesActions>(ELicensesActions.GetLicenseDocument),
      switchMap((action: GetLicenseDocument) => {
        this._documentHelperService.getDocument(action.payload.document, action.payload.qualificationId);
        return of(new GetLicenseDocumentInitiated());
      })
    );
  });

  createLicenseSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType<LicensesActions>(ELicensesActions.CreateLicenseSuccess),
        tap(() => this._notificationService.showNotification('Your license was added successfully!', 'success'))
      );
    },
    { dispatch: false }
  );

  updateLicenseSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType<LicensesActions>(ELicensesActions.UpdateLicenseSuccess),
        tap(() => this._notificationService.showNotification('Your license was updated successfully!', 'success'))
      );
    },
    { dispatch: false }
  );
}
