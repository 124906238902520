import { createSelector } from '@ngrx/store';
import { IAppState } from '../app/app.state';
import { SharedDataState } from './sharedData.state';
import { ZipCodeModel } from 'src/app/common/models/zip-code-model';
import { convertIntoDropdownData } from 'src/app/common/functions/dropdown-helpers';

const selectSharedDataState = (state: IAppState) => state.sharedData;

export const selectZipCodes = createSelector(selectSharedDataState, (state: SharedDataState) => state?.zipCodes?.data);

export const selectZipCodeDropdowns = createSelector(selectZipCodes, (zipCodes: ZipCodeModel[]) =>
  zipCodes ? Array.from(zipCodes, zip => convertIntoDropdownData(zip, 'zipCode')) : []
);
