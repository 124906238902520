import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { NursePortalApi } from '.';
import { IJob, TaskCompletionPopUpReturnIds } from '../common';
import { NurseTask } from '../common/models/db-objects';
import { TaskEntities, taskURLMap } from '../common/models/task-entities';
import { TaskStatusConstants } from '../common/models/task-status';
import { ApplyPopUpComponent } from '../components/shared/apply-pop-up/apply-pop-up.component';
import { NavHelper } from './nav-helper.service';

@Injectable({
  providedIn: 'root'
})
export class TaskNavigatorService {
  payTasksRemaining: Task[];

  // Set when taskList Loads
  applyTasksRemaining: Task[];

  returnToPayInfoJob = false;
  returnToApplyJob = false;

  constructor(
    private _router: Router,
    private _api: NursePortalApi,
    private _navHelper: NavHelper,
    public _route: ActivatedRoute,
    private _dialog: MatDialog
  ) {}

  /*
    Paths for task navigation
    1. Standard Navigation
    Task list completion, returns to task list, with options for job search or dashboard

    2. Show pay info
    Navigates to task screen for completing pay tasks, after completion navigates to specific job
    Uses session storage to store job Id, leaving "path" removes the Id.

    3. Complete apply tasks
    Navigates to task list showing only jobs required to apply
    After completion of apply tasks should navigate to the empty task list page (for apply tasks) then to application review
  */

  goToUrl(urlAndParams) {
    this._router.navigate([urlAndParams.url], { queryParams: urlAndParams.params });
  }

  // Pay Task Nav situation with single tasks immediately
  handlePayTaskInfoNavigation(job: string) {
    // Setting Job Guid for return to job
    sessionStorage.setItem('jobGuid', job);
    this.returnToPayInfoJob = false;
    this._api.getRequiredPayTasksCount().subscribe(o => {
      this.payTasksRemaining = o;
      this.navigateToTasks(this.payTasksRemaining, true);
    });
  }

  determineTaskPath(): number {
    // Pay Task Navigation
    if (this.checkPaySessionData()) {
      return NavigationalPaths.ShowPayInfoNavigation;
    }

    // Apply Task Navigation
    if (this._route.snapshot.queryParamMap.get('apply') === 'true') {
      return NavigationalPaths.CompleteApplyTasksNavigation;
    }

    // Standard Task Navigation
    return NavigationalPaths.StandardNavigation;
  }

  navigateToTasks(taskInformation: any, showUserPayDetails: boolean = false) {
    // Checks if one or more pay tasks to do.
    if (taskInformation.length === 1) {
      // Navigate Directly to next Task.
      this._navHelper.showUserPayDetails = showUserPayDetails;
      this._navHelper.beforeTasksRoute = this._router.url;
      this.doTask(taskInformation[0], true);
      this.returnToPayInfoJob = true;
    } else {
      // Navigate to Task List
      this._navHelper.goToPayTasks(showUserPayDetails);
    }
  }

  postTaskNavigation(): number {
    switch (this.determineTaskPath()) {
      case NavigationalPaths.StandardNavigation:
        // Standard Nav
        this._navHelper.goToTasks();
        return TaskCompletionPopUpReturnIds.goToTasks;

      case NavigationalPaths.ShowPayInfoNavigation:
        // Pay Task Nav
        this._api.getRequiredPayTasksCount().subscribe(o => {
          this.payTasksRemaining = o;
          if (this.payTasksRemaining.length === 0) {
            // No remaining tasks, navigate to job if Id stored
            if (this.checkPaySessionData()) {
              this._navHelper.goToJobsSpecific(sessionStorage.getItem('jobGuid'));
              sessionStorage.removeItem('jobGuid');
            } else {
              this._navHelper.goToPayTasks();
            }
          } else {
            this.setupPopupState();
            this._navHelper.goToPayTasks();
          }
        });
        return TaskCompletionPopUpReturnIds.payTaskNavigation;

      case NavigationalPaths.CompleteApplyTasksNavigation:
        // Apply Task Nav
        this._navHelper.goToApplyTasks();
        return TaskCompletionPopUpReturnIds.goToTasks;
    }
  }

  startApplyTaskNav(job: IJob): void {
    sessionStorage.setItem('InProgressApplication', job.id);
    this._dialog.open(ApplyPopUpComponent, {
      panelClass: 'custom-dialog-container',
      maxWidth: 300
    });
  }

  postTaskNavigationCancel(): void {
    this._api.getRequiredPayTasksCount().subscribe(o => {
      this.payTasksRemaining = o;
      if (this.payTasksRemaining.length === 0) {
        // No remaining tasks, navigate to job if Id stored
        if (this.checkPaySessionData()) {
          this._navHelper.goToJobsSpecific(sessionStorage.getItem('jobGuid'));
          this.removePayJobInfoTag();
        }
      } else {
        this.setupPopupState();
        this._navHelper.goToPayTasks();
      }
    });
  }

  fromJobInfoButton(): boolean {
    return sessionStorage.getItem('jobGuid') !== undefined && sessionStorage.getItem('jobGuid') !== null;
  }

  removePayJobInfoTag(): void {
    sessionStorage.removeItem('jobGuid');
    this.returnToPayInfoJob = false;
  }

  removeApplyJobTag(): void {
    sessionStorage.removeItem('InProgressApplication');
    this.returnToApplyJob = false;
  }

  setupPopupState(): void {
    // Determines buttons to display on task completion popup
    if (this.checkPaySessionData() && this.payTasksRemaining.length === 1) {
      this.returnToPayInfoJob = true;
    }

    if (this.checkApplySessionData() && this.applyTasksRemaining.length === 1) {
      this.returnToApplyJob = true;
    }
  }

  checkApplySessionData(): boolean {
    // Returns true if value is present
    return sessionStorage.getItem('InProgressApplication') !== undefined && sessionStorage.getItem('InProgressApplication') !== null;
  }

  checkPaySessionData(): boolean {
    // Returns true if value is present
    return sessionStorage.getItem('jobGuid') !== undefined && sessionStorage.getItem('jobGuid') !== null;
  }

  doTask(task: NurseTask, lastTask: boolean = false, jobId?: string): void {
    let urlAndParams = this.buildUrlObject(task);
    urlAndParams = this.setUrlFromTaskEntity(urlAndParams, task, jobId);

    if (this._route.snapshot.queryParamMap.get('apply') === 'true') {
      urlAndParams.params['apply'] = 'true';
    }

    if (lastTask) {
      urlAndParams.params['lastTask'] = 'true';
    }

    this.goToUrl(urlAndParams);
  }

  buildUrlObject(task: NurseTask): UrlObject {
    let urlAndParams: UrlObject = { url: '', params: {} };
    if (task.value.length === 2) {
      const value = task.value.filter(x => x !== TaskEntities.candidateQualifications)[0];
      urlAndParams.url = taskURLMap.get(value);
    } else {
      urlAndParams.url = taskURLMap.get(task.value[0]);
      if (task.value[0] === TaskEntities.overlap) {
        urlAndParams.url += task.id;
      }
    }
    return urlAndParams;
  }

  setUrlFromTaskEntity(urlObject: any, task: NurseTask, jobId?: string): UrlObject {
    const taskEntity = task.value[0];
    const isExpiringOrExpired = task.status === TaskStatusConstants.expired || task.status === TaskStatusConstants.expiring;

    if (jobId) {
      urlObject.params['jobId'] = jobId;
    } else {
      urlObject.params['fromTask'] = true;
    }

    switch (taskEntity) {
      case TaskEntities.backgroundQuestionnaire:
        urlObject.params['expiring'] = isExpiringOrExpired;
        break;
      case TaskEntities.certification:
        if (task.licenseCertificationId) {
          urlObject.params['id'] = task.licenseCertificationId;
        } else {
          urlObject.params['id'] = task.complianceRequirementId;
        }
        break;
      default:
        break;
    }

    return urlObject;
  }
}
// TODO [Potential for Refactor] - Abststract these classes out?
class Task {
  candidateQualificationId: string;
  complianceRequirementId: string;
  description: string;
  name: string;
  portalCategoryCodes: number[];
  priority: number;
  requiredForPay: boolean;
  requiredForApply: boolean;
  status: number;
  value: number[];
}

class UrlObject {
  url: string;
  params: any;
}

enum NavigationalPaths {
  //  Task list completion, returns to task list, with options for job search or dashboard
  StandardNavigation = 1,

  /*
    Navigates to task screen for completing pay tasks, after completion navigates to specific job
    Uses session storage to store job Id, leaving "path" removes the Id.
  */
  ShowPayInfoNavigation = 2,

  /*
    Navigates to task screen for completing pay tasks, after completion navigates to specific job
    Uses session storage to store job Id, leaving "path" removes the Id.
  */

  CompleteApplyTasksNavigation = 3
  /*
    Navigates to task list showing only jobs required to apply
    After completion of apply tasks should navigate to the empty task list page (for apply tasks) then to application review
  */
}
