import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-loading-screen-spinner',
  templateUrl: './loading-screen-spinner.component.html',
  styleUrls: ['./loading-screen-spinner.component.scss']
})
export class LoadingScreenSpinnerComponent {
  @Input() message: string;
  @Input() show = false;

  constructor() {}
}
