import { Component } from '@angular/core';
import { Observable, skipWhile, take, tap } from 'rxjs';
import { BulkUploadPopupComponent } from './bulk-upload-popup/bulk-upload-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { extensionList } from 'src/app/common/models/extension-list';
import { BadExtensionComponent } from 'src/app/components/shared/bad-extension-popup/bad-extension-popup.component';
import { Store } from '@ngrx/store';
import { AngularticsEventTrack } from 'src/app/store/angulartics2/angulartics2.actions';
import { Camera, CameraResultType } from '@capacitor/camera';
import { Capacitor } from '@capacitor/core';
import { IDataState } from 'src/app/store/app/app.models';
import { selectBulkUploadDocuments, selectBulkUploadDocumentsLoading } from 'src/app/store/userContext/userContext.selectors';
import { BulkUploadDocuments } from 'src/app/store/userContext/userContext.actions';

@Component({
  selector: 'app-bulk-upload',
  templateUrl: './bulk-upload.component.html',
  styleUrls: ['./bulk-upload.component.scss']
})
export class BulkUploadComponent {
  files: Array<File>;
  extList = extensionList;
  constructor(
    public readonly dialog: MatDialog,
    private readonly _store: Store
  ) {}

  isNative: boolean = Capacitor.isNativePlatform();

  bulkUploadDocuments$: Observable<IDataState> = this._store.select(selectBulkUploadDocuments);
  bulkUploadDocumentsLoading$: Observable<boolean> = this._store.select(selectBulkUploadDocumentsLoading);

  onSave(): void {
    this._store.dispatch(new BulkUploadDocuments({ files: this.files }));
    this.bulkUploadDocuments$
      .pipe(
        skipWhile(({ loading }) => loading),
        tap(({ error }) => {
          if (!error) this.onSuccess();
        }),
        take(1)
      )
      .subscribe();
  }

  onSuccess() {
    this._store.dispatch(new AngularticsEventTrack('Upload', 'Complete Profile', 'Drop and Go'));
    this.dialog.open(BulkUploadPopupComponent, {
      data: { fileCount: this.files.length }
    });
  }

  handleFileInput(fileList: FileList, input: HTMLInputElement) {
    const ext = fileList[0].name.split('.').pop().toLowerCase();
    if (!this.extList.includes(ext)) {
      input.value = null;
      this.dialog.open(BadExtensionComponent);
    } else {
      this.files = Array.from(fileList);
      this.onSave();
    }
  }

  async takePicture() {
    // Only allows a single photo instead of a list
    const image = await Camera.getPhoto({
      quality: 90,
      allowEditing: false,
      resultType: CameraResultType.Uri,
      webUseInput: true
    });

    // Fetch the photo, read as a blob, then convert to file format
    const response = await fetch(image.webPath);
    const blob = await response.blob();
    const filename = image.webPath.split('/').pop();
    const imageFile = new File([blob], filename, { type: 'image/jpg' });
    const extension = image.format.toLocaleLowerCase();

    if (!this.extList.includes(extension)) {
      this.dialog.open(BadExtensionComponent);
    } else {
      this.files = [imageFile];
      this.onSave();
    }
  }
}
