import { FormControl } from '@angular/forms';
import { IDropdownData } from 'hc-design-system-lib/lib/components/form/form.interfaces';

export class License {
  id: string;
  candidateId?: string;
  certification?: string;
  certificationId?: string;
  licenseNumber: string;
  type?: number;
  description?: string;
  state?: string;
  stateId?: string;
  issuingBody?: string;
  compactLicense?: number;
  primary?: number;
  licenseName: string;
  expirationDate: Date;
  statusId?: number;
  sharepointURL?: string;
  qualificationId?: string;
  hasNursysLink?: boolean;
  issuingBodyId?: string;
  issuingDate: Date;
  qualificationStatusId?: string;
  country?: string;
  countryId?: string;
  licenseType?: LicenseType; //IN type
}

export class LicenseIN {
  id: string;
  licenseName: string;
  country: string;
  countryId: string;
  state: string;
  stateId: string;
  issuingDate: string;
  expirationDate: string;
  statusId: number;
  type: string;
  licenseNumber: string;
  estLicensureDate?: string;
  statusString?: string;
  qualificationId?: string;
  sharepointURL: string;
}

export enum LicenseType {
  International = 'International License',
  State = 'State License'
}

export class LicenseForm {
  licenseNumber: FormControl<string>;
  licenseState: FormControl<IDropdownData>;
  dateOfBirth?: FormControl<Date | null>;
  ssnLast4?: FormControl<string | null>;
  issuingDate?: FormControl<Date | null>;
  expirationDate?: FormControl<Date | null>;
  fileUpload?: FormControl<FileList | null>;
}

export class NursysSetup {
  licenseNumber: string;
  stateCode: string;
  dateOfBirth: Date;
  ssnLast4: number;
}
