<ng-container
  *ngIf="{
    jobPreferences: jobPreferences$ | async,
    jobPreferencesLoading: jobPreferencesLoading$ | async,
    jobPreferencesIN: jobPreferencesIN$ | async,
    canSeeInternational: canSeeInternational$ | async
  } as obs"
>
  <hc-tile-card
    *ngIf="!obs.jobPreferencesLoading"
    class="job-preferences-tile"
    [cardConfig]="tileConfig"
    [templateReference]="obs.canSeeInternational ? jobPreferencesTemplateIN : jobPreferencesTemplate"
  >
  </hc-tile-card>

  <ng-template #jobPreferencesTemplate>
    <div class="job-preferences-details" fxLayout="column" fxLayoutGap="24px">
      <div fxLayout="row" fxLayoutGap="24px">
        <hc-body fxFlex="1 0 calc(50% - 24px)">
          <span class="job-preferences-section">Weekly Pay: </span>
          <span class="ellipsis" id="pay-preference-value">
            {{ getJobPreferencesPay(obs.jobPreferences?.weeklyPay) }}
          </span>
        </hc-body>
        <hc-body fxFlex="1 0 calc(50% - 24px)">
          <span class="job-preferences-section">Shift: </span>
          <span class="ellipsis" id="shift-preference-value">
            {{ getJobPreferencesShift(obs.jobPreferences?.shiftPreferences) }}
          </span>
        </hc-body>
      </div>
      <hc-body fxFlex="1 0 100%">
        <span class="job-preferences-section">Location: </span>
        <span class="ellipsis" id="location-preference-value">
          {{ getJobPreferencesLocations(obs.jobPreferences) }}
        </span>
      </hc-body>
    </div>
  </ng-template>

  <ng-template #jobPreferencesTemplateIN>
    <div class="job-preferences-details" fxLayout="column" fxLayoutGap="24px">
      <div fxLayout="row" fxLayoutGap="24px">
        <hc-body fxFlex="1 0 calc(50% - 24px)">
          <span class="job-preferences-section">Desired Specialties: </span>
          <span class="ellipsis" id="specialties-preference-value">
            {{ getJobPreferencesINSpecialties(obs.jobPreferencesIN) }}
          </span>
        </hc-body>
      </div>
      <hc-body fxFlex="1 0 100%">
        <span class="job-preferences-section">Location: </span>
        <span class="ellipsis" id="location-preference-value">
          {{ getJobPreferencesINLocations(obs.jobPreferencesIN) }}
        </span>
      </hc-body>
    </div>
  </ng-template>
</ng-container>
