import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivate, Router } from '@angular/router';
import { combineLatest, Observable } from 'rxjs';
import { AppUrls } from '../app-urls';
import { filter, map } from 'rxjs/operators';
import { RegistrationConstants } from '../common/models/registration-constants';
import { Store } from '@ngrx/store';
import { IAppState } from '../store/app/app.state';
import { PostRegistrationCheck } from '../store/userContext/userContext.actions';
import { selectAccountStateData, selectRegistrationCheckData } from '../store/userContext/userContext.selectors';

@Injectable({
  providedIn: 'root'
})
export class RegistrationCheckGuard implements CanActivate {
  registrationCheck$ = this._store.pipe(selectRegistrationCheckData);
  accountState$ = this._store.select(selectAccountStateData);

  constructor(
    private _router: Router,
    private _store: Store<IAppState>
  ) {}

  canActivate(_: ActivatedRouteSnapshot, __: RouterStateSnapshot): Observable<true | UrlTree> {
    this._store.dispatch(new PostRegistrationCheck());

    return combineLatest([this.registrationCheck$, this.accountState$]).pipe(
      filter(([registrationCheck, accountState]) => registrationCheck !== null && registrationCheck !== undefined && accountState !== null && accountState !== undefined),
      map(([registrationCheck, accountState]) => {
        if ((!accountState.canSeeInternational && !accountState.canSeeTravel) || (accountState.canSeeInternational && !accountState.canSeeTravel && accountState.isNew)) {
          return this._router.createUrlTree([AppUrls.LOGIN_ERROR]);
        }

        if (registrationCheck === RegistrationConstants.alreadyExists) {
          return this._router.createUrlTree([AppUrls.DASHBOARD]);
        } else if (registrationCheck === RegistrationConstants.MultipleMatchesFound) {
          return this._router.createUrlTree([AppUrls.MULTIPLE_ACCOUNTS_FOUND]);
        } else {
          return true;
        }
      })
    );
  }
}
