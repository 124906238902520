<div class="popup" fxLayout="column">
  <div mat-dialog-title fxLayout="row" fxLayoutAlign="end">
    <div class="proTip">
      <strong>Pro Tip</strong>: Our goal is to help you land the perfect next assignment. Let us know as much as you remember about your interview and we will do the rest.
    </div>
    <button id="interviewPopupCancel" mat-icon-button (click)="cancel()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div mat-dialog-content>
    <form [formGroup]="form" fxLayout="column" fxLayoutAlign="start start">
      <mat-form-field fxFlex>
        <input matInput formControlName="interviewer" placeholder="Name of Interviewer" />
      </mat-form-field>
      <mat-form-field fxFlex [ngClass]="{ 'mb-15': form.controls.interviewDate.hasError('required') && form.controls.interviewDate.touched }">
        <input matInput [matDatepicker]="interviewDate" formControlName="interviewDate" placeholder="Interview Date" />
        <mat-datepicker-toggle matSuffix [for]="interviewDate"></mat-datepicker-toggle>
        <mat-datepicker #interviewDate></mat-datepicker>
        <mat-error *ngIf="form.controls.interviewDate.hasError('required')">Please provide the date of the interview.</mat-error>
      </mat-form-field>
      <mat-form-field fxFlex [ngClass]="{ 'mb-15': form.controls.interviewNotes.hasError('required') && form.controls.interviewNotes.touched }">
        <textarea matInput maxlength="2000" formControlName="interviewNotes" placeholder="Additional Notes" cdkTextareaAutosize cdkAutosizeMaxRows="6"></textarea>
        <mat-error *ngIf="form.controls.interviewNotes.hasError('required')">Please provide any information you can.</mat-error>
      </mat-form-field>
    </form>
  </div>
  <div class="saveSection" fxLayout="row" fxLayoutAlign="center">
    <button id="interviewPopupSave" fxFlex mat-raised-button (click)="confirm()" [disabled]="!form.valid || saving">
      <div *ngIf="saving; else elseBlock" class="mr-20" fxLayout="row" fxLayoutAlign="center center">
        <mat-spinner class="mr-15" diameter="25" color="accent"></mat-spinner>
      </div>
      <ng-template #elseBlock>Save</ng-template>
    </button>
  </div>
</div>
