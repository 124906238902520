import { Injectable } from '@angular/core';
import { ApplicationInsights, IEventTelemetry, IExceptionTelemetry, IPageViewTelemetry, ITraceTelemetry, SeverityLevel } from '@microsoft/applicationinsights-web';
import { EnvironmentId } from 'src/app/common';
import { ActivatedRouteSnapshot, NavigationEnd, NavigationStart, ResolveEnd, Router, RoutesRecognized } from '@angular/router';
import { filter } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AppVersionInfo } from './version-retrieval-service';
import { HttpErrorResponse, HttpRequest, HttpResponse } from '@angular/common/http';
import { IsCatchAllRedirect } from 'src/app/common/router-helpers';
import { selectAccountStateData } from 'src/app/store/userContext/userContext.selectors';
import { IAppState } from '../store/app/app.state';
import { Store } from '@ngrx/store';
class PageDetails {
  name: string = '';
  loadStart: number;
  loadEnd: number;

  public startLoad() {
    this.name = '';
    this.loadStart = this.dateNow();
  }
  public stopLoad() {
    this.loadEnd = this.dateNow();
  }
  public getLoadDuraction() {
    return this.loadStart != 0 ? Math.abs(this.loadEnd - this.loadStart) : undefined;
  }
  private dateNow() {
    const dt = Date;
    if (dt.now) {
      return dt.now();
    }
    return new dt().getTime();
  }
}
class CallDetails {
  private _name: string;
  private _loadStart: number;
  private _loadEnd: number;

  private _request: HttpRequest<any>;
  private _response: HttpResponse<any>;

  private _errorResponse: HttpErrorResponse;
  private _appInsightsWrapper: AppInsights;

  constructor(request: HttpRequest<any>, appInsightsWrapper: AppInsights, name: string) {
    this._loadStart = this.dateNow();
    this._request = request;
    this._appInsightsWrapper = appInsightsWrapper;
    this._name = name;
  }

  public logResponse(response: HttpResponse<any>) {
    this._loadEnd = this.dateNow();
    this._response = response;
    this._appInsightsWrapper.logCallDetails(this);
  }

  public logErrorResponse(errorResponse: HttpErrorResponse) {
    this._loadEnd = this.dateNow();
    this._errorResponse = errorResponse;
    this._appInsightsWrapper.logCallDetails(this);
  }

  public getEventTelemetry() {
    const event: IEventTelemetry = {
      name: this._name,
      properties: {
        url: this._request.url,
        method: this._request.method,
        duration: this.getLoadDuration(),
        successful: this._response != null && this._response.ok,
        responseCode: this.getResponseCode()
      }
    };

    return event;
  }
  private getResponseCode() {
    if (this._response != null) {
      return this._response.status;
    } else if (this._errorResponse != null) {
      return this._errorResponse.status;
    } else {
      return undefined;
    }
  }

  private getLoadDuration() {
    return this._loadStart != 0 ? Math.abs(this._loadEnd - this._loadStart) : undefined;
  }
  private dateNow() {
    const dt = Date;
    if (dt.now) {
      return dt.now();
    }
    return new dt().getTime();
  }
}

@Injectable()
export class AppInsights {
  private _pageDetails = new PageDetails();
  private _appInsights = new ApplicationInsights({
    config: {
      instrumentationKey: environment.appInsightsLey,
      autoTrackPageVisitTime: true,
      enableCorsCorrelation: this.shouldEnableCorrelation(),
      enableRequestHeaderTracking: this.shouldEnableCorrelation(),
      enableResponseHeaderTracking: this.shouldEnableCorrelation(),
      // Eliminate some correlation calls that cause issues with CORS
      correlationHeaderExcludedDomains: [
        '*.segment.com',
        '*.segment.io',
        '*.google-analytics.com',
        '*.hotjar.io',
        'ekr.zdassets.com',
        '*.hubspot.com',
        'stats.g.doubleclick.net'
      ]
    }
  });

  private versionNumber = '';
  private userEmail = null;

  constructor(
    private _router: Router,
    private _store: Store<IAppState>
  ) {
    let userState$ = this._store.select(selectAccountStateData);

    userState$.subscribe(userState => {
      this.userEmail = userState?.email;
    });
  }

  shouldEnableCorrelation() {
    return environment.environmentId != EnvironmentId.Local;
  }

  public init(versionInfo: AppVersionInfo) {
    if (!this._appInsights.core.isInitialized()) {
      this._appInsights.loadAppInsights();
      this.setVersionNumber(versionInfo);
      this.recordInitialPageView(versionInfo);

      this._router.events.pipe(filter(event => event instanceof NavigationStart)).subscribe((event: NavigationStart) => {
        this._pageDetails.startLoad();
      });

      this._router.events.pipe(filter(event => event instanceof ResolveEnd)).subscribe((event: ResolveEnd) => {
        this._pageDetails.name = `${this.getRouteTemplate(event.state.root)}`;
      });

      this._router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
        this._pageDetails.stopLoad();
        const uri = event.urlAfterRedirects;
        this.logPageView(this._pageDetails.name, uri, this._pageDetails.getLoadDuraction());
      });

      this._router.events
        .pipe(
          filter(event => event instanceof RoutesRecognized),
          filter((event: RoutesRecognized) => IsCatchAllRedirect(event))
        )
        .subscribe((event: RoutesRecognized) => this.trackEvent('router.unknownRouteRequested', { requestedRoute: event.url, resolvedRoute: event.urlAfterRedirects }));
    }
  }

  setVersionNumber(versionInfo: AppVersionInfo) {
    if (versionInfo !== undefined && versionInfo.version) {
      this.versionNumber = versionInfo.version;
    } else {
      this.versionNumber = 'unknown';
    }
  }

  private recordInitialPageView(versionInfo: AppVersionInfo) {
    let versionNumber = 'unknown';
    if (versionInfo !== undefined) {
      versionNumber = versionInfo.version;
    }

    const telemetryData = {
      properties: { version: versionNumber }
    };
    this._appInsights.trackPageView(telemetryData);
  }

  private logPageView(name?: string, uri?: string, duration?: number) {
    const pageView: IPageViewTelemetry = {
      name: name,
      uri: uri,
      properties: {
        duration: duration
      }
    };
    this.addUserProperties(pageView);
    this._appInsights.trackPageView(pageView);
  }
  trackException(exception: Error) {
    const exceptionTele: IExceptionTelemetry = {
      error: exception
    };
    this._appInsights.trackException(exceptionTele);
  }
  trackRequest(request: HttpRequest<any>, name: string): CallDetails {
    return new CallDetails(request, this, name);
  }

  trackEvent(name: string, properties: any = {}) {
    const event: IEventTelemetry = {
      name: name,
      properties
    };
    this.addUserProperties(event);
    this._appInsights.trackEvent(event);
  }

  trackTelemetricAuthentication(message: string, severityLevel: SeverityLevel) {
    const ttrace: ITraceTelemetry = {
      message: message,
      severityLevel: severityLevel
    };
    this._appInsights.trackTrace(ttrace);
  }

  logCallDetails(callDetails: CallDetails) {
    const telemetry = callDetails.getEventTelemetry();
    this.addUserProperties(telemetry);
    this._appInsights.trackEvent(telemetry);
  }

  private getRouteTemplate(snapshot: ActivatedRouteSnapshot): string {
    let path = '';
    if (snapshot.routeConfig) {
      path += snapshot.routeConfig.path;
    }

    if (snapshot.firstChild) {
      return path + this.getRouteTemplate(snapshot.firstChild);
    }

    return path;
  }

  private addUserProperties(event: IEventTelemetry | IPageViewTelemetry): void {
    if (event.properties != null) {
      event.properties['email'] = this.userEmail;
    } else {
      event.properties = {
        email: this.userEmail
      };
    }

    // Add version number
    event.properties['version'] = this.versionNumber;
  }
}
