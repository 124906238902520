import { ContractType } from '../contracts/contract-type';
import { AssignmentSources } from './submittal-constants';

export class Submittal {
  id: string;
  assignmentSource?: AssignmentSources;
  assnStage: string;
  assnSubstage: string;
  jobOrderId: string;
  profession: string;
  specialty: string;
  workSite?: string;
  workSiteId: string;
  state: string;
  city: string;
  startDate?: Date;
  portalStatus: number;
  jobName: string;
  assnName: string;
  createdOn?: Date;
  shift?: number;
  hoursPerWeek?: number;
  statusLastUpdated?: boolean;
  estimatedFeedback?: Date;
  estimatedFeedback2?: Date;
  estimatedFeedback3?: Date;
  interviewDate?: Date;
  interviewer?: string;
  interviewNotes?: string;
  archived?: string;
  job: SubmittalJob;
  jobOrderInactiveDate?: Date;
  matchingInactiveJobOrder?: string;
  submittalWithdrawnDate?: Date;
  contractType?: ContractType;
  submittalType?: SubmittalType;
  facilityIN?: INFacility;
}

export class SubmittalJob {
  id: string;
  quantityRequired?: number;
  quantityOpen?: number;
  estGrossPay?: any;
  localEstWeeklyGross?: any;
  closed?: boolean;
}

export class DisplaySubmittal extends Submittal {
  jobName: string;
  location: string;
  workSite: string;
}

export enum SubmittalLinks {
  viewSimilarJobs = 'VIEW_SIMILAR_JOBS',
  interview = 'INTERVIEW',
  archive = 'ARCHIVE',
  withdrawApplication = 'WITHDRAW_APPLICATION',
  finishApplication = 'FINISH_APPLICATION',
  deleteApplication = 'DELETE_APPLICATION',
  transferApplication = 'TRANSFER_APPLICATION'
}

export enum SubmittalType {
  Travel = 'TN',
  International = 'IN'
}

export class INAssignment {
  id: string;
  assignmentLength: number;
  assignmentStage: string;
  assignmentSubStage: string;
  assignmentName: string;
  account: string;
  accountId: string;
  candidateId: string;
  startDate?: string;
  endDate?: string;
  unit?: string;
  shift?: number;
  profession?: string;
  speciality?: string;
  subSpecialty?: string;
  modifiedDate: string;
  facility: INFacility;
  job: INJob;
  jobId: string;
  interview?: INInterview;
  portalStatus: number;
  estHoursRemainingToConvert?: number;
  arrivalContractor?: string;
  scheduledEvalAttempt90?: string;
  evaluationAttempt1?: string;
  evaluationAttempt2?: string;
  evaluationAttempt3?: string;
  timeSheetSource?: number;
  workSiteId: string;
}

export class INFacility {
  id: string;
  name: string;
  city: string;
  state: string;
  traumaLevel?: number;
  pediatricTraumaLevel?: number;
  jointCommission: boolean;
  staffedBeds: number;
  teachingFacility: number;
  hospitalType: string;
  emrSystem?: string;
  birthsPerYear?: number;
  surgeriesPerYear?: number;
  erVisitsPerYear?: number;
  region: string;
  awards: string;
  website?: string;
  address: string;
  zipCode: string;
  brochure?: string;
  technologies?: string;
  ownerId: string;
}

export class INJob {
  id: string;
  jobName: string;
}

export class INInterview {
  candidateAccepted?: number;
  method?: number;
  date?: string;
  time?: string;
  timeZone?: number;
  timeFrame?: number;
  completedDate?: string;
  offerExtendedDate?: string;
  confirmationLetterRecievedDate?: string;
}
