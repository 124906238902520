import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { IJobFilterAPI } from 'src/app/common';
import { Recruiter } from 'src/app/common/models/recruiter';
import { IAppState } from 'src/app/store/app/app.state';
import { selectJobFilterV2 } from 'src/app/store/jobs/jobs.selectors';
import { selectRecruiterData } from 'src/app/store/recruiter/recruiter.selectors';

@Component({
  selector: 'app-job-search-layout',
  templateUrl: './job-search-layout.component.html',
  styleUrls: ['./job-search-layout.component.scss']
})
export class JobSearchLayoutComponent {
  recruiter$: Observable<Recruiter> = this._store.select(selectRecruiterData);

  jobFilter$: Observable<IJobFilterAPI> = this._store.select(selectJobFilterV2);

  constructor(private _store: Store<IAppState>) {}
}
