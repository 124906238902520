<ng-container
  *ngIf="{
    recruiter: recruiter$ | async,
    isMobile: isMobile$ | async,
    jobFilter: jobFilter$ | async,
    jobResults: availableJobs$ | async,
    jobResultsLoading: availableJobsLoading$ | async,
    similarJobs: similarJobs$ | async,
    similarJobsLoading: similarJobsLoading$ | async
  } as obs"
>
  <div class="job-search-results">
    <div class="jobs-recommended" *ngIf="!obs.jobFilter || obs.jobFilter.backToJobSearch">
      <div class="scrollable-results" [class.extra-padding]="(recruiter$ | async)?.userId">
        <app-recommended-jobs-carousel [showCardsAsColumn]="!obs.isMobile" [showCounter]="obs.isMobile"></app-recommended-jobs-carousel>
      </div>
    </div>
    <div class="search-results" *ngIf="obs.jobFilter && !obs.jobFilter.backToJobSearch">
      <div class="search-results-buttons">
        <div class="sort-by-container" *ngIf="jobSearchResults?.length">
          <hc-body [size]="small">Sort by</hc-body>
          <button mat-button [matMenuTriggerFor]="menu" class="sort-by-button">
            {{ sortByButtonTitle(obs.jobFilter) }}
            <mat-icon>expand_more</mat-icon>
          </button>
          <mat-menu #menu="matMenu" xPosition="before">
            <button mat-menu-item (click)="setOrderBy(obs.jobFilter, HIGHEST_PAY)" class="sort-by-option-button">
              Highest Pay
              <mat-icon *ngIf="obs.jobFilter?.orderBy === HIGHEST_PAY" class="sort-by-check">check </mat-icon>
            </button>
            <button mat-menu-item (click)="setOrderBy(obs.jobFilter, MOST_RECENT)" class="sort-by-option-button">
              Most Recent
              <mat-icon *ngIf="obs.jobFilter?.orderBy === MOST_RECENT" class="sort-by-check">check </mat-icon>
            </button>
            <button mat-menu-item (click)="setOrderBy(obs.jobFilter, START_DATE)" class="sort-by-option-button">
              Start Date
              <mat-icon *ngIf="obs.jobFilter?.orderBy === START_DATE" class="sort-by-check">check </mat-icon>
            </button>
          </mat-menu>
        </div>
      </div>

      <div class="scrollable-results" [class.scrollable-results-similar-jobs]="!obs.jobResults?.jobs?.length" [class.extra-padding]="(recruiter$ | async)?.userId">
        <ng-container *ngIf="obs.jobResultsLoading && obs.jobFilter?.reload; else jobResults">
          <ng-container *ngTemplateOutlet="loadingTemplate"></ng-container>
        </ng-container>

        <ng-template #jobResults>
          <ng-container *ngIf="jobSearchResults?.length; else similarJobs">
            <div *ngFor="let job of jobSearchResults; index as i">
              <app-job-card
                [id]="job.id"
                [job]="job"
                [jobConfig]="jobConfig"
                (jobSelected)="viewSpecificJob($event, obs.jobFilter)"
                (toggleJobSave)="updateSaveJob($event)"
              ></app-job-card>
              <div *ngIf="i === 4 && (candidateFacilitiesCount$ | async) > 0" class="facility-carousel">
                <ng-container [ngTemplateOutlet]="facilityCarousel"></ng-container>
              </div>
            </div>
            <div *ngIf="jobSearchResults?.length && jobSearchResults?.length < 5 && (candidateFacilitiesCount$ | async) > 0">
              <ng-container [ngTemplateOutlet]="facilityCarousel"></ng-container>
            </div>
            <ng-container *ngIf="obs.jobResultsLoading">
              <ng-container *ngTemplateOutlet="loadingTemplate"></ng-container>
            </ng-container>
            <div *ngIf="moreJobsToLoad" class="load-jobs-button-section">
              <hc-button id="loadMoreJobsButton" (buttonClick)="loadMoreJobs(obs.jobFilter)" [disabled]="obs.jobResultsLoading"> Load more </hc-button>
            </div>
          </ng-container>

          <ng-template #similarJobs>
            <div class="no-results-container">
              <app-facilities-carousel [successful]="false" style="width: 100%"></app-facilities-carousel>
              <ng-container *ngIf="obs.similarJobsLoading; else similarJobsResultsTemplate">
                <ng-container *ngTemplateOutlet="loadingTemplate"></ng-container>
              </ng-container>

              <ng-template #similarJobsResultsTemplate>
                <div class="similar-jobs-container" *ngIf="similarJobsResults?.length">
                  <div class="similar-jobs-title">
                    <hc-heading [size]="h5">Similar jobs available</hc-heading>
                    <div class="similar-jobs-description">
                      <hc-body> Curated picks based on your profile and searches </hc-body>
                      <hc-icon-prompt-button icon="lightbulb" (promptClick)="showRecommendationDialog()" segmentTrackCTA ctaText="Learn how we make suggestions">
                        Learn how we make suggestions
                      </hc-icon-prompt-button>
                    </div>
                  </div>

                  <ng-container *ngIf="obs.isMobile; else similarJobsColumn">
                    <hc-card-carousel-v2
                      [isLoading]="obs.similarJobsLoading"
                      [skeletonCardsCount]="3"
                      [cardData]="similarJobsCardConfigs"
                      (cardClicked)="viewSpecificJobCarousel($event, obs.jobFilter)"
                      (cardActionClicked)="updateSaveJobCarousel($event, similarJobsResults)"
                      [grid]="grid"
                      [gridBreakpoints]="gridBreakpoints"
                    ></hc-card-carousel-v2>
                  </ng-container>

                  <ng-template #similarJobsColumn>
                    <div *ngFor="let job of similarJobsResults">
                      <app-job-card
                        [id]="job.id"
                        [job]="job"
                        [jobConfig]="jobConfig"
                        (jobSelected)="viewSpecificJob($event, obs.jobFilter)"
                        (toggleJobSave)="updateSaveJob($event)"
                      ></app-job-card>
                    </div>
                  </ng-template>
                </div>
              </ng-template>
            </div>
          </ng-template>
        </ng-template>
      </div>
    </div>
  </div>

  <ng-template #loadingTemplate>
    <hc-job-card [isLoading]="true"></hc-job-card>
    <hc-job-card [isLoading]="true"></hc-job-card>
    <hc-job-card [isLoading]="true"></hc-job-card>
  </ng-template>

  <ng-template #recommendedDialogTemplate>
    <app-recommended-jobs-popup></app-recommended-jobs-popup>
  </ng-template>
</ng-container>

<ng-template #facilityCarousel>
  <app-facilities-carousel></app-facilities-carousel>
</ng-template>
