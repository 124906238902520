<div id="main-job-area-redesign">
  <mat-tab-group
    mat-stretch-tabs
    class="fill"
    [selectedIndex]="index"
    (wheel)="scrollTabs($event)"
    #tabGroup
    (selectedTabChange)="onLinkClick($event)"
    [class.extra-padding]="(recruiter$ | async)?.userId"
  >
    <mat-tab label="Postings">
      <app-job-search-layout></app-job-search-layout>
    </mat-tab>

    <mat-tab label="Favorites">
      <ng-template #favoritesTab matTabContent>
        <app-saved-jobs fxFlex [completedProfile]="profile?.length === 0" [setTabIndex]="setTabIndex"></app-saved-jobs>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>

<app-contact-recruiter-tray [contactRecruiterType]="contactRecruiterType"></app-contact-recruiter-tray>
