import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { CardElevation, DialogService, IDialogParameters } from 'hc-design-system-lib';
import { ICardConfig, ITileCardConfig } from 'hc-design-system-lib/lib/components/cards/cards.interfaces';
import { NavHelper } from 'src/app/services';
import { HcEvent } from 'hc-design-system-lib/lib/models/hc-event';
import { Observable, Subject, combineLatest } from 'rxjs';
import { skipWhile, map, filter, take, tap } from 'rxjs/operators';
import { NurseModel, IJob, ILookup } from 'src/app/common';
import { IAppState } from 'src/app/store/app/app.state';
import { GetRecommendedJobsByPreference, SetSavedRecommendedJob } from 'src/app/store/jobs/jobs.actions';
import { selectRecommendedJobsByPreference, selectRecommendedJobsByPreferenceLoading, selectSavedJobs } from 'src/app/store/jobs/jobs.selectors';
import {
  selectYesNoOnlyLookup,
  selectYesNoLookup,
  selectSpecialtyLookup,
  selectProfessionLookup,
  selectStateLookup,
  selectLookupsLoading
} from 'src/app/store/lookups/lookups.selectors';
import { selectNurseData } from 'src/app/store/userContext/userContext.selectors';
import { TRAVEL_BADGE } from 'src/app/common/models/badge';
import { mapJobToCardConfig } from '../../job-card-utils/job-card-utils';
import { ContractType } from 'src/app/common/contracts/contract-type';
import { JobDataModel } from 'src/app/common/models/job-data-model';

@Component({
  selector: 'app-recommended-jobs-tile',
  templateUrl: './recommended-jobs-tile.component.html',
  styleUrls: ['./recommended-jobs-tile.component.scss']
})
export class RecommendedJobsTileComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();
  @ViewChild('jobCardCarousel')
  jobCardCarousel: TemplateRef<any>;
  @ViewChild('recommendedDialogTemplate')
  recommendedDialogTemplate: TemplateRef<any>;

  cardConfig: ITileCardConfig = {
    title: 'Recommended For You',
    bodyText: 'Curated picks based on your profile and searches',
    promptText: 'Learn how we make suggestions'
  };

  yesNoOnlyLookup$ = this._store.select(selectYesNoOnlyLookup);
  yesNoLookup$ = this._store.select(selectYesNoLookup);
  specialtyLookup$ = this._store.select(selectSpecialtyLookup);
  professionalLookup$ = this._store.select(selectProfessionLookup);
  stateLookup$ = this._store.select(selectStateLookup);
  lookupsLoading$ = this._store.select(selectLookupsLoading);
  savedJobs$ = this._store.select(selectSavedJobs);

  nurse$: Observable<NurseModel> = this._store.select(selectNurseData);
  recommendedJobsByPreference$: Observable<IJob[]> = this._store.select(selectRecommendedJobsByPreference);
  recommendedJobsByPreferenceLoading$: Observable<boolean> = this._store.select(selectRecommendedJobsByPreferenceLoading);

  recommendedJobCardConfigs$: Observable<ICardConfig[]> = combineLatest([
    this.nurse$,
    this.recommendedJobsByPreference$,
    this.yesNoOnlyLookup$,
    this.yesNoLookup$,
    this.specialtyLookup$,
    this.professionalLookup$,
    this.stateLookup$,
    this.lookupsLoading$,
    this.savedJobs$
  ]).pipe(
    skipWhile(
      ([nurse, jobs, yesNoOnlyLookup, yesNoLookup, specialtyLookup, professionLookup, stateLookup, loading, ,]: [
        NurseModel,
        IJob[],
        Map<string, ILookup<string>>,
        Map<string, ILookup<string>>,
        Map<string, ILookup<string>>,
        Map<string, ILookup<string>>,
        Map<string, ILookup<string>>,
        boolean,
        JobDataModel
      ]) => nurse && !jobs?.length && loading && !!yesNoOnlyLookup && !yesNoLookup && !specialtyLookup && !professionLookup && !!stateLookup
    ),
    map(([, jobs, , yesNoLookup, specialtyLookup, professionLookup, stateLookup, , savedJobs]) =>
      this.mapJobsToCardConfigs(jobs, yesNoLookup, specialtyLookup, professionLookup, stateLookup, savedJobs)
    )
  );

  constructor(
    private _store: Store<IAppState>,
    private _navHelper: NavHelper,
    private _dialogService: DialogService
  ) {}

  ngOnInit(): void {
    this._store.dispatch(new GetRecommendedJobsByPreference());
  }
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  mapJobsToCardConfigs(
    jobs: IJob[],
    yesNoLookup: Map<string, ILookup<string>>,
    specialtyLookup: Map<string, ILookup<string>>,
    professionLookup: Map<string, ILookup<string>>,
    stateLookup: Map<string, ILookup<string>>,
    savedJobs: JobDataModel
  ): ICardConfig[] {
    const jobCardConfigs: ICardConfig[] = [];

    if (jobs?.length > 0) {
      jobs.forEach((job: IJob) => {
        let saved = savedJobs?.jobs.some(savedJob => savedJob.id === job.id && savedJob.contractType === ContractType.Travel);

        jobCardConfigs.push(
          mapJobToCardConfig({ ...job, contractType: ContractType.Travel, saved }, { yesNoLookup, specialtyLookup, professionLookup, stateLookup }, [], TRAVEL_BADGE)
        );
      });
    }

    return jobCardConfigs;
  }

  jobCardClicked(event: HcEvent, recommended = true): void {
    const jobData = event?.data?.jobCardData?.jobData;
    const cardIndex = event?.data?.jobCardData?.cardIndex;
    const jobId = jobData['jobId'] || null;

    this.recommendedJobCardConfigs$
      .pipe(
        filter(jobDetails => !!jobDetails),
        take(1),
        tap(jobDetails => {
          const contractType = jobDetails[cardIndex].primaryBadge?.title as ContractType;
          if (jobId) {
            this._navHelper.goToJobsSpecificCustomParams(jobId, {
              recommendedJob: recommended,
              contractType
            });
          }
        })
      )
      .subscribe();
  }

  toggleSavedJob(event: HcEvent): void {
    const jobEventInfo = event?.data?.jobCardData?.jobData;
    const clickedJobId = jobEventInfo['jobId'] || null;
    const contractType = jobEventInfo['contractType'];
    const cardIndex = event?.data?.jobCardData?.cardIndex;

    combineLatest([this.recommendedJobCardConfigs$, this.recommendedJobsByPreference$])
      .pipe(
        filter(([jobDetails, recommendedJobs]) => !!jobDetails && !!recommendedJobs),
        take(1),
        tap(([jobDetails, recommendedJobs]) => {
          const saveValue = !jobDetails[cardIndex].isCardActionActive;
          const job = recommendedJobs.find((job: IJob) => job.id === clickedJobId);
          this._store.dispatch(new SetSavedRecommendedJob({ job, saveValue, contractType }));
        })
      )
      .subscribe();
  }

  showRecommendationDialog(): void {
    const dialogData: IDialogParameters = {
      title: 'Job Recommendations',
      text: '',
      showCloseIcon: true,
      elevation: CardElevation.Default,
      icon: undefined,
      template: this.recommendedDialogTemplate
    };
    this._dialogService.showDialog(dialogData);
  }
}
